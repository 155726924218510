import styled from "styled-components";

export const Dados = styled.div`
	    /* max-height: 395px;
    overflow-y: scroll; */
    .container-dados{
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        @media (max-width: 768px) { 
            flex-direction: column;
         }
    }
.title{
    position: absolute;
    top:-60px;
}
.flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
}

.single-chart {
    width: 33%;
    /* padding: 30px; */
    justify-content: space-around;
    position: relative;
    @media (max-width: 768px) {    
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
         }
}

.circular-chart {
    display: block;
    margin: 0;
    width: 199px;
    height: 199px;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    /* stroke-linecap: round; */
    stroke-linecap: square;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.circular-chart.orange .circle {
    stroke: #9ED530;
}

.percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
}
@keyframes pulse {
    0% {
        background-color: rgba(255, 0, 0, 0.5);
        transform: scale(1);
    }

    100% {
        background-color: #FFFFFF;
        transform: scale(1.2);

    }
}
.marcadores-mes{
    .container-legenda{
        margin-top: 30px;
        font-size: 10px;
        justify-content: space-around;
    }
    .quadradinho {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
      border-radius: 10px;
      position: relative;
      .indicador-mes{
        position: absolute;
        font-size: 9px;
        top: -12px;
        background-color: #9ED530;
        border-radius: 15px;
        width: 26px;
        text-align: center;
        right: -7px;
        border: 1px solid #fff;
      }
    }

    /* Estilo para os quadradinhos verdes */
    .quadradinho.verde {
      background-color: #9ED530;
      color: #ffffff;
    }

    /* Estilo para os quadradinhos vermelhos */
    .quadradinho.vermelho {
      background-color: #F24404;
      color: #ffffff;
    }

    /* Estilo para os quadradinhos cinzas */
    .quadradinho.cinza {
      background-color: #EFEEED;
      color: #ffff;
    }
    
    /* Estilo para o container que agrupa os quadradinhos */
    .container {
      display: grid;
      grid-template-columns: repeat(4, 60px);
      grid-template-rows: repeat(3, 60px);
      gap: 20px;
    }
    .quadrado {
      width: 10px;
      height: 10px;
      display: inline-block;
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 10px;
    }

    /* Estilo para o quadrado vermelho */
    .quadrado.vermelho {
      background-color: #F24404;
      color: #ffffff;
    }

    /* Estilo para o quadrado verde */
    .quadrado.verde {
      background-color: #9ED530;
      color: #ffffff;
    }

    /* Estilo para o quadrado cinza */
    .quadrado.cinza {
      background-color: #EFEEED;
      color: #000000;
    }
}


`;
