import { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container, Fade } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Body } from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import * as yup from "yup";
//import MaskedInput from "react-maskedinput";
//import styled from "styled-components";
import iconCadeado from "../../images/img/icon-cadeado.svg";
import iconChecked from "../../images/img/icon-checked.svg";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

const sitekey = "6Ld482QbAAAAAM8iW9B-FKXtMj8Hde-TTzj62-PP";

export function RenovarSenha() {
  let navigate = useNavigate();
  const [escolhaForm, setEscolhaForm] = useState("");
  const [passo, setPasso] = useState(1);
  const [reenviaCodigo, setReenviaCodigo] = useState(false);
  const [segundos, setSegundos] = useState(0);
  const [minutos, setMinutos] = useState(5);
  const [tempoCodigo, setTempoCodigo] = useState("00");
  const [cpf, setCpf] = useState("");
  const [modalErro, setModalErro] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");
  const [codigo, setCodigo] = useState("");

  const [emailForm, setEmailForm] = useState("");
  const [telForm, setTelForm] = useState("");
  const [emailFinal, setEmailFinal] = useState();
  const [limparStore, setLimparStore] = useState(true);

  useEffect(() => {
    if (limparStore) {
      setLimparStore(false);
      setPasso(1);
      setTempoCodigo("00");
      setMinutos(4);
      setSegundos(59);
    }
  }, [limparStore]);

  const zero = "0";

  useEffect(() => {
    if (reenviaCodigo) {
      setTimeout(() => {
        if (segundos < 10) {
          if (segundos === 0) {
            setSegundos(59);
            if (minutos > 0) {
              setMinutos(minutos - 1);
            }
          } else {
            setSegundos(segundos - 1);
          }
          setTempoCodigo(zero + segundos);
        } else {
          setSegundos(segundos - 1);
          setTempoCodigo(segundos);
        }
      }, 1000);

      if (minutos === 0 && segundos === 0) {
        setReenviaCodigo(false);
      }
    }
  }, [segundos, reenviaCodigo, minutos, zero]);

  const handleLoginCpf = (values) => {
    console.log("valores", values);
    setCpf(values.cpf);

    api
      .get(rotasApi.obterDadosCadastrais + values.cpf, {
        headers: {
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (result.data.dadosCadastrais.email) {
            setEmailForm(`Email: ${result.data.dadosCadastrais.email}`);
            if (result.data.dadosCadastrais.celular) {
              setTelForm(`SMS: ${result.data.dadosCadastrais.celular}`);
            }

            let emailArray = result.data.dadosCadastrais.email.split("@");

            setEmailFinal("@" + emailArray[1]);
            if (passo === 1) {
              setPasso(passo + 1);
            }
          } else {
            setModalErro(true);
            setModalMensagem("Não foi possivel obter o Email e Celular");

            setTimeout(() => {
              setModalErro(false);
            }, 5000);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  };

  const handleLoginEscolha = (values) => {
    console.log("Escolha", values);
    console.log("CPF >", cpf);
    values.cpf = cpf;
    setEscolhaForm(values.escolha);
    setReenviaCodigo(true);

    const formData = new FormData();
    formData.append("Cpf", cpf);

    if (values.escolha === "email") {
      formData.append("EnviarPorEmail", true);
      formData.append("EnviarPorSMS", false);
    } else {
      formData.append("EnviarPorEmail", false);
      formData.append("EnviarPorSMS", true);
    }

    api
      .post(rotasApi.codigoAcessoRedefinicaoSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (passo === 2) {
            setPasso(passo + 1);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  };

  const handleLoginCodigo = (values) => {
    console.log("codigo > ", values);
    setCodigo(values.codigo.toUpperCase());

    const formData = new FormData();
    formData.append("Cpf", cpf);
    formData.append("CodigoAcesso", values.codigo.toUpperCase());

    api
      .post(rotasApi.codigoAcessoEsqueceuSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (passo === 3) {
            setPasso(passo + 1);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  };

  const handleLoginNovaSenha = (values) => {
    console.log("codigo > ", values);
    // setModalControl(true);

    const formData = new FormData();
    formData.append("Cpf", cpf);
    formData.append("Senha", values.senha);
    formData.append("Codigo", codigo);

    api
      .post(rotasApi.redefinicaoSenha, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (passo === 4) {
            setPasso(passo + 1);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  };

  const ativaBtn = () => {
    setPasso(2);
    setReenviaCodigo(true);
    setTempoCodigo("00");
    setMinutos(4);
    setSegundos(59);
  };

  const Cancelar = () => {
    console.log("asdf");
    navigate("/");
  };

  useEffect(() => {
    setPasso(1);
  }, []);

  const validationSchemaPassword = yup.object().shape({
    senha: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(/^(?=.*?[A-Z])(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/, {
        message: "Senha inválida",
        excludeEmptyString: true,
      })
      .required("Senha é obrigatório"),
    confirmaSenha: yup.string().when("senha", (value, schema) => {
      return schema
        .oneOf([yup.ref("senha")], "As senhas devem ser iguais")
        .required("Confirmar Senha é obrigatório");
    }),
  });

  const validationSchemaCpf = yup.object().shape({
    cpf: yup
      .string()
      .min(11, "CPF tem 11 digitos")
      .max(11, "CPF tem 11 digitos")
      .matches(/^[0-9]{11}$/, "somente números")
      .required("CPF é obrigatório"),
    recaptcha: yup.string().required(),
  });

  const validationSchemaCodigo = yup.object().shape({
    codigo: yup
      .string()
      .min(6, "Código deve ser preenchido")
      .required("Código é obrigatório"),
  });

  const validationSchemaEscolha = yup.object().shape({
    escolha: yup.string().required("Escolha uma opção"),
  });

  return (
    <Body className="d-flex justify-content-center align-items-center">
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <Fade in={modalErro}>
              <Card
                className="text-center p-3 m-2 position-absolute z-index-top"
                bg="danger"
                text="white"
              >
                <Card.Body>
                  <Card.Title as="h2">Erro</Card.Title>
                  <Card.Text>{modalMensagem}</Card.Text>
                </Card.Body>
              </Card>
            </Fade>
          </Col>
        </Row>
        {passo === 1 ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5 ">
                <Card.Body className="body-card">
                  <Card.Title className="text-center" as="h5">
                    Renove sua senha
                  </Card.Title>
                  <Card.Text className="text-center">
                    Informe o CPF cadastrado para receber as instruções
                    necessárias.
                  </Card.Text>
                  <Formik
                    initialValues={{ cpf: "" }}
                    onSubmit={(values) => {
                      handleLoginCpf(values);
                    }}
                    validationSchema={validationSchemaCpf}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      setFieldValue,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>CPF</Form.Label>
                          <Form.Control
                            type="text"
                            maxLength="11"
                            name="cpf"
                            value={values.cpf}
                            onChange={handleChange}
                            isInvalid={!!errors.cpf}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpf}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                          <ReCAPTCHA
                            sitekey={sitekey}
                            onChange={(e) => {
                              setFieldValue("recaptcha", e);
                            }}
                          />
                        </div>
                        <Button
                          type="submit"
                          className="w-100 mt-4 btn-laranja"
                          disabled={!isValid || !dirty}
                        >
                          Enviar
                        </Button>

                        <Link
                          className="btn btn-laranja-outline w-100 mt-3"
                          to="/"
                        >
                          Voltar
                        </Link>
                      </Form>
                    )}
                  </Formik>
                  <Card.Text className="text-center small mt-4">
                    Já possuí cadastro?{" "}
                    <Link to="/" className="link-secondary">
                      Entrar
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : passo === 2 ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                <Card.Body className="body-card">
                  <Card.Text className="text-center icon-size">
                    <img src={iconCadeado} alt="" />
                  </Card.Text>
                  <Card.Title className="text-center" as="h5">
                    Quase lá
                  </Card.Title>
                  <Card.Text className="text-center">
                    Para sua segurança vamos enviar um código escolha como
                    prefere receber:
                  </Card.Text>
                  <Formik
                    initialValues={{ escolha: "" }}
                    onSubmit={(values) => {
                      handleLoginEscolha(values);
                    }}
                    validationSchema={validationSchemaEscolha}
                  >
                    {({
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Check
                            type="radio"
                            label={
                              emailForm.slice(0, 10).padEnd(18, "*") +
                              emailFinal
                            }
                            onChange={handleChange}
                            value="email"
                            name="escolha"
                            id="1"
                          />
                          {telForm && (
                            <Form.Check
                              type="radio"
                              label={
                                telForm.slice(0, 9).padEnd(15, "*") +
                                "-" +
                                telForm.slice(-4)
                              }
                              onChange={handleChange}
                              value="phone"
                              name="escolha"
                              id="2"
                            />
                          )}

                          <Form.Control.Feedback type="invalid">
                            {errors.escolha}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                          type="submit"
                          className="w-100 mt-4 btn-laranja"
                          disabled={!isValid || !dirty}
                        >
                          Enviar
                        </Button>
                        <Button
                          className="btn-laranja-outline w-100 mt-3"
                          onClick={Cancelar}
                        >
                          Cancelar
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : passo === 3 ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                <Card.Body className="body-card">
                  <Card.Text className="text-center icon-size">
                    <img src={iconCadeado} alt="" />
                  </Card.Text>
                  <Card.Title className="text-center" as="h5">
                    Código enviado com sucesso!
                  </Card.Title>
                  <Card.Text className="text-center">
                    Código enviado para:{" "}
                    <span className="fw-bold">
                      {escolhaForm === "email"
                        ? emailForm
                          .slice(0, 10)
                          .padEnd(18, "*")
                          .replace("Email:", "") + emailFinal
                        : telForm
                          .slice(0, 9)
                          .padEnd(15, "*")
                          .replace("SMS:", "") +
                        "-" +
                        telForm.slice(-4)}
                    </span>
                  </Card.Text>
                  <Card.Text className="text-center">
                    Caso não tenha recebido dentro de{" "}
                    <span className="fw-bold">
                      {minutos}:{tempoCodigo} minutos
                    </span>
                    , tente novamente.
                  </Card.Text>
                  <Card.Text className="text-center">
                    <span className="fw-bold">
                      Digite abaixo o código que você recebeu!
                    </span>
                  </Card.Text>
                  <Formik
                    initialValues={{ codigo: "" }}
                    onSubmit={(values) => handleLoginCodigo(values)}
                    validationSchema={validationSchemaCodigo}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Código</Form.Label>
                          <Form.Control
                            className="text-uppercase"
                            maxLength="6"
                            type="text"
                            name="codigo"
                            value={values.codigo}
                            onChange={handleChange}
                            isInvalid={!!errors.codigo}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.codigo}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Card.Text className="text-center small">
                          Caso não tenha recebido, clique em reenviar.
                        </Card.Text>

                        <Button
                          type="submit"
                          className="w-100 mt-4 btn-laranja"
                          disabled={!isValid || !dirty}
                        >
                          Enviar
                        </Button>

                        <Button
                          className="w-100 mt-2 btn-laranja-outline"
                          disabled={reenviaCodigo}
                          onClick={ativaBtn}
                          variant="secondary-outline"
                        >
                          Reenviar
                        </Button>

                        <Link
                          className="btn btn-outline-secondary w-100 mt-4"
                          to="/"
                        >
                          Cancelar
                        </Link>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : passo === 4 ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                <Card.Body className="body-card">
                  <Card.Title className="text-center" as="h5">
                    Crie uma nova senha
                  </Card.Title>
                  <Formik
                    initialValues={{ senha: "", confirmaSenha: "" }}
                    onSubmit={(values) => handleLoginNovaSenha(values)}
                    validationSchema={validationSchemaPassword}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                          <Form.Label className="label-input">Senha</Form.Label>
                          <Form.Control
                            type="password"
                            name="senha"
                            value={values.senha}
                            onChange={handleChange}
                            isInvalid={!!errors.senha}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.senha}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="label-input">
                            Confirma Senha
                          </Form.Label>
                          <Form.Control
                            type="password"
                            name="confirmaSenha"
                            value={values.confirmaSenha}
                            onChange={handleChange}
                            isInvalid={!!errors.confirmaSenha}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmaSenha}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Card.Text className="small">
                          A senha precisa conter no mínimo 8 caracteres, uma
                          letra maiúscula, uma letra minúscula, um número e um caractere especial.
                          (ex.: @, !, ?, %).
                        </Card.Text>

                        <Button
                          type="submit"
                          className="w-100 mt-4 btn-laranja"
                          disabled={!isValid || !dirty}
                        >
                          Salvar
                        </Button>
                        <Link
                          className="btn btn-laranja-outline w-100 mt-3"
                          to="/"
                        >
                          Cancelar
                        </Link>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : passo === 5 ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                <Card.Body className="body-card">
                  <Card.Text className="text-center icon-size">
                    <img src={iconChecked} alt="" />
                  </Card.Text>
                  <Card.Title className="text-center" as="h5">
                    Parabéns Senha redefinida com sucesso
                  </Card.Title>

                  <Link
                    className="btn btn-secondary w-100 mt-3 btn-laranja"
                    to="/"
                  >
                    Ir para Login
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className=" text-center">
          <Col>
            <div className="d-flex justify-content-center mt-1">
              <div className="info-telefone text-center">
                <p className="my-0 strong">Central de Atendimento +top</p>
                <h5 className="my-0">0800 780 0606</h5>
                <p className="my-1 font-weight-light ">2ª a 6ª Feira (exceto feriados)</p>
                <p className="my-1 strong">Atendimento Telefônico das 10h às 16h | WhatsApp das 9h às 18h</p>
                <p className="my-0">atendimento@programamaistop.com.br</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}
