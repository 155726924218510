import styled from "styled-components";

export const Home = styled.div`
	background: #efeeed;
  .btn-historico,.btn-solicitar{
    height: 50px;
  }
  .background{
    background: #fff;
  }
  .card-item-produto{
    align-items: start;
    width: 100%;
    padding: 10px 25px;
    border-radius: 7px;
    height: 100px;
    align-items: center;
    display: flex;
    background: #fff;
    @media (max-width: 768px) { 
        display: none;
    }
    span{
      margin-left: 20px;
      font-weight: bold;
    }
  }
  .card-item-produto-mobile{
    width: 100%;
    padding: 10px 25px;
    border-radius: 7px;
    height: 100px;
    align-items: center;
    display: flex;
    background: #fff;
    justify-content: center;
    margin-bottom: 20px;
    display: none;
    @media (max-width: 768px) { 
      display: flex;
    }
    span{
      margin-left: 20px;
      font-weight: bold;
    }
  }
  .tabela-items{
    display: flex;
    font-size: 13px;
    @media (max-width: 768px) { 
        flex-direction: column;
    }
    thead{
      background: #7032D1;
      height: 74px;
      color: #fff;
    }
    tr{
      height: 91px;
    }
    .tabela-container{
      overflow-y: scroll;
      height: 300px;
      background-color: #fff;
      @media (max-width: 768px) { 
        overflow-x: scroll;
        align-self: center;
        margin-right: 0rem!important;
      }
      .botoes-quantidade{
        display: flex;
        justify-content: center;
        button{
          width: 25px;
          height: 26px;
          border-radius: 27px;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
      .image-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        }
    }
    
  }
  .dados-pedido{
    background: #fff;
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;
    thead{
      height: 44px;
      border-bottom:1px solid #cdcdcd;
    }
    tr{
      height: 41px;
    }
  }
  .container-info-entregas{
    background: #fff;
    border-radius: 7px;
    padding-bottom: 150px;
    p{
      margin: 0;
    }
    .botoes-acao{
      position: absolute;
      right: 0;
      bottom: -123px;
      display: flex;
        @media (max-width: 768px) { 
          justify-content: center;
        } 
    }
    @media (max-width: 768px) { 
      flex-direction: column;
      }
  }
  .container-dados-pedido{
    background: #fff;
    border-radius: 8px;
    @media (max-width: 768px) { 
      overflow-x: auto;
      }
  }
  .imagem-ampliada-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        /* img{
          width: 50vw;
          max-height: 50vh;
        } */
  }
`;

export const ModalSucesso = styled.div`
    padding-bottom: 40px;
  .modal-sucesso {
    width: 552px;
    max-width: 250px;
    height: 100%;
    max-height: 458px;
    margin: 0 auto;
    .img-sucesso {
        margin-top: 40px;

    }
    .h2-sucesso {
      font-size: 24px;
      line-height: 32px;
      padding-top: 15px;
      padding-bottom: 11px;
      color: #313131;

    }
    .mensagem-sucesso {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 52px;
    }
  }
`;
