import styled from "styled-components";

export const Dados = styled.div`
  position: relative;
    .container-dados{
      justify-content: space-around;
      display: flex;
      .title-marcador{
        @media (max-width: 768px) { 
          font-size: 11px;
          margin-right: 1;
         }   
      }
    }
    .filtros{
      position: absolute;
      right: 1px;
      z-index: 1;
      top: 19px;
    }
    .indicadores-meses{
      justify-content: center;
      display: flex;
    }
    
      .quadrado{
            width: 20%;
            height: 35px;
            margin-right: 8px; /* Distância entre os quadrados */
            display: flex; /* Exibe os quadrados como flex container */
            align-items: center; /* Centraliza verticalmente o conteúdo */
            justify-content: center; /* Centraliza horizontalmente o conteúdo */
            background-color: #c4c4c4; /* Fundo padrão */
            color: #cdcdcd; /* Cor do texto padrão */
            border-radius: 3px;
            font-size: 13px;
            @media (max-width: 768px) { 
            display: none;
         }
            /* position: absolute; */
        }

        /* Estilo para o primeiro quadrado */
        .quadrado-1 {
            /* background-color: #9ED530; */
            color: #fff;
        }

        /* Estilo para o segundo quadrado */
        .quadrado-2 {
            /* background-color: #c4c4c4; */
            color: grey;
        }
        .active{
          background-color: #9ED530;
        }
        .inactive{
          background-color: #c4c4c4;
        }
`;

export const Marcador = styled.div`
    .check{
        width: 3%;
        @media (max-width: 768px) { 
          margin-right: 0.2rem!important;
          margin-left: 0.2rem!important;
          width: 4.1%;
         }
      }
      .space{
        margin-right: 3rem!important;
        @media (max-width: 768px) { 
          margin-right: 1rem!important;
        }
      }
    .Explorador{
      margin-left: 15px;
      position: absolute;
      top: -45px;
      left: -23px;
    }
    .Visionario{
      position: absolute;
      top: -85px;
      left: 132px;
    }
    .Realizador{
      position: absolute;
      top: -125px;
      left: 273px;
    }
    .Conquistador{
      position: absolute;
      top: -165px;
      left: 415px;
    }
`;
