import { Col, Row, Container, Button, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { HistoricoMensagensStyle } from "./styles";
import { useState, useEffect } from "react";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
//   import iconAnexo from '../../images/img/icon-anexo.svg'

export function HistoricoMensagens({ user }) {
  let navigate = useNavigate();

  const [chamados, setChamados] = useState([]);
  const [chamadosAguardandoCliente, setChamadosAguardandoCliente] = useState([]);
  const [chamadosAndamentos, setChamadosAndamentos] = useState([]);

  let [botaoFiltro, setBotaoFiltro] = useState(1);

  const buscaChamados = () => {
    let status = botaoFiltro === 1 ? "ABERTO" : "ENCERRADO";
    if (botaoFiltro !== 1 ){
      setChamados([]);
      setChamadosAguardandoCliente([]);
      setChamadosAndamentos([]);
    }
    api
      .get(rotasApi.buscarChamados + `?status=${status}`)
      .then((result) => {
        setChamados(result.data.ocorrencias.reverse());
        // eslint-disable-next-line no-unused-vars
        let teste = botaoFiltro === 1 ? buscaChamadosAndamento() : ''
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const buscaChamadosAndamento = () => {

    let status = "ANDAMENTO"
    api
      .get(rotasApi.buscarChamados + `?status=${status}`)
      .then((result) => {
        setChamadosAguardandoCliente(result.data.ocorrencias.reverse());
        buscaChamadosAguardandoPaticipante();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const buscaChamadosAguardandoPaticipante = () => {

    let status = "AGUARDANDO_PARTICIPANTE"
    api
      .get(rotasApi.buscarChamados + `?status=${status}`)
      .then((result) => {
        setChamadosAndamentos(result.data.ocorrencias.reverse());
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
    
  const convertDate = (date) => {
    var convertdate = new Date(date);

    var ddconvertdate = String(convertdate.getDate()).padStart(2, "0");
    var mmconvertdate = String(convertdate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyyconvertdate = String(convertdate.getFullYear()).padStart(4, "0");

    convertdate = ddconvertdate + "/" + mmconvertdate + "/" + yyyyconvertdate;

    return convertdate;
  };

  // buscaChamados();

  useEffect(() => {
    buscaChamados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botaoFiltro]);

  function mudarBotao(num) {
    setBotaoFiltro((botaoFiltro = num));
  }

  return (
    <HistoricoMensagensStyle className="historico-mensagens-blackfriday">
      <NavBar user={user} />
      <Container className="historico-mensagens-container">
        <Breadcrumb className="mt-3">
          <Link className="breadcrumb-item" to="/home">
            Home
          </Link>
          <Link className="breadcrumb-item" to="/fale-conosco">
            Fale Conosco
          </Link>
          <Breadcrumb.Item active>Histórico de mensagens</Breadcrumb.Item>
        </Breadcrumb>
        <hr className="mt-3" />
        <Row className="mb-4">
          <Col lg={12} className="d-flex justify-content-center mb-5">
            <div className="col-lg-8 col-12">
              <div className="text-center mb-5 d-flex justify-content-center">
                <div className="titulo-pagina col-lg-10">
                  <h2 className="mb-4">Histórico de mensagens</h2>
                </div>

              </div>
              <div className="col-lg-12 col-12 d-flex justify-content-center container-campos">
                <div className="col-lg-10 col-11 d-flex flex-column">
                  <div className="lista-chamados-container">
                    <div className="botoes-filtro">
                      <Button
                        onClick={() => mudarBotao(1)}
                        className={
                          botaoFiltro === 1 ? "btn-laranja" : "btn-disable"
                        }
                      >
                        Andamento
                      </Button>
                      <Button
                        onClick={() => mudarBotao(2)}
                        className={
                          botaoFiltro === 2 ? "btn-verde" : "btn-disable"
                        }
                      >
                        Finalizados
                      </Button>
                    </div>
                    <div>
                      <div className="header-table">
                        <div className="d-flex justify-content-between items-header">
                          <p className="w30">Data</p>
                          <p className="w30">Chamado</p>
                          <p className="w40">Assunto</p>
                        </div>
                      </div>
                      <div className="container-items">
                        {chamadosAguardandoCliente.map(
                          (
                            { id, dataCadastro, codigo, sacAssunto, status },
                            index
                          ) => (
                            <div className="item-historico" key={index}>
                              <div
                                className="d-flex justify-content-between"
                                onClick={() =>
                                  navigate(`/chamado-andamento`, {
                                    state: {
                                      codigo: codigo,
                                      statusOcorrencia: status
                                    },
                                  })
                                }
                              >
                                <p className="w30">
                                  {convertDate(dataCadastro)}
                                </p>
                                <p className="w30">{codigo}</p>
                                <p className="w40">
                                  {sacAssunto.descricao}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                        {chamados.map(
                          (
                            { id, dataCadastro, codigo, sacAssunto, status },
                            index
                          ) => (
                            <div className="item-historico" key={index}>
                              <div
                                className="d-flex justify-content-between"
                                onClick={() =>
                                  navigate(`/chamado-andamento`, {
                                    state: {
                                      codigo: codigo,
                                      statusOcorrencia: status
                                    },
                                  })
                                }
                              >
                                <p className="w30">
                                  {convertDate(dataCadastro)}
                                </p>
                                <p className="w30">{codigo}</p>
                                <p className="w40">
                                  {sacAssunto.descricao}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                        {chamadosAndamentos.map(
                          (
                            { id, dataCadastro, codigo, sacAssunto, status },
                            index
                          ) => (
                            <div className="item-historico" key={index}>
                              <div
                                className="d-flex justify-content-between"
                                onClick={() =>
                                  navigate(`/chamado-andamento`, {
                                    state: {
                                      codigo: codigo,
                                      statusOcorrencia: status
                                    },
                                  })
                                }
                              >
                                <p className="w30">
                                  {convertDate(dataCadastro)}
                                </p>
                                <p className="w30">{codigo}</p>
                                <p className="w40">
                                  {sacAssunto.descricao}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </HistoricoMensagensStyle>
  );
}
