import { Col, Row, Container, Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { BarraStatus, Chat, CircleAvatar, EnviaMsg } from "./styles";
import { Link, useLocation } from "react-router-dom";
import images from "../../images/images";
import { useEffect, useState, useRef } from "react";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import iconAnexo from "../../images/img/icon-anexo.svg";
import ImgPerfil from "../../images/img/img-perfil.svg";

export function ChamadoAndamento({ user }) {
  const { state } = useLocation();
  const [chatMsg, setChatMsg] = useState([]);
  const [imgMsg, setImgMsg] = useState();
  const [imgMsgFile, setImgMsgFile] = useState();
  const [imgAviso, setImgAviso] = useState(false);
  const [atualiza, setAtualiza] = useState(true);
  const [imgName, setImgName] = useState();
  const [imgSize, setimgSize] = useState();

  const [fotoPerfil, setFotoPerfil] = useState();


  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo(0, 9999);
  };

  console.log("codigo >", state);


  useEffect(() => {

    let fotoString = user.foto.split("FotoPerfil/");
    let temFoto = fotoString[1];
    Number(temFoto.charAt(0))
      ? setFotoPerfil(user.foto)
      : setFotoPerfil(ImgPerfil);

    if (atualiza) {
      api
        .get(
          rotasApi.buscarTodasMensagensDaOcorrencia +
          `?codigoOcorrencia=${state.codigo}`
        )
        .then((response) => {
          console.log("resp>", response);
          setChatMsg(response.data.mensagens_sac);
          setImgMsg();
          scrollToBottom();
          setImgMsgFile();
          setAtualiza(false);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [state, atualiza, user]);

  const convertDate = (date) => {
    var convertdate = new Date(date);

    var ddconvertdate = String(convertdate.getDate()).padStart(2, "0");
    var mmconvertdate = String(convertdate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyyconvertdate = String(convertdate.getFullYear()).padStart(4, "0");

    var hsconvertdate = String(convertdate.getHours()).padStart(2, "0");
    var miconvertdate = String(convertdate.getMinutes()).padStart(2, "0");

    convertdate =
      ddconvertdate +
      "/" +
      mmconvertdate +
      "/" +
      yyyyconvertdate +
      " às " +
      hsconvertdate +
      ":" +
      miconvertdate;

    return convertdate;
  };

  const handleMessage = (values) => {
    const formData = new FormData();
    console.log("values", values);

    formData.append("CodigoOcorrencia", state.codigo);
    formData.append("UsuarioId", user.id);
    formData.append("Mensagem", values.mensagem);
    formData.append("arquivo", imgMsgFile);

    api
      .post(rotasApi.enviarMensagem, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log("response", response);
        setAtualiza(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const ImageMsg = (e) => {
    console.log("anexo > ", e.target.files[0]);
    let file = e.target.files[0];
    setImgMsg(URL.createObjectURL(file));
    setImgName(e.target.files[0].name);
    let tamImg = e.target.files[0].size;
    if (tamImg > 1000000) {
      let strVal = String(tamImg);
      setimgSize(strVal.slice(0, 1) + "." + strVal.slice(1, 2) + " Mb");
    } else {
      let strVal = String(tamImg);
      if (strVal.length > 5) {
        setimgSize(strVal.slice(0, 3) + " Kb");
      } else {
        setimgSize(strVal.slice(0, 2) + " Kb");
      }
    }

    //this.foto = file;
  };

  const CancelImg = () => {
    setImgMsg();
    setImgMsgFile();
  };

  return (
    <><div className="chamado-andamento-blackfriday">
      <NavBar user={user} />
      <BarraStatus className="d-flex align-items-center text-white">
        <Container>
          <div className="space-width small">
            <Link
              to="/fale-conosco-mensagens"
              className="text-decoration-none text-white d-flex align-items-center"
            >
              <i className="bi bi-arrow-left icon-large"></i>{" "}
              <span className="d-none d-lg-flex m-2">Voltar</span>
            </Link>
            <p className="m-0">
              Chamado - <span className="fw-bold">{state.codigo}</span>
            </p>
            <p className="m-0">
              Status -{" "}
              <span className="fw-bold">
                {state.statusOcorrencia === "AGUARDANDO_PARTICIPANTE"
                  ? state.statusOcorrencia.replace('_', ' ')
                  : state.statusOcorrencia}
              </span>
            </p>
          </div>
        </Container>
      </BarraStatus>
      <Container fluid /*className="bg-white"*/>
        <Container className="pt-5 pb-5">
          <Chat ref={messagesEndRef}>
            {chatMsg.map(
              ({ arquivo, mensagem, dataCadastro, tipoMensagemSac, interna }, index) =>
                !interna && (tipoMensagemSac === "PARTICIPANTE" ? (
                  <Row className="mt-2" key={index}>
                    <Col
                      lg={{ span: 6, offset: 5 }}
                      xs={9}
                      className="msgPadding"
                    >
                      <Card className="bg-card border-0">
                        <Card.Body>
                          <Card.Text className="small m-0 mb-1">
                            {mensagem}
                          </Card.Text>
                          {arquivo && (
                            <Card.Text>
                              <img src={arquivo} alt="" className="img-fluid" />
                            </Card.Text>
                          )}
                        </Card.Body>
                      </Card>
                      <div className="tempo-message">
                        {convertDate(dataCadastro)}
                      </div>
                    </Col>
                    <Col
                      lg={1}
                      xs={2}
                      className="d-flex justify-content-flex-start"
                    >
                      <CircleAvatar>
                        <img src={fotoPerfil} alt="" className="img-fluid" />
                      </CircleAvatar>
                    </Col>
                  </Row>
                ) : (
                  <Row className="mt-2">
                    <Col lg={1} xs={2} className="cliente-msg">
                      <CircleAvatar>
                        <img src={ImgPerfil} alt="" className="img-fluid" />
                      </CircleAvatar>
                    </Col>
                    <Col lg={6} xs={9}>
                      <Card className="bg-card border-0">
                        <Card.Body>
                          <Card.Text className="small m-0 mb-1">
                            {mensagem}
                          </Card.Text>
                          {arquivo && (
                            <Card.Text>
                              <img src={arquivo} alt="" className="img-fluid" />
                            </Card.Text>
                          )}
                        </Card.Body>
                      </Card>
                      <div className="tempo-message">
                        {convertDate(dataCadastro)}
                      </div>
                    </Col>
                  </Row>
                ))
            )}
          </Chat>
          {state.statusOcorrencia !== "ENCERRADO" && (
            <Row>
              <Col>
                <Formik
                  initialValues={{ mensagem: "", file: imgMsgFile }}
                  onSubmit={(values, { resetForm }) => {
                    handleMessage(values);
                    resetForm({ values: "" });
                  }}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    isValid,
                    handleSubmit,
                    setFieldValue,
                    dirty,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="col-12 position-relative"
                    >
                      <EnviaMsg>
                        {imgMsg && (
                          <div className="w-100">
                            <div className="boxImg mb-2">
                              <img
                                src={images.ImgAnexo}
                                alt=""
                                className="m-3"
                              />
                              <div className="conteudoAnexo">
                                <p>{imgName}</p>
                                <p className="text-secondary">{imgSize}</p>
                              </div>
                              <div className="excluirAnexo">
                                <img
                                  src={images.ExluirAnexo}
                                  alt=""
                                  onClick={CancelImg}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="position-relative">
                          <Form.Group>
                            <Form.Control
                              as="textarea"
                              name="mensagem"
                              value={values.mensagem}
                              onChange={handleChange}
                              isInvalid={!!errors.mensagem}
                              className="textArea m-0"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.mensagem}
                            </Form.Control.Feedback>

                            <div className="anexoMsg">
                              <Form.Label htmlFor="file-input">
                                {" "}
                                <img
                                  src={iconAnexo}
                                  className="marginIcon"
                                  alt=""
                                />
                              </Form.Label>

                              <Form.Control
                                type="file"
                                name="file"
                                id="file-input"
                                className="d-none"
                                onChange={(e) => {
                                  if (e.currentTarget.files[0].size <= 2000000) {
                                    ImageMsg(e);
                                    setImgMsgFile(e.currentTarget.files[0]);
                                    setFieldValue(
                                      "file",
                                      e.currentTarget.files[0]
                                    );
                                    setImgAviso(false);
                                  } else {
                                    setImgAviso(true);
                                  }
                                }}
                                isInvalid={!!errors.file}
                              />
                              {imgAviso && (
                                <p className="text-center small text-danger invalidImg">
                                  A imagem é maior que 2 Mb
                                </p>
                              )}
                            </div>
                          </Form.Group>

                          <div className="d-flex justify-content-center btnEnvia">
                            <Button
                              type="submit"
                              disabled={!isValid || !dirty}
                              variant="secondary col"
                              className="btn-verde h36"
                            >
                              <i className="bi bi-send-fill"></i>
                            </Button>
                          </div>
                        </div>
                      </EnviaMsg>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          )}
        </Container>
      </Container>
      <Footer />
    </div>
    </>
  );
}
