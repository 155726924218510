import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

export function GraficoVendaLoja({ tipoGrafico }) {

    const [options, setOptions] = useState()
    const [dadosEvolucao, setDadosEvolucao] = useState()
    const [dadosMeses, setDadosMeses] = useState()
    const [dadosMesesDistribuicao, setDadosMesesDistribuicao] = useState()
    var today = new Date();
    var yyyy = today.getFullYear();


    const MeuDesempenhoDadosVendas = (e) => {
        api
            .get(rotasApi.MeuDesempenhoDadosVendas + `?ano=${e}`)
            .then((result) => {
                formataDado(result)
                formataDadoEvolucao(result)
                formataDadoDistribuicao(result)
            }).catch((error) => {
                console.log('error obter desempenho campanha')
            })
    };

    const formataDado = (result) => {
        const getMonthName = (monthNumber) => {
            const monthNames = [
                'Jan', 'Fev', 'Mar', 'Abr',
                'Mai', 'Jun', 'Jul', 'Ago',
                'Set', 'Out', 'Nov', 'Dez'
            ];
            return monthNames[monthNumber - 1];
        }
        let data = result.data.abaVendas.meses.map((item) => ({
            label: getMonthName(item.mes),
            value: item.qtdSuperTop,
            value2: item.mediaVendasLoja
        }));
        let chartData = [['Mes', 'Minha média', 'Média Loja'], ...data.map(({ label, value, value2 }) => [label, value, value2])];
        setDadosMeses(chartData)
    }

    const formataDadoEvolucao = (result) => {
        const getMonthName = (monthNumber) => {
            const monthNames = [
                'Jan', 'Fev', 'Mar', 'Abr',
                'Mai', 'Jun', 'Jul', 'Ago',
                'Set', 'Out', 'Nov', 'Dez'
            ];
            return monthNames[monthNumber - 1];
        }
        let data = result.data.abaVendas.meses.map((item) => ({
            label: getMonthName(item.mes),
            value: item.qtdSuperTop,
            value2: item.qtdParticipante
        }));
        let chartData = [['Mes', 'Supertop', 'Participante'], ...data.map(({ label, value, value2 }) => [label, value, value2])];
        setDadosEvolucao(chartData)
    }

    const formataDadoDistribuicao = (result) => {
        const getMonthName = (monthNumber) => {
            const monthNames = [
                'Jan', 'Fev', 'Mar', 'Abr',
                'Mai', 'Jun', 'Jul', 'Ago',
                'Set', 'Out', 'Nov', 'Dez'
            ];
            return monthNames[monthNumber - 1];
        }
        let data = result.data.abaVendas.meses.map((item) => ({
            label: getMonthName(item.mes),
            value: item.totalPontosMes,
        }));
        let chartData = [['Minha', 'Pontos'], ...data.map(({ label, value }) => [label, value])];
        setDadosMesesDistribuicao(chartData)
    }

    useEffect(() => {
        MeuDesempenhoDadosVendas(yyyy)
    }, [tipoGrafico])

    return (
        <>
            {tipoGrafico === 1 ?

                <div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { MeuDesempenhoDadosVendas(e.target.value) }}>
                        <option value={yyyy}>{yyyy}</option>
                        <option value={yyyy - 1}>{yyyy - 1}</option> 
                    </Form.Select>
                </div> : tipoGrafico === 2 ? <div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { MeuDesempenhoDadosVendas(e.target.value) }}>
                        <option value={yyyy}>{yyyy}</option>
                        <option value={yyyy - 1}>{yyyy - 1}</option>
                    </Form.Select>
                </div> : tipoGrafico === 3 ? <div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { MeuDesempenhoDadosVendas(e.target.value) }}>
                        <option value={yyyy}>{yyyy}</option>
                        <option value={yyyy - 1}>{yyyy - 1}</option>
                    </Form.Select>
                </div> : ('')}

            {tipoGrafico === 1 ?

                <Chart
                    chartType="Bar"
                    width="97%"
                    height="270px"
                    data={dadosMeses}
                    options={{
                        colors: ['#9ED530', 'orange'],
                        legend: { position: "bottom" },

                    }}
                    style={{ "marginLeft": "12px" }}
                /> : tipoGrafico === 2 ? <Chart
                    chartType="Line"
                    width="100%"
                    height="270px"
                    data={dadosEvolucao}
                    options={{
                        colors: ['#9ED530', 'orange'],
                        legend: { position: "bottom" },
                    }}

                /> : tipoGrafico === 3 ?
                    <Chart
                        chartType="Line"
                        width="97%"
                        height="270px"
                        data={dadosMesesDistribuicao}
                        options={
                            {
                                colors: ['#9ED530', 'orange'],
                                legend: { position: "bottom" },
                            }
                        }

                    />

                    : ('')}
        </>
        // 
    )
}
