import styled from "styled-components";

export const BodyModal = styled.div`
    background: var(--background-escuro);
    height: 100vh;

  .info-telefone {
    font-size: 14px;
    color: #fff;
  }
  .body-card {
    @media (min-width: 992px) {
      width: 28rem;
    }
  }
  .icon-size {
    font-size: 6rem;
  }
`;

export const ModalCadastroSelfie1 = styled.div`
        position: relative;
        text-align: center;
        height: 100vh;  
   .icon-principal{
       max-width: 300px;
       margin-top: 70px;
       margin-bottom: 70px;
       @media (max-height: 576px) { 
        max-width: 210px; 
        }
   }
   h2{
       font-size: 25px;
       margin-bottom: 25px;
   }
   p{
       font-size: 15px;
   }
   button{
        position: absolute;
        bottom: 30px;
        width: 302px;
        height: 50px;
        margin-left: -152px;
   }
`

export const ModalCadastroSelfie2 = styled.div`
  position: relative;
  text-align: center;
  height: 100vh;
  background: #000;
  color: #fff;
  .icon-principal {
    margin-top: 10vh;
    margin-bottom: 8vh;
  }
  h2 {
    font-size: 16px;
  }
  .lista-itens {
    text-align: left;
    margin-bottom: 5vh;
    ul {
      text-align: left;
      /* padding-left: 1rem; */
      li {
        list-style-type: none;
        margin: 10px 0;
        span {
          color: #fff;
          margin-right: 6px;
        }
      }
    }
  }
  button {
    position: absolute;
    bottom: 30px;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }
`;

export const ModalCadastroSelfie3 = styled.div`
    text-align: center;
    height: 100vh;
   .icon-principal{
       max-width: 300px;
       margin-top: 30%;
       margin-bottom: 20%;
   }
   h2{
       font-size: 24px;
       @media (max-height: 576px) { 
        margin-top:1vh ;
    }
  }
  p {
    font-size: 12px;
  }
  .btn-tirar-foto1,
  .btn-tirar-foto2 {
    margin-top: 10vh;
    border: 0;
    background: orange;
    height: 146px;
    width: 146px;
    border-radius: 7px;
    @media (max-height: 576px) {
      margin-top: 2vh;
    }
    img {
      width: 40px;
    }
    span {
      color: #fff;
    }
  }
`;

export const ModalCadastroSelfie4 = styled.div`
    position: relative;
    text-align: center;
    height: 100vh;
   .icon-principal{
       max-width: 300px;
       margin-top: 30%;
       margin-bottom: 20%;
       @media (max-height: 576px) { 
        max-width: 140px;
        margin-top: 10%!important; 
        }
   }
   h2{
       font-size: 24px;
       @media (max-height: 576px) { 
        font-size:20px;
        }
   }
   .container-img-frente{
    height: 53px;
    border: 1px dashed #9a9a9a;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    .miniatura-img-frente {
      width: 48px;
      height: 38px;
      border: 1px dashed #9a9a9a;
      margin-left: 6px;
    }
    .nome-img-frente {
      margin-top: -24px;
      color: #9a9a9a;
    }
    .icon-img {
      margin-right: 10px;
    }
  }
  .btn-continuar {
    position: absolute;
    bottom: 10vh;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }
  #image_Front,
  #image_Back {
    display: none;
  }
  .img-prev {
    height: 36px;
  }
`;
export const ModalCadastroSelfie5 = styled.div`
    position: relative;
    text-align: center;
    height: 100vh;
   .icon-principal{
       max-width: 300px;
       margin-top: 30%;
       margin-bottom: 20%;
       @media (max-height: 576px) { 
        max-width: 260px; 
        }
   }
   h2{
       font-size: 24px;
       @media (max-height: 576px) { 
        margin-bottom:-20px ;
        }
   }
   p{
       font-size: 14px;
   }
   button{
        position: absolute;
        bottom: 30px;
        width: 302px;
        height: 50px;
        margin-left: -152px;
   }
`

export const ModalCadastroSelfie6 = styled.div`
  text-align: center;
  background:  #EFEEED;
  height: 100vh;
  color: #857777;
  position: relative;
  .img-tomas-exemplo{
    width: 150px;
    margin-top: 8vh;
    @media (max-height: 576px) {
      margin-top: 10px;
    }
  }
  h2 {
    font-size: 15px;
    margin-top: 70px;
    margin-bottom: 30px;
    color: #000;
    @media (max-height: 576px) {
      font-size: 15px;
      margin-top: 5px;
    }
  }
  h3 {
    font-size: 14px;
    margin-bottom: 5px;
    color: var(--laranja);
    @media (max-height: 576px) {
      margin-top: -20px;
    }
  }
  .lista-itens {
    text-align: left;
    margin-bottom: 5vh;
    ul {
      text-align: left;
      /* padding-left: 1rem; */
      li {
        list-style-type: none;
        margin: 25px 0;
        @media (max-height: 576px) {
          margin: 25px 0;
        }
        span {
          color: #fff;
          margin-right: 6px;
        }
      }
    }
  }
  button {
    position: absolute;
    bottom: 30px;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }
  .input-selfie {
    position: absolute;
    top: 0;
    left: 0;
    width: 21.5rem;
    height: 3.5rem;
    border: 1px solid;
    opacity: 0;
  }
`;
export const ModalCadastroSelfie7 = styled.div`
  position: relative;
  text-align: center;
  height: 100vh;
  background-color: #FBFCFE;
  .container-principal {
    /* position: relative; */
  }
  .icon-principal {
    max-width: 250px;
  }
  h2 {
    font-size: 25px;
  }
  p {
    font-size: 14px;
    margin-top: 5%;
  }
  button {
    position: absolute;
    bottom: 30px;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }
  .btn-continuar {
    position: absolute;
    bottom: 10vh;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }
`;
export const ModalCadastroSelfie8 = styled.div`
    position: relative;
    text-align: center;
    height: 100vh;
    .container-principal{
      /* position: relative; */
      margin-top: 30%;
       margin-bottom: 20%;
    }
   .icon-principal{
       max-width: 250px;
   }
   h2{
       font-size: 25px;
   }
   p{
       font-size: 14px;
       margin-top: 5%;
   }
   button{ 
    position: absolute;
    bottom: 30px;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }
`;

export const ModalCadastroSelfie9 = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    height: 100vh;
    .texto-confirma-selfie{
      margin-top: 4vh;
    }   
   .icon-principal{
       max-width: 300px;
       margin-top: 30%!important;
 
       @media (max-height: 576px) { 
        max-width: 140px;
        margin-top: 10%!important; 
        }
   }
   h2{
       font-size: 24px;
       @media (max-height: 576px) { 
            margin-top: 25px;
        }
   }
   .container-img-frente{
    height: 53px;
    border: 1px dashed #9a9a9a;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    .miniatura-img-frente {
      width: 48px;
      height: 38px;
      border: 1px dashed #9a9a9a;
      margin-left: 6px;
    }
    .nome-img-frente {
      margin-top: -24px;
      color: #9a9a9a;
    }
    .icon-img {
      margin-right: 10px;
    }
  }
  .btn-continuar {
    position: absolute;
    bottom: 10vh;
    width: 302px;
    height: 50px;
    margin-left: -152px;
  }

  #image_Front,
  #image_Back {
    display: none;
  }
  .img-prev {
    height: 36px;
  }
  .input-selfie {
    position: absolute;
    top: 0;
    left: 0;
    width: 21.5rem;
    height: 3.5rem;
    border: 1px solid;
    opacity: 0;
   }
   .selfie-img {
       height: 24rem;
       @media (max-height: 576px) { 
        height: 16rem;
        }
   }
`

