import { Col, Container, Row, Button, Card, Fade } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import IconPage1 from "../../images/img/icon-cadastro-selfie1.svg";
import IconPage2 from "../../images/img/icon-cadastro-selfie2.svg";
import IconPage3 from "../../images/img/icon-cadastro-selfie3.svg";
import IconPage4 from "../../images/img/icon-cadastro-selfie4.svg";
import IconPage5 from "../../images/img/icon-cadastro-selfie5.svg";
import IconPage6 from "../../images/img/icon-cadastro-selfie6.svg";
import IconPage9 from "../../images/img/icon-cadastro-selfie9.svg";
import IconCelular from "../../images/img/icon-celular.svg";
import IconBtn1 from "../../images/img/icon-cadastro-selfie3-btn1.svg";
import IconBtn2 from "../../images/img/icon-cadastro-selfie3-btn2.svg";

import iconCadastroCelfieCheck from "../../images/img/icon-cadastro-selfieCheck.svg";
import {
  BodyModal,
  ModalCadastroSelfie1,
  ModalCadastroSelfie2,
  ModalCadastroSelfie3,
  ModalCadastroSelfie4,
  ModalCadastroSelfie5,
  ModalCadastroSelfie6,
  ModalCadastroSelfie7,
  // ModalCadastroSelfie8,
  ModalCadastroSelfie9,
} from "./styles";

import iconSelectFrenteVerso from "../../images/img/icon-cadastro-selfie-frente.svg";
import iconSelectFrenteVersoGaleria from "../../images/img/icon-cadastro-selfie-galeria.svg";
//import { Formik } from 'formik';
//import * as yup from 'yup';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { primeiroAcessoSelector } from "../../slices/primeiroAcessoSlice";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import images from "../../images/images";
import { LoadingModal } from "../../components/LoadingModal"

//import { Link } from "react-router-dom";

//const schema = yup.object().shape({
//    file: yup.mixed().required(),
//});
// import { Link } from "react-router-dom";

export function CadastroSelfie({ cpfStorage }) {
  let navigate = useNavigate();
  const [passo, setPasso] = useState(1);
  const [orientacaoSelfie, setOrientacaoSelfie] = useState(0)

  const [docFrenteCamera, setDocFrenteCamera] = useState();
  const [docVersoCamera, setDocVersoCamera] = useState();
  const [docFrenteGaleria, setDocFrenteGaleria] = useState();
  const [docVersoGaleria, setDocVersoGaleria] = useState();
  const [selfie, setSelfie] = useState();

  const [docFrenteCameraFile, setDocFrenteCameraFile] = useState();
  const [docVersoCameraFile, setDocVersoCameraFile] = useState();
  const [docFrenteGaleriaFile, setDocFrenteGaleriaFile] = useState();
  const [docVersoGaleriaFile, setDocVersoGaleriaFile] = useState();
  const [selfieFile, setSelfieFile] = useState();

  const [disableBtn, setDisableBtn] = useState(
    "btn-continuar disabled btn-laranja"
  );
  const [modalErro, setModalErro] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");
  const [limparStore, setLimparStore] = useState(true);
  const { primeiroAcessoState } = useSelector(primeiroAcessoSelector);
  const [modalShowLoading, setModalShowLoading] = useState(false);

  let cpf
  if (cpfStorage) {
    cpf = cpfStorage.cpf;
    sessionStorage.removeItem("UserInfo");
  } else {
    cpf = primeiroAcessoState.cpf;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (limparStore) {
      setPasso(1);
      setLimparStore(false);
    }
    if (!cpf) {
      window.location.href = '/'
    }
  }, [limparStore, dispatch, cpf]);

  function mudarPasso() {
    setPasso(passo + 1);
    console.log("passo> ", passo);
  }
  function mudarPassoNove() {
    setPasso(9);
  }
  function mudarPassoCinco() {
    let imagemFrente;
    let imagemVerso;

    if (docFrenteCameraFile && docVersoCameraFile) {
      imagemFrente = docFrenteCameraFile;
      imagemVerso = docVersoCameraFile;
    } else {
      imagemFrente = docFrenteGaleriaFile;
      imagemVerso = docVersoGaleriaFile;
    }
    setModalShowLoading(true)
    const formData = new FormData();
    formData.append("imagemFrente", imagemFrente);
    formData.append("imagemVerso", imagemVerso);

    api
      .post(
        rotasApi.cadastroEnviarImagemCPFouCNH + "cpfUsuario=" + cpf,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (passo === 4 || passo === 9) {
            setPasso(5);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      })
      .finally(function () {
        setModalShowLoading(false)
      })
  }
  function mudarPassoSelfie() {
    const formData = new FormData();
    formData.append("selfie", selfieFile);

    api
      .post(
        rotasApi.cadastroEnviarImagemSelfie + "cpfUsuario=" + cpf,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (passo === 7) {
            setPasso(passo + 1);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  }
  function ativarBtn() {
    setDisableBtn("btn-continuar btn-laranja");
  }
  const ChangeDocFrenteCamera = (e) => {
    let file = e.target.files[0];
    setDocFrenteCameraFile(file);
    setDocFrenteCamera(URL.createObjectURL(file));
    //this.foto = file;
    if (docVersoCamera) {
      ativarBtn();
    }
  };
  const ChangeDocVersoCamera = (e) => {
    let file = e.target.files[0];
    setDocVersoCameraFile(file);
    setDocVersoCamera(URL.createObjectURL(file));
    //this.foto = file;
    if (docFrenteCamera) {
      ativarBtn();
    }
  };
  const ChangeDocFrenteGaleria = (e) => {
    let file = e.target.files[0];
    setDocFrenteGaleriaFile(file);
    setDocFrenteGaleria(URL.createObjectURL(file));
    //this.foto = file;
    if (docVersoGaleria) {
      ativarBtn();
    }
  };
  const ChangeDocVersoGaleria = (e) => {
    let file = e.target.files[0];
    setDocVersoGaleriaFile(file);
    setDocVersoGaleria(URL.createObjectURL(file));
    //this.foto = file;
    if (docFrenteGaleria) {
      ativarBtn();
    }
  };

  const ChangeSelfie = (e) => {
    let file = e.target.files[0];
    setSelfieFile(file);
    setSelfie(URL.createObjectURL(file));
    setPasso(7);
    setOrientacaoSelfie(0)
  };

  const home = () => {
    navigate("/");
  };

  return (
    <>
      <LoadingModal modalShow={modalShowLoading} />
      <Row>
        <Col className="d-flex justify-content-center">
          <Fade in={modalErro}>
            <Card
              className="text-center p-3 m-2 z-index-top position-absolute"
              bg="danger"
              text="white"
            >
              <Card.Body>
                <Card.Title as="h2">Erro</Card.Title>
                <Card.Text>{modalMensagem}</Card.Text>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
      </Row>
      {passo === 1 ? (
        <>
          <BodyModal className="justify-content-center align-items-center d-none d-lg-flex">
            <Container>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                    <Card.Body className="body-card">
                      <Card.Text className="text-center icon-size">
                        <img
                          src={IconCelular}
                          className="icon-principal"
                          alt=""
                        />
                      </Card.Text>
                      <Card.Title className="text-center" as="h5">
                        Continue seu cadastro pelo celular
                      </Card.Title>

                      <Card.Text className="text-center mt-5">
                        Para passar pela etapa final de validação de identidade
                        precisamos que você acesse o Programa +top através de um
                        celular com câmera
                      </Card.Text>
                      <Link
                        to="/"
                        className="btn btn-secondary btn-laranja w-100 mt-3"
                      >
                        Sair
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3 text-center">
                <Col>
                  <p className="info-telefone">
                    Dúvidas ? Ligue para 0800 780 0606 e<br /> fale com um de
                    nossos atendentes.
                  </p>
                </Col>
              </Row>
            </Container>
          </BodyModal>

          <ModalCadastroSelfie1 className="d-flex d-lg-none">
            <Container>
              <Row>
                <Col lg={12} className="flex-column justify-content-center">
                  <img src={IconPage1} className="icon-principal" alt="" />
                  <h2>
                    Confirme seus
                    <br /> dados para proteger
                    <br /> sua conta!
                  </h2>
                  <div className="col-12 d-flex justify-content-center">
                    <p className="col-10">
                      Para garantir que ninguém possa criar uma conta em seu
                      nome, vamos validar sua identidade. Tenha em mãos sua CNH
                      ou RG e busque um local iluminado.
                    </p>
                  </div>
                  <Button className="col-10 btn-laranja" onClick={mudarPasso}>
                    Começar
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalCadastroSelfie1>
        </>
      ) : passo === 2 ? (
        <ModalCadastroSelfie2>
          <Container>
            <Row>
              <Col lg={12} className="flex-column justify-content-center">
                <img src={IconPage2} className="icon-principal" alt="" />
                <h2>
                  Agora você vai fotografar o seu RG
                  <br />
                  ou CNH seguindo estes passos:
                </h2>
                <div className="col-12 d-flex justify-content-center lista-itens">
                  <ul className="text-left character-bullets">
                    <li>
                      <span>
                        <img src={iconCadastroCelfieCheck} alt="" />
                      </span>
                      Retire o documento da capa plástica;
                    </li>
                    <li>
                      <span>
                        <img src={iconCadastroCelfieCheck} alt="" />
                      </span>
                      Procure um lugar com boa iluminação;
                    </li>
                    <li>
                      <span>
                        <img src={iconCadastroCelfieCheck} alt="" />
                      </span>
                      Fotografe um lado de cada vez;
                    </li>
                    <li>
                      <span>
                        <img src={iconCadastroCelfieCheck} alt="" />
                      </span>
                      Certifique-se de que o documento esteja bem enquadrado e
                      todas as informações estejam nítidas e visíveis.
                    </li>
                  </ul>
                </div>
                <Button className="col-10 btn-laranja" onClick={mudarPasso}>
                  Começar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie2>
      ) : passo === 3 ? (
        <ModalCadastroSelfie3>
          <Container>
            <Row>
              <Col lg={12} className="flex-column justify-content-center">
                <img src={IconPage3} className="icon-principal" alt="" />
                <h2>
                  Escolha como
                  <br /> deseja enviar o<br /> documento.
                </h2>
                <div className="d-flex justify-content-around">
                  <button
                    onClick={mudarPasso}
                    className="btn-tirar-foto1 d-flex flex-column align-items-center"
                  >
                    <img src={IconBtn1} alt="" className="mt-5 mb-2" />
                    <span>Tirar Foto</span>
                  </button>

                  <button
                    onClick={mudarPassoNove}
                    className="btn-tirar-foto2 d-flex flex-column align-items-center"
                  >
                    <img src={IconBtn2} alt="" className="mt-5 mb-2" />
                    <span>Carregar Imagem</span>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie3>
      ) : passo === 4 ? (
        <ModalCadastroSelfie4>
          <Container>
            <Row>
              <Col lg={12} className="flex-column justify-content-center">
                <img src={IconPage4} className="icon-principal" alt="" />
                <h2>
                  Fotografe a frente
                  <br /> e depois o verso do
                  <br /> seu documento:
                </h2>
                <div className="col-12 d-flex flex-column justify-content-center mt-5">
                  <label
                    for="image_Front"
                    className="container-img-frente align-items-center align-self-center col-10 mb-4"
                  >
                    <div className="miniatura-img-frente bd-highlight">
                      <img
                        src={docFrenteCamera}
                        alt=""
                        className="img-fluid img-prev"
                      />
                    </div>
                    <div className="nome-img-frente p-2 bd-highlight">
                      IMAGEM FRENTE
                    </div>
                    <div className="icon-img ms-auto p-2 bd-highlight">
                      <img
                        src={iconSelectFrenteVerso}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </label>
                  <input
                    id="image_Front"
                    type="file"
                    onChange={ChangeDocFrenteCamera}
                    accept="image/*"
                    capture
                  />
                  <label
                    for="image_Back"
                    className="container-img-frente align-items-center align-self-center col-10"
                  >
                    <div className="miniatura-img-frente bd-highlight">
                      <img
                        src={docVersoCamera}
                        alt=""
                        className="img-fluid img-prev"
                      />
                    </div>
                    <div className="nome-img-frente p-2 bd-highlight">
                      IMAGEM VERSO
                    </div>
                    <div className="icon-img ms-auto p-2 bd-highlight">
                      <img src={iconSelectFrenteVerso} alt="" />
                    </div>
                  </label>
                  <input
                    id="image_Back"
                    type="file"
                    onChange={ChangeDocVersoCamera}
                    accept="image/*"
                    capture
                  />
                </div>
                <Button
                  className={disableBtn}
                  variant="secondary"
                  size=""
                  onClick={mudarPassoCinco}
                >
                  Continuar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie4>
      ) : passo === 5 ? (
        <ModalCadastroSelfie5>
          <Container>
            <Row>
              <Col lg={12} className="flex-column justify-content-center">
                <img src={IconPage5} className="icon-principal" alt="" />
                <h2>
                  Etapa concluída
                  <br /> com sucesso!
                </h2>
                <div className="col-12 mb-5 mt-5">
                  <p className="col-12">
                    Seu documento foi enviado para análise.
                  </p>
                  <p className="col-12">
                    Clique em continuar para seguir para
                    <br /> a última etapa do cadastro.
                  </p>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary btn-laranja col-10"
                  onClick={mudarPasso}
                >
                  Continuar
                </button>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie5>
      ) : passo === 6 ? (
        <ModalCadastroSelfie6>
          <Container>
            <Row>
              <Col lg={12} className="d-flex justify-content-center col-12">
                <div className="col-11 flex-column justify-content-center">
                  <img className="img-tomas-exemplo" src={images.TomCadastroSelfieDocumento} alt="" />
                  <h2>
                    Agora, use a câmera frontal do seu celular e tire uma selfie segurando
                    seu documento.
                  </h2>
                  <h3>
                    Siga os passos abaixo:
                  </h3>
                  <div className="col-12 d-flex justify-content-center lista-itens">
                    <ul className="text-left character-bullets p-1">
                      <li>
                        <span>
                          <img src={iconCadastroCelfieCheck} alt="" />
                        </span>
                        Use roupa neutra e procure um lugar com boa iluminação;
                      </li>
                      <li>
                        <span>
                          <img src={iconCadastroCelfieCheck} alt="" />
                        </span>
                        Não use óculos escuros, máscaras ou adereços
                        que possam dificultar sua identificação.
                      </li>
                    </ul>
                  </div>

                  <Button
                    className="btn-continuar btn-laranja"
                    variant="secondary"
                    size=""
                  >
                    Continuar
                    <input
                      type="file"
                      className="input-selfie"
                      onChange={ChangeSelfie}
                      accept="image/*"
                      capture
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie6>
      ) : passo === 7 ? (
        <ModalCadastroSelfie9>
          <Container>
            <Row>
              <Col
                lg={12}
                className="flex-column justify-content-center container-principal p-5"
              >

                {orientacaoSelfie === 2 ? <h2> Enviar essa foto?</h2> : <h3>Confirme se sua foto<br /> segue as recomendações.</h3>}
                <div className="col-12 d-flex flex-column justify-content-center mt-3">
                  <div className="bd-highlight">
                    <img src={selfie} alt="" className="img-fluid selfie-img" />
                  </div>
                  {orientacaoSelfie === 0 ? <span className="texto-confirma-selfie">A foto foi tirada segurando<br /> o documento ?</span> : ''}
                </div>
                {orientacaoSelfie === 0 ?
                  < Button
                    onClick={(e) => { setOrientacaoSelfie(1) }}
                    className="w-100 mt-2 btn-laranja"
                    size=""
                  >
                    Sim
                  </Button> : ''}

                {orientacaoSelfie === 1 ? <p className="texto-confirma-selfie">A foto foi tirada sem bonés, óculos, ou outros acessórios que dificultem a identificação?</p> : ''}

                {orientacaoSelfie === 1 ?
                  < Button
                    onClick={(e) => { setOrientacaoSelfie(2) }}
                    className="w-100 mt-2 btn-laranja"
                    size=""
                  >
                    Sim
                  </Button> : ''}

                {orientacaoSelfie === 2 ?
                  <Button
                    onClick={mudarPassoSelfie}
                    className="w-100 mt-5 btn-laranja"
                    size=""
                  >
                    Sim
                  </Button> : ''}

                <Button
                  className="w-100 mt-3 position-relative"
                  variant="outline-secondary"
                  size=""
                >
                  Tirar Outra
                  <input
                    type="file"
                    className="input-selfie"
                    onChange={ChangeSelfie}
                    accept="image/*"
                    capture
                  />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie9>
      ) : passo === 8 ? (
        <ModalCadastroSelfie7>
          <Container>
            <Row>
              <Col
                lg={12}
                className="flex-column justify-content-center container-principal"
              >
                <img src={IconPage6} className="icon-principal" alt="" />
                <h2>
                  Etapa final concluída
                  <br /> com sucesso!
                </h2>
                <div className="col-12 d-flex justify-content-center">
                  <p className="col-10">
                    Seu documento e foto foram enviados para análise e poderão ser validados em até 5 dias úteis. Você receberá um e-mail ou SMS assim que análise for concluída.
                  </p>
                </div>
                <Button
                  onClick={home}
                  className="btn-continuar btn-laranja"
                  variant="secondary"
                >
                  Sair
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie7>
      ) : passo === 9 ? (
        <ModalCadastroSelfie9>
          <Container>
            <Row>
              <Col lg={12} className="flex-column justify-content-center">
                <img
                  src={IconPage9}
                  className="icon-principal mt-5 mb-5"
                  alt=""
                />
                <h2>
                  Escolha as imagens
                  <br /> que deseja enviar:
                </h2>
                <div className="col-12 d-flex flex-column justify-content-center mt-5">
                  <label
                    for="image_Front"
                    className="container-img-frente align-items-center align-self-center col-10 mb-4"
                  >
                    <div className="miniatura-img-frente bd-highlight">
                      <img
                        src={docFrenteGaleria}
                        alt=""
                        className="img-fluid img-prev"
                      />
                    </div>
                    <div className="nome-img-frente p-2 bd-highlight">
                      IMAGEM FRENTE
                    </div>
                    <div className="icon-img ms-auto p-2 bd-highlight">
                      <img src={iconSelectFrenteVersoGaleria} alt="" />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="image_Front"
                    onChange={ChangeDocFrenteGaleria}
                    name="image_uploads"
                    accept=".jpg, .jpeg, .png"
                  />
                  <label
                    for="image_Back"
                    className="container-img-frente align-items-center align-self-center col-10"
                  >
                    <div className="miniatura-img-frente bd-highlight">
                      <img
                        src={docVersoGaleria}
                        alt=""
                        className="img-fluid img-prev"
                      />
                    </div>
                    <div className="nome-img-frente p-2 bd-highlight">
                      IMAGEM VERSO
                    </div>
                    <div className="icon-img ms-auto p-2 bd-highlight">
                      <img src={iconSelectFrenteVersoGaleria} alt="" />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="image_Back"
                    onChange={ChangeDocVersoGaleria}
                    name="image_uploads"
                    accept=".jpg, .jpeg, .png"
                  />
                </div>
                <Button
                  onClick={mudarPassoCinco}
                  className={disableBtn}
                  variant="secondary"
                  size=""
                >
                  Continuar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalCadastroSelfie9>
      ) : (
        ""
      )
      }
    </>
  );
}
