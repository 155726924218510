import styled from 'styled-components'

export const Body = styled.div`
    background: var(--background-escuro);
    height: 100vh;

    .info-telefone {
        font-size: 14px;
        color: #fff;
        font-weight: 100;
        @media (max-width: 992px) {
        font-size: 10px!important;
        margin-bottom: 31px;
        }
        @media (max-height: 731px) {
        p{
            font-size: 9px;
            }
        h5{
            font-size: 10px;
            }
        }
    }
    }
  
  .info-telefone .strong{
    font-weight: 800;
  }
    
    .body-card{
        @media (min-width: 992px) { 
            width: 28rem;
        }
        
    }
    .icon-size{
        font-size: 4rem;
    }
`