import styled from "styled-components";

export const FaleConoscoStyle = styled.div`
  height: 80vh;
  .titulo-pagina {
    margin-top: 50px;
    @media (max-width: 992px) {
      /* display: none;    */
    }
    .subtitulo {
      font-weight: 700;
      margin-top: -15px;
    }
  }

  .titulo-pagina-mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
  }

  .container-itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container-campos {
    margin-top: 50px;
  }
  .btn-historico {
    color: #fff;
    text-decoration: none;
    height: 45px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .btn-wpp-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 0.5rem;
    background: var(--verde);
    border: 1px solid var(--verde);
    text-align: center;
    color: white;
    border-radius: 0.25rem;
    text-decoration: none;
    &:hover {
      background: transparent;
      color: var(--verde);
      border: 1px solid var(--verde);
    }
    i {
      font-size: 1.2rem;
    }
  }
  .btn-historico-mobile {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    a {
      color: #f58420;
      &:hover {
        color: white;
      }
    }
    &:hover {
      a {
        color: white;
      }
    }
    i {
      font-size: 1.2rem;
    }
  }
  .link-historico-chamadas {
    text-decoration: none;
  }
  .anexo-arquivos {
    .label-anexo {
      align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #f58420;
    border: 1px dashed #313131;
    width: 100%;
    border-radius: 5px;
    height: 45px;
    cursor: pointer;
    }
    #file-input {
      display: none;
    }
    .btn-enviar {
      height: 45px;
    }
    .imgSize {
      height: 2.6rem;
    }
    .boxImg {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      overflow: hidden;
      .closeImg {
        position: absolute;
        color: red;
        right: -6px;
        top: -6px;
        height: 19px;
        background: white;
        border-radius: 1rem;
        cursor: pointer;
      }
    }
    .boxAnexos{
      position: relative;
      height: 45px;
      width: 100%;
      align-items: center;
      display: flex;
      background-color: #fff;
      border-radius: 0.25rem;
      .conteudoAnexo {
        p{
          margin: 0;
          font-size: 0.8rem;
          
        }
        
      }
      .excluirAnexo {
        right: 1rem;
    position: absolute;
    cursor: pointer;
      }
    }
  }
`;

export const ModalSucesso = styled.div`
  .modal-sucesso {
    width: 250px;
    margin: 0 auto;
    .h2-sucesso {
      font-size: 24px;
      line-height: 32px;
      padding-top: 25px;
      padding-bottom: 11px;
    }
    .mensagem-sucesso {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 52px;
    }
  }
`;
