import { Spinner } from "react-bootstrap";
import React from "react";


export function LoadingSpinner({ show }) {

    return (
        <>
            {show ?
                <Spinner animation="border" role="status" style={{ width: "4rem", height: "4rem" }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                : ('')}
        </>
    )
}
