import { Col, Row, Form, Container, Button, Breadcrumb } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { FaleConoscoStyle, ModalSucesso } from "./styles";
import iconAnexo from "../../images/img/icon-anexo.svg";
import images from '../../images/images'
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checked from "../../images/img/icon-checked.svg";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

export function FaleConosco({ user }) {
  let navigate = useNavigate();
  const [assuntos, setAssuntos] = useState([]);
  const [formAssunto, setFormAssunto] = useState([]);
  const [formMsg, setFormMsg] = useState([]);
  const [formArquivo, setFormArquivo] = useState();
  const [modalSucesso, setModalSucesso] = useState(false);
  const [imgName, setImgName] = useState();
  const [imgSize, setimgSize] = useState();
  const [imgAviso, setImgAviso] = useState(false);

  const buscaAssuntos = () => {
    api
      .get(rotasApi.buscarTodosOsAssuntos)
      .then((result) => {
        setAssuntos(result.data.assuntos);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const enviaMsg = () => {
    const formData = new FormData();

    formData.append("UsuarioId", user.id);
    formData.append("AssuntoId", formAssunto);
    formData.append("Mensagem", formMsg);
    formData.append("arquivo", formArquivo);

    api
      .post(rotasApi.abrirChamado, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        setModalSucesso(true);
        setFormAssunto();
        setFormMsg();
        CancelImg();
        
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const fechaModal = () => {
    setModalSucesso(false);
    navigate(`/fale-conosco-mensagens`);
  }

  useEffect(() => {
    buscaAssuntos();
  }, []);

  const ImageMsg = (e) => {
    console.log('anexo > ', e.target.files[0])
  //  let file = e.target.files[0];
   // setImgMsg(URL.createObjectURL(file));
    setImgName(e.target.files[0].name)
    let tamImg = e.target.files[0].size
    if(tamImg > 1000000){
      let strVal = String(tamImg)
      setimgSize(strVal.slice(0,1)+'.'+strVal.slice(1,2) + ' Mb')
    }else{
      let strVal = String(tamImg)
      if(strVal.length>5){
        setimgSize(strVal.slice(0,3)+ ' Kb')
      }else{
        setimgSize(strVal.slice(0,2)+ ' Kb')
      }
      
    }
    
    //this.foto = file;
  };

  const CancelImg = () => {
    setFormArquivo();
  };

  return (
    <FaleConoscoStyle className="fale-conosco-blackfriday">
      <Modal
        show={modalSucesso}
        onHide={() => setModalSucesso(false)}
        dialogClassName="modal-90w"
        className="modal-sucesso"
      >
        <Modal.Body className="text-center">
          <ModalSucesso>
            <div className="modal-sucesso">
              <img src={Checked} alt="" />
              <h2 className="h2-sucesso">Mensagem enviada com sucesso</h2>
              <p className="mensagem-sucesso">
                Em um prazo de <strong>7 dias úteis</strong> nossa equipe de
                atendimento entrará em contato com você.{" "}
              </p>
            </div>
            <Button
              className="btn-laranja btn-enviar col-8"
              onClick={fechaModal}
            >
              Fechar
            </Button>
          </ModalSucesso>
        </Modal.Body>
      </Modal>

      <NavBar user={user} />
      <Container>
        <Breadcrumb className="mt-3">
          <Link className="breadcrumb-item" to="/home">
            Home
          </Link>
          <Breadcrumb.Item active>Fale Conosco</Breadcrumb.Item>
        </Breadcrumb>
        <hr className="mt-3" />
        <Row className="mb-4">
          <Col lg={12} className="d-flex justify-content-center mb-5">
            <div className="col-lg-8 col-12">
              <div className="text-center mb-5 d-flex justify-content-center">
                <div className="titulo-pagina col-lg-10">
                  <h2 className="mb-4">Fale Conosco</h2>
                  <p className="subtitulo">
                    Quer falar com a gente? É só escrever sua mensagem no
                    formulário abaixo.
                  </p>
                  <p>
                    Nosso atendimento é feito de segunda a sexta-feira, das 9h
                    às 18h(exceto feriados) ou entre em contato com a nossa
                    central de relacionamento através do 0800 780 0606
                  </p>
                </div>
              </div>

              <div className="col-lg-12 col-12 d-flex justify-content-center container-campos">
                <div className="col-lg-5 col-11 d-flex flex-column">
                  <a
                    className="btn-wpp-mobile mb-5"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send?phone=551108007800606"
                    target="_blank"
                  >
                    <i className="bi bi-whatsapp me-3"></i>Falar pelo WhatsApp
                  </a>

                  <Link
                      to="/fale-conosco-mensagens"
                      className="btn-laranja-outline btn-historico-mobile mb-5 link-historico-chamadas mb-5"
                    >
                      <i className="bi bi-clock-history me-3"></i>
                      Ver histórico de mensagens
                  </Link>

                  <Form.Group className="mb-5">
                    <Form.Label className="m-0">Assunto</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setFormAssunto(e.target.value)}
                    >
                      <option value="">Selecionar assunto</option>
                      {assuntos.map(({ descricao, id }) => (
                        <option value={id} key={id}>
                          {descricao}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="m-0">Mensagem</Form.Label>
                    <Form.Control
                      style={{ resize: "none" }}
                      as="textarea"
                      rows={3}
                      onBlur={(e) => setFormMsg(e.target.value)}
                    />
                  </Form.Group>
                  <div className="anexo-arquivos mt-2 mb-2">
                    <div className="position-relative">
                      
                        <label
                          htmlFor="file-input"
                          className="label-anexo text-center"
                        >
                          {" "}
                          <img src={iconAnexo} className="m-2" alt="" /> Clique Aqui para anexar arquivos
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={(e) => {
                            if (e.currentTarget.files[0].size <= 2000000) {
                              ImageMsg(e);
                              setFormArquivo(e.currentTarget.files[0]);
                              setImgAviso(false);
                            } else {
                              setImgAviso(true);
                            }                            
                          }}
                        />
                        {imgAviso && (
                            <p className="text-center small text-danger w-100 m-0 mt-2">
                              A imagem é maior que 2 Mb
                            </p>
                          )}
                        <p className="small text-center m-2 text-secondary">Formatos suportados: jpg, png de no máximo 2 Mb</p>
                 
                    </div>
                    {formArquivo &&  <div className="mt-3">
                      <div className="boxAnexos mt-3 mb-3">
                        <img src={images.ImgAnexo} alt="" className="m-3" />
                          <div className="conteudoAnexo">
                            
                            <p>{imgName}</p>
                            <p className="text-secondary">{imgSize}</p>
                          </div>
                          <div className="excluirAnexo">
                            <img src={images.ExluirAnexo} alt="" onClick={CancelImg} />
                          </div>
                      </div>
                    </div>}
                   
                   
                     </div>
                    <Button
                      className="btn-laranja btn-enviar col"
                      onClick={enviaMsg}
                    >
                      Enviar mensagem
                    </Button>
                 
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </FaleConoscoStyle>
  );
}
