import styled from 'styled-components'

export const BodyModal = styled.div`
  background-color: #58595b;
  height: 100vh;

  .info-telefone {
    font-size: 14px;
    color: #fff;
  }
  .body-card {
    @media (min-width: 992px) {
      width: 28rem;
    }
  }
  .icon-size {
    font-size: 4rem;
  }
`;