import styled from "styled-components";

export const ModalBodySucesso = styled.div`
    height: 45vh;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    .btn-fechar{
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
    }
    h2{
        margin-top: 3vh;
    }
    img{
       margin-top: 5vh;
    }
`;
// 