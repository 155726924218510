import { BodyModal } from "./styles";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Regulamento() {
  return (
    <BodyModal className="d-flex justify-content-center align-items-center">
      <Container>
      <Row className="m-0">
        <Col className="d-flex justify-content-center">
            
            <Card className="d-flex justify-content-center align-items-center shadow bd-radius">
              <Card.Body>
              <h3 className="text-center">TERMOS E CONDIÇÕES DO PROGRAMA +TOP E USO DA PLATAFORMA</h3>
                <Card.Text className="text-center modal-text">
                
                  <div>
                    
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      style={{
                        paddingTop: "11pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "107%",
                        textAlign: "justify",
                      }}
                    >
                      O Programa +TOP (“<b>Programa</b>”) é um programa de
                      relacionamento, capacitação e reconhecimento mantido pela
                      Whirlpool S.A., pessoa jurídica de direito privado com
                      sede na Rua Olympia Semeraro 675, Prédio Administrativo,
                      Jardim Santa Emília, São Paulo, SP, inscrita no CNPJ nº.
                      59.105.999/0001-86 (“<b>Whirlpool</b>”) e é destinado
                      exclusivamente aos vendedores, gerentes, gerentes
                      regionais e gestores de informações das Revendas
                      Participantes e que tenham interesse na sua participação.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      className="s1"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "107%",
                        textAlign: "justify",
                      }}
                    >
                      A participação no Programa é absolutamente facultativa,
                      cabendo a cada Revenda Participante
                      <span className="p"> </span>optar pela adesão e
                      participação dela e de seus respectivos colaboradores. É
                      sabido, ainda,<span className="p"> </span>que este
                      Programa <b>não </b>possui qualquer finalidade de
                      fidelização da Revenda Participante ou
                      <span className="p"></span>de estabelecer qualquer tipo de
                      exclusividade ou priorização na comercialização de
                      produtos<span className="p"> </span>Whirlpool em
                      detrimento de outros<span className="p">.</span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      className="s1"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "112%",
                        textAlign: "justify",
                      }}
                    >
                      Neste sentido, a Revenda Participante é (e sempre será)
                      livre para aderir e para declinar da sua
                      <span className="p"></span>participação no Programa, a
                      qualquer tempo, como também tem a Revenda Participante
                      <span className="p"></span>preservado o direito de
                      participar de iniciativas iguais ou similares de todas e
                      quaisquer outras<span className="p"> </span>empresas que
                      as promovam, sejam essas empresas atuantes no mesmo
                      segmento da<span className="p"> </span>Whirlpool ou não
                      <span className="p">.</span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <ol id="l1">
                      <li data-list-text={1}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "justify",
                          }}
                        >
                         1. REVENDA PARTICIPANTE
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l2">
                          <li>
                            <p
                              style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "113%",
                                textAlign: "justify",
                              }}
                            >
                              1.1 Para fins deste Regulamento, Revenda Participante
                              consiste na pessoa jurídica que tenha relação
                              contratual/comercial com a Whirlpool para a compra
                              e posterior revenda dos produtos das marcas
                              Brastemp e Consul (“<b>Produtos</b>”) no
                              território nacional, bem como tenha aderido às
                              condições definidas nestes Termos e Condições para
                              o pleno funcionamento Programa.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li>
                            <p
                              style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              1.2 A Revenda Participante é responsável por informar
                              à Whirlpool os nomes dos funcionários elegíveis
                              que tiverem interesse em participar do Programa,
                              assim como seu número do CPF, cargo (se é
                              vendedor, gerente, gerente regional e gestor da
                              informação), loja (unidade/filial participante) e
                              as vendas dos Produtos Whirlpool realizadas por
                              cada um dos Vendedores Participantes, conforme
                              mecanismos e demais disposições descritas neste
                              Instrumento.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <ol id="l3">
                              <li data-list-text="1.2.1">
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  1.2.1 A responsabilidade pelo envio e veracidade das
                                  informações das vendas realizadas pelos
                                  Participantes ao longo do Programa é
                                  exclusivamente da Revenda Participante, não
                                  podendo a Whirlpool ser responsabilizada por
                                  qualquer falha ou imprecisão das informações
                                  recebidas.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                            </ol>
                          </li>
                          <li data-list-text="1.3">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              1.3 Para fins do disposto nestes Termos, a Revenda
                              Participante e a Whirlpool serão consideradas
                              controladoras independentes de dados pessoais, nos
                              termos e definições da Lei n.º 13.709/2018 (Lei
                              Geral de Proteção de Dados), sendo cada qual
                              responsável pelo tratamento de dados que vier a
                              realizar.
                            </p>
                            <p
                              style={{
                                paddingTop: "3pt",
                                paddingLeft: "42pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              1.3.1. Na qualidade de controladora independente,
                              a Revenda Participante é a única e exclusiva
                              responsável por garantir que quaisquer dados
                              pessoais compartilhados com a Whirlpool para o
                              funcionamento do Programa, especialmente os
                              previstos no item 1.2 acima, tenham sido obtidos
                              em conformidade com os requisitos da Lei Geral de
                              Proteção de Dados, sobretudo no que diz respeito à
                              determinação da base legal adequada, inclusive o
                              consentimento, quando este for aplicável, e às
                              obrigações de transparência para com os
                              funcionários elegíveis.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                        </ol>
                      </li>
                      <li data-list-text={2}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "justify",
                          }}
                        >
                         2. PARTICIPANTES
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l4">
                          <li data-list-text="2.1">
                            <p
                              style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              2.1 Somente poderão participar do Programa os
                              funcionários das Revendas Participantes com os
                              cargos de vendedores, gerentes, gerentes regionais
                              e gestores das Revendas Participantes, maiores de
                              18 anos, que estejam diretamente envolvidos com a
                              venda dos produtos das marcas Brastemp e Consul e
                              cujas informações tenham sido enviadas pela
                              Revenda Participante, conforme estabelecido no
                              item 1 acima (“<b>Participantes</b>”).
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <ol id="l5">
                              <li data-list-text="2.1.1">
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  2.1.1 Cada funcionário da Revenda Participante
                                  somente poderá ser cadastrado como ocupante de
                                  um único cargo, sendo que, em nenhuma
                                  hipótese, poderá o funcionário da Revenda
                                  Participante ter mais de um cargo cadastrado
                                  no Programa.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                            </ol>
                          </li>
                          <li data-list-text="2.2">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              2.2 Caso o funcionário da Revenda Participante seja
                              elegível a participar do Programa, deverá realizar
                              a ativação do seu cadastro no site{" "}
                              <a
                                rel="noopener noreferrer"
                                href="https://docs.google.com/document/d/1jQayzWF7niQA08K_NhAsHpKWDAVF-sULg3zDi4EIELs/edit"
                                className="a"
                                target="_blank"
                              >
                              https://programamaistop.com.br</a> (“<b>Site</b>
                              
                                ”, complementando as informações requeridas e
                                criando uma senha pessoal e intransferível. O
                                Participante deverá ler e aceitar estes Termos e
                                Condições e as condições do Programa, bem como
                                ler e compreender a{" "}
                          
                                Política de Privacidade{" "}
                           
                             
                              aplicável ao Programa e igualmente disponível no
                              Site.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <ol id="l6">
                              <li data-list-text="2.2.1">
                                <p
                                  style={{
                                    paddingLeft: "47pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  
                                  2.2.1 É de inteira responsabilidade do
                                    Participante o preenchimento de todos os
                                    dados solicitados de maneira correta e
                                    verídica. Caso haja o fornecimento de dados
                                    incorretos, inverídicos e/ou incompletos, o
                                    Participante não será considerado cadastrado
                                    neste Programa; e se identificado que o
                                    Participante inseriu informações
                                    não-verdadeiras, a sua participação no
                                    Programa poderá ser cancelada, a qualquer
                                    tempo. Da mesma forma, caso o Participante
                                    perceba qualquer erro cadastral, deverá
                                    comunicar à Whirlpool imediatamente por meio
                                    da Central de Atendimento ao Participante
                                    (0800 780 0606 ou{" "}
                                 
                                  <a
                                    rel="noopener noreferrer"
                                    href="http://www.programamaistop.com.br/FaleConosco/"
                                    target="_blank"
                                  >
                                    www.programamaistop.com.br/FaleConosco/
                                  </a>
                                  ).
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="2.2.2">
                                <p
                                  style={{
                                    paddingLeft: "47pt",
                                    textIndent: "0pt",
                                    lineHeight: "113%",
                                    textAlign: "justify",
                                  }}
                                >
                                  2.2.2 Ao aceitar estes termos e condições, o
                                  Participante autoriza a Whirlpool a lançar seu
                                  nome no Ranking e divulgá-lo no Site.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="2.2.3">
                                <p
                                  style={{
                                    paddingLeft: "47pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                 2.2.3 <u>Status Pré-Cadastro</u>. Caso o
                                  Participante não ative seu cadastro na
                                  plataforma por prazo superior a 90 (noventa)
                                  dias, contados da data de seu cadastro no
                                  sistema e conforme estipulado no item 2.2
                                  supra, o seu acesso/perfil será
                                  automaticamente alterado para <i>inativo</i>,
                                  deixando de fazer jus aos benefícios do
                                  Programa. Para ativar o
                                </p>
                                <p
                                  style={{
                                    paddingTop: "3pt",
                                    paddingLeft: "47pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  seu cadastro, o Participante deverá entrar em
                                  contato com a Central de Atendimento.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                            </ol>
                          </li>
                          <li data-list-text="2.3">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              2.3 A sua participação no Programa e a sua pontuação
                              somente valerão a partir da realização do seu
                              cadastro no Site, a sua posterior ativação com
                              confirmação pela Whirlpool. Para ser elegível ao
                              recebimento de qualquer pontuação, o Participante
                              deverá, ainda, acessar a Plataforma ao menos uma
                              vez ao mês para validar a sua participação no
                              Programa nos termos aqui previstos, bem como para
                              dar o aceite nas condições aplicáveis no
                              respectivo mês de acesso.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="2.4">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              2.4 Caso o Participante não realize ao menos um login
                              por mês na Plataforma (inclusive para realizar o
                              aceite mensal indicado no item 2.3) e essa
                              situação perdurar por 02 (dois) meses
                              consecutivos, o perfil do Participante será
                              alterado para <i>Somente Catálogo</i>, o que fará
                              com que ele não possa acumular novos pontos, sendo
                              assegurado a ele o direito de resgatar seus
                              pontos. Se a situação persistir, no mês seguinte
                              (3º mês consecutivo) o respectivo perfil será
                              alterado para
                              <i>Inativado</i>, ocasião em que não poderá
                              receber novos pontos ou convertê-los em produtos.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <ol id="l7">
                              <li data-list-text="2.4.1">
                                <p
                                  style={{
                                    paddingLeft: "46pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  2.4.1 O Participante será automaticamente excluído
                                  do Programa em caso de rescisão do contrato ou
                                  desligamento da Revenda Participante a qual
                                  estava vinculado, independentemente do motivo.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="2.4.2">
                                <p
                                  style={{
                                    paddingLeft: "46pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  2.4.2 Em caso de rescisão contratual ou desligamento
                                  da Revenda Participante durante o prazo de
                                  vigência do Programa, o Participante terá o
                                  prazo improrrogável de até 90 (noventa) dias
                                  para o resgate dos pontos acumulados por ele
                                  após a inativação da Revenda Participante. Se,
                                  no decorrer do Programa, o Participante
                                  tornar-se elegível novamente, em decorrência
                                  da contratação por outra Revenda Participante,
                                  os pontos acumulados por ele poderão ser
                                  resgatados no prazo aqui indicado. E uma vez
                                  transcorrido o prazo aqui indicado, os pontos
                                  expirarão sem possibilidade de reversão.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                            </ol>
                          </li>
                          <li data-list-text="2.5">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              2.5 Os Participantes que forem admitidos pelas
                              Revendas Participantes após o início do Programa
                              terão seus pontos computados somente a partir do
                              mês em que a própria Revenda Participante fizer o
                              encaminhamento das informações pertinentes às
                              vendas realizadas por eles, bem como a partir de
                              quando for concluído o cadastro do Participante no
                              Site.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="2.6">
                            <p
                              style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              2.6 Será automaticamente desclassificado e excluído do
                              Programa, sem necessidade de prévia comunicação, o
                              Participante que praticar qualquer tipo de ato
                              considerado fraudulento, ilegal, ilícito, ou que
                              atente contra os objetivos deste Programa e/ou
                              estes termos e condições.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="2.7">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "113%",
                                textAlign: "justify",
                              }}
                            >
                              2.7 Não será aceito o pedido de cadastro que não
                              preencher todos os requisitos descritos nestes
                              termos e condições.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                        </ol>
                      </li>
                      <li data-list-text={3}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "justify",
                          }}
                        >
                          3. DO PROGRAMA E PONTUAÇÃO
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l8">
                          <li data-list-text="3.1">
                            <p
                              style={{
                                paddingLeft: "24pt",
                                textIndent: "-18pt",
                                textAlign: "justify",
                              }}
                            >
                              3.1 Este Programa terá duração de 11 de abril de 2022
                              a 31 de dezembro de 2023.
                            </p>
                          </li>
                          <li data-list-text="3.2">
                            <p
                              style={{
                                paddingTop: "3pt",
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              3.2 Os Participantes receberão pontos, que podem ser
                              acumulados e trocados por prêmios do Portal de
                              Prêmios, conforme disposições no Site e
                              disponibilidade de produtos. Em nenhuma hipótese
                              haverá pagamento em dinheiro ao Participante ou
                              conversão dos pontos em qualquer outro benefício.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="3.3">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              3.3 Os pontos começam a ser contabilizados aos
                              Participantes a partir do mês que for realizado o
                              seu cadastro de forma correta e desde que eles
                              tenham dado o aceite nestes Termos e Condições e
                              nas condições específicas disponibilizadas na
                              Plataforma e aplicáveis a cada mês, nos termos do
                              item 2.3 acima. Em nenhuma hipótese, serão aceitas
                              as vendas realizadas em meses anteriores à
                              realização do cadastro e do aceite das condições
                              previstas nos documentos ora indicados. Caberá à
                              Revenda Participante, por meio do Gestor de
                              Informações, disponibilizar as informações de
                              vendas de cada Participante nos respectivos meses.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="3.4">
                            <p
                              style={{
                                paddingLeft: "7pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              3.4 Fica desde já estabelecido que o valor de cada
                              ponto conquistado pelo Participante neste Programa
                              é de R$1,00 (um real).
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                        </ol>
                      </li>
                      <li data-list-text={4}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "justify",
                          }}
                        >
                          4. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS VENDEDORES
                          PARTICIPANTES
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l9">
                          <ol id="l10">
                            <li data-list-text="4.1.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                4.1. Os funcionários da Revenda Participante com o
                                cargo de vendedores e que preencherem todos os
                                requisitos estipulados no item 2 (“
                                <b>Vendedores Participantes</b>”) serão
                                avaliados por meio da venda dos Produtos
                                Whirlpool comercializados durante o período do
                                Programa.
                              </p>
                            </li>
                            <li data-list-text="4.2.">
                              <p
                                style={{
                                  paddingTop: "10pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                4.2. Os Vendedores Participantes receberão pontos de
                                acordo com as vendas feitas, que podem ser
                                acumulados e trocados por prêmios do catálogo
                                online, acessível através do Site, conforme
                                demonstra a tabela constante no Site do
                                Programa.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <ol id="l11">
                                <li data-list-text="4.2.1">
                                  <p
                                    style={{
                                      paddingLeft: "47pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    4.2.1 Apenas as categorias de Produtos
                                    participantes indicadas na tabela constante
                                    no Site serão pontuadas de acordo com as
                                    vendas. Vendas de categorias que não
                                    estiverem indicadas no Site não serão
                                    consideradas para pontuação no Programa.
                                  </p>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l12">
                          <ol id="l13">
                            <li data-list-text="4.3">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                4.3 A cada início de mês, a Whirlpool estabelecerá e
                                comunicará no Site as Categorias e/ou Produtos,
                                por Revenda Participante, que valerão mais
                                pontos do que a tabela regular de pontuação que
                                consta no Site. A venda de cada unidade vendida
                                dessas Categorias e/ou Produtos definidos
                                acumularão a quantidade específica de pontos
                                comunicada a cada mês.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="4.4">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                4.4 Os pontos a que o Vendedor Participante fará jus
                                ao recebimento serão definidos com base na
                                performance da Revenda Participante da qual ele
                                faz parte. Para o recebimento de tais pontos, o
                                Vendedor Participante tem de realizar os
                                treinamentos disponibilizados a ele a cada mês,
                                tendo necessariamente de acertar, no mínimo, 80%
                                (oitenta por cento) da avaliação de cada
                                treinamento. O não atingimento da meta mínima de
                                acertos (80% de cada treinamento) impossibilita
                                ao Vendedor Participante o recebimento de pontos
                                extras.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="4.5">
                              <p
                                style={{
                                  paddingLeft: "24pt",
                                  textIndent: "-18pt",
                                  textAlign: "justify",
                                }}
                              >
                                4.5 No mês de aniversário do Vendedor Participante,
                                ele fará jus a 10 (dez) pontos.
                              </p>
                            </li>
                          </ol>
                        </ol>
                      </li>
                      <li data-list-text={5}>
                        <h2
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "left",
                          }}
                        >
                          5. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS GERENTES
                          PARTICIPANTES
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l14">
                          <ol id="l15">
                            <li data-list-text="5.1.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                5.1. Os funcionários com o cargo de gerentes dos
                                Vendedores Participantes de uma loja da Revenda
                                Participante e que preencherem todos os
                                requisitos estipulados no item 2 (“
                                <b>Gerentes Participantes</b>”) receberão 10%
                                (dez por cento) da pontuação alcançada pela sua
                                equipe de Vendedores Participantes.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="5.2.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "113%",
                                  textAlign: "justify",
                                }}
                              >
                                5.2. Caso a Revenda Participante informe mais de 01
                                (um) Gerente Participante por loja, será
                                considerada a mesma pontuação para ambos os
                                Gerentes Participantes.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="5.3.">
                              <p
                                style={{
                                  paddingLeft: "27pt",
                                  textIndent: "-21pt",
                                  textAlign: "left",
                                }}
                              >
                                5.3. No mês de aniversário do Gerente Participante
                                cadastrado, este receberá 10 (dez) pontos.
                              </p>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "112%",
                            textAlign: "justify",
                          }}
                        >
                          5.4 Se o Gerente Participante não acessar o Site do
                          Programa, por 02 (dois) meses consecutivos, terá seu
                          status alterado automaticamente para{" "}
                          <i>Somente Catálogo</i>, ocasião em que não receberá
                          nenhum ponto a partir da alteração do status. Se o
                          Gerente Participante não acessar o Site do Programa
                          por 03 (três) meses consecutivos, terá seu status
                          alterado para <i>Inativo</i>, não podendo receber
                          novos pontos ou convertê-los em produtos.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={6}>
                        <h2
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "113%",
                            textAlign: "left",
                          }}
                        >
                          6. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS GERENTES
                          REGIONAIS PARTICIPANTES
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l16">
                          <ol id="l17">
                            <li data-list-text="6.1.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                6.1. Os funcionários com o cargo de gerente regional
                                da Revenda Participante e que preencherem todos
                                os requisitos estipulados no item 2 (“
                                <b>Gerentes Regionais</b>”) receberão 5% (cinco
                                por cento) da pontuação alcançada no mês,
                                atribuída aos Vendedores Participantes das lojas
                                atendidas por ele.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="6.2.">
                              <p
                                style={{
                                  paddingLeft: "27pt",
                                  textIndent: "-21pt",
                                  textAlign: "left",
                                }}
                              >
                                6.2. No mês de aniversário do Gerente Regional, este
                                receberá 10 (dez) pontos.
                              </p>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={7}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "left",
                          }}
                        >
                          7. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS GESTORES DE
                          INFORMAÇÃO
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l18">
                          <ol id="l19">
                            <li data-list-text="7.1.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                7.1. Serão considerados “
                                <b>Gestores da Informação</b>”, para fins
                                exclusivos deste Programa, os funcionários das
                                Revendas Participantes escolhidos por estas no
                                momento de aceite de Programa e responsáveis por
                                passar as informações descritas na Cláusula 1.2,
                                bem como outras informações necessárias para a
                                execução desse Programa.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="7.2.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                7.2. Cada Revenda Participante poderá indicar apenas
                                um Gestor da Informação. O Gestor da Informação
                                deverá, além de realizar o cadastro no site do
                                Programa, enviar corretamente as bases de
                                Vendedores Participantes e informações sobre as
                                vendas realizadas no respectivo mês, através do
                                Site no modelo pré-acordado com cada Revenda
                                Participante, até no máximo o 5º (quinto) dia
                                útil do mês subsequente.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="7.3.">
                              <p
                                style={{
                                  paddingLeft: "29pt",
                                  textIndent: "-22pt",
                                  textAlign: "left",
                                }}
                              >
                                7.3. O Gestor da Informação receberá 300 (trezentos)
                                pontos sempre que fizer o envio correto
                              </p>
                              <p
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                das informações, dentro do modelo padrão e
                                dentro do prazo acima estabelecido. Na hipótese
                                do não envio das informações até o 5º (quinto)
                                dia útil do mês, o Gestor da Informação não fará
                                jus ao recebimento dos 300 (trezentos) pontos,
                                bem como não receberá a pontuação estabelecida
                                no item 7.3.1. a seguir.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <ol id="l20">
                                <li data-list-text="7.3.1.">
                                  <p
                                    style={{
                                      paddingLeft: "41pt",
                                      textIndent: "0pt",
                                      lineHeight: "106%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    7.3.1. Se, por 03 (três) meses consecutivos, o
                                    Gestor da Informação enviar corretamente as
                                    bases e informações na forma e prazo
                                    estabelecidos nas cláusulas 7.2 e 7.3 supra,
                                    o Gestor da Informação receberá 300
                                    (trezentos) pontos adicionais além daqueles
                                    já indicados no item 7.3.
                                  </p>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l21">
                          <ol id="l22">
                            <ol id="l23">
                              <li data-list-text="7.3.2">
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "107%",
                                    textAlign: "justify",
                                  }}
                                >
                                  7.3.2. A pontuação trimestral, conforme estabelecido
                                  na Cláusula 7.3.1, será sempre creditada nos
                                  meses de março, junho, setembro e dezembro. Se
                                  o Gestor da Informação se inscrever no
                                  Programa entre esses meses, no primeiro
                                  trimestre, o Gestor da Informação ganhará a
                                  pontuação proporcional ao período remanescente
                                  do correspondente trimestre.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="7.3.3">
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  7.3.3. Caso a Revenda Participante não autorize a
                                  participação de um Gestor da Informação, a
                                  Revenda Participante se responsabiliza pela
                                  veracidade das informações por ela repassadas,
                                  bem como por cumprir com os prazos de envio
                                  definidos nesta seção 7.
                                </p>
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="7.3.4">
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "114%",
                                    textAlign: "justify",
                                  }}
                                >
                                  7.3.4. No mês de aniversário do Gestor da Informação
                                  Participante, este fará jus a 10 (dez) pontos.
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={8}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "justify",
                          }}
                        >
                          8. PREMIAÇÃO NO PORTAL DE PRÊMIOS
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l24">
                          <ol id="l25">
                            <li data-list-text="8.1.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                8.1. Os pontos acumulados pelos Participantes neste
                                Programa poderão ser trocados por produtos do
                                catálogo disponibilizado no site de prêmios (“
                                <b>Prêmio</b>”). As condições para a troca dos
                                pontos por Prêmios serão aquelas estabelecidas
                                pelo site de prêmios.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="8.2.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                8.2. No momento em que for solicitado o resgate, será
                                verificado se a quantidade de pontos válidos é
                                suficiente para a troca desejada. Em caso
                                negativo, o Participante será informado da
                                insuficiência de saldo para resgate, uma vez que
                                não é possível utilizar, cumulativamente, pontos
                                e recursos financeiros próprios para o resgate
                                de Prêmios.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="8.3.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                8.3. Os saldos atualizados dos pontos estarão
                                disponíveis para o Participante até o 20º
                                (vigésimo) dia útil do mês de apuração, desde
                                que a Revenda Participante forneça as
                                informações até o 15º (décimo quinto) dia deste
                                mesmo mês.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="8.4.">
                              <p
                                style={{
                                  paddingLeft: "7pt",
                                  textIndent: "0pt",
                                  lineHeight: "119%",
                                  textAlign: "justify",
                                }}
                              >
                                8.4. No caso de indisponibilidade do Prêmio escolhido
                                pelo Participante no momento em que for
                                solicitado o resgate, caberá a ele, o
                                Participante, selecionar outro Prêmio que esteja
                                disponível. A disponibilidade de prêmios varia
                                de acordo com o momento de resgate, não havendo
                                qualquer ingerência da Whirlpool na definição de
                                quais prêmios estarão disponíveis e em qual
                                período.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="8.5.">
                              <p
                                style={{
                                  paddingLeft: "30pt",
                                  textIndent: "-24pt",
                                  textAlign: "justify",
                                }}
                              >
                                8.5. O resgate dos pontos poderá ser parcial ou
                                integral, de acordo com o interesse dos
                              </p>
                            </li>
                          </ol>
                        </ol>
                        <p
                          style={{
                            paddingTop: "3pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "left",
                          }}
                        >
                          Participantes e de acordo com os termos deste
                          Programa.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={9}>
                        <h2
                          style={{
                            paddingLeft: "18pt",
                            textIndent: "-12pt",
                            textAlign: "justify",
                          }}
                        >
                         9. DA VALIDADE DOS PONTOS E DA ENTREGA DOS PRÊMIOS
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l26">
                          <ol id="l27">
                            <li data-list-text="9.1.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "113%",
                                  textAlign: "justify",
                                }}
                              >
                                9.1. Observados e cumpridos todas as disposições
                                deste instrumento, incluindo, mas não se
                                limitando à Cláusula 2ª, e salvo disposição
                                contrária, o Participante terá até 12 (doze)
                                meses após a data de crédito da pontuação para
                                resgatar os pontos acumulados, realizando a
                                troca por Prêmios.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <ol id="l28">
                                <li data-list-text="9.1.1.">
                                  <p
                                    style={{
                                      paddingLeft: "41pt",
                                      textIndent: "0pt",
                                      lineHeight: "113%",
                                      textAlign: "left",
                                    }}
                                  >
                                    9.1.1 Após o término do prazo acima mencionado, os
                                    pontos não estarão mais disponíveis para
                                    resgate do Participante, expirando-se
                                    automaticamente.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="9.2.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                9.2. Os Prêmios resgatados serão entregues pela
                                empresa responsável no endereço cadastrado pelo
                                Participante no Site, de acordo com o prazo de
                                entrega de cada parceiro, considerando que o
                                Prêmio esteja disponível em estoque.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="9.3.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                9.3. A partir do resgate, o Participante contemplado
                                deverá seguir as condições e regras gerais da
                                Política de troca da empresa responsável pelo
                                site de prêmios, ficando a Whirlpool totalmente
                                isenta de qualquer responsabilidade pelo envio
                                do prêmio resgatado.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <ol id="l29">
                                <li data-list-text="9.3.1.">
                                  <p
                                    style={{
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "113%",
                                      textAlign: "left",
                                    }}
                                  >
                                    9.3.1. Fica determinado que eventuais despesas com
                                    frete para entrega dos Prêmios resgatados
                                    serão suportadas pelo Participante.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="9.4.">
                              <p
                                style={{
                                  paddingLeft: "27pt",
                                  textIndent: "-21pt",
                                  textAlign: "justify",
                                }}
                              >
                                9.4. Toda pontuação e os prêmios concedidos são
                                pessoais e intransferíveis.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="9.5.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "117%",
                                  textAlign: "justify",
                                }}
                              >
                                9.5. Não será permitido ao Participante, em hipótese
                                alguma, trocar sua pontuação ou Prêmios
                                resgatados por qualquer outro bem, produto ou
                                serviço, nem mesmo por dinheiro.
                              </p>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "112%",
                            textAlign: "justify",
                          }}
                        >
                          9.6 O prazo para contestação de qualquer pontuação é
                          de 07 (sete) dias corridos contados da data do seu
                          crédito ou da data em que tal crédito deveria ter sido
                          computado; ou de 07 dias corridos a partir da
                          divulgação dos ganhadores, não podendo o Participante
                          reclamar sobre divergências ou irregularidades em sua
                          pontuação após este prazo.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={10}>
                        <h2
                          style={{
                            paddingLeft: "24pt",
                            textIndent: "-18pt",
                            textAlign: "justify",
                          }}
                        >
                          10. CAMPANHAS ADICIONAIS
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l30">
                          <ol id="l31">
                            <li data-list-text="10.1.">
                              <p
                                style={{
                                  paddingLeft: "34pt",
                                  textIndent: "-27pt",
                                  textAlign: "justify",
                                }}
                              >
                                10.1. Campanhas adicionais são aquelas lançadas pela
                                Whirlpool sazonalmente no Site.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="10.2.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "113%",
                                  textAlign: "justify",
                                }}
                              >
                                10.2. Para participação de campanhas adicionais, as
                                pessoas elegíveis para participar da
                                correspondente campanha adicional deverão
                                acessar o Site e aceitar a respectiva Campanha.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="10.3.">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                10.3. Para apuração de campanhas adicionais, serão
                                considerados válidos apenas os resultados dos
                                Participantes que confirmaram a sua participação
                                no Site mediante aceitação dos Termos e
                                Condições/Regulamento das respectivas Campanhas
                                e que foram abrangidos pelas informações
                                fornecidas pelo Gestor da Informação da Revenda
                                Participante.
                              </p>
                              <ol id="l32">
                                <li data-list-text="10.3.1">
                                  <p
                                    style={{
                                      paddingTop: "3pt",
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    10.3.1 Estes termos e condições são complementares
                                    ao regulamento específico de cada Campanha
                                    adicional e, caso tal regulamento seja
                                    omisso, as disposições destes termos e
                                    condições são aplicáveis, o que inclui, mas
                                    não se limita à cláusula 9ª e 12ª.
                                  </p>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l33">
                          <ol id="l34">
                            <li data-list-text="10.4">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                10.4 Ademais, caso o Regulamento específico da
                                Campanha Adicional não estipule uma regra
                                específica de desempate entre os vendedores
                                ganhadores da correspondente premiação, será
                                obedecida a seguinte ordem de desempate:
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingLeft: "42pt",
                                  textIndent: "0pt",
                                  lineHeight: "114%",
                                  textAlign: "left",
                                }}
                              >
                                1º) Maior número de unidades vendidas de
                                produtos supertops no período da Campanha;
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingLeft: "42pt",
                                  textIndent: "0pt",
                                  lineHeight: "113%",
                                  textAlign: "left",
                                }}
                              >
                                2º) Se o empate persistir: maior número de
                                unidades de produtos participantes no período da
                                Campanha;
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingLeft: "42pt",
                                  textIndent: "0pt",
                                  lineHeight: "114%",
                                  textAlign: "left",
                                }}
                              >
                                3º) Se o empate persistir: maior quantidade de
                                treinamentos obrigatórios realizados no período
                                da Campanha;
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingLeft: "42pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                4º) Se o empate persistir: Participante com data
                                mais antiga de cadastro.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="10.5">
                              <p
                                style={{
                                  paddingLeft: "5pt",
                                  textIndent: "1pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                10.5 Caso o prêmio de uma Campanha Adicional seja uma
                                viagem, não será possível modificar o destino e
                                o período da viagem a pedido do Vendedor
                                Participante, os quais serão definidos
                                unicamente pela Whirlpool. Cabe aos
                                Participantes e à Revenda Participante
                                conversarem entre si sobre a possibilidade de
                                ausência do referido Participante durante
                                referido período. A premiação em viagem é
                                limitada ao custeio das passagens e eventuais
                                outros custos divulgados expressamente pela
                                Whirlpool, estando certo que quaisquer custos
                                adicionais relacionados direta ou indiretamente
                                à viagem, tais como estada, passeios, transfer,
                                visto e passaporte, se necessários, serão de
                                responsabilidade integral do Vendedor
                                Participante.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                              <ol id="l35">
                                <li data-list-text="10.5.1">
                                  <p
                                    style={{
                                      paddingLeft: "5pt",
                                      textIndent: "36pt",
                                      lineHeight: "114%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    10.5.1 A Whirlpool reserva a si a faculdade de
                                    alterar o prêmio ou, caso mantido o prêmio
                                    <i>viagem</i>, a Whirlpool reserva a si a
                                    faculdade de alterar o destino e o período
                                    da viagem, a qualquer tempo.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="10.6">
                              <p
                                style={{
                                  paddingLeft: "5pt",
                                  textIndent: "1pt",
                                  lineHeight: "117%",
                                  textAlign: "justify",
                                }}
                              >
                                10.6 Em caso de divergências entre os presentes
                                Termos e Condições e o Regulamento de cada
                                Campanha Adicional, prevalecerão os termos do
                                presente Instrumento.
                              </p>
                            </li>
                          </ol>
                        </ol>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={11}>
                        <h2
                          style={{
                            paddingLeft: "24pt",
                            textIndent: "-17pt",
                            textAlign: "justify",
                          }}
                        >
                          11. INFORMAÇÕES SOBRE A PROPRIEDADE INTELECTUAL E DIREITOS
                          AUTORAIS
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <ol id="l36">
                          <li data-list-text="11.1">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              11.1 Ao aceitar estes Termos, o Participante reconhece
                              e concorda que todo o conteúdo apresentado no Site
                              está protegido por direito autoral e direito de
                              propriedade intelectual. O Participante só poderá
                              utilizar o seu conteúdo conforme expressamente
                              indicado neste regulamento, ou seja, para o seu
                              uso pessoal e sem que isso signifique qualquer
                              tipo de licença. O Participante não pode copiar,
                              reproduzir, modificar, republicar, carregar,
                              publicar, transmitir ou distribuir qualquer
                              documento ou informação do Site de qualquer forma
                              ou por outro meio. O uso não autorizado dos
                              materiais que aparecem no Site pode violar
                              direitos
                            </p>
                            <p
                              style={{
                                paddingTop: "3pt",
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                textAlign: "left",
                              }}
                            >
                              autorais e direito de propriedade intelectual,
                              podendo resultar em penalidades criminais ou
                              civis.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                        </ol>
                      </li>
                      <li data-list-text={12}>
                        <h2
                          style={{
                            paddingLeft: "24pt",
                            textIndent: "-18pt",
                            textAlign: "justify",
                          }}
                        >
                          12. ACEITAÇÃO TOTAL DOS TERMOS
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: "5pt",
                            textIndent: "1pt",
                            lineHeight: "113%",
                            textAlign: "justify",
                          }}
                        >
                          12.1. O Participante manifesta expressamente ter a
                          capacidade legal para usar o Site. Assim, manifesta
                          ter fornecido informação real, veraz e fidedigna.
                          Portanto, de forma expressa e inequívoca declara ter
                          lido e que aceita todas as disposições contidas nestes
                          Termos e Condições.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={13}>
                        <h2
                          style={{
                            paddingLeft: "24pt",
                            textIndent: "-18pt",
                            textAlign: "justify",
                          }}
                        >
                          13. CONSIDERAÇÕES GERAIS
                        </h2>
                      </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <ol id="l37">
                      <ol id="l38">
                        <li data-list-text="13.1.">
                          <p
                            style={{
                              paddingLeft: "5pt",
                              textIndent: "1pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            13.1. Ao participar deste Programa e ao aceitar estes
                            Termos e Condições, os Participantes autorizam o uso
                            de sua imagem, voz e textos, bem como os direitos de
                            expor, publicar, reproduzir, armazenar e/ou de
                            qualquer outra forma delas se utilizarem, o que os
                            Participantes fazem de modo expresso e em caráter
                            irrevogável e irretratável, desde já e de pleno
                            direito, em caráter gratuito e sem qualquer
                            remuneração, ônus ou encargo, podendo referidos
                            direitos serem exercidos por meio de cartazes,
                            filmes e/ou spots, jingles e/ou vinhetas, bem como
                            em qualquer tipo de mídia e/ou peças promocionais,
                            inclusive em televisão, rádio, jornal, cartazes,
                            faixas, outdoors, mala-direta e na Internet, para a
                            ampla divulgação deste Regulamento e/ou de seu
                            desenvolvimento posterior, com exclusividade e sem
                            que tal autorização signifique, implique ou resulte
                            em qualquer obrigação de divulgação nem de
                            pagamento, concordando ainda, inclusive, em assinar
                            eventuais recibos e instrumentos neste sentido e
                            para tal efeito, sempre que solicitado pela
                            Realizadora.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.2.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            13.2. Aceitando participar do Programa e estes Termos e
                            Condições, o Participante entende que não estabelece
                            nenhum vínculo de natureza empregatícia,
                            subordinação ou de responsabilidade entre a
                            Whirlpool e o Participante.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.3.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            13.3. O Programa, por exclusivo critério da Whirlpool e a
                            qualquer tempo, poderá ser interrompido, suspenso ou
                            encerrado, bem como pode ter os seus termos e
                            condições modificados a qualquer tempo, inclusive
                            alterar as regras das campanhas adicionais, o que
                            inclui, mas não se limita a alterar os pontos e as
                            premiações. Nesses casos, todas as informações
                            pertinentes serão divulgadas através de e-mail ou
                            outro meio de comunicação escolhido pela Whirlpool.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.4.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "left",
                            }}
                          >
                            13.4. A participação no Programa será voluntária e
                            facultativa, não havendo qualquer sanção àqueles que
                            optarem por não participar.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.5.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "left",
                            }}
                          >
                            13.5. Para obter esclarecimentos sobre este Programa, os
                            interessados poderão fazer contato com a Whirlpool
                            através do telefone ou WhatsApp{" "}
                            <b>0800 780 0606 </b>ou pelo “Fale Conosco”
                            disponível no Site.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.6.">
                          <p
                            style={{
                              paddingLeft: "34pt",
                              textIndent: "-26pt",
                              textAlign: "left",
                            }}
                          >
                            13.6. As dúvidas não esclarecidas neste Instrumento serão
                            dirimidas pela Whirlpool, de forma
                          </p>
                          <p
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            soberana e irrecorrível.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.7.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            13.7. O Participante se obriga a manter atualizados seus
                            dados cadastrais junto ao Site. Desse modo,
                            comunicações enviadas ao Participante serão tidas
                            como devidamente efetivadas se dirigidas a um dos
                            meios de contato presentes no cadastro.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.8.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            13.8. Tendo em vista as características inerentes ao
                            ambiente da Internet, a Whirlpool não garante o
                            pleno funcionamento e acesso ao Site de maneira
                            ininterrupta. Ademais, a Whirlpool não garante que o
                            Site esteja livre de invasões, interrupções ou
                            suspensões. O Participante reconhece e concorda que
                            a Whirlpool não poderá ser responsabilizada por tais
                            fatos e/ou atos em decorrência de hipótese de força
                            maior, caso fortuito ou ainda pela ocorrência
                            indicada no item 13.8.2.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l39">
                            <li data-list-text="13.8.1.">
                              <p
                                style={{
                                  paddingLeft: "41pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                13.8.1. Em razão do exposto no item anterior, na
                                hipótese de ocorrência de invasões, interrupções
                                ou suspensões de qualquer gênero, durante o
                                período de vigência do Programa, a Whirlpool não
                                ficará obrigada a prorrogar o prazo do mesmo,
                                salvo decisão em contrário a exclusivo critério
                                da Whirlpool.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="13.8.2.">
                              <p
                                style={{
                                  paddingLeft: "41pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                13.8.2. O Participante reconhece que dada as
                                características do Programa no ambiente da
                                Internet, terceiros de má-fé poderão remotamente
                                tentar utilizar-se de subterfúgios tecnológicos
                                a fim de terem acesso a dados pessoais do
                                Participante ou a fraudar o programa. Diante
                                disso, o Participante também reconhece que a
                                Whirlpool não poderá ser responsabilizada pelos
                                atos praticados por terceiros.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                            <li data-list-text="13.8.3.">
                              <p
                                style={{
                                  paddingLeft: "41pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                13.8.3. A Whirlpool reserva a si o direito de efetuar
                                toda e qualquer ação corretiva que se aplique às
                                ações de terceiros que venham a interferir no
                                bom funcionamento do Programa, como também
                                reserva a si o direito de efetuar análises
                                periódicas, baseadas nos registros dos
                                Participantes inscritos, hipótese em que, se
                                constatadas inconsistências, fica-lhe facultado
                                o direito de efetuar automático bloqueio da
                                respectiva conta.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li data-list-text="13.9.">
                          <p
                            style={{
                              paddingLeft: "41pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            13.9. A participação neste Programa implica na aceitação
                            total de todos os itens deste Regulamento, não sendo
                            dado a qualquer dos Participantes o direito de
                            alterá-lo.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l40">
                            <li data-list-text="13.9.1.">
                              <p
                                style={{
                                  paddingLeft: "77pt",
                                  textIndent: "0pt",
                                  lineHeight: "125%",
                                  textAlign: "justify",
                                }}
                              >
                                13.9.1 O Regulamento poderá ser alterado pela Whirlpool
                                tantas vezes quanto necessário, a seu exclusivo
                                critério, com posterior divulgação. A
                                participação não implica em qualquer
                                exclusividade de venda, sendo os Participantes
                                livres para comercializar quaisquer produtos de
                                outras empresas de acordo com os seus interesses
                                e com as orientações de seu empregador.
                              </p>
                              <p
                                style={{ textIndent: "0pt", textAlign: "left" }}
                              >
                                <br />
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li data-list-text="13.10.">
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "113%",
                              textAlign: "justify",
                            }}
                          >
                           13.10. A participação no Programa é voluntária e gratuita,
                            não estando sujeita a qualquer espécie de cobrança
                            ou à necessidade de desembolso por parte dos
                            Participantes.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text="13.11.">
                          <p
                            style={{
                              paddingLeft: "7pt",
                              textIndent: "0pt",
                              lineHeight: "113%",
                              textAlign: "left",
                            }}
                          >
                            13.11. Em caso de fraude comprovada ou descumprimento deste
                            Regulamento, o Participante será automaticamente
                            excluído do Programa.
                          </p>
                        </li>
                        <li data-list-text="13.12.">
                          <h2
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            Lei aplicável e foro
                            <span className="p">
                            13.12. Esses termos e condições são regidos,
                              interpretados e executados de acordo com as leis
                              da República Federativa do Brasil,
                              independentemente dos conflitos dessas leis com
                              leis de outros estados ou países, sendo competente
                              o Foro da Comarca de São Paulo (SP), para dirimir
                              qualquer dúvida decorrente deste instrumento. O
                              Participante consente, expressamente, com a
                              competência desse juízo, e renuncia, neste ato, à
                              competência de qualquer outro foro, por mais
                              privilegiado que seja ou venha a ser.
                            </span>
                          </h2>
                        </li>
                      </ol>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <h2
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Versão: <span className="p">setembro de 2023.</span>
                    </h2>
                  </div>
                </Card.Text>
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    style={{ height: "2rem" }}
                    className="col-5 mt-4 mb-3 btn btn-laranja text-white justify-content-center d-flex align-items-center"
                    to="/home"
                  >
                    Fechar
                  </Link>
                </div>
              </Card.Body>
            </Card>
          
        </Col>
      </Row>
      </Container>
    </BodyModal>
  );
}
