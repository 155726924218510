import { createContext, useContext, useState } from "react";

const LoginContext = createContext(false)

export function LoginProvider({ children }){
    const [login, setLogin] = useState(false)

    async function createLogin(data){

        console.log('loginnn >', data)
     /*   let dataLogin = {
            Cpf: "38473800877",
            token: "",
            Password: "Prod@999*",
            Ip: "",
            AppId: "",
            Sistema: 1
        }*/
        
        //     api.get(rotasApi.appVersion)
        //      .then(response => console.log(response.data))
    
        //    const response = await api.post(rotasApi.login, data)
            
        
        //console.log(response.data)
        
        //const login = localStorage.getItem('token')
        
        if(data){
            setLogin(true)
        }else{
            setLogin(false)
        }
        

    }
    async function Logout(){
      setLogin(false)
    }


    return (
        <LoginContext.Provider value={{ login, createLogin, Logout }}>
            {children}
        </LoginContext.Provider>
    )
}

export function useLogin(){
    const context = useContext(LoginContext)
    return context
}
