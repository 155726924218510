import { Breadcrumb, Col, Container, Row, Form } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Home, DadosRight, DadosLeft, CardsNumeros, NivelUsuario } from "./styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { ConquistasUsuario } from "../../components/ConquistasUsuario";
import { useEffect } from "react";


export function MeuDesempenho({ user }) {


  

  // const [data1, setData1] = useState(2)2
  const [extratoGeralLista, setExtratoGeralLista] = useState();
  const [erroExtrato, setErroextrato] = useState(false);
  const [dadosDesempenho, setDadosDesempenho] = useState([]);
  const [isHorizontal, setIsHorizontal] = React.useState(false);
  // const [data, setData] = useState([])
  const [data, setData] = useState()
  var today = new Date();
  var yyyy = today.getFullYear();


  useEffect(() => {
    MeuDesempenho(yyyy - 1)
    const handleResize = () => {
      setIsHorizontal(window.innerWidth > 768); // Verifica se a largura da tela é maior que 768 pixels
    };

    handleResize(); // Verifica o estado inicial
    window.addEventListener('resize', handleResize);

  }, []);

  const options = {
    colors: ['#F58420', '#9A5CFA'],
    titleTextStyle: {
      color: 'red',
      fontSize: 20,
      bold: true,
    },
  };

  const convertDate = (date) => {
    var convertdate = new Date(date);

    var ddconvertdate = String(convertdate.getDate()).padStart(2, "0");
    var mmconvertdate = String(convertdate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyyconvertdate = String(convertdate.getFullYear()).padStart(4, "0");

    convertdate = ddconvertdate + "/" + mmconvertdate + "/" + yyyyconvertdate;

    return convertdate;
  };

  const MeuDesempenho = (e) => {

    api
      .get(rotasApi.ObterMeuDesempenho + `?ano=${e}`)
      .then((result) => {
        setDadosDesempenho(result.data.resultado)
        let newArrayPremios = [];
        result.data.resultado.meusPremios.map(
          ({
            data,
            premio,
            pontuacao

          }) =>
            newArrayPremios.push({
              dataCadastro: data,
              descricao: premio,
              pontuacao: pontuacao
            })
        );
        result.data.resultado.meusPremios = newArrayPremios;
        setExtratoGeralLista(result.data.resultado.meusPremios.reverse());
        console.log('aqui teste lista', extratoGeralLista)
        const ArrayToPush = [["Mês", "Minhas vendas", "Média Vendas Loja"]]
        result.data.resultado.desempenho.forEach((item) => {
          // Obter a inicial de 3 letras do nome do mês
          const mes = new Date(2023, item.mes - 1, 1).toLocaleString("default", {
            month: "short",
          });
          const mesInicial = mes.slice(0, 3);
          const desempenho = [mesInicial, item.minhasVendas, item.vendasLoja]
          // Adicionar o item formatado aos dados do gráfico
          ArrayToPush.push(desempenho);
        });
        setData(ArrayToPush)
      }).catch(() => {

      })
  };


  
  return (
    <>
      <Home className="duvidas-blackfriday">
        <NavBar user={user} />
        <Container>

          <Breadcrumb className="mt-3">
            <Link className="breadcrumb-item" to="/home">Home</Link>
            <Breadcrumb.Item active>Meu Desempenho</Breadcrumb.Item>
          </Breadcrumb>

          <hr className="mt-3" />
          <Row className="mt-5">
            <Col className="mt-0 mb-4" md="4">
              <DadosLeft>
                <CardsNumeros>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de vendas realizadas</p></div>
                      <div>
                        <h1>{dadosDesempenho.totalVendasRealizadas}</h1>
                      </div>
                    </div>
                    <div className="card-item">
                      <div><p>Total de aceites em campanhas</p></div>
                      <div><h1>{dadosDesempenho.totalAceitesCampanhas}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de acesso na plataforma</p></div>
                      <div><h1>{dadosDesempenho.totalAcessosNaPlataforma}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Total de treinamentos realizados</p></div>
                      <div><h1>{dadosDesempenho.totalTreinamentosRealizados}</h1></div>
                    </div>
                  </div>
                </CardsNumeros>
                <NivelUsuario>
                  <h5>Conquistas</h5>
                  <Row className=" m-0 consquistas-usuario">
                    <ConquistasUsuario
                      user={user}
                    // childToParent={childToParent}
                    />
                  </Row>
                </NivelUsuario>
              </DadosLeft>
            </Col>
            <Col className=" mb-4" md="8">
              <DadosRight>
                <div className="grafico">
                  <div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { MeuDesempenho(e.target.value) }}>
                      <option value={yyyy - 1}>{yyyy - 1}</option>
                      <option value={yyyy}>{yyyy}</option>
                    </Form.Select>
                  </div>
                  <h5 className="mt-2 ms-4">Desempenho</h5>
                  {data ?
                    <Chart
                      chartType="Bar"
                      width="97%"
                      height="270px"
                      data={data}
                      options={options}
                      style={{ "marginLeft": "12px" }}
                    /> : ('')}
                </div>
                <div className="tabela-container">
                <h5>Meus prêmios</h5>
                  <div className="tabela-resultado">
                    {erroExtrato ? (
                      <div>
                        <p>{erroExtrato}</p>
                      </div>
                    ) : (
                      <>
                        <div className="topo-tabela">
                          <div className="w-75">Premio</div>
                          <div className="w-25">Pontuação</div>
                          <div className="w-25">Data</div>
                          {/* <div className="w-25">Apurado</div> */}
                        </div>
                        <div className="p-1 ps-0 bg-white barra">
                          <div className="corpo-tabela">
                            {extratoGeralLista ? extratoGeralLista.length > 0 ? extratoGeralLista.map(
                              (
                                {
                                  descricao,
                                  dataCadastro,
                                  pontuacao
                                },
                                index
                              ) => (
                                <div className="linha-tabela" key={index}>
                                  <div className="w-75">{descricao}</div>
                                  <div className="w-25">
                                    {pontuacao}
                                  </div>
                                  <div className="w-25">
                                    {convertDate(dataCadastro)}
                                  </div>
                                </div>
                              )
                            ) : (<div className="linha-tabela">
                              <div className="w-100 text-center">Não hã dados a serem exibidos</div>
                              {/* <div className="w-25">
                              none
                            </div> */}
                            </div>) : ('')}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </DadosRight>
            </Col>
          </Row>
        </Container>
        <Footer className="m-0 p-0"></Footer>
      </Home>

    </>
  );
}
