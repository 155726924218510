import styled from "styled-components";

export const ManutencaoImagem = styled.div`
    overflow: hidden;
    @media (max-width: 768px) { 
            .mob{
                display: block!important;
            }
            .desk{
                display: none;
            }
        }
    .mob{
        display: none;
    }
    img{
        height: 100vh;
        width: 100%;
    }
`;