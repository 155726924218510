import { Card, Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BodyModal } from "./styles";
import IconAviso from "../../images/img/icon-aviso.svg";

export function Erro() {

  return (
    <BodyModal className="d-flex justify-content-center align-items-center">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center">
                <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                  <Card.Body className="body-card">
                    <Card.Text className="text-center icon-size">
                      <img src={IconAviso} alt="" />
                    </Card.Text>
                    <Card.Text className="text-center">
                      Ops algo deu errado!
                    </Card.Text>
                    <Link
                      className="btn btn-secondary btn-laranja w-100 mt-3"
                      to="/"
                    >Fechar</Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3 text-center">
              <Col>
                <p className="info-telefone">
                  Dúvidas ? Ligue para 0800 780 0606 e<br /> fale com um de
                  nossos atendentes.
                </p>
              </Col>
            </Row>
          </Container>
        </BodyModal>
  );
}
