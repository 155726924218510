import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #EF4E23;
    justify-content: center;
    height: 100vh;
    text-align: center;
    h1{
      font-size: 360px;
      @media (max-width: 768px) { 
            font-size:160px
         }
    }
    h2{
        color: #000;
        @media (max-width: 768px) { 
            font-size:32px
         }
    }
    p{
        color: #000;
        margin-bottom: 56px;
        @media (max-width: 768px) { 
            font-size:16px
         }
    }
    button{
        color: #fff;
        background-color:#EF4E23;
        border: none;
        border-radius: 5px;
        padding: 16px;
        width: 384px;
        height: 56px;
        @media (max-width: 768px) { 
            width: 328px;
         }
    }
`;