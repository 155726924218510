import React from 'react';
import { Container } from "./styles";

export function Error404() {

    return (

        <Container>
            <h1>404</h1>
            <h2>Ooops!!</h2>
            <p>Parece que a página que você está procurando não está disponível no momento.</p>
            <a href="/login"><button>Voltar para a home</button></a>
        </Container>
    )
}