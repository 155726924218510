import { Col, Row, Navbar, Container } from "react-bootstrap";
import { NavBarPrincipalPrimeiroAcesso, } from "./styles";
import Logo from "../../images/img/logo.svg";



export function NavBarPrimeiroAcesso() {
    return (
        <NavBarPrincipalPrimeiroAcesso>
            <Row className="m-0">
                <Col className="p-0">
                    <Navbar bg="light">
                        <Container className="col-12 d-flex justify-content-center">
                            <Navbar.Brand className="" href="#home"><img className="logo-nav-bar" src={Logo} alt="" /></Navbar.Brand> 
                        </Container>
                    </Navbar>
                </Col>
            </Row>
        </NavBarPrincipalPrimeiroAcesso>
    )
}

