import { Modal, Button } from "react-bootstrap";
import images from "../../images/images";
import React, { useState } from "react";
import { ModalBodySucesso } from "./styles";
import { useEffect } from "react";
// NovaLojaCadastroIcon



export function ModalAvisoCadastroLoja({ modalSuccess }) {


    const [modalShow1, setModalShow1] = useState(modalSuccess);
    const handleClose = () => setModalShow1(false);
    // const teste = () => modalSuccess();

    useEffect(() => {
        setModalShow1(modalSuccess)
    }, [modalSuccess]);

    return (
        <Modal
            show={modalShow1}
            size="md"
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName="background-dialog"
            contentClassName="modal-css"
        >
            <Modal.Body className="d-flex justify-content-center ">
                <ModalBodySucesso>
                    {/* <div className="flex-column justify-content-center text-center modal-corpo"> */}
                        <img className="" src={images.NovaLojaCadastroIcon} alt="" />
                        <h2 onClick={modalSuccess} className="">Nova loja cadastrada<br/> com sucesso</h2>
                        <Button type="submit" className="btn-fechar btn-laranja" onClick={handleClose}>
                           Fechar
                        </Button>
                    {/* </div> */}
                </ModalBodySucesso>
            </Modal.Body>
        </Modal>
    )
}
