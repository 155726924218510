import { Breadcrumb, Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Home, ModalSucesso } from "./styles";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Checked from "../../images/img/icon-checked.svg";


export function MaterialLoja({ user }) {
  const [formsActive, setFormsActive] = useState(false)
  const [passo, setPasso] = useState(1)
  const [dadosPedidosSalvar, setdadosPedidosSalvar] = useState()
  const form1Ref = useRef(null);
  const [enderecoAPI, setEnderecoAPI] = useState(true);
  const [listaMpvFogao, setListaMpvFogao] = useState([])
  const [listaMpvAr, setListaMpvAr] = useState([])
  const [modalSucesso, setModalSucesso] = useState(false)
  const [selectedItems, setSelectedItems] = useState([]);
  const today = new Date();
  const [dadosHistorico, setDadosHistorico] = useState();
  const [imagemAmpliada, setImagemAmpliada] = useState(null);

  const salvarDadosPedidos = () => {

    api
      .post(
        rotasApi.CadastrarMpvs, dadosPedidosSalvar,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((result) => {
        setdadosPedidosSalvar('')
        setModalSucesso(true)
        setPasso(1)


      })
      .catch((error) => {

      });
  }

  const handleCEP = (cep) => {
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((result) => {
        form1Ref.current.setFieldValue("endereco", result.data.logradouro);
        form1Ref.current.setFieldValue("bairro", result.data.bairro);
        form1Ref.current.setFieldValue("cidade", result.data.localidade);
        form1Ref.current.setFieldValue("uf", result.data.uf);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    listarMPV()

  }, []);

  const validationSchemaEndereco = yup.object().shape({
    cep: yup
      .string()
      .test("cep", "buscar cep", (val) => {

        if (val) {
          if (enderecoAPI && val.length === 8) {
            if (!val.includes('-')) {
              form1Ref.current.setFieldValue('cep', val.slice(0, 5) + '-' + val.slice(5, 8));
            }
            handleCEP(val)
            setEnderecoAPI(false)
          }
          if (val.length < 9) {
            setEnderecoAPI(true)
          }
        }
        return true
      })
      .required("CEP é obrigatório"),
    cnpj: yup
      .string()
      .min(14, "CNPJ inválido")
      .max(14, "CNPJ Muito Grande")
      .required("CNPJ é obrigatório"),

  });
  const listarMPV = () => {
    api
      .get(rotasApi.ListarMpvs)
      .then((result) => {
        setListaMpvFogao(result.data.mpvs[0].mpvItems)
        setListaMpvAr(result.data.mpvs[1].mpvItems)

      }).catch((error) => {
        console.log('error obter list mpv')
      })
  };

  const listarHistorico = () => {
    api
      .get(rotasApi.ListarPedidos)
      .then((result) => {
        setDadosHistorico(result.data.pedidos)
      }).catch((error) => {
        console.log('error obter desempenho Desafios')
      })
  };
  const salvarDadosCadastrais = (values) => {
    values.mpvPedidoItems = selectedItems
    values.cnpj = JSON.stringify(values.cnpj)
    setdadosPedidosSalvar(values)
    setPasso(2)
  }

  const ActiveFroms = () => {
    setFormsActive(!formsActive)
  }

  const mudarPasso = (e) => {
    setPasso(e)
  }
  const fechaModal = () => {
    setModalSucesso(false);
  };



  function formatDate(date, format) {
    const map = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      aa: date.getFullYear().toString().slice(-2),
      aaaa: date.getFullYear()
    }

    return format.replace(/mm|dd|aa|aaaa/gi, matched => map[matched])
  }



  const handleQuantityChange = (itemId, quantidade, opcao, descricao) => {

    const itemIndex = selectedItems.findIndex(item => item.id === itemId);

    if (itemIndex !== -1) {
      // O item já existe, atualiza a quantidade
      const updatedItems = [...selectedItems];
      const newQuantity = updatedItems[itemIndex].quantidade + quantidade;

      // Impõe o limite máximo de 2 itens
      if (newQuantity <= 2 && newQuantity >= 0) {
        updatedItems[itemIndex].quantidade = newQuantity;

        // Remove o item se a quantidade for 0
        if (newQuantity === 0) {
          updatedItems.splice(itemIndex, 1);
        }

        setSelectedItems(updatedItems);
      }
    } else {
      // O item não existe, cria um novo objeto
      setSelectedItems(prevItems => [
        ...prevItems,
        { id: itemId, quantidade: 1, opcao: opcao, descricao: descricao }
      ]);
    }
  };


  const handleImagemClick = (imagem) => {
    setImagemAmpliada(imagem);
  };
  const fecharImagemAmpliada = () => {
    setImagemAmpliada(null);
  };

  const elementoRef = useRef(null);

  const handleClick = () => {
    if (elementoRef.current) {
      setTimeout(() => {
        elementoRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);

    }
  };


  return (
    <>
      <Home className="">
        <NavBar user={user} />
        <Modal
          show={modalSucesso}
          onHide={() => setModalSucesso(false)}
          dialogClassName="modal-90w"
          className="modal-sucesso"
          centered
        >
          <Modal.Body className="text-center">
            <ModalSucesso>
              <div className="modal-sucesso">
                <img src={Checked} alt="" className="img-sucesso" />
                <h2 className="h2-sucesso">Seu Pedido foi confirmado!</h2>
                <p>O prazo para envio do seu pedido é de até<br /> 45 dias a partir do dia 31/05.
                </p>
              </div>
              <Button
                className="btn-laranja btn-enviar col-8"
                onClick={fechaModal}
              >
                Fechar
              </Button>
            </ModalSucesso>
          </Modal.Body>
        </Modal>
        {passo === 1 ?
          <Container>
            <Breadcrumb className="mt-3">
              <Link className="breadcrumb-item" to="/home">Home</Link>
              <Breadcrumb.Item active>Material loja</Breadcrumb.Item>
            </Breadcrumb>

            <hr className="mt-3" />
            <Row className="mt-5 text-center">
              <div className="d-flex justify-content-center">
                <div className="col-10 col-lg-7">
                  <h2>Solicitação de material para loja</h2><br />
                  <p>Escolha a opção de material que você precisa em sua loja.</p>
                  <p>O período para solicitação estará aberto entre os dias <b>22/05</b> e <b>31/05</b></p>
                  <p>
                    <b>Importante:</b> a solicitação é uma cortesia do Programa +top e não gera custo nenhum. Você pode fazer uma solicitação por período, limitada a 2 unidades de cada item por pedido.</p>
                  <Button
                    type="submit"
                    className="mb-5 btn-laranja col-10 col-lg-5 mt-5 btn-historico"
                    onClick={(e) => { mudarPasso(3); listarHistorico() }}
                  >
                    Histórico de pedidos
                  </Button>
                </div>

              </div>
              <div className="w-100 p-0 mb-5">
                <div className="d-flex justify-content-between ">
                  <div className="card-item-produto me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="52.59" height="56.214" viewBox="0 0 52.59 56.214">
                      <g id="Grupo_1468" data-name="Grupo 1468" transform="translate(565.413 -235.89)">
                        <path id="Caminho_29" data-name="Caminho 29" d="M-541.5,235.89h-23.916c.006.255.019.512.019.767v55.4c.2.015.352.037.509.037,2.286,0,4.573-.009,6.859.012a.644.644,0,0,0,.7-.441c.3-.685.667-1.342.968-2.028a.607.607,0,0,1,.666-.4c3.732.012,7.463.007,11.194.007h3c5.108,0,14.527,0,18.9-.009a.681.681,0,0,1,.746.452c.307.7.653,1.389,1.009,2.068a.63.63,0,0,0,.411.328c2.5.021,5.008.015,7.588.015v-.783q0-27.327,0-54.653c0-.255.012-.512.018-.767Zm25.324,52.908c-.768,0-1.531.013-2.291-.015-.11,0-.248-.185-.314-.312-.372-.733-.713-1.483-1.1-2.207a.671.671,0,0,0-.482-.305c-5.112-.005-15.366-.01-21.137-.01h-1.117q-7.621,0-15.243.011a.721.721,0,0,0-.513.339c-.383.705-.7,1.446-1.085,2.153a.741.741,0,0,1-.516.333c-.688.038-1.38.015-2.1.015v-6.343h45.9Zm0-9.72h-45.878V250.32h45.878Zm0-32.125h-45.9v-7.73h45.9Z" fill="#313131" />
                        <path id="Caminho_30" data-name="Caminho 30" d="M-505.168,356.68h-14.645v22.21h39.226V356.68h-24.581Zm14.13,3.3h7.159V375.6h-32.653v-15.62h7.156v6.4h18.337Zm-3.342,0v3.093H-506.04V359.98h11.659Z" transform="translate(-38.913 -103.076)" fill="#313131" />
                        <path id="Caminho_31" data-name="Caminho 31" d="M-317.424,274.005h-3.214v3.219h3.214Z" transform="translate(-208.879 -32.525)" fill="#313131" />
                        <path id="Caminho_32" data-name="Caminho 32" d="M-272.237,274.071h-3.237v3.214h3.237Z" transform="translate(-247.42 -32.582)" fill="#313131" />
                      </g>
                    </svg>
                    <span>Fogões Consul</span>
                  </div>
                  <div className="card-item-produto ms-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="66.702" height="56.214" viewBox="0 0 66.702 56.214">
                      <g id="Grupo_1452" data-name="Grupo 1452" transform="translate(894.777 -1282.75)">
                        <path id="Caminho_49" data-name="Caminho 49" d="M-828.075,1313.125c-.239,0-.478.013-.716.013h-65.985v-30.324h.893q32.42,0,64.841,0c.323,0,.645-.04.967-.062Zm-57.591-11.674h48.435v7.74h5.205v-22.407h-58.851v22.407h5.21Zm44.486,3.908H-881.7v3.827h40.516Z" fill="#313131" />
                        <path id="Caminho_50" data-name="Caminho 50" d="M-844.73,1486.214h3.818c.033.053.076.089.075.125-.034,2.5.092,5.007-.144,7.483-.5,5.272-5.285,9.8-10.545,10.439a9.235,9.235,0,0,1-9.7-5.158c-1.383-3.013-1.04-6.182,1.014-8.325a6.216,6.216,0,0,1,6.4-1.917,5.064,5.064,0,0,1,3.688,4.924h-3.809a1.443,1.443,0,0,0-1.649-1.233,2.777,2.777,0,0,0-2.432,2.055,4.419,4.419,0,0,0,1.077,4c1.641,1.932,3.767,2.149,6.061,1.531a8.348,8.348,0,0,0,6.147-7.974c.015-1.738,0-3.476,0-5.213Z" transform="translate(-27.017 -168.029)" fill="#313131" />
                        <path id="Caminho_51" data-name="Caminho 51" d="M-666.774,1486.236h3.911c0,.258,0,.511,0,.763.037,2.148-.1,4.316.152,6.439a8.234,8.234,0,0,0,8.219,7.014,5.107,5.107,0,0,0,5.033-4.835,2.858,2.858,0,0,0-1.888-2.9,1.559,1.559,0,0,0-2.3,1.09h-3.789a5.079,5.079,0,0,1,3.695-4.954,6.674,6.674,0,0,1,8.1,5.411,8.966,8.966,0,0,1-9.609,10.073,12.187,12.187,0,0,1-11.3-10.089,24.626,24.626,0,0,1-.21-3.893C-666.8,1489-666.774,1487.635-666.774,1486.236Z" transform="translate(-188.287 -168.047)" fill="#313131" />
                        <path id="Caminho_52" data-name="Caminho 52" d="M-714.489,1486.2h3.859v20.781h-3.859Z" transform="translate(-148.889 -168.017)" fill="#313131" />
                        <path id="Caminho_53" data-name="Caminho 53" d="M-678.56,1348.995h-3.82v-3.844h3.82Z" transform="translate(-175.406 -51.533)" fill="#313131" />
                        <path id="Caminho_54" data-name="Caminho 54" d="M-634.558,1349.023V1345.2h3.826v3.825Z" transform="translate(-214.9 -51.572)" fill="#313131" />
                        <path id="Caminho_55" data-name="Caminho 55" d="M-586.615,1349.042v-3.813h3.836v3.813Z" transform="translate(-254.493 -51.598)" fill="#313131" />
                      </g>
                    </svg>

                    <span>Ar Condicionado Consul</span>
                  </div>
                </div>
              </div>
              <div className="w-100 tabela-items p-0">

                <div className="tabela-container me-3 col-10 col-lg-6">
                  <div className="card-item-produto-mobile me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="52.59" height="56.214" viewBox="0 0 52.59 56.214">
                      <g id="Grupo_1468" data-name="Grupo 1468" transform="translate(565.413 -235.89)">
                        <path id="Caminho_29" data-name="Caminho 29" d="M-541.5,235.89h-23.916c.006.255.019.512.019.767v55.4c.2.015.352.037.509.037,2.286,0,4.573-.009,6.859.012a.644.644,0,0,0,.7-.441c.3-.685.667-1.342.968-2.028a.607.607,0,0,1,.666-.4c3.732.012,7.463.007,11.194.007h3c5.108,0,14.527,0,18.9-.009a.681.681,0,0,1,.746.452c.307.7.653,1.389,1.009,2.068a.63.63,0,0,0,.411.328c2.5.021,5.008.015,7.588.015v-.783q0-27.327,0-54.653c0-.255.012-.512.018-.767Zm25.324,52.908c-.768,0-1.531.013-2.291-.015-.11,0-.248-.185-.314-.312-.372-.733-.713-1.483-1.1-2.207a.671.671,0,0,0-.482-.305c-5.112-.005-15.366-.01-21.137-.01h-1.117q-7.621,0-15.243.011a.721.721,0,0,0-.513.339c-.383.705-.7,1.446-1.085,2.153a.741.741,0,0,1-.516.333c-.688.038-1.38.015-2.1.015v-6.343h45.9Zm0-9.72h-45.878V250.32h45.878Zm0-32.125h-45.9v-7.73h45.9Z" fill="#313131" />
                        <path id="Caminho_30" data-name="Caminho 30" d="M-505.168,356.68h-14.645v22.21h39.226V356.68h-24.581Zm14.13,3.3h7.159V375.6h-32.653v-15.62h7.156v6.4h18.337Zm-3.342,0v3.093H-506.04V359.98h11.659Z" transform="translate(-38.913 -103.076)" fill="#313131" />
                        <path id="Caminho_31" data-name="Caminho 31" d="M-317.424,274.005h-3.214v3.219h3.214Z" transform="translate(-208.879 -32.525)" fill="#313131" />
                        <path id="Caminho_32" data-name="Caminho 32" d="M-272.237,274.071h-3.237v3.214h3.237Z" transform="translate(-247.42 -32.582)" fill="#313131" />
                      </g>
                    </svg>
                    <span>Fogões Consul</span>
                  </div>
                  <div className="col-12">
                    <table className="col-12">
                      <thead>
                        <tr>
                          <th>ITEM</th>
                          <th>DESCRIÇÃO</th>
                          <th>PRODUTOS <br />PARA APLICAÇÃO</th>
                          <th>QUANT.<br />(limite 2un.)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaMpvFogao ? listaMpvFogao.map((item) => {
                          const selectedItem = selectedItems.find((selectedItem) => selectedItem.id === item.id);
                          const currentQuantity = selectedItem ? selectedItem.quantidade : 0;

                          return (
                            <tr key={item.id}>
                              <td style={{ paddingLeft: "10px" }}>
                                <div style={{ cursor: "pointer" }} onClick={() => handleImagemClick(item.imagem)} className="d-flex">
                                  <img
                                    src={item.imagem}
                                    alt=""
                                    style={{ width: "40px" }}
                                  />
                                  <i class="bi bi-zoom-in"></i>
                                </div>
                              </td>
                              <td>{item.descricao}</td>
                              <td>{item.produtosAplicacao}</td>
                              <td className="text-center">
                                <div className="botoes-quantidade">
                                  <button
                                    onClick={() => handleQuantityChange(item.id, -1, '2', item.descricao)} // Diminuir quantidade em 1
                                    disabled={currentQuantity <= 0}
                                  >
                                    -
                                  </button>
                                  <td>{currentQuantity}</td>
                                  <button
                                    onClick={() => handleQuantityChange(item.id, 1, '1', item.descricao)} // Aumentar quantidade em 1
                                    disabled={currentQuantity >= 2}
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        }) : ('')}
                      </tbody>
                    </table>
                    {imagemAmpliada && (
                      <div className="imagem-ampliada-overlay" onClick={fecharImagemAmpliada}>
                        <div className="imagem-ampliada-container">
                          <img src={imagemAmpliada} alt="" />
                          <button className="fechar-imagem" onClick={fecharImagemAmpliada}>
                            <i class="bi bi-x-lg"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tabela-container col-10 col-lg-6 mt-lg-0 mt-4" >
                  <div className="card-item-produto-mobile me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="66.702" height="56.214" viewBox="0 0 66.702 56.214">
                      <g id="Grupo_1452" data-name="Grupo 1452" transform="translate(894.777 -1282.75)">
                        <path id="Caminho_49" data-name="Caminho 49" d="M-828.075,1313.125c-.239,0-.478.013-.716.013h-65.985v-30.324h.893q32.42,0,64.841,0c.323,0,.645-.04.967-.062Zm-57.591-11.674h48.435v7.74h5.205v-22.407h-58.851v22.407h5.21Zm44.486,3.908H-881.7v3.827h40.516Z" fill="#313131" />
                        <path id="Caminho_50" data-name="Caminho 50" d="M-844.73,1486.214h3.818c.033.053.076.089.075.125-.034,2.5.092,5.007-.144,7.483-.5,5.272-5.285,9.8-10.545,10.439a9.235,9.235,0,0,1-9.7-5.158c-1.383-3.013-1.04-6.182,1.014-8.325a6.216,6.216,0,0,1,6.4-1.917,5.064,5.064,0,0,1,3.688,4.924h-3.809a1.443,1.443,0,0,0-1.649-1.233,2.777,2.777,0,0,0-2.432,2.055,4.419,4.419,0,0,0,1.077,4c1.641,1.932,3.767,2.149,6.061,1.531a8.348,8.348,0,0,0,6.147-7.974c.015-1.738,0-3.476,0-5.213Z" transform="translate(-27.017 -168.029)" fill="#313131" />
                        <path id="Caminho_51" data-name="Caminho 51" d="M-666.774,1486.236h3.911c0,.258,0,.511,0,.763.037,2.148-.1,4.316.152,6.439a8.234,8.234,0,0,0,8.219,7.014,5.107,5.107,0,0,0,5.033-4.835,2.858,2.858,0,0,0-1.888-2.9,1.559,1.559,0,0,0-2.3,1.09h-3.789a5.079,5.079,0,0,1,3.695-4.954,6.674,6.674,0,0,1,8.1,5.411,8.966,8.966,0,0,1-9.609,10.073,12.187,12.187,0,0,1-11.3-10.089,24.626,24.626,0,0,1-.21-3.893C-666.8,1489-666.774,1487.635-666.774,1486.236Z" transform="translate(-188.287 -168.047)" fill="#313131" />
                        <path id="Caminho_52" data-name="Caminho 52" d="M-714.489,1486.2h3.859v20.781h-3.859Z" transform="translate(-148.889 -168.017)" fill="#313131" />
                        <path id="Caminho_53" data-name="Caminho 53" d="M-678.56,1348.995h-3.82v-3.844h3.82Z" transform="translate(-175.406 -51.533)" fill="#313131" />
                        <path id="Caminho_54" data-name="Caminho 54" d="M-634.558,1349.023V1345.2h3.826v3.825Z" transform="translate(-214.9 -51.572)" fill="#313131" />
                        <path id="Caminho_55" data-name="Caminho 55" d="M-586.615,1349.042v-3.813h3.836v3.813Z" transform="translate(-254.493 -51.598)" fill="#313131" />
                      </g>
                    </svg>

                    <span>Ar Condicionado Consul</span>
                  </div>
                  <div className="col-12">
                    <table className="col-12">
                      <thead>
                        <tr>
                          <th>ITEM</th>
                          <th>DESCRIÇÃO</th>
                          <th>PRODUTOS <br />PARA APLICAÇÃO</th>
                          <th>QUANT.<br />(limite 2un.)</th>
                          {/* <th>Quantidade</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {listaMpvAr ? listaMpvAr.map(item => {
                          const selectedItem = selectedItems.find(selectedItem => selectedItem.id === item.id);
                          const currentQuantity = selectedItem ? selectedItem.quantidade : 0;

                          return (
                            <tr key={item.id}>
                              {/* <td>{item.id}</td> */}
                              <td style={{ paddingLeft: "10px" }}>
                                <div style={{ cursor: "pointer" }} onClick={() => handleImagemClick(item.imagem)} className="d-flex">
                                  <img
                                    src={item.imagem}
                                    alt=""
                                    style={{ width: "40px" }}
                                  />
                                  <i class="bi bi-zoom-in"></i>
                                </div>
                              </td>
                              <td>{item.descricao}</td>
                              <td>{item.produtosAplicacao}</td>
                              <td className="text-center">
                                <div className="botoes-quantidade">
                                  <button
                                    onClick={() => handleQuantityChange(item.id, -1, '2', item.descricao)} // Diminuir quantidade em 1
                                    disabled={currentQuantity <= 0} // Desabilita o botão se a quantidade atual for 0 ou menos
                                  >
                                    -
                                  </button>
                                  <td>{currentQuantity}</td>
                                  <button
                                    onClick={() => handleQuantityChange(item.id, 1, '2', item.descricao)} // Aumentar quantidade em 1
                                    disabled={currentQuantity >= 2} // Desabilita o botão se a quantidade atual for 2 ou mais
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        }) : ('')}
                      </tbody>
                    </table>
                    {imagemAmpliada && (
                      <div className="imagem-ampliada-overlay" onClick={fecharImagemAmpliada}>
                        <div className="imagem-ampliada-container">
                          <img src={imagemAmpliada} alt="" />
                          <button className="fechar-imagem" onClick={fecharImagemAmpliada}>
                            <i class="bi bi-x-lg"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div>Opções disponíveis:</div> */}
              </div>
              <div onClick={(e) => { handleClick() }}>
                <Button
                  type="submit"
                  className="mb-3 btn-verde col-9 col-lg-3 btn-solicitar mt-5 mb-5 "
                  onClick={(e) => { ActiveFroms() }}
                >
                  Solicitar
                </Button>
              </div>
            </Row>
            <Row className="mb-5">
              {formsActive ?
                <Formik
                  innerRef={form1Ref}
                  initialValues={{
                    nomeSolicitante: dadosPedidosSalvar ? dadosPedidosSalvar.nomeSolicitante : user.nome,
                    nomeLoja: dadosPedidosSalvar ? dadosPedidosSalvar.nomeLoja : "",
                    cnpj: dadosPedidosSalvar ? dadosPedidosSalvar.cnpj : "",
                    cep: dadosPedidosSalvar ? dadosPedidosSalvar.cep : "",
                    endereco: dadosPedidosSalvar ? dadosPedidosSalvar.endereco : "",
                    numero: dadosPedidosSalvar ? JSON.stringify(dadosPedidosSalvar.numero) : "",
                    complemento: dadosPedidosSalvar ? dadosPedidosSalvar.complemento : "",
                    pontoReferencia: dadosPedidosSalvar ? dadosPedidosSalvar.pontoReferencia : "",
                    bairro: dadosPedidosSalvar ? dadosPedidosSalvar.bairro : "",
                    cidade: dadosPedidosSalvar ? dadosPedidosSalvar.cidade : "",
                    uf: dadosPedidosSalvar ? dadosPedidosSalvar.uf : "",
                  }}
                  onSubmit={(values) => {
                    salvarDadosCadastrais(values);
                  }}
                  validationSchema={validationSchemaEndereco}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    isValid,
                    handleSubmit,
                    setFieldValue,
                    dirty,

                  }) => (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}>

                      <Col lg={12} className="d-flex justify-content-center">
                        <div className="container-campos d-flex justify-content-center col-9 col-lg-5">
                          <div className="container-campos align-self-center ">
                            <Row>
                              <div className="titulo-page">
                              </div>

                              <Col className="mb-5">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label-input">
                                    Nome Solicitante
                                  </Form.Label>

                                  <Form.Control
                                    placeholder=""
                                    type="text"
                                    name="nomeSolicitante"
                                    value={values.nomeSolicitante}
                                    onChange={handleChange}

                                  />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label className="label-input">
                                    Nome loja
                                  </Form.Label>

                                  <Form.Control
                                    placeholder=""
                                    type="text"
                                    name="nomeLoja"
                                    value={values.nomeLoja}
                                    onChange={handleChange}

                                  />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label className="label-input">
                                    CNPJ
                                  </Form.Label>

                                  <Form.Control
                                    placeholder=""
                                    type="number"
                                    name="cnpj"
                                    value={values.cnpj}
                                    onChange={handleChange}
                                  />
                                </Form.Group>

                              </Col>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  CEP*
                                </Form.Label>
                                {/* <div className="small position-relative">
                              <a
                                className="busca-cep"
                                rel="noopener noreferrer"
                                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                target="_blank"
                              >
                                Não sei meu CEP
                              </a>
                            </div> */}
                                <Form.Control
                                  maxLength="9"
                                  placeholder=""
                                  type="text"
                                  name="cep"
                                  value={values.cep}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cep}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cep}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Rua / Avenida / Travessa / Praça*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="endereco"
                                  value={values.endereco}
                                  onChange={handleChange}
                                  isInvalid={!!errors.rua}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.rua}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Número*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="numero"
                                  value={values.numero}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Complemento
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="complemento"
                                  value={values.complemento}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Bairro*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="bairro"
                                  value={values.bairro}
                                  onChange={handleChange}
                                  isInvalid={!!errors.bairro}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.bairro}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Cidade*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="cidade"
                                  value={values.cidade}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cidade}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cidade}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  UF*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="uf"
                                  value={values.uf}
                                  onChange={handleChange}
                                  isInvalid={!!errors.uf}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.uf}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Ponto de referência
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="pontoReferencia"
                                  value={values.pontoReferencia}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Row>
                            <Button
                              type="submit"
                              className="w-100 mt-4 btn-laranja"
                              variant="secondary"
                            // onClick={(e) => { setPasso(2) }}
                            >
                              Avançar
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Form>
                  )}
                </Formik>
                : ('')}
            </Row>
          </Container>
          :
          passo === 2 ?
            <Container>
              <Breadcrumb className="mt-3">
                <Link className="breadcrumb-item" to="/home">Home</Link>
                <Breadcrumb.Item onClick={(e) => { setPasso(1) }}>Material loja</Breadcrumb.Item>
                <Breadcrumb.Item active>Checkout</Breadcrumb.Item>
              </Breadcrumb>
              <hr className="mt-3 mb-5" />
              <Row className="flex-column container-dados-pedido">
                <Col className="d-flex dados-pedido pt-5">
                  <div className="text-center">
                    <h1>Confira seu pedido</h1>
                  </div>
                  <div className="w-100 tabela-items-confirma d-flex p-0">
                    <div className="tabela-container me-3 col-12 d-flex justify-content-center">
                      <div className="col-lg-9 col-11">
                        <table className="text-center">
                          <thead>
                            <tr>
                              <th>Data do pedido</th>
                              <th>Opção</th>
                              <th>Descrição</th>
                              <th>Quantidade</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dadosPedidosSalvar ? dadosPedidosSalvar.mpvPedidoItems.map((item) => (
                              <tr key={item.id}>
                                <td className="col-4">{formatDate(today, 'dd/mm/aa')}</td>
                                <td className="col-4">{item.opcao === '1' ? "Fogões" : "Ar Condic."}</td>
                                <td className="col-4">{item.descricao}</td>
                                <td className="col-4">{item.quantidade}</td>
                              </tr>
                            )) : ('')}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
                <hr className="col-11 align-self-center mt-3 mb-5" />
                <Col className="p-0">
                  <div className="d-flex container-info-entregas">
                    <div className="text-center">
                      <h1>Confira a entrega</h1>
                    </div>
                    <div className="col-9 d-flex justify-content-around position-relative">
                      <div className="col-3">
                        <p><b>Nome:</b>{dadosPedidosSalvar.nomeSolicitante}</p>
                        <p><b>Nome Loja:</b>{dadosPedidosSalvar.nomeLoja}</p>
                        <p><b>CNPJ:</b>{dadosPedidosSalvar.cnpj}</p>
                      </div>
                      <div className="col-3">
                        <p><b>CEP:</b> {dadosPedidosSalvar.cep}</p>
                        <p><b>Endereço:</b> {dadosPedidosSalvar.endereco}</p>
                        <p><b>Número:</b> {dadosPedidosSalvar.numero}</p>
                        <p><b>Complemento:</b>{dadosPedidosSalvar.complemento}</p>
                        <p><b>Bairro:</b>{dadosPedidosSalvar.bairro}</p>
                        <p><b>Cidade:</b>{dadosPedidosSalvar.cidade}</p>
                        <p><b>Estado:</b>{dadosPedidosSalvar.uf}</p>
                      </div>
                      <div className="botoes-acao col-lg-6 col-9">
                        <Button
                          type="submit"
                          className="mb-3 btn-laranja-outline col-5 btn-solicitar mt-5 mb-5 me-3"
                          onClick={(e) => { setPasso(1); handleClick() }}
                        >
                          Editar
                        </Button>
                        <Button
                          type="submit"
                          className="mb-3 btn-laranja col-5 btn-solicitar mt-5 mb-5"
                          onClick={(e) => salvarDadosPedidos()}
                        >
                          Confirmar Pedido
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container> :
            <Container>
              <Breadcrumb className="mt-3">
                <Link className="breadcrumb-item" to="/home">Home</Link>
                <Breadcrumb.Item onClick={(e) => { setPasso(1) }}>Material loja</Breadcrumb.Item>
                <Breadcrumb.Item active>Historico</Breadcrumb.Item>
              </Breadcrumb>
              <hr className="mt-3 mb-5" />
              <Row className="mt-5 text-center">
                <div className="d-flex justify-content-center">
                  <div className="col-7">
                    <h2>Solicitação de material para loja</h2><br />
                    <p>Escolha a opção de material que você precisa em sua loja.</p>
                    <p>O período para solicitação estará aberto entre os dias <b>22/05</b> e <b>31/05</b></p>
                    <p>
                      <b>Importante:</b> a solicitação é uma cortesia do Programa +top e não gera custo nenhum. Você pode fazer uma solicitação por período, limitada a 2 unidades de cada item por pedido.</p>
                  </div>
                </div>
                <Row className="flex-column container-dados-pedido pb-5">
                  <Col className="d-flex dados-pedido pt-5 col-12">
                    <div className="text-center">
                      <h1>Histórico de pedidos</h1>
                    </div>
                    <div className="w-100 tabela-items-confirma d-flex p-0">
                      <div className="tabela-container me-3 col-12 d-flex justify-content-center">
                        <div className="col-lg-10 col-11" style={{ maxHeight: "300px", overflowY: "auto" }}>
                          <table className="text-center position-relative">
                            <thead>
                              <tr>
                                <th style={{ minWidth: "120px" }}>ID pedido</th>
                                <th style={{ minWidth: "120px" }}>Data do pedido</th>
                                <th style={{ minWidth: "120px" }}>Opção</th>
                                <th style={{ minWidth: "120px" }}>Descrição</th>
                                <th style={{ minWidth: "120px" }}>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dadosHistorico ? dadosHistorico.map((item, index) => (
                                <tr key={index}>
                                  <td className="col-lg-3 col-4">{item.id}</td>
                                  <td className="col-lg-3 col-4">{item.data}</td>
                                  <td className="col-lg-3 col-4">{item.item}</td>
                                  <td className="col-lg-3 col-4">{item.descricao}</td>
                                  <td className="col-lg-3 col-4">{item.quantidade}</td>
                                </tr>
                              )) : (<div className="">
                                <p className="position-absolute" style={{right:"12rem"}}>Não há itens no histórico.</p>
                              </div>)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>
                <div>
                  <Button
                    type="submit"
                    className="mb-5 btn-laranja col-3 mt-5 btn-historico"
                    onClick={(e) => { setPasso(1) }}
                  >
                    Voltar
                  </Button>
                </div>
              </Row>
            </Container>
        }
        <div ref={elementoRef}></div>
        <Footer className="m-0 p-0"></Footer>
      </Home>
    </>
  );
}
