import styled from "styled-components";

export const Home = styled.div`
	background: #efeeed;
`;

export const Etapa1 = styled.div`
	.baixar-arquivos{
		background: #fff;
	}
	.container-cards{
		@media (max-width: 992px) { 
              flex-direction: column;
        }
		.card-item{
			/* max-width: 250px;
			min-height: 250px; */
			@media (max-width: 992px) { 
              height: 170px;
			  margin: 5px 0px;
			  align-self: center;
			  padding: 0px 10px;
			  width: 80vw;
        	}
			width: 20vw;
			min-height: 200px;
			background: #fff;
			padding: 0px 15px;
			margin: 0px 20px;
			h1{
				padding-right: 15px;
				padding-top: 6px;
				color: var(--verde);
				font-size: 30px;
			}
			h6{
				margin-top: 10px;
				color: var(--verde);
				font-size: 15px;
			}
			p{
				font-size: 0.9rem;
			}
		}
	}
	.btn-planilhas{
		height: 45px;
	}
`;

export const Etapa2 = styled.div`
	.container-itens-instrucao{
		margin-top: 5%;
		@media (max-width: 770px) {
			flex-direction: column;
			width: 100%;
		}
		.itens-instrucao{
			background: #fff;
			width: 29%;
			height: 10vh;
			padding: 8px 15px;
			@media (max-width: 770px) {
				width: 85%;
				align-self: center;
				height: 14vh;
			}
			h1{
				color: var(--verde);
				padding-right: 15px;
				@media (max-width: 770px) {
				margin-top: 4px;
				}
			}
			p{
				padding-top: 2px;
			}
		}
		
	}
	
	  .container-dados-importacao{
		  background: #fff;
		  height: 20vh;
		  @media (max-width: 770px) {
			height: 45vh;
		}
		  .header-busca-dados{
			  width: 100%;
			  background: #000;
			  color: #fff;
			  text-align: center;
			  height: 25%;
			  border-radius: 5px;
			  p{
				padding: 10px 10px;
			  } 	
		  }
		  .input-mes-ano {
			/* width: 18rem; */
			height: 45px;
			font-size: 12px;
			padding: 14px 11px;
			color: #c4c4c4;
			border-radius: 5px;
			border: 1px solid #000;
			/* margin-right: 30px; */
			@media (min-width: 770px) {
			display: inline;
			/* margin-right: 20px; */
			}
			@media (max-width: 770px) {
			display: inline;
			margin-right: 0px;
			/* width:; */
			}
		}
		.btn-buscar{
			height: 45px;
			font-size: 13px;
		}
		@media (max-width: 770px){
			.input-revenda{
				width: 90%;
				align-self: center;
				margin-right: 0px;
				padding: 0px 15px;
			}
		}
		.form-inputs{
			@media (max-width: 770px){
				justify-content: center;
				.inputs-select{
					justify-content: space-between;
					.input-ano{
						width: 40%;
					}
					.input-mes{
						width: 40%;
					}
				}
			}
		}
	  }
	  .resultados{
		  /* item-status-planilha d-flex erro justify-content-center flex-column text-center */
		  .item-status-planilha{
			background: #fff;
			width: 170px;
			height: 90px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			@media (max-width: 770px){
				width:80px;
				height: 80px;
			}
		  }
		  .erro{
			  background: red;
			  color: #fff;
		  }
		  .success{
			  background: var(--verde);
			  color: #fff;
		  }
		  .pendente{
			  background: #ffdb58;
			  color: #fff;
		  }
		.progressBarcontainer {
			width: 100%;
			height: 15px;
			background: #e2e2e2;
			overflow: hidden;
			border-radius: 5px;
		}
		.progress-container {
			position: relative;
		}
		.progressBarValue {
			height: 15px;
			float: left;
			border-radius: 5px;
			background: #9ed530;
		}
		.w10{
			width: 13.3333%;
		}
		 .w30{
			width: 33.3333%;
		}
		.w60{
			width: 66.6666%;
		}
		.w100{
			width: 100%;
		}
		.input-busca-nome{
            /* width: 35vw; */
            height: 45px;
            border: 1px solid #000;
            @media (max-width: 992px) { 
                /* width: 100%; */
            }
        }
		.filtros-tabela-resultado{
			background: #fff;
		}
		@media (max-width: 770px){
				
		}
	  }
`;

export const TabelaResultado = styled.div`
    .lista-chamados-container{
      .botoes-filtro{
          background: #fff;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          height: 45px;
          align-items: center;
          border-radius: 5px;
          button{
              width: 48.5%;
              height: 40px;
              margin: 0 3px;
          }
          button:hover{
            background: #cdcdcd;
          }
      }
      .header-table{
          background: #000;
          color: #fff;
          border-radius: 5px;
          .items-header{
			  text-align: center;
              p{
                  margin: 0;
                  padding: 10px;
              }
             
              .w30{
            width: 30%;
          }
          .w40{
            width: 40%;
          }
          }
      }
      .item-historico{
          margin: 15px 0;
          background: #fff;
          border-radius: 5px;
          p{
              margin: 0;
              padding: 10px;
          }
         
          .w35{
            width: 30%;
          }
          .w40{
            width: 40%;
          }
      }
      .item-historico:hover{
          background: #cdcdcd;
          cursor: pointer;
      }
      .container-items{
        max-height: 360px;
        overflow-x: auto;
      }
  }
`;