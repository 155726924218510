import styled from "styled-components";

export const Home = styled.div`
	background: #efeeed;
`;

export const DadosLeft = styled.div`
	    /* max-height: 395px;
    overflow-y: scroll; */
`;

export const DadosRight = styled.div`
  .grafico{
    padding: 10px;
    background: #fff;
    /* border: 1px solid black; */
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    .select-ano{
      width: 100px;
      position: absolute;
      top: 7px;
      z-index: 1;
      right: 63px;
      @media (max-width: 1360px) {
        right: 13px;
      }
    }
  }
  .tabela-container{
    background-color: #fff;
    padding-top: 18px;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 19px;
    margin-top: 21px;
    border-radius: 5px;
    @media (max-width: 1360px) {
				padding-left:20px
        }
  }
	.tabela-resultado {
      font-size: 0.9rem;
      border-radius: 5px;
	    margin-top: 26px;

      @media (max-width: 770px) {
        max-width: 103%;
        overflow: scroll;
        width: 27rem;
      }

      .topo-tabela,
      .rodape-tabela {
        display: flex;
        justify-content: space-between;
        background: #313131;
        color: #fff;
      }
      .topo-tabela {
        font-size: 14px;
        padding: 14px 20px 14px 14px;
        border-radius: 5px 5px 0 0;

        @media (max-width: 770px) {
          width: 40rem;
          padding: 14px 26px 14px 14px;
        }

        .titulo-descricao {
          width: 100%;
          max-width: 390px;

          @media (max-width: 770px) {
            width: 25%;
          }
        }
        .titulo-quantidade {
          width: 100%;
          //	max-width: 250px;

          @media (max-width: 770px) {
            width: 25%;
          }
        }
        .titulo-space {
          width: 100%;
          //	max-width: 250px;

          @media (max-width: 770px) {
            width: 0;
          }
        }
        .titulo-numero {
          width: 100%;
          //	max-width: 250px;

          @media (max-width: 770px) {
            width: 25%;
          }
        }
        .titulo-pontos {
          width: 100%;
          max-width: 230px;

          @media (max-width: 770px) {
            width:  25%;
          }
        }
        .titulo-data {
          width: 100%;
          max-width: 90px;

          @media (max-width: 770px) {
            width: 20%;
          }
        }
      }
      .barra{
        @media (max-width: 770px) {
          width: 40rem;
        }
      }
      .corpo-tabela {
        background: #fff;
        overflow-y: scroll;
        height: 20rem;
        @media (max-width: 770px) {
          width: 40rem;
          padding: 0px 0 0 7px;
          margin: 0px 0px 0px -7px;
        }
        ::-webkit-scrollbar {
          width: 6px;  
        }

        ::-webkit-scrollbar-track {
          background: #eee;
          border-radius: 20px;
        }
        
        ::-webkit-scrollbar-thumb {
          background-color: orange; 
          border-radius: 20px;       
        }
      }
      .linha-tabela {
        border-bottom: 1px solid #707070;
        display: flex;
        align-items: baseline;
        padding: 14px;
        justify-content: space-between;

 
      }
      .pontos,
      .data,
      .quantidade,
      .numero,
      .space,
      .descricao {
        width: 100%;
        max-width: 390px;
        font-size: 13px;
      }
      .numero {
        @media (max-width: 770px) {
          width: 40%;
        }
      }
      .quantidade {
        @media (max-width: 770px) {
          width: 20%;
        }
      }
      .space {
        @media (max-width: 770px) {
          width: 0;
        }
      }
      .descricao {
        @media (max-width: 770px) {
          width: 35%;
        }
      }
      .pontos {
        width: 100%;
        max-width: 210px;

        @media (max-width: 770px) {
          width: 25%;
        }
      }
      .data {
        width: 100%;
        max-width: 80px;

        @media (max-width: 770px) {
          width: 20%;
        }
      }
      .rodape-tabela {
        font-size: 1rem;
        padding: 12px 15px 12px 14px;
        border-radius: 0 0 5px 5px;
        background: #9ccb3d;

        @media (max-width: 770px) {
          padding-left: 15px;
          width: 40rem;
        }

        .total {
          width: 100%;
          //	max-width: 390px;

          @media (max-width: 770px) {
            width: 12rem;
          }
        }

        .pontos {
          font-weight: bold;
          padding-right: 30px;

        }
        .valor-resgates{
          padding-left: 3rem;
          @media (max-width: 770px) {
            padding-left: 2rem;
          }
        }
      }
    	}
`;

export const CardsNumeros = styled.div`
	  display: flex;
    flex-wrap: wrap;
    justify-content: center;
		.card-item{
			width: 44%;
			height: 140px;
			background-color: #fff;
			text-align: center;
			padding: 18px;
			margin-bottom: 2vw;
			border-radius: 5px;
			@media (max-width: 1360px) {
				width: 44%;
				height: 150px;
        }
			h1{
				color: orange;
			}
      p{
        font-size: 13px;
      }
		}

		
		
`;
export const NivelUsuario = styled.div`
    background: #fff;
    padding: 17px;
    border-radius: 5px;
    #reactgooglegraph-1{
      margin-left: 22px;
    }
	.consquistas-usuario{
    max-height: 396px;
    overflow-y: scroll;
  }
  .nivel-atual-user{
    border: none;
    background-color: #efeeed!important;
  }
  .task-card-container{
    border: none;
    background-color: #efeeed!important;
  }
  .icon-task{
    background-color: #fff;
  }
  .progressBarcontainer{
    background-color: #fff!important;
  }
  .task-card-conteudo{
    h2{
      font-size: 12px!important;
    }
  }
`;