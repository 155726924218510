import { Modal, Spinner } from "react-bootstrap";
import React from "react";


export function LoadingModal({modalShow}) {


    return (
        <Modal
            show={modalShow}
            centered
            backdropClassName="background-dialog"
            contentClassName="modal-css"
            size="lg"
            fullscreen={true}
        >

            <Modal.Body className="pontos-participantes col-12 d-flex justify-content-center">
                <div className="col-11 col-lg-7 align-self-center text-center align-items-center align-middle">
                    <Spinner animation="border" role="status" style={{width: "4rem", height: "4rem"}}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <h3>Aguarde um momento enquanto suas imagens são carregadas...</h3>
                </div>
            </Modal.Body>
        </Modal>)
}
