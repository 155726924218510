import { BodyModal } from "./styles";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Politica() {
  return (
    <BodyModal className="d-flex justify-content-center align-items-center">
      <Container>
      <Row className="m-0">
        <Col className="d-flex justify-content-center">
          
            <Card className="d-flex justify-content-center align-items-center shadow bd-radius">
              <Card.Body>
              <h3 className="text-center">POLÍTICA DE PRIVACIDADE "PROGRAMA + TOP"</h3>
                <Card.Text className="text-center modal-text">
                  <div>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      A presente Política de Privacidade (“<b>Política</b>”
                      <b>) </b>tem por finalidade demonstrar o compromisso da
                      Whirlpool S.A., sociedade empresária inscrita no CNPJ sob
                      o no. 59.105.999/0001-86, com sede na Rua Olympia Semeraro
                      675, Jardim Santa Emília, São Paulo, SP, e demais empresas
                      do grupo econômico Whirlpool (“<b>Whirlpool</b>” ou “
                      <b>Nós</b>”), donas das marcas Brastemp, Compra Certa,
                      Consul e KitchenAid, com a privacidade e a proteção dos
                      dados pessoais dos Participantes do Programa +TOP (“
                      <b>Você</b>” ou “<b>Participantes</b>”<b>)</b>, explicando
                      como utilizamos seus Dados Pessoais para a realização do
                      Programa + TOP (Programa), quais os seus direitos como
                      titular de dados e como você pode exercê-los.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      className="s1"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      1- Para participar da Campanha, você declara:
                    </p>
                    <ol id="l1">
                      <li data-list-text="(i)">
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "17pt",
                            textIndent: "-11pt",
                            textAlign: "justify",
                          }}
                        >
                          ser maior de 18 (dezoito) anos;
                        </p>
                      </li>
                      <li data-list-text="(ii)">
                        <p
                          style={{
                            paddingTop: "5pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          ter feito a leitura completa e atenta das regras desta
                          Política e aceitado os Termos e Condições do Programa
                          +TOP e Uso da Plataforma (“<b>Termos</b>”<b>)</b>,
                          estando plenamente ciente sobre os respectivos
                          conteúdos;
                        </p>
                      </li>
                      <li data-list-text="(iii)">
                        <p
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "22pt",
                            textIndent: "-16pt",
                            textAlign: "justify",
                          }}
                        >
                          ter compartilhado conosco apenas dados e informações
                          verdadeiras
                        </p>
                      </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <ol id="l2">
                      <li data-list-text={2}>
                        <p
                          className="s1"
                          style={{
                            paddingTop: "7pt",
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "left",
                          }}
                        >
                          - Quando coletamos os seus dados?
                        </p>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          Os dados que coletamos dependem da forma como você se
                          relaciona conosco e por quê. Podemos coletar seus
                          dados, por exemplo:
                        </p>
                        <ol id="l3">
                          <li data-list-text="(i)">
                            <p
                              style={{
                                paddingTop: "4pt",
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "114%",
                                textAlign: "justify",
                              }}
                            >
                              quando a Revenda Participante (“<b>Revenda</b>”),
                              conforme definição estabelecida nos Termos, adere
                              ao Programa e compartilha conosco seu nome, CPF,
                              cargo e histórico de vendas de produtos Whirlpool,
                              a fim de viabilizar seu pré-cadastro na plataforma
                              e participação no Programa;
                            </p>
                          </li>
                          <li data-list-text="(ii)">
                            <p
                              style={{
                                paddingTop: "4pt",
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "114%",
                                textAlign: "justify",
                              }}
                            >
                              quando você realiza, voluntariamente, seu cadastro
                              na Plataforma do Programa +TOP (“<b>Plataforma</b>
                              ”) e compartilha os demais dados necessários à
                              realização do Programa;
                            </p>
                          </li>
                          <li data-list-text="(iii)">
                            <p
                              style={{
                                paddingTop: "4pt",
                                paddingLeft: "22pt",
                                textIndent: "-16pt",
                                textAlign: "justify",
                              }}
                            >
                              quando você os fornece através dos canais de
                              atendimento do Programa;
                            </p>
                          </li>
                        </ol>
                        <p
                          style={{
                            paddingTop: "5pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          (iii) através de cookies, tags e tecnologias
                          semelhantes.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={3}>
                        <p
                          className="s1"
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "left",
                          }}
                        >
                          - Quais dados são coletados no âmbito do Programa e
                          qual a finalidade?
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            marginLeft: "6.75pt",
                            width: "100%",
                          }}
                          cellSpacing={0}
                        >
                          <tbody>
                            <tr style={{ height: "20pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s2"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Dados Coletados
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s2"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Finalidades
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "42pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                                rowSpan={4}
                              >
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                                <p
                                  className="s3"
                                  style={{
                                    paddingLeft: "5pt",
                                  }}
                                >
                                  Nome completo, CPF, cargo e histórico de venda
                                  de produtos Whirlpool.
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Confirmar sua identidade e elegibilidade para
                                  a participação no Programa e realizar seu
                                  pré-cadastro na Plataforma
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "41pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Permitir o crédito de
                                </p>
                                <p
                                  className="s3"
                                  style={{
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  pontos e resgate de prêmios, conforme o
                                  previsto nos Termos.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "32pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                >
                                  Realizar análises de prevenção à fraude e
                                  riscos associados<span className="s4">.</span>
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "21pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Permitir o cumprimento de obrigações legais e
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            marginLeft: "6.75pt",
                            width: "100%",
                          }}
                          cellSpacing={0}
                        >
                          <tbody>
                            <tr style={{ height: "30pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  style={{
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  <br />
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "4pt",
                                  }}
                                >
                                  regulatórias pela Whirlpool e resguardar os
                                  direitos desta.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "31pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                                rowSpan={5}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Nome completo, e-mail, número de telefone
                                  (celular e fixo), endereço, data de
                                  nascimento, gênero, estado civil, senha de
                                  acesso e foto.
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "3pt",
                                  }}
                                >
                                  Permitir que você realize seu cadastro na
                                  Plataforma e participe do Programa
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "21pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  Prestar atendimento relacionado ao Programa.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "41pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Realizar estudos internos sobre o Programa,
                                  com o intuito de aprimorar nossos produtos,
                                  serviços e operações.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "52pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Enviar comunicações sobre o funcionamento do
                                </p>
                                <p
                                  className="s3"
                                  style={{
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Programa, novidades relacionadas a este e
                                  assuntos relevantes para a manutenção do
                                  relacionamento com os Participantes.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "41pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "5pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Possibilitar ações promocionais e de
                                  marketing, dentre elas a de promover nossos
                                  produtos e serviços e novas campanhas do
                                  Programa.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "62pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Filiação
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Possibilitar o cumprimento de obrigações
                                  legais e regulatórias referentes à emissão de
                                  cartões
                                </p>
                                <p
                                  className="s3"
                                  style={{
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  pré-pagos, quando o Participante opta por essa
                                  forma de resgate dos prêmios, conforme o
                                  previsto nos Termos.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "31pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                                rowSpan={4}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "6pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Dados de navegação, como endereço IP, data e
                                  hora de acesso, tipo de navegador, duração da
                                  visita, páginas visitadas. Dados sobre o
                                  dispositivo de acesso como modelo, fabricante,
                                  sistema operacional, operadora de telefonia,
                                  tipo de navegador, tipo e velocidade da
                                  conexão.
                                </p>
                              </td>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "46pt",
                                    paddingRight: "2pt",
                                    textIndent: "-27pt",
                                    textAlign: "left",
                                  }}
                                >
                                  Registrar o seu IP, data e hora de acesso,
                                  para o cumprimento de obrigações legais.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "41pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Ativar funcionalidades essenciais, como
                                  software antivírus, apresentação do conteúdo
                                  na tela, entre outras funções.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "31pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "5pt",
                                  }}
                                >
                                  Impedir atividades fraudulentas e melhorar a
                                  segurança da Plataforma.
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "62pt" }}>
                              <td
                                style={{
                                  width: "234pt",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "2pt",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "2pt",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "2pt",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "2pt",
                                }}
                              >
                                <p
                                  className="s3"
                                  style={{
                                    paddingTop: "5pt",
                                    paddingLeft: "5pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                  }}
                                >
                                  Entender seu comportamento de navegação e como
                                  o website está sendo usado, para melhorar sua
                                  experiência como usuário. Os dados coletados
                                  para essa finalidade são agregados e, sempre
                                  que possível, anonimizados.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={4}>
                        <p
                          className="s1"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "justify",
                          }}
                        >
                          - Cookies e tecnologias semelhantes
                        </p>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          Utilizamos cookies e tecnologias semelhantes, tais
                          como tags e pixels (Cookies) para coletar informações
                          sobre seu navegador, dispositivo e demais dados de
                          navegação. Eles nos permitem saber como e quando o
                          website é acessado, bem como quantas pessoas o
                          acessam. Eles podem ser úteis para, por exemplo,
                          fazermos o website encaixar na sua tela, entender
                          melhor a utilização do website. Utilizamos os
                          seguintes tipos de cookies:
                        </p>
                        <ul id="l4">
                          <li data-list-text="●">
                            <p
                              style={{
                                paddingTop: "4pt",
                                paddingLeft: "42pt",
                                textIndent: "-18pt",
                                lineHeight: "114%",
                                textAlign: "justify",
                              }}
                            >
                              Cookies essenciais: São os Cookies estritamente
                              necessários para fornecer nossos serviços e para
                              que o website funcione corretamente, garantindo a
                              segurança da navegação, o dimensionamento do
                              conteúdo e o cumprimento de obrigações legais pela
                              Whirlpool.
                            </p>
                          </li>
                          <li data-list-text="●">
                            <p
                              style={{
                                paddingTop: "4pt",
                                paddingLeft: "42pt",
                                textIndent: "-18pt",
                                lineHeight: "114%",
                                textAlign: "justify",
                              }}
                            >
                              Cookies de publicidade: São Cookies usados para
                              direcionamento de conteúdos e publicidade conforme
                              o seu perfil e preferências. Eles servem para que
                              você veja anúncios mais relevantes e mais
                              interessantes durante a sua navegação.
                            </p>
                          </li>
                          <li data-list-text="●">
                            <p
                              style={{
                                paddingLeft: "42pt",
                                textIndent: "-18pt",
                                lineHeight: "114%",
                                textAlign: "justify",
                              }}
                            >
                              Cookies estatísticos (analytics): Fornecem
                              informações sobre seu comportamento de navegação e
                              como o website está sendo usado. Os dados
                              coletados são agregados e nosso objetivo é
                              entender melhor o nosso público, para que possamos
                              oferecer conteúdo, serviços e produtos mais
                              interessantes para quem acessa nosso website.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <p
                              style={{
                                paddingTop: "9pt",
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "156%",
                                textAlign: "justify",
                              }}
                            >
                              Se você quiser saber quais cookies estão
                              instalados no seu dispositivo, ou se deseja
                              excluí-los ou restringi-los, use a configuração do
                              seu navegador. Você encontrará mais explicações
                              sobre como proceder clicando nos links abaixo.
                              Para encontrar informações relacionadas a outros
                              navegadores, visite o site do desenvolvedor do
                              navegador.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "150%",
                                textAlign: "left",
                              }}
                            >
                              <a
                                rel="noopener noreferrer"
                                href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox"
                                className="s5"
                                target="_blank"
                              >
                                Firefox
                              </a>
                              <a
                                rel="noopener noreferrer"
                                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                                style={{
                                  color: "#263874",
                                  fontFamily: "Arial, sans-serif",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  textDecoration: "none",
                                  fontSize: "10pt",
                                }}
                                target="_blank"
                              >
                                {" "}
                              </a>
                              <a
                                rel="noopener noreferrer"
                                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                                className="s5"
                                target="_blank"
                              >
                                Chrome
                              </a>
                              <a
                                rel="noopener noreferrer"
                                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                style={{
                                  color: "#263874",
                                  fontFamily: "Arial, sans-serif",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  textDecoration: "none",
                                  fontSize: "10pt",
                                }}
                                target="_blank"
                              >
                                {" "}
                              </a>
                              <a
                                rel="noopener noreferrer"
                                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                className="s5"
                                target="_blank"
                              >
                                Safari
                              </a>
                            </p>
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                textAlign: "left",
                              }}
                            >
                              <a
                                rel="noopener noreferrer"
                                href="https://support.microsoft.com/pt-br/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                                className="s5"
                              >
                                Internet Explorer
                              </a>
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "156%",
                                textAlign: "justify",
                              }}
                            >
                              Lembre-se que o uso de cookies nos permite
                              oferecer uma melhor experiência em nossos produtos
                              e serviços. Se você bloquear cookies em nosso
                              website, não podemos garantir o correto
                              funcionamento de todas as funcionalidades e talvez
                              você não consiga acessar determinadas áreas e/ou
                              páginas. Além disso, é bem provável que certas
                              funções e páginas não funcionem da maneira
                              adequada.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text={5}>
                        <p
                          className="s1"
                          style={{
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "left",
                          }}
                        >
                          - Os seus direitos como titular de dados
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          <a
                            rel="noopener noreferrer"
                            href="mailto:privacidadebr@whirlpool.com"
                            style={{
                              color: "black",
                              fontFamily: "Arial, sans-serif",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              textDecoration: "none",
                              fontSize: "10pt",
                            }}
                            target="_blank"
                          >
                            Você tem alguns direitos relativos aos dados que
                            tratamos. Abaixo, trazemos uma lista deles, além de
                            informações sobre como você pode exercê-los. Ao
                            recebermos a sua solicitação, poderemos pedir
                            algumas informações adicionais para verificarmos sua
                            identidade e analisarmos seu pedido. Para exercer
                            quaisquer desses direitos, envie um e-mail para{" "}
                          </a>
                          <span
                            style={{
                              color: "#1154CC",
                              fontFamily: "Arial, sans-serif",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              textDecoration: "underline",
                              fontSize: "10pt",
                            }}
                          >
                            privacidadebr@whirlpool.com
                          </span>
                          , aos cuidados do Encarregado de Proteção de Dados.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <p
                          className="s7"
                          style={{
                            paddingLeft: "5pt",
                          }}
                        >
                          Confirmação e Acesso{" "}
                          <span className="s8">
                            Permite que você possa verificar se tratamos dados
                            pessoais seus e, em caso positivo, requisitar uma
                            cópia dos dados pessoais que nós temos sobre você.
                          </span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <p
                          className="s7"
                          style={{
                            paddingLeft: "5pt",
                          }}
                        >
                          
                          <span className="s8">
                          Correção Permite que você corrija alguns dos seus dados
                            pessoais incompletos, inexatos ou desatualizados por
                            meio do seu próprio cadastro no site.
                          </span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "44pt",
                            textIndent: "-32pt",
                            textAlign: "left",
                          }}
                        >
                          Anonimização, bloqueio ou eliminação
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "12pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Permite que você nos peça, quando os dados tratados
                          sejam desnecessários, excessivos ou tratados em
                          desconformidade com a lei, para (a) anonimizar seus
                          dados, de forma a que eles não possam mais ser
                          relacionados a você e, portanto, deixem de ser dados
                          pessoais; (b) bloquear seus dados, suspendendo
                          temporariamente a sua possibilidade de tratarmos; e
                          (c) eliminar seus dados, caso em que apagaremos todos
                          os seus dados sem possibilidade de reversão, salvo nos casos previstos em lei e quando a manutenção
                          for necessária para o cumprimento de obrigação legal
                          ou regulatória.
                        </p>
                    
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "7pt",
                            paddingLeft: "12pt",
                            textIndent: "0pt",
                            textAlign: "left",
                          }}
                        >
                          Portabilidade
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "12pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Você tem o direito de solicitar, mediante requisição
                          expressa e dentro dos parâmetros estabelecidos pela
                          Autoridade Nacional de Proteção de Dados (ANPD), que
                          seja fornecido a você, ou a terceiro que você
                          escolher, os seus dados pessoais em formato
                          estruturado e interoperável, para transferência a
                          outra empresa, desde que não viole a propriedade
                          intelectual ou segredo de negócios da empresa.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "12pt",
                            textIndent: "-3pt",
                            textAlign: "left",
                          }}
                        >
                          Informação sobre o compartilhamento
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "4pt",
                            textIndent: "0pt",
                            textAlign: "left",
                          }}
                        >
                          Você tem o direito de saber as entidades públicas e
                          privadas com as quais realizamos uso compartilhado de
                          dados.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "5pt",
                            paddingTop: "8pt",
                            textIndent: "21pt",
                            textAlign: "left",
                          }}
                        >
                          Informação sobre a possibilidade de não consentir
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Permite que você tenha informações claras e completas
                          sobre a possibilidade e as consequências de não
                          fornecer consentimento. O seu consentimento, quando
                          necessário, deve ser livre e informado. Portanto,
                          sempre que pedirmos seu consentimento, você poderá
                          negá-lo – ainda que, nesses casos, é possível que
                          tenhamos que limitar alguns serviços.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "left",
                          }}
                        >
                          Revogação do consentimento
                        </p>
                        <p
                          className="s8"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Você tem o direito de retirar o seu consentimento em
                          relação às atividades de tratamento que se baseiam no
                          consentimento. No entanto, isso não afetará a
                          legalidade de qualquer tratamento realizado
                          anteriormente. Se você retirar o seu consentimento,
                          talvez não possamos fornecer determinadas comunicações
                          ou serviços para você, mas iremos avisá-lo quando isso
                          ocorrer.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                      </li>
                      <li data-list-text={6}>
                        <p
                          className="s1"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "justify",
                          }}
                        >
                          - Com quem compartilhamos os seus dados?
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          A Whirlpool opera em parceria com outras empresas para
                          o desenvolvimento desta Campanha e pode compartilhar
                          com elas seus dados pessoais, sempre dentro dos
                          limites das finalidades informadas no tópico 3 acima e
                          de forma a preservar ao máximo a sua privacidade:
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <h2
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          Grupo Econômico Whirlpool
                          <span className="p">
                            : Suas informações podem ser compartilhadas entre as
                            empresas do mesmo grupo econômico da Whirlpool para
                            (i) atender aos legítimos interesses da Whirlpool e
                            aos seus interesses; (ii) possibilitar o cumprimento
                            de obrigações legais e regulatórias e o exercício
                            regular de direitos por parte da Whirlpool (iii)
                            suportar o desenvolvimento de novos produtos da
                            Whirlpool; (iv) segmentar e fazer profiling e
                            estudos, para melhorar nossos produtos e serviços;
                            (vi) possibilitar reorganizações societárias, como
                            fusões, aquisições e incorporações.
                          </span>
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <h2
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          Fornecedores e Parceiros
                          <span className="p">
                            : Contamos com a ajuda de fornecedores e parceiros
                            que podem tratar dados pessoais que coletamos. As
                            informações são divulgadas apenas para as
                            finalidades informadas no tópico 3 acima. Sempre
                            buscamos avaliar cuidadosamente nossos fornecedores
                            e firmar com eles obrigações contratuais de proteção
                            de dados pessoais e segurança da informação, com o
                            objetivo de minimizar riscos para os titulares.
                            Entre esses fornecedores, estão, por exemplo:
                            agências de marketing e publicidade; parceiros do
                            Programa responsáveis por fazer a entrega e
                            disponibilização dos produtos e serviços resgatados;
                            servidores de hospedagem de dados; bancos e
                            instituições financeiras.
                          </span>
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <h2
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          Autoridades públicas:
                          <span className="p">
                            podemos compartilhar quaisquer dados pessoais
                            necessários ao cumprimento de obrigações legais ou
                            regulatórias ou, ainda, proteger os nossos direitos
                            e dos nossos colaboradores. Além disso, também
                            podemos ser obrigados a compartilhar alguns de seus
                            dados pessoais com entidades públicas para cumprir
                            obrigações legais ou regulatórias ou para cumprir
                            ordens de autoridades competentes
                          </span>
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        <h2
                          style={{
                            paddingTop: "3pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "108%",
                            textAlign: "justify",
                          }}
                        >
                          Outros países
                          <span className="p">
                            : Ainda que a Campanha seja destinada a pessoas
                            localizadas no Brasil, a Whirlpool é uma empresa
                            global, de modo que os dados pessoais podem ser
                            transferidos para empresas e fornecedores
                            localizados em outros países, como os Estados
                            Unidos. Adotamos as salvaguardas adequadas para que
                            essa transferência seja realizada de forma segura e
                            que seus direitos como titular dos dados pessoais
                            sejam resguardados.
                          </span>
                        </h2>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={7}>
                        <p
                          className="s1"
                          style={{
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "justify",
                          }}
                        >
                          - Quais são nossas responsabilidades e como protegemos
                          os seus dados pessoais?
                        </p>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Nossa responsabilidade é cuidar dos seus dados
                          pessoais, e utilizá-los para finalidades lícitas,
                          conforme descrito nesta Política. Para garantir a sua
                          privacidade e a proteção dos seus dados pessoais,
                          adotamos as práticas de segurança adequadas para nosso
                          mercado, com o uso de técnicas de criptografia e
                          outros sistemas de segurança da informação.
                        </p>
                        <p
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Nós trabalhamos para preservar a sua privacidade e
                          proteger os seus dados pessoais, mas infelizmente não
                          podemos garantir segurança absoluta. Entrada ou uso
                          não autorizado de terceiros em sua conta, falha de
                          hardware ou software que não esteja sob controle da
                          Whirlpool e outros fatores podem comprometer a
                          segurança dos seus dados pessoais. Por isso, sua
                          atuação é fundamental para a manutenção de um ambiente
                          seguro para todos. Você pode nos ajudar adotando boas
                          práticas de segurança em relação à sua conta e aos
                          seus dados e caso você tome conhecimento de algo que
                          comprometa a segurança dos seus dados, por favor,
                          entre em contato conosco por meio do nosso Encarregado
                          (Data Protection Officer), cujos canais de contato se
                          encontram abaixo.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={8}>
                        <p
                          className="s1"
                          style={{
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "justify",
                          }}
                        >
                          - Por quanto tempo os dados pessoais serão
                          armazenados?
                        </p>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          A Whirlpool tem uma política com regras sobre a
                          retenção e o descarte ou anonimização de dados
                          pessoais. Ela define as diretrizes para determinar o
                          período de retenção adequado para cada tipo de dado
                          pessoal coletado, considerando a sua natureza,
                          necessidade de coleta e finalidade para a qual ele
                          será tratado. Dados pessoais são armazenados somente
                          pelo tempo que for necessário para cumprir com as
                          finalidades para as quais foram coletados, salvo se
                          houver qualquer outra razão para sua manutenção, por
                          exemplo, cumprimento de quaisquer obrigações legais,
                          regulatórias, contratuais, entre outras, desde que
                          fundamentadas com uma base legal.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={9}>
                        <p
                          className="s1"
                          style={{
                            paddingTop: "6pt",
                            paddingLeft: "15pt",
                            textIndent: "-9pt",
                            textAlign: "justify",
                          }}
                        >
                          - Informações Gerais
                        </p>
                        <h2
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Alterações na Política de Privacidade
                        </h2>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          Como estamos sempre buscando melhorar nossos Produtos
                          e Experiências, esta Política de Privacidade pode
                          passar por atualizações para refletir as melhorias
                          realizadas, bem para permitir a adequação a novas
                          disposições legais e regulamentares aplicáveis. Desta
                          forma, recomendamos a visita periódica desta página
                          para que você tenha conhecimento sobre as modificações
                          efetivadas<span style={{ color: "#6E6E6E" }}>.</span>
                        </p>
                        <p
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Versão atual publicada em: abril de 2022
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <h2
                          style={{
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          Lei aplicável e jurisdição
                        </h2>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "justify",
                          }}
                        >
                          A presente Política de Privacidade será regida,
                          interpretada e cumprida segundo a legislação
                          brasileira. O foro competente para dirimir qualquer
                          litígio ou controvérsia envolvendo o presente
                          documento será definido conforme a legislação
                          aplicável.
                        </p>
                      </li>
                      <li data-list-text={10}>
                        <p
                          className="s1"
                          style={{
                            paddingTop: "3pt",
                            paddingLeft: "21pt",
                            textIndent: "-15pt",
                            textAlign: "justify",
                          }}
                        >
                          - Dúvidas e Contato
                        </p>
                        <p
                          style={{
                            paddingTop: "9pt",
                            paddingLeft: "6pt",
                            textIndent: "0pt",
                            textAlign: "justify",
                          }}
                        >
                          <a
                            rel="noopener noreferrer"
                            href="mailto:privacidadebr@whirlpool.com"
                            style={{
                              color: "black",
                              fontFamily: "Arial, sans-serif",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              textDecoration: "none",
                              fontSize: "10pt",
                            }}
                            target="_blank"
                          >
                            Se quiser exercer algum direito previsto no item 5
                            acima ou tiver dúvidas, comentários ou sugestões
                            relacionadas a esta Política envie um e-mail para{" "}
                          </a>
                          <span
                            style={{
                              color: "#1154CC",
                              fontFamily: "Arial, sans-serif",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              textDecoration: "underline",
                              fontSize: "10pt",
                            }}
                          >
                            privacidadebr@whirlpool.com
                          </span>
                          , aos cuidados do Encarregado de Proteção de Dados.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text={11}>
                        <p
                          className="s1"
                          style={{
                            paddingLeft: "20pt",
                            textIndent: "-14pt",
                            textAlign: "justify",
                          }}
                        >
                          - Vocabulário
                        </p>
                      </li>
                    </ol>
                    <p
                      style={{
                        paddingTop: "9pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "justify",
                      }}
                    >
                      A fim de descomplicar a sua leitura, apresentamos algumas
                      definições úteis para a sua interpretação
                      <span style={{ color: "#6E6E6E" }}>:</span>
                    </p>
                    <h2
                      style={{
                        paddingTop: "5pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      Dado Pessoal
                      <span className="p">
                        : É o dado relativo a uma pessoa física e que seja capaz
                        de identificar a pessoa ou tornar possível a sua
                        identificação. São exemplos de Dados Pessoais que
                        permitem a sua identificação: Nome, CPF, RG, endereço,
                        telefone celular, e-mail etc.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "justify",
                      }}
                    >
                      Dado Anonimizado:{" "}
                      <span className="p">
                        É qualquer informação que perde a capacidade de
                        identificar uma pessoa física.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      Encarregado de Proteção de Dados (DPO)
                      <span className="p">
                        : é aquele que atua como canal de comunicação entre o
                        Grupo Whirlpool, os Titulares dos Dados e a Autoridade
                        Nacional de Proteção de Dados (ANPD), quando o assunto é
                        a proteção dos seus Dados Pessoais.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      Endereço IP
                      <span className="p">
                        : Abreviatura de Internet Protocol. É o conjunto
                        alfanumérico que identifica os dispositivos dos Usuários
                        na Internet.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      Grupo Whirlpool:{" "}
                      <span className="p">
                        Para os fins da presente Política, considera-se a
                        WHIRLPOOL S.A., sociedade empresária com sede na Rua
                        Olympia Semeraro, nº 675, São Paulo, SP, CEP 04183-090,
                        inscrita no CNPJ/MF sob o nº 59.105.999/0001-86, e todas
                        sociedades nas quais ela detém participação societária
                        direta ou indiretamente, e/ou com sócio em comum,
                        existentes ou que venham a existir.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      Lei Geral de Proteção de Dados ou LGPD:{" "}
                      <span className="p">
                        Lei nº 13.709/18, que dispõe sobre o Tratamento de Dados
                        Pessoais, com o objetivo de proteger os direitos
                        fundamentais de liberdade e de privacidade e o livre
                        desenvolvimento da personalidade da pessoa natural.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "left",
                      }}
                    >
                      Marcas
                      <span className="p">
                        : são as Marcas do Grupo Whirlpool, existentes ou que
                        venham a ser, como Brastemp, Compra Certa, Consul,
                        KitchenAid etc.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Plataforma: 
                        designa o endereço eletrônico{" "}
                      <a
                        rel="noopener noreferrer"
                        href="https://www.programamaistop.com.br/"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          textDecoration: "none",
                          fontSize: "10pt",
                        }}
                        target="_blank"
                      >
                        https://www.programamaistop.com.br/
                      </a>
                      
                        
                      <span className="s11"> </span>
                      <span className="p">e seus subdomínios.</span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "left",
                      }}
                    >
                      Produtos e Serviços:{" "}
                      <span className="p">
                        São todos os produtos, negócios, experiências e jornada
                        de consumo oferecidas pelas Marcas do Grupo Whirlpool,
                        como vendas, plataformas, tecnologias e outros
                      </span>
                      <span style={{ color: "#6E6E6E" }}>.</span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Programa +TOP:{" "}
                      <span className="p">
                        programa de relacionamento, capacitação e reconhecimento
                        mantido pela Whirlpool.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Titular de Dados:{" "}
                      <span className="p">
                        É você, a pessoa física a quem os Dados Pessoais se
                        referem.
                      </span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        textAlign: "justify",
                      }}
                    >
                      Tratamento de Dados Pessoais:{" "}
                      <span className="p">
                        são os usos que nós fazemos dos seus Dados Pessoais,
                        incluindo, mas não se limitando às seguintes atividades:
                        coleta, armazenamento, consulta, compartilhamento,
                        classificação, reprodução e avaliação{" "}
                      </span>
                      <span style={{ color: "#6E6E6E" }}>destes.</span>
                    </h2>
                    <h2
                      style={{
                        paddingTop: "6pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "justify",
                      }}
                    >
                      Participante:{" "}
                      <span className="p">
                        é você, a pessoa física que participa do Programa.
                      </span>
                    </h2>
                  </div>
                </Card.Text>
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    style={{ height: "2rem" }}
                    className="col-5 mt-5 mb-3 btn btn-laranja text-white justify-content-center d-flex align-items-center"
                    to="/home"
                  >
                    Fechar
                  </Link>
                </div>
              </Card.Body>
            </Card>
          
        </Col>
      </Row>
      </Container>
    </BodyModal>
  );
}
