import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { Dados } from "./styles";
import IndicadoresMeses from "./IndicadoresMeses";
import { GraficoGChart } from "../GraficoGChart"

export function GameficacaoDesempenho({ dados, tipoGrafico }) {

    const [nivelUsuario, setNivelUsuario] = useState(0)
    const [dadosDesempenhoGrafico, setDadosDesempenhoTreinamento] = useState()
    const [DadosDesempenhoTreinamentoGrafico, setDadosDesempenhoTreinamentoGrafico] = useState()
    const [tipoProduto, setTipoProduto] = useState('Supertop')
    const [anoSelected, setAnoSelected] = useState(2023)
    var today = new Date();
    var yyyy = today.getFullYear();

    const ObterConquista = () => {

        api
            .post(rotasApi.ObterConquistas)
            .then((result) => {
                setNivelUsuario(result.data.conquistas_usuario.nivelUsuario)
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        ObterConquista()
        MeuDesempenhoDadosDesafios(yyyy - 1)
        let data = yyyy
        setAnoSelected(data)
        tipoGrafico = 1
    }, []);

    useEffect(() => {
        MeuDesempenhoDadosDesafios()
    }, [tipoProduto, anoSelected]);

    const MeuDesempenhoDadosDesafios = () => {
        api
            .get(rotasApi.MeuDesempenhoDadosDesafio + `?tipoProduto=${tipoProduto}&ano=${anoSelected}`)
            .then((result) => {
                setDadosDesempenhoTreinamento(result.data.dto)
                prepararDadosGrafico(result.data.dto)

            }).catch((error) => {
                console.log('error obter desempenho Desafios')
            })
    };

    const prepararDadosGrafico = (result) => {

        let data = result.vendasVsMetas.resultadoMensal.map((item) => ({
            label: item.meses,
            value: item.percentualAlcancado,
        }));
        let meses = [['Meses', 'Valor'], ...data.map(({ label, value }) => [label, value])];
        let chartData = [{ 'meses': meses }]
        result.vendasVsMetas.resultadoMensal = chartData[0]
        setDadosDesempenhoTreinamentoGrafico(result.vendasVsMetas.resultadoMensal)

    }


    return (
        <Dados>
            {tipoGrafico === 2 ?
                <div className="filtros"><div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { setTipoProduto(e.target.value) }}>
                        <option value='supertop'>Supertop</option>
                        <option value='participante'>Participante</option>
                    </Form.Select>
                </div>  <div className="d-flex justify-content-end">
                        <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { setAnoSelected(e.target.value) }}>
                            <option value={yyyy}>{yyyy}</option>
                            <option value={yyyy - 1}>{yyyy - 1}</option>
                        </Form.Select>
                    </div></div> : ('')}
            {dadosDesempenhoGrafico && tipoGrafico === 1 ?
                <div className="container-dados">
                    <div className="mt-lg-5 pt-3">
                        <div className="d-flex justify-content-between my-3 ">
                            <div className="col-2 title-marcador">Acessos</div>
                            <div className="col-10 d-flex justify-content-around position-relative">
                                <IndicadoresMeses mesesConsecutivos={dadosDesempenhoGrafico.geral.acessos.mesesConsecutivos} mesAtual={dadosDesempenhoGrafico.geral.acessos.mesAtual} dadoIndicatorTopo={'Explorador'} nivelUser={nivelUsuario === 1 ? 'active' : 'false'} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between my-3 ">
                            <div className="col-2 title-marcador">SuperTop</div>
                            <div className="col-10 d-flex justify-content-around position-relative">
                                <IndicadoresMeses mesesConsecutivos={dadosDesempenhoGrafico.geral.superTop.mesesConsecutivos} mesAtual={dadosDesempenhoGrafico.geral.participante.mesAtual} dadoIndicatorTopo={'Visionario'} nivelUser={nivelUsuario === 2 ? 'active' : 'false'} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between my-3 ">
                            <div className="col-2 title-marcador">Participante</div>
                            <div className="col-10 d-flex justify-content-around position-relative">
                                <IndicadoresMeses mesesConsecutivos={dadosDesempenhoGrafico.geral.participante.mesesConsecutivos} mesAtual={dadosDesempenhoGrafico.geral.superTop.mesAtual} dadoIndicatorTopo={'Realizador'} nivelUser={nivelUsuario === 3 ? 'active' : 'false'} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between my-3 ">
                            <div className="col-2 title-marcador">Treinamento</div>
                            <div className="col-10 d-flex justify-content-around position-relative">
                                <IndicadoresMeses mesesConsecutivos={dadosDesempenhoGrafico.geral.treinamento.mesesConsecutivos } mesAtual={dadosDesempenhoGrafico.geral.treinamento.mesAtual} dadoIndicatorTopo={'Conquistador'} nivelUser={nivelUsuario === 4 ? 'active' : 'false'} />
                            </div>
                        </div>
                    </div>
                </div> : ('')}

            {DadosDesempenhoTreinamentoGrafico && tipoGrafico === 2 ? <GraficoGChart chartType="LineChart" dadosdata={DadosDesempenhoTreinamentoGrafico} /> : ('')}

        </Dados>

    )
}
