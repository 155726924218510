import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import loginSlice from '../slices/loginSlice';
import primeiroAcessoSlice from '../slices/primeiroAcessoSlice';
import obterDadosCadastraisSlice from '../slices/obterDadosCadastraisSlice';


export const store = configureStore({
  reducer: {
    login: loginSlice,
    primeiroAcesso: primeiroAcessoSlice,
    obterDadosCadastrais: obterDadosCadastraisSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
