import { Breadcrumb, Col, Container, Row, Button, Form, Modal, CloseButton } from "react-bootstrap";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { Formik } from "formik";
import { Background, Tabela } from "./styles";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import novaLojaIcon from "../../images/img/nova-loja.svg"
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api.js";
import { ModalCadastroLoja } from "../../components/ModalCadastroLoja";
import { ModalAvisoCadastroLoja } from "../../components/ModalAvisoCadastroLoja"

export function CadastroLoja({ user }) {

  let [dadosTabela, setDadosTabela] = useState([]);
  let [dadosLoja, setDadosLoja] = useState([]);
  let [dadosNovaLoja, setDadosNovaLoja] = useState({
    lojaDto: {
      cnpj: '',
      codigoLoja: '',
      id: '',
      nome: '',
      endereco: {
        bairro: '',
        cep: '',
        cidade: '',
        uf: '',
        numero: '',
        endereco: '',
        complemento: '',
      }
    }
  });

  const [modalCadastroLoja, setModalCadastroLoja] = useState(false);
  const [modalCadastroNovaLoja, setModalCadastroNovaLoja] = useState(false);
  const [idLojaStatus, setIdLojaStatus] = useState()
  const [modalShow1, setModalShow1] = useState(false);
  const [modalMensagemShow1, setModalMensagemShow1] = useState('');
  const [modalSuccessState, setModalSuccessState] = useState(false)
  const handleClose = () => setModalShow1(false);

  const fecharModal = (e, sucesso) => {
    e ? setModalCadastroNovaLoja(!modalCadastroNovaLoja) : setModalCadastroLoja(!modalCadastroLoja)
    if (sucesso === true) {
      setModalSuccessState(true)
      setTimeout(() => {
        setModalSuccessState(false)
      }, 1000)
    } else { }
  }

  const handleBuscar = (values) => {
    console.log(values)
    if ((values.nome === '') && (values.cnpj === '')) {
      ListarLojas()
    }

    api

      .get(rotasApi.ListarLojasDaRede + `/${user.lojas[0].redeId}`)
      .then(result => {

        let filteredItems = result.data.filter(
          item => item.nome.toLowerCase().includes(values.nome.toLowerCase()) ?
            item.cnpj.toLowerCase().includes(values.cnpj) : (''));
        setDadosTabela(filteredItems)

      })

      .catch(error => {
        console.log(error);
      });




  };

  const BuscarLojaPorId = (e) => {


    api
      .get(rotasApi.BuscarLojaPorId + `?lojaid=${e}`)
      .then(result => {

        setDadosLoja(result.data)
        setModalCadastroLoja(true)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const ConfirmaAtivamento = (e) => {

    if (modalMensagemShow1 === 'ativação') {

      api
        .put(rotasApi.AtivarLoja + `?lojaid=${idLojaStatus}`)

        .then(result => {
          handleClose()
          setDadosLoja(result.data)
          ListarLojas()

        })
        .catch(error => {
          console.log(error);
        });

    } else {

      api
        .put(rotasApi.InativarLoja + `?lojaid=${idLojaStatus}`)

        .then(result => {
          handleClose()
          setDadosLoja(result.data)
          ListarLojas()

        })
        .catch(error => {
          console.log(error);
        });
    }
  }


  const AtivarLojaPorId = (e) => {

    setModalShow1(true)
    setIdLojaStatus(e)
  }

  useEffect(() => {
    ListarLojas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCadastroNovaLoja, modalCadastroLoja]);


  const ListarLojas = () => {
    api
      .get(rotasApi.ListarLojasDaRede + `/${user.lojas[0].redeId}`)
      .then(result => {
        setDadosTabela(result.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const modalSuccess = (e) => {
    return modalSuccessState
  }

  return (
    <Background className="cadastro-loja-blackfriday">
      {modalCadastroLoja ? <ModalCadastroLoja fecharModal={fecharModal} modalShow={modalCadastroLoja} lojaDados={dadosLoja} /> : ''}
      {modalCadastroNovaLoja ? <ModalCadastroLoja fecharModal={fecharModal} modalShow={modalCadastroNovaLoja} novaLoja={true} lojaDados={dadosNovaLoja} /> : ''}
      <NavBar user={user} />

      <ModalAvisoCadastroLoja modalSuccess={modalSuccess} />
      <Modal
        show={modalShow1}
        size="xs"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="background-dialog"
        contentClassName="modal-css"

      >
        <Modal.Header closeButton>
          <Modal.Title>{modalMensagemShow1} loja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Confirma a {modalMensagemShow1} desta loja ?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary btn-laranja" onClick={ConfirmaAtivamento}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <Breadcrumb className="mt-3">
          <Link className="breadcrumb-item" to="/home">
            Home
          </Link>
          <Breadcrumb.Item active>Cadastro Loja</Breadcrumb.Item>
        </Breadcrumb>

        <hr className="mt-3" />

        <Row>
          <Col className="mt-4 mb-2">
            <h3 className="titulo text-center">Cadastro de lojas</h3>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 mb-4 text-center d-flex justify-content-center align-items-center">
            <Button className="btn btn-laranja text-white btn-novaLoja" onClick={(e) => { setModalCadastroNovaLoja(true) }}><img src={novaLojaIcon} alt="nova loja" />Cadastrar nova loja</Button>
          </Col>
        </Row>

        <Row>
          <Tabela>
            <div className="campos-filtro">
              <Formik
                initialValues={{
                  nome: "",
                  cnpj: "",
                  ativo: "",
                }}
                onSubmit={(values) => handleBuscar(values)}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  isValid,
                  handleSubmit,
                  setFieldValue,
                  dirty,
                }) => (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="form-flex"
                  >
                    <Row className="w-100 row-flex">
                      <Form.Group className="mb-3" as={Col}>
                        <Form.Label>Busca por</Form.Label>

                        <Form.Control
                          placeholder="Digite o nome da loja"
                          type="text"
                          name="nome"
                          className="input-mes-ano"
                          value={values.nome}
                          onChange={handleChange}
                          isInvalid={!!errors.loja}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.loja}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" as={Col}>
                        <Form.Label className="label-input">CNPJ:</Form.Label>

                        <Form.Control
                          placeholder="Digite o cnpj"
                          type="text"
                          name="cnpj"
                          className="input-mes-ano"
                          value={values.cnpj}
                          onChange={handleChange}
                          isInvalid={!!errors.cnpj}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cnpj}
                        </Form.Control.Feedback>
                      </Form.Group>


                      <Col className="d-flex align-items-center pt-3">
                        <Button type="submit" className="btn-buscar">
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="tabela-resultado text-center">
              <>
                <div className="topo-tabela  justify-content-around">
                  <div className="titulo-loja">Nome da Loja</div>
                  <div className="titulo-cnpj"><span className="">CNPJ</span></div>
                  <div className="titulo-status"><span className="ps-4">Status</span></div>
                  {/* <div className="titulo-edit"></div> */}
                </div>
                <div className="corpo-tabela">
                  {dadosTabela.map(({ nome, cnpj, ativo, id }, index) => (
                    <div className="linha-tabela  justify-content-around" key={index}>
                      <div className="loja">{nome}</div>
                      <div className="cnpj">{cnpj}</div>
                      {/* <div className="status">{ativo}</div> */}

                      <div className="edit">
                        <Button className="btn-simple" onClick={(e) => BuscarLojaPorId(id)
                        }>
                          Editar
                        </Button>{" "}
                        |{" "}
                        {ativo === true && (
                          <Button className="btn-simple" onClick={(e) => (AtivarLojaPorId(id), setModalMensagemShow1('inativação'))}>
                            Inativar
                          </Button>
                        )}
                        {ativo === false && (
                          <Button className="btn-simple" onClick={(e) => (AtivarLojaPorId(id), setModalMensagemShow1('ativação'))}>
                            Ativar
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </div>
          </Tabela>
        </Row>
      </Container>

      <Footer className="m-0 p-0"></Footer>
    </Background>
  );
}
