import { useState, useRef } from "react";
import { Row, Form, Container, Col, Button } from "react-bootstrap";
import { NavBarPrimeiroAcesso } from "../../../components/NavBarPrimeiroAcesso/index";
import { LojaStyle } from "./styles";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";




import axios from "axios";




export function Loja() {

  const [enderecoAPI, setEnderecoAPI] = useState(true);


  const form1Ref = useRef(null);

  const handleLogin = (values) => {
    



    const formData = new FormData();

    formData.append("Nome", values.nome);
    formData.append("Codigo", values.codigo);
    formData.append("Cnpj", values.cnpj);

    formData.append("CEP", values.cep);
    formData.append("Endereco", values.endereco);
    formData.append("Numero", values.numero);
    formData.append("Complemento", values.complemento);
    formData.append("Bairro", values.bairro);
    formData.append("Cidade", values.cidade);
    formData.append("Estado", values.uf);


  }



  const handleCEP = (cep) => {
    axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(result => {
          form1Ref.current.setFieldValue('endereco', result.data.logradouro);
          form1Ref.current.setFieldValue('bairro', result.data.bairro);
          form1Ref.current.setFieldValue('cidade', result.data.localidade);
          form1Ref.current.setFieldValue('uf', result.data.uf);
          form1Ref.current.setFieldValue('complemento', result.data.complemento);
        })
        .catch(error => {
          console.log('error',error)
         });
  }

  const validationSchema = yup.object().shape({
    nome: yup
    .string()
    .min(10, "Nome Completo")
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Nome inválido"
    )
    .required("Nome é obrigatório"),

    codigo: yup
    .string()
    .min(2, "Código é obrigatório")
    .required("Código é obrigatório"),

    cnpj: yup
    .string()
    .min(10, "CNPJ Completo")
    .matches(
      /^([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})+$/,
      "cnpj inválido"
    )
    .required("CNPJ é obrigatório"),


    cep: yup
    .string()
    .test("cep","buscar cep", (val) => {
      if(val){
        if(enderecoAPI && val.length === 9){
        handleCEP(val)
        setEnderecoAPI(false)
        }
        if(val.length < 9){
          setEnderecoAPI(true)
        }
      }
      return true
    })
    .required("CEP é obrigatório")
    .matches(
      /^[0-9]{5}-[0-9]{3}$/,
      "preencha no formato xxxxx-xxx (com hífen)"
    ),

    endereco: yup.string().min(4, "Endereço inválido").required("Endereço é obrigatório"),
    numero: yup.number().integer().required("Número é obrigatório"),
    bairro: yup.string().min(4, "Bairro inválido").required("Bairro é obrigatório"),
    cidade: yup
    .string()
    .min(4, "Cidade inválida")
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Cidade inválida"
    )
    .required("Cidade é obrigatório"),
    
    uf: yup
    .string()
    .min(2, "UF inválida")
    .max(2, "UF inválida")
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "UF inválida"
    )
    .required("UF é obrigatório"),

  

  });

  

  return (
    <>
          <NavBarPrimeiroAcesso />
          <LojaStyle>
            <Container>
              <Formik
                innerRef={form1Ref}
                initialValues={{
                  nome: "",
                  codigo: "",
                  cnpj: "",
      
                  cep: "",
                  endereco: "",
                  numero: "",
                  complemento: "",
                  bairro: "",
                  cidade: "",
                  uf: "",
          
                }}
                onSubmit={(values) => {
                  handleLogin(values);
                }}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  isValid,
                  handleSubmit,
                  setFieldValue,
                  dirty,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mt-5">
                    <Col lg={{ span:4,offset:4 }}>
                        <div className="container-campos align-self-center ">
                          <div className="titulo-page text-center">
                          <Link to="/cadastro-loja" className="back">
                          <i className="bi bi-arrow-left"></i> <span className="small">Voltar</span>
                          </Link>
                            <h2>Cadastrar nova loja</h2>
                            <span>Preencha todos os campos com (*)</span>
                          </div>
                          <Row className="pt-5">
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Nome da loja*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="nome"
                                  value={values.nome}
                                  onChange={handleChange}
                                  isInvalid={!!errors.nome}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.nome}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Código da loja*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="nome"
                                  value={values.codigo}
                                  onChange={handleChange}
                                  isInvalid={!!errors.codigo}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.codigo}
                                </Form.Control.Feedback>
                              </Form.Group>

                              
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  CNPJ
                                </Form.Label>

                                <Form.Control
                                  type="number"
                                  name="cpf"
                                  value={values.cnpj}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cnpj}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cnpj}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>

                          


                          <Row className="mt-5">
                          <Col>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  CEP*
                                </Form.Label>
                                <div className="small position-relative">
                                  <a className="busca-cep" rel="noopener noreferrer" href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Não sei meu CEP</a>
                                </div>

                                <Form.Control
                                  type="text"
                                  maxLength="9"
                                  name="cep"
                                  value={values.cep}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cep}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cep}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Rua /  Avenida / Travessa / Praça*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="endereco"
                                  value={values.endereco}
                                  onChange={handleChange}
                                  isInvalid={!!errors.endereco}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.endereco}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Número
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="numero"
                                  value={values.numero}
                                  onChange={handleChange}
                                  isInvalid={!!errors.numero}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.numero}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Complemento
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="complemento"
                                  value={values.complemento}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Bairro*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="bairro"
                                  value={values.bairro}
                                  onChange={handleChange}
                                  isInvalid={!!errors.bairro}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.bairro}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Cidade*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="cidade"
                                  value={values.cidade}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cidade}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cidade}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  UF*
                                </Form.Label>

                                <Form.Control
                                  className="text-uppercase"
                                  type="text"
                                  maxLength="2"
                                  name="uf"
                                  value={values.uf}
                                  onChange={handleChange}
                                  isInvalid={!!errors.uf}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.uf}
                                </Form.Control.Feedback>
                              </Form.Group>
                              
                            </Col>
                          </Row>

                          <Row className="d-flex justify-content-center mt-5">
                            <Col>
                              
                              <Button
                                type="submit"
                                disabled={!isValid || !dirty}
                                className="mb-3 w-100 btn-laranja"
                              >
                                Cadastrar
                              </Button>
                              <Link
                                to="/cadastro-loja"
                                className="btn btn-outline-secondary btn-laranja-outline w-100"
                              >
                                Cancelar
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Container>
          </LojaStyle>
        </>
   
  );
}
