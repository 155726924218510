import { Modal, Button } from "react-bootstrap";
import images from "../../images/images";
import React, { useState } from "react";
import { CloseButton } from "./styles";
import { useEffect } from "react";


export function ModalAviso({ modalShow, mensagem, excluirItem }) {


    const [modalShow1, setModalShow1] = useState(modalShow);
    const handleClose = () => setModalShow1(false);

    useEffect(() => {
        setModalShow1(modalShow)
    }, [modalShow]);
    return (


        <Modal
            show={modalShow1}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton onClick={() => { excluirItem(false) && setModalShow1(false) }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>{mensagem}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Ao excluir essa foto não podera ser recuperada
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-verde" onClick={() => { excluirItem(true) && setModalShow1(false) }}>Sim</Button>
                <Button className="btn-danger" onClick={() => { excluirItem(false) && setModalShow1(false) }}>Não</Button>
            </Modal.Footer>
        </Modal>
    )
}
