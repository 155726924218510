import { Modal } from "react-bootstrap";
import images from "../../images/images";
import React, { useState } from "react";
import { CloseButton } from "./styles";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";

export function ModalComunicado({ dadosBannerComunicado }) {


    const [modalShow1, setModalShow1] = useState(true);

    const handleClose = (id) => {
        const formData = new FormData();

        formData.append("bannerId", id);

        api
            .post(rotasApi.ConfirmarLeituraBanner, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((result) => {
                setModalShow1(false);
            })
            .catch((error) => {
                console.log(error)
            });

    }




    return (
        <>
            {dadosBannerComunicado.map((item) => (
                <Modal
                    show={modalShow1}
                    size="xs"
                    onHide={(e) => { handleClose(item.id) }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdropClassName="background-dialog"
                    contentClassName="modal-css"
                >
                    <Modal.Body className="show-grid d-flex justify-content-center p-0 position-relative">
                        <CloseButton onClick={(e) => { handleClose(item.id) }} >
                            x
                        </CloseButton>
                        <a href={item.url}>
                            <img
                                className="d-block w-100"
                                src={item.urlImagem}
                                alt="First slide"
                            />
                        </a>
                    </Modal.Body>
                </Modal>
            ))}

        </>
    )
}
