import React, { useState, useEffect } from "react";
import { TopNewsPage, ContainerCards } from "./styles";
import { Container, Breadcrumb, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import parse from "html-react-parser";
import img from "./img-exemple.png"
import like from '../../images/img/like-unlike.svg';
import likeLiked from '../../images/img/like-liked.svg';
import smile from '../../images/img/smile-unlike.svg';
import smileLiked from '../../images/img/smile-liked.svg';
import heart from '../../images/img/heart-unlike.svg';
import heartLiked from '../../images/img/heart-liked.svg';
import tomasIcon from "./tomas-icon.png"


export function TopNews({ user }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [currenPost, setCurrentPost] = useState({
        urlArquivoConteudo: '',
        id: 0,
        titulo: '',
        dataPublicacao: '',
        idReacao: '',
        descricao: '',
    });
    const [listaPost, setListaPost] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 

    const handleImageClick = (id) => {
        setCurrentPost({
            urlArquivoConteudo: '',
            id: 0,
            titulo: '',
            dataPublicacao: '',
            idReacao: '',
            descricao: ''
        })

        api
            .get(
                rotasApi.BuscarDetalhesPublicacao + `?publicacaoId=${id}`,
            )
            .then((result) => {
                result.data.publicacao.urlArquivoConteudo.includes('.mp4') ? setIsVideo(true) : setIsVideo(false)
                setCurrentPost({
                    urlArquivoConteudo: result.data.publicacao.urlArquivoConteudo,
                    id: result.data.publicacao.id,
                    titulo: result.data.publicacao.titulo,
                    dataPublicacao: result.data.publicacao.dataPublicacao,
                    idReacao: result.data.publicacao?.reacaoId,
                    descricao: result.data.publicacao.descricao,
                })
                setModalOpen(true);
            })
            .catch((error) => {
                console.log('error List post=->', error)
            });

    };

    const handleFilterClick = (e) => {
        const listaInvertida = [...listaPost].reverse();
        setListaPost(listaInvertida);
    };

    const EnviarReacao = (publicacaoId, reacaoId) => {

        const formData = new FormData();

        formData.append("PublicacaoId", publicacaoId);
        formData.append("ReacaoId", reacaoId);

        api
            .post(rotasApi.SalvarReacao, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(result => {
                ListarPostagens()
            }).catch(error => {
            })
    };

    const closeModal = () => {
        setModalOpen(false);
    };


    useEffect(() => {
        ListarPostagens()
    }, []);

    function handleIconClick(idReacao, post) {
        EnviarReacao(post.id, idReacao);
        setCurrentPost({
            urlArquivoConteudo: post.urlArquivoConteudo,
            id: post.id,
            titulo: post.titulo,
            dataPublicacao: post.dataPublicacao,
            idReacao: idReacao,
            descricao: post.descricao,
        })
    }


    const ListarPostagens = () => {
        api
            .get(
                rotasApi.ListarPostagens,
            )
            .then((result) => {
                setListaPost(result.data.publicacoes)
            })
            .catch((error) => {
                console.log('error List post=->', error)
            });
    };

    return (
        <>
            <TopNewsPage className="theme">
                <NavBar user={user} natal={true} />
                <Container xs={12} className=" mb-5 container-topnews">
                    <Breadcrumb className="mt-3">
                        <Link className="breadcrumb-item" to="/home">
                            Home
                        </Link>
                        <Breadcrumb.Item active><b>TopNews</b></Breadcrumb.Item>
                    </Breadcrumb>

                    <hr className="mt-3" />
                    <div className="d-flex justify-content-between titulo-pagina">
                        <div><h4>Novidades +top news</h4></div>
                        <div>
                            <div className="d-flex justify-content-end">
                                <div className="col-11 d-flex ">
                                    <span className="col-mt-2">Filtrar busca por:</span>
                                    <Form.Select
                                        type="text"
                                        name="sexo"
                                        className="mb-4"
                                        onChange={(e) => { handleFilterClick(e.target.value) }}
                                    >
                                        <option value="recente">Recente</option>
                                        <option value="antigo">Mais Antigos</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContainerCards>
                        <div className="card-container">
                            {listaPost ?
                                listaPost.map((item, index) => (
                                    <div className="grid-item" key={index}>
                                        <div className="overlay" onClick={() => handleImageClick(item.id)}>
                                            <span className="overlay-text" >Clique e veja mais</span>
                                        </div>
                                        <img
                                            className="image"
                                            key={index}
                                            src={item.urlThumbnail}
                                            alt={item}
                                            onClick={() => handleImageClick(item.id)}
                                        />
                                        <div className="reacoes d-flex">
                                            <div>
                                                {item.reacaoId === 1 ?
                                                    <img src={heartLiked} alt="Imagem Ampliada" className="reacao-icon" onClick={() => EnviarReacao(item.id, 1)} />
                                                    :
                                                    <img src={heart} alt="Imagem Ampliada" className="reacao-icon" onClick={() => EnviarReacao(item.id, 1)} />
                                                }
                                            </div>
                                            <div>
                                                {item.reacaoId === 2 ?
                                                    <img src={likeLiked} alt="Imagem Ampliada" className="reacao-icon" onClick={() => EnviarReacao(item.id, 2)} />
                                                    :
                                                    <img src={like} alt="Imagem Ampliada" className="reacao-icon" onClick={() => EnviarReacao(item.id, 2)} />
                                                }
                                            </div>
                                            <div>
                                                {item.reacaoId === 3 ?
                                                    <img src={smileLiked} alt="Imagem Ampliada" className="reacao-icon" onClick={() => EnviarReacao(item.id, 2)} />
                                                    :
                                                    <img src={smile} alt="Imagem Ampliada" className="reacao-icon" onClick={() => EnviarReacao(item.id, 3)} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                ))
                                : ''
                            }
                        </div>
                        {isModalOpen && (
                            <div className={`modal ${isModalOpen ? 'active' : ''}`} onClick={closeModal}>
                                <div className="modal-content" onClick={e => e.stopPropagation()}>
                                    <div className="close-btn" onClick={closeModal}>
                                        <svg id="close-circle" xmlns="http://www.w3.org/2000/svg" width="38.853" height="38.853" viewBox="0 0 38.853 38.853">
                                            <path id="Vector" d="M16.189,32.377A16.189,16.189,0,1,0,0,16.189,16.236,16.236,0,0,0,16.189,32.377Z" transform="translate(3.238 3.238)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path id="Vector-2" data-name="Vector" d="M0,9.163,9.163,0" transform="translate(14.845 14.845)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path id="Vector-3" data-name="Vector" d="M9.163,9.163,0,0" transform="translate(14.845 14.845)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path id="Vector-4" data-name="Vector" d="M0,0H38.853V38.853H0Z" fill="none" opacity="0" />
                                        </svg>

                                    </div>
                                    <div className="icon-tomas">
                                        <img src={tomasIcon} alt="Imagem Ampliada" className="" />
                                        <div className="info">
                                            <h1>Tomastop</h1>
                                            <span>Postado em {currenPost.dataPublicacao ? currenPost.dataPublicacao : ''}</span>
                                        </div>
                                    </div>
                                    <p className="text-post">{currenPost.descricao ? parse(currenPost.descricao) : ('')}</p>

                                    {currenPost.urlArquivoConteudo ?
                                        <>
                                            {isVideo ?
                                                <video width="100%" height="auto" controls className="video">
                                                    <source src={currenPost.urlArquivoConteudo} type="video/mp4" />
                                                </video>
                                                :
                                                <img src={currenPost.urlArquivoConteudo} alt="Imagem Ampliada" className="modal-image" />
                                            }
                                        </>
                                        : ('')
                                    }
                                    <div className="reacoes">
                                        <div>
                                            {currenPost.idReacao === 1 ?
                                                <img src={heartLiked} alt="Imagem Ampliada" className="reacao-icon" onClick={() => handleIconClick(1, currenPost)} />
                                                :
                                                <img src={heart} alt="Imagem Ampliada" className="reacao-icon" onClick={() => handleIconClick(1, currenPost)} />
                                            }
                                        </div>
                                        <div>
                                            {currenPost.idReacao === 2 ?
                                                <img src={likeLiked} alt="Imagem Ampliada" className="reacao-icon" onClick={() => handleIconClick(2, currenPost)} />
                                                :
                                                <img src={like} alt="Imagem Ampliada" className="reacao-icon" onClick={() => handleIconClick(2, currenPost)} />
                                            }
                                        </div>
                                        <div>
                                            {currenPost.idReacao === 3 ?
                                                <img src={smileLiked} alt="Imagem Ampliada" className="reacao-icon" onClick={() => handleIconClick(3, currenPost)} />
                                                :
                                                <img src={smile} alt="Imagem Ampliada" className="reacao-icon" onClick={() => handleIconClick(3, currenPost)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ContainerCards>
                </Container>
                <Footer className="m-0 p-0"></Footer>
            </TopNewsPage>
        </>
    );
}