import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Home, DuvidasEstilo, BarraFaleConosco } from "./styles";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

export function Duvidas({ user }) {
	const [duvidas, setDuvidas] = useState([]);
	const [filtrarDuvida, setFiltrarDuvida] = useState([]);
	const [msgErro, setMsgErro] = useState();

	const buscaPerguntas = () => {
		api
			.get(rotasApi.buscaDuvidas, )
			.then(result => {
				setDuvidas(result.data.duvidas);
			})
			.catch(error => {
				console.log(error.response);
			});
	};

	const pesquisaDuvida = e => {
		e.preventDefault();
		if (filtrarDuvida.length === 0 || filtrarDuvida == null ) {
			buscaPerguntas();
			setMsgErro(false);
			return
		}
		api
			.get(
				`${rotasApi.pesquisaDuvidas}${filtrarDuvida}`
			)
			.then(result => {
				setMsgErro(false)
				setDuvidas(result.data.duvidas);
			})
			.catch(error => {
				setDuvidas([]);
				setMsgErro(error.response.data.mensagem)
			});
	};

	useEffect(() => {
		buscaPerguntas();
	}, []);

	return (
		<>
		<Home className="duvidas-blackfriday">
			<NavBar user={user} />
			<Container>
			
				<Breadcrumb className="mt-3">
					<Link className="breadcrumb-item" to="/home">Home</Link>
						<Breadcrumb.Item active>Dúvidas</Breadcrumb.Item>
					</Breadcrumb>
					
					<hr className="mt-3" />
				<Row>
					<Col className=" mt-0 mb-4" md="12">
						<DuvidasEstilo className="text-center">
							<h3 className="titulo text-center">
								Dúvidas frequentes sobre o +top
							</h3>

							<form onSubmit={pesquisaDuvida} className="form-busca">
								<button type="submit" className="btn-buscar">
									<i className="bi bi-search"></i>
								</button>
								<input
									type="text"
									placeholder="Digite sua dúvida aqui"
									className="input-busca"
									value={filtrarDuvida}
									onChange={event => setFiltrarDuvida(event.target.value)}
								/>
							</form>
						</DuvidasEstilo>

						{duvidas.map(({ titulo, descricao }, index) => (
							<Accordion>
								<Accordion.Item
									eventKey="0"
									className="mb-3 border-0 rounded"
									key={index}
								>
									<Accordion.Header>
										<span className="fw-bold">{titulo}</span>
									</Accordion.Header>
									<Accordion.Body>{descricao}</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						))}
					</Col>
				</Row>

				{msgErro && (
					<Row msgErro={true}>
						<Alert
							variant="danger"
							onClick={() => setMsgErro(false)}
							dismissible
						>
							<p>
								{msgErro}
							</p>
						</Alert>
					</Row>
				)}

					<BarraFaleConosco>
						<div className="barra-flutuante">
							<div className="mensagem">
								<h3 className="texto">Não encontrou o que precisa?</h3>

								<Link to="/fale-conosco" className="btn-fale-conosco">
									Fale Conosco
								</Link>
							</div>
						</div>
					</BarraFaleConosco>
		
			</Container>
			
			<Footer className="m-0 p-0"></Footer>
		<div className="space-fale-conosco"></div>
		</Home>
		
		</>
	);
}
