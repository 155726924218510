import React, { useState } from "react";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { Dados } from "./styles";

export function ComponenteAceiteCampanha({ dados }) {

    const [data, setData] = useState([
        ['Element', 'Density', { role: 'style' }],
        ['Copper', 8.94, '#b87333'],            // RGB value
        ['Silver', 10.49, 'silver'],            // English color name
        ['Gold', 19.30, 'gold'],
        ['Platinum', 21.45, 'color: #e5e4e2'], // CSS-style declaration
    ])

    const options = {
        colors: ['#F58420', '#9A5CFA'],
        titleTextStyle: {
            color: 'red',
            fontSize: 20,
            bold: true,
        },
    };

    useEffect(() => {
        console.log(dados)
    }, []);

    return (
        <Dados>
            <div className="container-dados">

                <div className="single-chart">
                    <p className="title">Porcentagem de aceite
                        de campanha</p>
                    <svg viewBox="0 0 36 36" className="circular-chart orange">
                        <path className="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
                        <path className="circle" strokeDasharray={`${dados.percentualAceite.split(',')[0]}, 100`} d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
                        <text x="18" y="20.35" className="percentage">{dados.percentualAceite.split(',')[0]}%</text>
                    </svg>
                </div>
                <div className="marcadores-mes">
                    {/* <div className="container">
                        <div className="quadradinho verde">JAN</div>
                        <div className="quadradinho vermelho">FEV</div>
                        <div className="quadradinho cinza">MAR</div>
                        <div className="quadradinho verde">ABR</div>
                        <div className="quadradinho vermelho">MAI</div>
                        <div className="quadradinho cinza">JUN</div>
                        <div className="quadradinho verde">JUL</div>
                        <div className="quadradinho vermelho">AGO</div>
                        <div className="quadradinho cinza">SET</div>
                        <div className="quadradinho verde">OUT</div>
                        <div className="quadradinho vermelho">NOV</div>
                        <div className="quadradinho cinza">DEZ</div>
                    </div> */}
                    <div className="container">
                        {dados.campanhasMensal.map(({ mes, totalCampanhas, totalAceites }, index) => (
                            <div key={index} className={`quadradinho ${+(totalCampanhas > 0) && (totalCampanhas === totalAceites) ? 'verde' : (totalCampanhas === 0) ? 'cinza' : (totalCampanhas > totalAceites) ? 'vermelho' : ''}`}><span className="indicador-mes">{totalCampanhas}/{totalAceites}</span>
                                {mes.charAt(0).toUpperCase() + mes.slice(1, 3)}
                            </div>
                        ))
                        }
                    </div>
                    <div className="d-flex container-legenda">
                        <div>
                            <div className="quadrado vermelho"></div>
                            <span>Não aceitou</span>
                        </div>
                        <div>
                            <div className="quadrado verde"></div>
                            <span>Aceitou</span>
                        </div>
                        <div>
                            <div className="quadrado cinza"></div>
                            <span>Sem campanha</span>
                        </div>
                    </div>
                </div>
            </div>
        </Dados>

    )
}
