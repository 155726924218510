import React from 'react';

export function LoadingArquivo() {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,

    
  };

  return (
    <div style={overlayStyle}>
      <svg width="280" height="170" viewBox="0 0 200 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
          .loading-animation path {
      animation: slide 2s ease-in-out infinite;
    }

    .loading-animation path:nth-child(1) {
      animation-delay: 0s;
    }

    .loading-animation path:nth-child(2) {
      animation-delay: 0.2s;
    }

    .loading-animation path:nth-child(3) {
      animation-delay: 0.4s;
    }

    .loading-animation path:nth-child(4) {
      animation-delay: 0.6s;
    }

    .loading-animation path:nth-child(5) {
      animation-delay: 0.8s;
    }

    @keyframes slide {
      0%, 100% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(10px);
      }
    }

    .loading-text {
      font-family: Arial, sans-serif;
      font-size: 14px;
      fill: #313131;
      text-anchor: middle;
      animation: slide-text 2s ease-in-out infinite;
      animation-delay: 1s;
    }
svg{

    @media (max-width: 768px) { 
            margin-left: 105px;
         }
}

    @keyframes slide-text {
      0%, 100% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(5px);
      }
    }
        `}
        </style>
        <g class="loading-animation">
          <path d="M14.6241 6.16688C10.2013 6.44751 6.99921 8.36668 6.19023 13.1073V19.2651H14.619V15.3473C14.619 15.304 14.619 15.2638 14.619 15.2216V13.0469H16.9189C17.0131 13.0469 17.1094 13.0399 17.2108 13.0399L19.777 13.0469H20.8092V6.16788L14.6241 6.16688Z" fill="#9CCB3D"></path>
          <path d="M6.19023 13.0962C10.613 12.8156 13.8151 10.8964 14.6241 6.15581V0H6.19432V3.92282C6.19432 3.96607 6.19432 4.00631 6.19432 4.04855V6.21616H3.89129C3.79708 6.21616 3.70082 6.2232 3.59944 6.2232L1.03222 6.21616H0V13.0952L6.19023 13.0962Z" fill="#EF4E23"></path>
          <path d="M33.1968 13.0952H27.0066V6.21616H33.1968V0H41.6307V6.21616H47.8465V13.0952H41.6307V23.889C41.6307 25.4873 42.3209 26.1662 44.1907 26.1662H47.8414V33.1931H42.9087C37.1874 33.1931 33.1917 30.8193 33.1917 23.7944L33.1968 13.0952Z" fill="#313131"></path>
          <path d="M64.4777 33.178C56.6316 33.178 50.6984 28.0089 50.6984 19.6906C50.6984 11.3722 56.7739 6.20309 64.5729 6.20309C72.419 6.20309 78.4474 11.3732 78.4474 19.6906C78.4474 28.0079 72.3248 33.178 64.4777 33.178ZM64.4767 26.2226C67.3951 26.2226 70.1221 24.1103 70.1221 19.6906C70.1221 15.2256 67.4433 13.1586 64.5719 13.1586C61.6022 13.1586 59.0217 15.2266 59.0217 19.6906C59.0217 24.1083 61.5111 26.2226 64.4767 26.2226Z" fill="#313131"></path>
          <path d="M98.4599 5.76957C105.218 5.76957 110.594 11.1006 110.594 19.6252C110.594 28.1498 105.218 33.5783 98.4599 33.5783C96.8039 33.6368 95.1595 33.286 93.6774 32.5581C92.1953 31.8302 90.9229 30.7485 89.9769 29.4121V46.0811H81.543V6.21616H89.9769V9.98509C90.9038 8.62654 92.1711 7.52516 93.6568 6.78687C95.1424 6.04859 96.7965 5.69825 98.4599 5.76957ZM95.9449 12.9886C92.7878 12.9886 89.9277 15.3624 89.9277 19.6745C89.9277 23.9865 92.7889 26.3604 95.9449 26.3604C99.1511 26.3604 102.011 23.9383 102.011 19.6211C102.011 15.304 99.1511 12.9825 95.9449 12.9825" fill="#313131"></path>
        </g>
        <text x="66" y="70" class="loading-text">Enviando arquivo ...</text>
      </svg>
    </div>
  );
};

