import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Row, Form, Container, Col, Button, Card, Fade } from "react-bootstrap";
import { NavBarPrimeiroAcesso } from "../../components/NavBarPrimeiroAcesso/index";
import { MeusDadosCampos, BodyModal } from "./styles";
import ImgPerfil from "../../images/img/img-perfil.svg";
import iconCadeado from "../../images/img/icon-cadeado.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
//import MaskedInput from "react-maskedinput";
//import styled from "styled-components";
//import ReCAPTCHA from "react-google-recaptcha";

import { primeiroAcessoSelector } from "../../slices/primeiroAcessoSlice";
import axios from "axios";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

//const sitekey = "6Ld482QbAAAAAM8iW9B-FKXtMj8Hde-TTzj62-PP";

export function Cadastro() {
  const { primeiroAcessoState } = useSelector(primeiroAcessoSelector);

  let navigate = useNavigate();

  const form1Ref = useRef(null);

  const [imgAvatar, setImgAvatar] = useState(ImgPerfil);
  const [modalAviso, setModalAviso] = useState(false);

  const [modalRegulamento, setModalRegulamento] = useState(false);
  const [modalPolitica, setModalPolitica] = useState(false);

  const [imgAviso, setImgAviso] = useState(false);
  const [imgSelecionada, setImgSelecionada] = useState(false);

  const [reenviaCodigo, setReenviaCodigo] = useState(false);
  const [segundos, setSegundos] = useState(0);
  const [minutos, setMinutos] = useState(5);
  const [tempoCodigo, setTempoCodigo] = useState("00");

  const [modalErro, setModalErro] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");

  const [passo, setPasso] = useState(1);

  const [escolhaForm, setEscolhaForm] = useState("");
  const [emailForm, setEmailForm] = useState("");
  const [telForm, setTelForm] = useState("");
  const [emailFinal, setEmailFinal] = useState();
  const [displayPage, setdisplayPage] = useState("d-flex");
  const [aceitoRegula, setAceitoRegula] = useState(false);
  const [aceitoPolitica, setAceitoPolitica] = useState(false);
  const [campoRespotaSeguranca, setCampoRespotaSeguranca] = useState('text');
  const [enderecoAPI, setEnderecoAPI] = useState(true);

  const [passwordShown, setPasswordShown] = useState(false);
  const [changeEye, setChangeEye] = useState("bi bi-eye-slash pass-hide-cadastro");
  const [passwordShownConfirma, setPasswordShownConfirma] = useState(false);
  const [changeEyeConfirma, setChangeEyeConfirma] = useState("bi bi-eye-slash pass-hide-cadastro");

  const togglePassword = () => {
    passwordShown ? setChangeEye('bi bi-eye-slash pass-hide-cadastro') : setChangeEye('bi bi-eye pass-hide-cadastro')
    setPasswordShown(!passwordShown);
  };
  const togglePasswordConfirma = () => {
    passwordShownConfirma ? setChangeEyeConfirma('bi bi-eye-slash pass-hide-cadastro') : setChangeEyeConfirma('bi bi-eye pass-hide-cadastro')
    setPasswordShownConfirma(!passwordShownConfirma);
  };

  const [limparStore, setLimparStore] = useState(true);

  const cpf = primeiroAcessoState.cpf;

  useEffect(() => {
    if (limparStore) {
      setLimparStore(false)
      setPasso(1)
    }
  }, [limparStore])

  useEffect(() => {
    if (cpf === undefined) {
      navigate('/')
    }

  }, [cpf, navigate])


  useEffect(() => {

    api
      .get(rotasApi.obterDadosCadastrais + cpf, {
        headers: { 
        "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
        "SkipAuth": "true"},
      }).then(result => {
        form1Ref.current.setFieldValue('nome', result.data.dadosCadastrais.nome);
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const zero = "0";

  useEffect(() => {
    if (reenviaCodigo) {
      setTimeout(() => {
        if (segundos < 10) {
          if (segundos === 0) {
            setSegundos(59);
            if (minutos > 0) {
              setMinutos(minutos - 1);
            }
          } else {
            setSegundos(segundos - 1);
          }
          setTempoCodigo(zero + segundos);
        } else {
          setSegundos(segundos - 1);
          setTempoCodigo(segundos);
        }
      }, 1000);

      if (minutos === 0 && segundos === 0) {
        setReenviaCodigo(false);
      }
    }
  }, [segundos, reenviaCodigo, minutos, zero]);

  const handleModalRegulamento = () => {
    setTimeout(() => {
      window.scrollTo(0, 2200);
    }, 100);
    setModalRegulamento(!modalRegulamento);
    displayPage === "d-flex"
      ? setdisplayPage("d-none")
      : setdisplayPage("d-flex");
  };

  const handleAceiteRegulamento = () => {
    handleModalRegulamento();
    setAceitoRegula(true);
  };

  const handleModalPolitica = () => {
    setTimeout(() => {
      window.scrollTo(0, 2200);
    }, 100);
    setModalPolitica(!modalPolitica);
    displayPage === "d-flex"
      ? setdisplayPage("d-none")
      : setdisplayPage("d-flex");
  };
  const handleAceitePolitica = () => {
    handleModalPolitica();
    setAceitoPolitica(true);
  };


  const handleLogin = (values) => {

    console.log("valores", values);

    // dispatch(PreCadastroAction(values));


    const formData = new FormData();
    //formData.append("UsuarioId", data.cpf);
    formData.append("Nome", values.nome);
    formData.append("DataNascimento", values.dataNascimento);
    formData.append("Cpf", values.cpf);
    formData.append("Email", values.email);
    formData.append("Celular", values.celular);
    formData.append("FotoPerfil", values.avatarImg);
    formData.append("CEP", values.cep);
    formData.append("Endereco", values.endereco);
    formData.append("Numero", values.numero);
    formData.append("Complemento", values.complemento);
    formData.append("PontoDeReferencia", values.referencia);
    formData.append("Bairro", values.bairro);
    formData.append("Cidade", values.cidade);
    formData.append("Estado", values.uf);
    formData.append("PerguntaDeSeguranca", values.perguntaSeguranca);
    formData.append("RespostaPerguntaDeSeguranca", values.respostaSeguranca);
    formData.append("CodigoConvite", values.codigoAmigo);

    formData.append("Senha", values.senha);

    formData.append("AceitePoliticasDePrivacidade", values.politica);
    formData.append("AceiteRegulamento", values.regulamento);
    formData.append("AceiteMarketing", values.aceito);


    api
      .post(rotasApi.cadastroPreCadastro, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      }).then(result => {

        console.log('sucesso >', result.data)

        if (result.data.sucesso) {
          localStorage.removeItem('IdConvite')
          setEmailForm(`Email: ${result.data.usuario.email}`);
          setTelForm(`SMS: ${result.data.usuario.celular}`);

          let emailArray = result.data.usuario.email.split("@");

          setEmailFinal("@" + emailArray[1]);

          setModalAviso(true);


        } else {
          window.scrollTo(0, 0);
          setModalErro(true);
          setModalMensagem(result.data)
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }



      })
      .catch(error => {
        console.log('error', error.response)
        window.scrollTo(0, 0);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem)
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });


  };


  const handleLoginEscolha = (values) => {
    console.log("Escolha", values);
    console.log("CPF >", cpf);
    values.cpf = cpf;
    setEscolhaForm(values.escolha);
    setReenviaCodigo(true);

    const formData = new FormData();
    formData.append("Cpf", cpf);

    if (values.escolha === "email") {
      formData.append("EnviarPorEmail", true);
      formData.append("EnviarPorSMS", false);
    } else {
      formData.append("EnviarPorEmail", false);
      formData.append("EnviarPorSMS", true);
    }

    api
      .post(rotasApi.codigoAcessoPreCadastro, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          if (passo === 1) {
            setPasso(passo + 1);
          }
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  };

  const handleLoginCodigo = (values) => {
    console.log("codigo > ", values);

    const formData = new FormData();
    formData.append("Cpf", cpf);
    formData.append("CodigoAcesso", values.codigo.toUpperCase());

    api
      .post(rotasApi.codigoAcessoValidar, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          navigate("/cadastro-selfie");
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
  };

  const ImageAvatar = (e) => {
    let file = e.target.files[0];
    setImgAvatar(URL.createObjectURL(file));
    //this.foto = file;
  };

  const ativaBtn = () => {
    setPasso(1)
    setReenviaCodigo(true);
    setTempoCodigo("00");
    setMinutos(4);
    setSegundos(59);
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear() - 18;

  today = yyyy + "-" + mm + "-" + dd;

  const handleCEP = (cep) => {


    axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      .then(result => {
        form1Ref.current.setFieldValue('endereco', result.data.logradouro);
        form1Ref.current.setFieldValue('bairro', result.data.bairro);
        form1Ref.current.setFieldValue('cidade', result.data.localidade);
        form1Ref.current.setFieldValue('uf', result.data.uf);
        form1Ref.current.setFieldValue('complemento', result.data.complemento);
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  const validationSchema = yup.object().shape({


    dataNascimento: yup
      .string()
      .test("data", "Você precisa ter 18 anos", (val) => val <= today)
      .required("Data de Nascimento é obrigatória"),
    /* cpf: yup
      .string()
      .min(14, "CPF deve ser preenchido")
      .matches(/\d{3}\.?\d{3}\.?\d{3}-?\d{2}/, "CPF deve ser preenchido")
      .required("CPF é obrigatório"),*/
    celular: yup
      .string()
      .min(11, "Numero inválido")
      .max(11, "Numero inválido")
      .matches(
        /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
        "Numero inválido"
      )
      .required("Celular é obrigatório"),

    cep: yup
      .string()
      .required("CEP é obrigatório")
      .matches(
        /^[0-9]{5}-[0-9]{3}$/,
        "preencha no formato xxxxx-xxx (com hífen)"
      )
      .test("cep", "buscar cep", (val) => {

        if (val) {
          if (enderecoAPI && val.length === 8) {
            console.log('val includes >', val.includes('-'))
            if (!val.includes('-')) {
              form1Ref.current.setFieldValue('cep', val.slice(0, 5) + '-' + val.slice(5, 8));
            }

            console.log('cep > ', val)
            handleCEP(val)
            setEnderecoAPI(false)
          }
          if (val.length < 9) {
            setEnderecoAPI(true)
          }
        }
        return true
      }),

    endereco: yup.string().min(2, "Endereço inválido").required("Endereço é obrigatório"),
    numero: yup.number().integer().required("Número é obrigatório"),
    bairro: yup.string().min(2, "Bairro inválido").required("Bairro é obrigatório"),
    cidade: yup
      .string()
      .min(2, "Cidade inválida")
      .matches(
        /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
        "Cidade inválida"
      )
      .required("Cidade é obrigatório"),

    uf: yup
      .string()
      .min(2, "UF inválida")
      .max(2, "UF inválida")
      .matches(
        /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
        "UF inválida"
      )
      .required("UF é obrigatório"),

    perguntaSeguranca: yup
      .string()
      .required("Pergunta é obrigatório")
      .test("pergunta", "pergunta ano", (val) => {
        if (val === "Ano de ínicio da sua carreira de vendedor") {
          setCampoRespotaSeguranca('number')
        } else {
          setCampoRespotaSeguranca('text')
        }
        return true;

      }),
    respostaSeguranca: yup
      .string()
      .required("Resposta é obrigatória"),

    regulamento: yup
      .bool()
      .required()
      .test("regulamento", "aceite o regulamento", (val) => {
        if (val === true) {
          setAceitoRegula(true);
          return true;
        } else {
          setAceitoRegula(false);
          return false;
        }
      }),
    politica: yup
      .bool()
      .required()
      .test("politica", "aceite a política", (val) => {
        if (val === true) {
          setAceitoPolitica(true);
          return true;
        } else {
          setAceitoPolitica(false);
          return false;
        }
      }),
    email: yup
      .string()
      .email("E-mail inválido")
      .required("E-mail é obrigatório"),
    emailConfirma: yup.string().when("email", (value, schema) => {
      return schema
        .oneOf([yup.ref("email")], "E-mail está diferente")
        .required("Confirmar E-mail é obrigatório");
    }),

    senha: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(/^(?=.*?[A-Z])(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/, {
        message: "Senha inválida",
        excludeEmptyString: true,
      })
      .required("Senha é obrigatório"),

    senhaConfirma: yup.string().when("senha", (value, schema) => {
      return schema
        .oneOf([yup.ref("senha")], "As senhas devem ser iguais")
        .required("Confirmar Senha é obrigatório");
    }),
    //  recaptcha: yup.string().required(),
    //  avatarImg: yup.mixed()
    //      .test('fileSize', "Imagem muito grande", value => {
    //      const sizeInBytes = 500000;
    //       return value.size <= sizeInBytes;
    //  })
  });

  const validationSchemaCodigo = yup.object().shape({
    codigo: yup
      .string()
      .min(6, "Código deve ser preenchido")
      .required("Código é obrigatório"),
  });

  const validationSchemaEscolha = yup.object().shape({
    escolha: yup.string().required("Escolha uma opção"),
  });

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <Fade in={modalErro}>
            <Card
              className="text-center p-3 m-2 z-index-top position-absolute"
              bg="danger"
              text="white"
            >
              <Card.Body>
                <Card.Title as="h2">Erro</Card.Title>
                <Card.Text>{modalMensagem}</Card.Text>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
      </Row>
      {!modalAviso && (
        <>
          <NavBarPrimeiroAcesso />
          <MeusDadosCampos className={displayPage}>
            <Container className="col-8 col-lg-5 d-flex flex-wrap p-0 justify-content-center pb-5">
              <Formik
                innerRef={form1Ref}
                initialValues={{
                  avatarImg: {},
                  nome: "",
                  dataNascimento: "",
                  cpf: cpf,
                  email: "",
                  emailConfirma: "",
                  celular: "",
                  cep: "",
                  endereco: "",
                  numero: "",
                  complemento: "",
                  bairro: "",
                  cidade: "",
                  uf: "",
                  referencia: "",
                  perguntaSeguranca: "Nome do seu pai",
                  respostaSeguranca: "",
                  codigoAmigo: localStorage.getItem('IdConvite') ? localStorage.getItem('IdConvite') : '',
                  senha: "",
                  senhaConfirma: "",
                  regulamento: false,
                  politica: false,
                  aceito: false,
                  recaptcha: "",
                }}
                onSubmit={(values) => {
                  handleLogin(values);
                }}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  isValid,
                  handleSubmit,
                  setFieldValue,
                  dirty,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mt-5">
                      <Col lg={3} className="position-relative">
                        <div className="titulo-page-mobile text-center">
                          <h2>Cadastre seus dados pessoais</h2>
                          <span>Preencha todos os campos com (*)</span>
                        </div>
                        <Row>
                          <Col className="d-flex justify-content-center">
                            <img
                              src={imgAvatar}
                              className="img-prev img-fluid"
                              alt=""
                            />
                          </Col>
                        </Row>
                        <Form.Group controlId="formFile">
                          <Form.Control
                            className="input-img"
                            accept=".png, .jpg, .jpeg"
                            type="file"
                            onChange={(e) => {
                              setImgSelecionada(true)
                              if (e.currentTarget.files[0].size <= 2000000) {
                                ImageAvatar(e);
                                setFieldValue(
                                  "avatarImg",
                                  e.currentTarget.files[0]
                                );
                                setImgAviso(false);
                              } else {
                                setImgAviso(true);
                              }
                            }}
                            isInvalid={!!errors.avatarImg}
                          />

                          {imgAviso && (
                            <p className="img-invalid text-center small text-danger w-100">
                              A imagem é maior que 2Mb
                            </p>
                          )}
                          {!imgSelecionada && (< div className="formatos-imgs">
                            <p className="text-center small m-0">
                              Clique para adicionar uma imagem ao seu perfil
                            </p>
                            <p className="text-center small">
                              formatos aceitos: jpg/png até 2Mb
                            </p>
                          </div>)}
                        </Form.Group>
                      </Col>
                      <Col lg={9}>
                        <div className="container-campos align-self-center ">
                          <div className="titulo-page">
                            <h2>Dados Cadastrais</h2>
                            <span>Preencha todos os campos com (*)</span>
                          </div>
                          <Row className="p-0">
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Nome completo
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="nome"
                                  value={values.nome}
                                  onChange={handleChange}
                                  disabled
                                />

                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Data de nascimento*
                                </Form.Label>

                                <Form.Control
                                  max={today}
                                  step="1"
                                  type="date"
                                  name="dataNascimento"
                                  value={values.dataNascimento}
                                  onChange={handleChange}
                                  isInvalid={!!errors.dataNascimento}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.dataNascimento}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  CPF
                                </Form.Label>

                                <Form.Control
                                  disabled
                                  type="number"
                                  name="cpf"
                                  value={cpf}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cpf}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cpf}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="mt-5">
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  E-mail*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.email}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Confirmar e-mail*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="emailConfirma"
                                  value={values.emailConfirma}
                                  onChange={handleChange}
                                  isInvalid={!!errors.emailConfirma}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.emailConfirma}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Celular(com DDD) *
                                </Form.Label>

                                <Form.Control
                                  // as={phoneMask}
                                  maxLength="11"
                                  placeholder=""
                                  type="text"
                                  name="celular"
                                  value={values.celular}
                                  onChange={handleChange}
                                  isInvalid={!!errors.celular}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.celular}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>


                          <Row className="mt-5">
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  CEP*
                                </Form.Label>
                                <div className="small position-relative">
                                  <a className="busca-cep" rel="noopener noreferrer" href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Não sei meu CEP</a>
                                </div>

                                <Form.Control
                                  type="text"
                                  maxLength="9"
                                  name="cep"
                                  value={values.cep}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cep}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cep}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Rua /  Avenida / Travessa / Praça*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="endereco"
                                  value={values.endereco}
                                  onChange={handleChange}
                                  isInvalid={!!errors.endereco}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.endereco}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Número
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="numero"
                                  value={values.numero}
                                  onChange={handleChange}
                                  isInvalid={!!errors.numero}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.numero}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Complemento
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="complemento"
                                  value={values.complemento}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Bairro*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="bairro"
                                  value={values.bairro}
                                  onChange={handleChange}
                                  isInvalid={!!errors.bairro}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.bairro}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Cidade*
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="cidade"
                                  value={values.cidade}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cidade}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cidade}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  UF*
                                </Form.Label>

                                <Form.Control
                                  className="text-uppercase"
                                  type="text"
                                  maxLength="2"
                                  name="uf"
                                  value={values.uf}
                                  onChange={handleChange}
                                  isInvalid={!!errors.uf}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.uf}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Ponto de referência
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="referencia"
                                  value={values.referencia}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="d-flex justify-content-center mt-5">
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Pergunta de segurança*
                                </Form.Label>
                                <Form.Select
                                  type="text"
                                  name="perguntaSeguranca"
                                  value={values.perguntaSeguranca}
                                  onChange={handleChange}
                                  isInvalid={!!errors.perguntaSeguranca}
                                >
                                  <option>Nome do seu Pai</option>
                                  <option>Nome da sua Mãe</option>
                                  <option>
                                    Qual a cidade natal da sua Mãe
                                  </option>
                                  <option>
                                    Ano de ínicio da sua carreira
                                  </option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {errors.perguntaSeguranca}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Resposta
                                </Form.Label>

                                <Form.Control
                                  type={campoRespotaSeguranca}
                                  name="respostaSeguranca"
                                  value={values.respostaSeguranca}
                                  onChange={handleChange}
                                  isInvalid={!!errors.respostaSeguranca}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.respostaSeguranca}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Código do amigo
                                </Form.Label>

                                <Form.Control
                                  name="codigoAmigo"
                                  value={values.codigoAmigo}
                                  onChange={handleChange}
                                  disabled={localStorage.getItem('IdConvite') ? true : false}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.respostaSeguranca}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-5">
                            <Col>

                              <Form.Group className="mb-3 position-relative">
                                <Form.Label className="label-input">
                                  Senha*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type={passwordShown ? "text" : "password"}
                                  name="senha"
                                  value={values.senha}
                                  onChange={handleChange}
                                  isInvalid={!!errors.senha}
                                />
                                <i className={changeEye}
                                  onClick={togglePassword}
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {errors.senha}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3 position-relative">
                                <Form.Label className="label-input">
                                  Confirmar senha
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type={passwordShownConfirma ? "text" : "password"}
                                  name="senhaConfirma"
                                  value={values.senhaConfirma}
                                  onChange={handleChange}
                                  isInvalid={!!errors.senhaConfirma}
                                />
                                <i className={changeEyeConfirma}
                                  onClick={togglePasswordConfirma}
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {errors.senhaConfirma}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <p className="font-weight-bold info-senha">
                                A senha precisa conter no mínimo 8 carácteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial (ex.: @, !, ?, %)
                              </p>

                              <div
                                id="regulamentos"
                                className="form-check checkbox-regulamentos"
                              >
                                <Form.Group>
                                  <Form.Check
                                    required
                                    onClick={
                                      !aceitoRegula
                                        ? handleModalRegulamento
                                        : null
                                    }
                                    id="aceitoRegulamento"
                                    name="regulamento"
                                    onChange={handleChange}
                                    isInvalid={!!errors.regulamento}
                                    feedback={errors.regulamento}
                                    feedbackType="invalid"
                                  />
                                  <Form.Label
                                    htmlFor="aceitoRegulamento"
                                    className="label-input"
                                  >
                                    Li e aceito o{" "}
                                    <Link
                                      className="pe-none"
                                      to="#regulamentos"
                                    >
                                      Regulamento
                                    </Link>{" "}
                                    do programa
                                  </Form.Label>
                                </Form.Group>
                              </div>

                              <div className="form-check checkbox-regulamentos">
                                <Form.Group>
                                  <Form.Check
                                    required
                                    id="aceitoPolitica"
                                    onClick={
                                      !aceitoPolitica ? handleModalPolitica : null
                                    }
                                    name="politica"
                                    onChange={handleChange}
                                    isInvalid={!!errors.politica}
                                    feedback={errors.politica}
                                    feedbackType="invalid"
                                  />
                                  <Form.Label
                                    htmlFor="aceitoPolitica"
                                    className="label-input"
                                  >
                                    Li e aceito o{" "}
                                    <Link to="#"
                                      className="pe-none">
                                      Política de Privacidade
                                    </Link>{" "}
                                    do programa
                                  </Form.Label>
                                </Form.Group>
                              </div>

                              <div className="form-check checkbox-regulamentos">
                                <Form.Group>
                                  <Form.Check
                                    id="aceitoReceber"
                                    name="aceito"
                                  />
                                  <Form.Label
                                    htmlFor="aceitoReceber"
                                    className="label-input"
                                  >
                                    Aceito receber e-mails, sms, whatsapp com
                                    novidades e informações sobre o programa.
                                  </Form.Label>
                                </Form.Group>
                              </div>
                              {/*<div className="d-flex justify-content-center align-items-center mt-3">
                                <ReCAPTCHA
                                  sitekey={sitekey}
                                  onChange={(e) => {
                                    setFieldValue("recaptcha", e);
                                  }}
                                />
                                </div>*/}
                              <Button
                                type="submit"
                                disabled={!isValid || !dirty}
                                className="mt-5 mb-3 w-100 btn-laranja"
                              >
                                Salvar
                              </Button>
                              <Link
                                to="/"
                                className="btn btn-outline-secondary btn-laranja-outline w-100"
                              >
                                Voltar
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Container>
          </MeusDadosCampos>
        </>
      )}

      {modalAviso && (
        <BodyModal className="d-flex justify-content-center align-items-center">
          <Container>
            {passo === 1 ? (
              <Row>
                <Col className="d-flex justify-content-center">
                  <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                    <Card.Body className="body-card">
                      <Card.Text className="text-center icon-size">
                        <img src={iconCadeado} alt="" />
                      </Card.Text>
                      <Card.Title className="text-center" as="h5">
                        Confirmação de cadastro
                      </Card.Title>
                      <Card.Text className="text-center">
                        Para sua segurança vamos enviar um código para validar o
                        seu cadastro, escolha como prefere receber:
                      </Card.Text>
                      <Formik
                        initialValues={{ escolha: "" }}
                        onSubmit={(values) => {
                          handleLoginEscolha(values);
                        }}
                        validationSchema={validationSchemaEscolha}
                      >
                        {({
                          handleChange,
                          errors,
                          isValid,
                          handleSubmit,
                          dirty,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Check
                                type="radio"
                                label={
                                  emailForm.slice(0, 10).padEnd(18, "*") +
                                  emailFinal
                                }
                                onChange={handleChange}
                                value="email"
                                name="escolha"
                                id="1"
                              />
                              <Form.Check
                                type="radio"
                                label={
                                  telForm.slice(0, 9).padEnd(15, "*") +
                                  "-" +
                                  telForm.slice(-4)
                                }
                                onChange={handleChange}
                                value="phone"
                                name="escolha"
                                id="2"
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.escolha}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Button
                              type="submit"
                              className="w-100 mt-4 btn-laranja"
                              disabled={!isValid || !dirty}
                              variant="secondary"
                            >
                              Enviar
                            </Button>
                            <Link
                              className="btn btn-outline-secondary btn-laranja-outline w-100 mt-3"
                              to="/"
                            >
                              Cancelar
                            </Link>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : passo === 2 ? (
              <Row>
                <Col className="d-flex justify-content-center">
                  <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                    <Card.Body className="body-card">
                      <Card.Text className="text-center icon-size">
                        <img src={iconCadeado} alt="" />
                      </Card.Text>
                      <Card.Title className="text-center" as="h5">
                        Código enviado com sucesso!
                      </Card.Title>
                      <Card.Text className="text-center">
                        Código enviado para:{" "}
                        <span className="fw-bold">
                          {escolhaForm === "email"
                            ? emailForm
                              .slice(0, 10)
                              .padEnd(18, "*")
                              .replace("Email:", "") + emailFinal
                            : telForm
                              .slice(0, 9)
                              .padEnd(15, "*")
                              .replace("SMS:", "") +
                            "-" +
                            telForm.slice(-4)}
                        </span>
                      </Card.Text>
                      <Card.Text className="text-center">
                        Caso não tenha recebido dentro de{" "}
                        <span className="fw-bold">
                          {minutos}:{tempoCodigo} minutos
                        </span>
                        , tente novamente.
                      </Card.Text>
                      <Card.Text className="text-center">
                        <span className="fw-bold">
                          Digite abaixo o código que você recebeu!
                        </span>
                      </Card.Text>
                      <Formik
                        initialValues={{ codigo: "" }}
                        onSubmit={(values) => {
                          handleLoginCodigo(values);
                        }}
                        validationSchema={validationSchemaCodigo}
                      >
                        {({
                          values,
                          handleChange,
                          errors,
                          isValid,
                          handleSubmit,
                          dirty,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Código</Form.Label>
                              <Form.Control
                                className="text-uppercase"
                                type="text"
                                maxLength="6"
                                name="codigo"
                                value={values.codigo}
                                onChange={handleChange}
                                isInvalid={!!errors.codigo}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.codigo}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Card.Text className="text-center small">
                              Caso não tenha recebido, clique em reenviar.
                            </Card.Text>

                            <Button
                              type="submit"
                              className="w-100 mt-4 btn-laranja"
                              disabled={!isValid || !dirty}
                              variant="secondary"
                            >
                              Enviar
                            </Button>

                            <Button
                              className="w-100 mt-2 btn-laranja-outline"
                              disabled={reenviaCodigo}
                              onClick={ativaBtn}
                              variant="outline-success"
                            >
                              Reenviar
                            </Button>

                            <Link
                              className="btn btn-outline-secondary w-100 mt-4"
                              to="/"
                            >
                              Cancelar
                            </Link>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row className="mt-3 text-center">
              <Col>
                <p className="info-telefone">
                  Dúvidas ? Ligue para 0800 780 0606 e<br /> fale com um de
                  nossos atendentes.
                </p>
              </Col>
            </Row>
          </Container>
        </BodyModal>
      )}
      {modalRegulamento && (
        <BodyModal className="d-flex justify-content-center align-items-center position-absolute top-0">
          <Row className="m-0">
            <Col
              lg={{ span: 6, offset: 3 }}
              className="d-flex justify-content-center"
            >
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                <Card.Body>
                  <Card.Title className="text-center" as="h5">
                    TERMOS E CONDIÇÕES DO PROGRAMA +TOP E USO DA PLATAFORMA
                  </Card.Title>
                  <Card.Text className="text-center modal-text">
                    <div className="m-2">
                      <h1
                        style={{
                          paddingTop: "3pt",
                          textIndent: "0pt",
                          textAlign: "center",
                        }}
                      >
                        TERMOS E CONDIÇÕES DO PROGRAMA +TOP E USO DA PLATAFORMA
                      </h1>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <p
                        style={{
                          paddingTop: "11pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "107%",
                          textAlign: "justify",
                        }}
                      >
                        O Programa +TOP (“<b>Programa</b>”) é um programa de
                        relacionamento, capacitação e reconhecimento mantido pela
                        Whirlpool S.A., pessoa jurídica de direito privado com
                        sede na Rua Olympia Semeraro 675, Prédio Administrativo,
                        Jardim Santa Emília, São Paulo, SP, inscrita no CNPJ nº.
                        59.105.999/0001-86 (“<b>Whirlpool</b>”) e é destinado
                        exclusivamente aos vendedores, gerentes, gerentes
                        regionais e gestores de informações das Revendas
                        Participantes e que tenham interesse na sua participação.
                      </p>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <p
                        className="s1"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "107%",
                          textAlign: "justify",
                        }}
                      >
                        A participação no Programa é absolutamente facultativa,
                        cabendo a cada Revenda Participante
                        <span className="p"> </span>optar pela adesão e
                        participação dela e de seus respectivos colaboradores. É
                        sabido, ainda,<span className="p"> </span>que este
                        Programa <b>não </b>possui qualquer finalidade de
                        fidelização da Revenda Participante ou
                        <span className="p"></span>de estabelecer qualquer tipo de
                        exclusividade ou priorização na comercialização de
                        produtos<span className="p"> </span>Whirlpool em
                        detrimento de outros<span className="p">.</span>
                      </p>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <p
                        className="s1"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "112%",
                          textAlign: "justify",
                        }}
                      >
                        Neste sentido, a Revenda Participante é (e sempre será)
                        livre para aderir e para declinar da sua
                        <span className="p"></span>participação no Programa, a
                        qualquer tempo, como também tem a Revenda Participante
                        <span className="p"></span>preservado o direito de
                        participar de iniciativas iguais ou similares de todas e
                        quaisquer outras<span className="p"> </span>empresas que
                        as promovam, sejam essas empresas atuantes no mesmo
                        segmento da<span className="p"> </span>Whirlpool ou não
                        <span className="p">.</span>
                      </p>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <ol id="l1">
                        <li data-list-text={1}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "justify",
                            }}
                          >
                            1. REVENDA PARTICIPANTE
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l2">
                            <li>
                              <p
                                style={{
                                  paddingLeft: "5pt",
                                  textIndent: "0pt",
                                  lineHeight: "113%",
                                  textAlign: "justify",
                                }}
                              >
                                1.1 Para fins deste Regulamento, Revenda Participante
                                consiste na pessoa jurídica que tenha relação
                                contratual/comercial com a Whirlpool para a compra
                                e posterior revenda dos produtos das marcas
                                Brastemp e Consul (“<b>Produtos</b>”) no
                                território nacional, bem como tenha aderido às
                                condições definidas nestes Termos e Condições para
                                o pleno funcionamento Programa.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                            <li>
                              <p
                                style={{
                                  paddingLeft: "5pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                1.2 A Revenda Participante é responsável por informar
                                à Whirlpool os nomes dos funcionários elegíveis
                                que tiverem interesse em participar do Programa,
                                assim como seu número do CPF, cargo (se é
                                vendedor, gerente, gerente regional e gestor da
                                informação), loja (unidade/filial participante) e
                                as vendas dos Produtos Whirlpool realizadas por
                                cada um dos Vendedores Participantes, conforme
                                mecanismos e demais disposições descritas neste
                                Instrumento.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <ol id="l3">
                                <li data-list-text="1.2.1">
                                  <p
                                    style={{
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    1.2.1 A responsabilidade pelo envio e veracidade das
                                    informações das vendas realizadas pelos
                                    Participantes ao longo do Programa é
                                    exclusivamente da Revenda Participante, não
                                    podendo a Whirlpool ser responsabilizada por
                                    qualquer falha ou imprecisão das informações
                                    recebidas.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="1.3">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                1.3 Para fins do disposto nestes Termos, a Revenda
                                Participante e a Whirlpool serão consideradas
                                controladoras independentes de dados pessoais, nos
                                termos e definições da Lei n.º 13.709/2018 (Lei
                                Geral de Proteção de Dados), sendo cada qual
                                responsável pelo tratamento de dados que vier a
                                realizar.
                              </p>
                              <p
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "42pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                1.3.1. Na qualidade de controladora independente,
                                a Revenda Participante é a única e exclusiva
                                responsável por garantir que quaisquer dados
                                pessoais compartilhados com a Whirlpool para o
                                funcionamento do Programa, especialmente os
                                previstos no item 1.2 acima, tenham sido obtidos
                                em conformidade com os requisitos da Lei Geral de
                                Proteção de Dados, sobretudo no que diz respeito à
                                determinação da base legal adequada, inclusive o
                                consentimento, quando este for aplicável, e às
                                obrigações de transparência para com os
                                funcionários elegíveis.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li data-list-text={2}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "justify",
                            }}
                          >
                            2. PARTICIPANTES
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l4">
                            <li data-list-text="2.1">
                              <p
                                style={{
                                  paddingLeft: "5pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                2.1 Somente poderão participar do Programa os
                                funcionários das Revendas Participantes com os
                                cargos de vendedores, gerentes, gerentes regionais
                                e gestores das Revendas Participantes, maiores de
                                18 anos, que estejam diretamente envolvidos com a
                                venda dos produtos das marcas Brastemp e Consul e
                                cujas informações tenham sido enviadas pela
                                Revenda Participante, conforme estabelecido no
                                item 1 acima (“<b>Participantes</b>”).
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <ol id="l5">
                                <li data-list-text="2.1.1">
                                  <p
                                    style={{
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    2.1.1 Cada funcionário da Revenda Participante
                                    somente poderá ser cadastrado como ocupante de
                                    um único cargo, sendo que, em nenhuma
                                    hipótese, poderá o funcionário da Revenda
                                    Participante ter mais de um cargo cadastrado
                                    no Programa.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="2.2">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                2.2 Caso o funcionário da Revenda Participante seja
                                elegível a participar do Programa, deverá realizar
                                a ativação do seu cadastro no site{" "}
                                <a
                                  rel="noopener noreferrer"
                                  href="https://docs.google.com/document/d/1jQayzWF7niQA08K_NhAsHpKWDAVF-sULg3zDi4EIELs/edit"
                                  className="a"
                                  target="_blank"
                                >
                                  https://programamaistop.com.br</a> (“<b>Site</b>

                                ”, complementando as informações requeridas e
                                criando uma senha pessoal e intransferível. O
                                Participante deverá ler e aceitar estes Termos e
                                Condições e as condições do Programa, bem como
                                ler e compreender a{" "}

                                Política de Privacidade{" "}


                                aplicável ao Programa e igualmente disponível no
                                Site.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <ol id="l6">
                                <li data-list-text="2.2.1">
                                  <p
                                    style={{
                                      paddingLeft: "47pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >

                                    2.2.1 É de inteira responsabilidade do
                                    Participante o preenchimento de todos os
                                    dados solicitados de maneira correta e
                                    verídica. Caso haja o fornecimento de dados
                                    incorretos, inverídicos e/ou incompletos, o
                                    Participante não será considerado cadastrado
                                    neste Programa; e se identificado que o
                                    Participante inseriu informações
                                    não-verdadeiras, a sua participação no
                                    Programa poderá ser cancelada, a qualquer
                                    tempo. Da mesma forma, caso o Participante
                                    perceba qualquer erro cadastral, deverá
                                    comunicar à Whirlpool imediatamente por meio
                                    da Central de Atendimento ao Participante
                                    (0800 780 0606 ou{" "}

                                    <a
                                      rel="noopener noreferrer"
                                      href="http://www.programamaistop.com.br/FaleConosco/"
                                      target="_blank"
                                    >
                                      www.programamaistop.com.br/FaleConosco/
                                    </a>
                                    ).
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                                <li data-list-text="2.2.2">
                                  <p
                                    style={{
                                      paddingLeft: "47pt",
                                      textIndent: "0pt",
                                      lineHeight: "113%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    2.2.2 Ao aceitar estes termos e condições, o
                                    Participante autoriza a Whirlpool a lançar seu
                                    nome no Ranking e divulgá-lo no Site.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                                <li data-list-text="2.2.3">
                                  <p
                                    style={{
                                      paddingLeft: "47pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    2.2.3 <u>Status Pré-Cadastro</u>. Caso o
                                    Participante não ative seu cadastro na
                                    plataforma por prazo superior a 90 (noventa)
                                    dias, contados da data de seu cadastro no
                                    sistema e conforme estipulado no item 2.2
                                    supra, o seu acesso/perfil será
                                    automaticamente alterado para <i>inativo</i>,
                                    deixando de fazer jus aos benefícios do
                                    Programa. Para ativar o
                                  </p>
                                  <p
                                    style={{
                                      paddingTop: "3pt",
                                      paddingLeft: "47pt",
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    seu cadastro, o Participante deverá entrar em
                                    contato com a Central de Atendimento.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="2.3">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                2.3 A sua participação no Programa e a sua pontuação
                                somente valerão a partir da realização do seu
                                cadastro no Site, a sua posterior ativação com
                                confirmação pela Whirlpool. Para ser elegível ao
                                recebimento de qualquer pontuação, o Participante
                                deverá, ainda, acessar a Plataforma ao menos uma
                                vez ao mês para validar a sua participação no
                                Programa nos termos aqui previstos, bem como para
                                dar o aceite nas condições aplicáveis no
                                respectivo mês de acesso.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                            <li data-list-text="2.4">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                2.4 Caso o Participante não realize ao menos um login
                                por mês na Plataforma (inclusive para realizar o
                                aceite mensal indicado no item 2.3) e essa
                                situação perdurar por 02 (dois) meses
                                consecutivos, o perfil do Participante será
                                alterado para <i>Somente Catálogo</i>, o que fará
                                com que ele não possa acumular novos pontos, sendo
                                assegurado a ele o direito de resgatar seus
                                pontos. Se a situação persistir, no mês seguinte
                                (3º mês consecutivo) o respectivo perfil será
                                alterado para
                                <i>Inativado</i>, ocasião em que não poderá
                                receber novos pontos ou convertê-los em produtos.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <ol id="l7">
                                <li data-list-text="2.4.1">
                                  <p
                                    style={{
                                      paddingLeft: "46pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    2.4.1 O Participante será automaticamente excluído
                                    do Programa em caso de rescisão do contrato ou
                                    desligamento da Revenda Participante a qual
                                    estava vinculado, independentemente do motivo.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                                <li data-list-text="2.4.2">
                                  <p
                                    style={{
                                      paddingLeft: "46pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    2.4.2 Em caso de rescisão contratual ou desligamento
                                    da Revenda Participante durante o prazo de
                                    vigência do Programa, o Participante terá o
                                    prazo improrrogável de até 90 (noventa) dias
                                    para o resgate dos pontos acumulados por ele
                                    após a inativação da Revenda Participante. Se,
                                    no decorrer do Programa, o Participante
                                    tornar-se elegível novamente, em decorrência
                                    da contratação por outra Revenda Participante,
                                    os pontos acumulados por ele poderão ser
                                    resgatados no prazo aqui indicado. E uma vez
                                    transcorrido o prazo aqui indicado, os pontos
                                    expirarão sem possibilidade de reversão.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text="2.5">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                2.5 Os Participantes que forem admitidos pelas
                                Revendas Participantes após o início do Programa
                                terão seus pontos computados somente a partir do
                                mês em que a própria Revenda Participante fizer o
                                encaminhamento das informações pertinentes às
                                vendas realizadas por eles, bem como a partir de
                                quando for concluído o cadastro do Participante no
                                Site.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                            <li data-list-text="2.6">
                              <p
                                style={{
                                  paddingLeft: "5pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                2.6 Será automaticamente desclassificado e excluído do
                                Programa, sem necessidade de prévia comunicação, o
                                Participante que praticar qualquer tipo de ato
                                considerado fraudulento, ilegal, ilícito, ou que
                                atente contra os objetivos deste Programa e/ou
                                estes termos e condições.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                            <li data-list-text="2.7">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "113%",
                                  textAlign: "justify",
                                }}
                              >
                                2.7 Não será aceito o pedido de cadastro que não
                                preencher todos os requisitos descritos nestes
                                termos e condições.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li data-list-text={3}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "justify",
                            }}
                          >
                            3. DO PROGRAMA E PONTUAÇÃO
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l8">
                            <li data-list-text="3.1">
                              <p
                                style={{
                                  paddingLeft: "24pt",
                                  textIndent: "-18pt",
                                  textAlign: "justify",
                                }}
                              >
                                3.1 Este Programa terá duração de 11 de abril de 2022
                                a 31 de dezembro de 2023.
                              </p>
                            </li>
                            <li data-list-text="3.2">
                              <p
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                3.2 Os Participantes receberão pontos, que podem ser
                                acumulados e trocados por prêmios do Portal de
                                Prêmios, conforme disposições no Site e
                                disponibilidade de produtos. Em nenhuma hipótese
                                haverá pagamento em dinheiro ao Participante ou
                                conversão dos pontos em qualquer outro benefício.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                            <li data-list-text="3.3">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                3.3 Os pontos começam a ser contabilizados aos
                                Participantes a partir do mês que for realizado o
                                seu cadastro de forma correta e desde que eles
                                tenham dado o aceite nestes Termos e Condições e
                                nas condições específicas disponibilizadas na
                                Plataforma e aplicáveis a cada mês, nos termos do
                                item 2.3 acima. Em nenhuma hipótese, serão aceitas
                                as vendas realizadas em meses anteriores à
                                realização do cadastro e do aceite das condições
                                previstas nos documentos ora indicados. Caberá à
                                Revenda Participante, por meio do Gestor de
                                Informações, disponibilizar as informações de
                                vendas de cada Participante nos respectivos meses.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                            <li data-list-text="3.4">
                              <p
                                style={{
                                  paddingLeft: "7pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                3.4 Fica desde já estabelecido que o valor de cada
                                ponto conquistado pelo Participante neste Programa
                                é de R$1,00 (um real).
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li data-list-text={4}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "justify",
                            }}
                          >
                            4. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS VENDEDORES
                            PARTICIPANTES
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l9">
                            <ol id="l10">
                              <li data-list-text="4.1.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  4.1. Os funcionários da Revenda Participante com o
                                  cargo de vendedores e que preencherem todos os
                                  requisitos estipulados no item 2 (“
                                  <b>Vendedores Participantes</b>”) serão
                                  avaliados por meio da venda dos Produtos
                                  Whirlpool comercializados durante o período do
                                  Programa.
                                </p>
                              </li>
                              <li data-list-text="4.2.">
                                <p
                                  style={{
                                    paddingTop: "10pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "114%",
                                    textAlign: "justify",
                                  }}
                                >
                                  4.2. Os Vendedores Participantes receberão pontos de
                                  acordo com as vendas feitas, que podem ser
                                  acumulados e trocados por prêmios do catálogo
                                  online, acessível através do Site, conforme
                                  demonstra a tabela constante no Site do
                                  Programa.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <ol id="l11">
                                  <li data-list-text="4.2.1">
                                    <p
                                      style={{
                                        paddingLeft: "47pt",
                                        textIndent: "0pt",
                                        lineHeight: "112%",
                                        textAlign: "justify",
                                      }}
                                    >
                                      4.2.1 Apenas as categorias de Produtos
                                      participantes indicadas na tabela constante
                                      no Site serão pontuadas de acordo com as
                                      vendas. Vendas de categorias que não
                                      estiverem indicadas no Site não serão
                                      consideradas para pontuação no Programa.
                                    </p>
                                  </li>
                                </ol>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l12">
                            <ol id="l13">
                              <li data-list-text="4.3">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  4.3 A cada início de mês, a Whirlpool estabelecerá e
                                  comunicará no Site as Categorias e/ou Produtos,
                                  por Revenda Participante, que valerão mais
                                  pontos do que a tabela regular de pontuação que
                                  consta no Site. A venda de cada unidade vendida
                                  dessas Categorias e/ou Produtos definidos
                                  acumularão a quantidade específica de pontos
                                  comunicada a cada mês.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="4.4">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  4.4 Os pontos a que o Vendedor Participante fará jus
                                  ao recebimento serão definidos com base na
                                  performance da Revenda Participante da qual ele
                                  faz parte. Para o recebimento de tais pontos, o
                                  Vendedor Participante tem de realizar os
                                  treinamentos disponibilizados a ele a cada mês,
                                  tendo necessariamente de acertar, no mínimo, 80%
                                  (oitenta por cento) da avaliação de cada
                                  treinamento. O não atingimento da meta mínima de
                                  acertos (80% de cada treinamento) impossibilita
                                  ao Vendedor Participante o recebimento de pontos
                                  extras.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="4.5">
                                <p
                                  style={{
                                    paddingLeft: "24pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify",
                                  }}
                                >
                                  4.5 No mês de aniversário do Vendedor Participante,
                                  ele fará jus a 10 (dez) pontos.
                                </p>
                              </li>
                            </ol>
                          </ol>
                        </li>
                        <li data-list-text={5}>
                          <h2
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "left",
                            }}
                          >
                            5. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS GERENTES
                            PARTICIPANTES
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l14">
                            <ol id="l15">
                              <li data-list-text="5.1.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  5.1. Os funcionários com o cargo de gerentes dos
                                  Vendedores Participantes de uma loja da Revenda
                                  Participante e que preencherem todos os
                                  requisitos estipulados no item 2 (“
                                  <b>Gerentes Participantes</b>”) receberão 10%
                                  (dez por cento) da pontuação alcançada pela sua
                                  equipe de Vendedores Participantes.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="5.2.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "113%",
                                    textAlign: "justify",
                                  }}
                                >
                                  5.2. Caso a Revenda Participante informe mais de 01
                                  (um) Gerente Participante por loja, será
                                  considerada a mesma pontuação para ambos os
                                  Gerentes Participantes.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="5.3.">
                                <p
                                  style={{
                                    paddingLeft: "27pt",
                                    textIndent: "-21pt",
                                    textAlign: "left",
                                  }}
                                >
                                  5.3. No mês de aniversário do Gerente Participante
                                  cadastrado, este receberá 10 (dez) pontos.
                                </p>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            5.4 Se o Gerente Participante não acessar o Site do
                            Programa, por 02 (dois) meses consecutivos, terá seu
                            status alterado automaticamente para{" "}
                            <i>Somente Catálogo</i>, ocasião em que não receberá
                            nenhum ponto a partir da alteração do status. Se o
                            Gerente Participante não acessar o Site do Programa
                            por 03 (três) meses consecutivos, terá seu status
                            alterado para <i>Inativo</i>, não podendo receber
                            novos pontos ou convertê-los em produtos.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={6}>
                          <h2
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "113%",
                              textAlign: "left",
                            }}
                          >
                            6. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS GERENTES
                            REGIONAIS PARTICIPANTES
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l16">
                            <ol id="l17">
                              <li data-list-text="6.1.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  6.1. Os funcionários com o cargo de gerente regional
                                  da Revenda Participante e que preencherem todos
                                  os requisitos estipulados no item 2 (“
                                  <b>Gerentes Regionais</b>”) receberão 5% (cinco
                                  por cento) da pontuação alcançada no mês,
                                  atribuída aos Vendedores Participantes das lojas
                                  atendidas por ele.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="6.2.">
                                <p
                                  style={{
                                    paddingLeft: "27pt",
                                    textIndent: "-21pt",
                                    textAlign: "left",
                                  }}
                                >
                                  6.2. No mês de aniversário do Gerente Regional, este
                                  receberá 10 (dez) pontos.
                                </p>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={7}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "left",
                            }}
                          >
                            7. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS GESTORES DE
                            INFORMAÇÃO
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l18">
                            <ol id="l19">
                              <li data-list-text="7.1.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  7.1. Serão considerados “
                                  <b>Gestores da Informação</b>”, para fins
                                  exclusivos deste Programa, os funcionários das
                                  Revendas Participantes escolhidos por estas no
                                  momento de aceite de Programa e responsáveis por
                                  passar as informações descritas na Cláusula 1.2,
                                  bem como outras informações necessárias para a
                                  execução desse Programa.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="7.2.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  7.2. Cada Revenda Participante poderá indicar apenas
                                  um Gestor da Informação. O Gestor da Informação
                                  deverá, além de realizar o cadastro no site do
                                  Programa, enviar corretamente as bases de
                                  Vendedores Participantes e informações sobre as
                                  vendas realizadas no respectivo mês, através do
                                  Site no modelo pré-acordado com cada Revenda
                                  Participante, até no máximo o 5º (quinto) dia
                                  útil do mês subsequente.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="7.3.">
                                <p
                                  style={{
                                    paddingLeft: "29pt",
                                    textIndent: "-22pt",
                                    textAlign: "left",
                                  }}
                                >
                                  7.3. O Gestor da Informação receberá 300 (trezentos)
                                  pontos sempre que fizer o envio correto
                                </p>
                                <p
                                  style={{
                                    paddingTop: "3pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  das informações, dentro do modelo padrão e
                                  dentro do prazo acima estabelecido. Na hipótese
                                  do não envio das informações até o 5º (quinto)
                                  dia útil do mês, o Gestor da Informação não fará
                                  jus ao recebimento dos 300 (trezentos) pontos,
                                  bem como não receberá a pontuação estabelecida
                                  no item 7.3.1. a seguir.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <ol id="l20">
                                  <li data-list-text="7.3.1.">
                                    <p
                                      style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        lineHeight: "106%",
                                        textAlign: "justify",
                                      }}
                                    >
                                      7.3.1. Se, por 03 (três) meses consecutivos, o
                                      Gestor da Informação enviar corretamente as
                                      bases e informações na forma e prazo
                                      estabelecidos nas cláusulas 7.2 e 7.3 supra,
                                      o Gestor da Informação receberá 300
                                      (trezentos) pontos adicionais além daqueles
                                      já indicados no item 7.3.
                                    </p>
                                  </li>
                                </ol>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l21">
                            <ol id="l22">
                              <ol id="l23">
                                <li data-list-text="7.3.2">
                                  <p
                                    style={{
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "107%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    7.3.2. A pontuação trimestral, conforme estabelecido
                                    na Cláusula 7.3.1, será sempre creditada nos
                                    meses de março, junho, setembro e dezembro. Se
                                    o Gestor da Informação se inscrever no
                                    Programa entre esses meses, no primeiro
                                    trimestre, o Gestor da Informação ganhará a
                                    pontuação proporcional ao período remanescente
                                    do correspondente trimestre.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                                <li data-list-text="7.3.3">
                                  <p
                                    style={{
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "112%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    7.3.3. Caso a Revenda Participante não autorize a
                                    participação de um Gestor da Informação, a
                                    Revenda Participante se responsabiliza pela
                                    veracidade das informações por ela repassadas,
                                    bem como por cumprir com os prazos de envio
                                    definidos nesta seção 7.
                                  </p>
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </li>
                                <li data-list-text="7.3.4">
                                  <p
                                    style={{
                                      paddingLeft: "42pt",
                                      textIndent: "0pt",
                                      lineHeight: "114%",
                                      textAlign: "justify",
                                    }}
                                  >
                                    7.3.4. No mês de aniversário do Gestor da Informação
                                    Participante, este fará jus a 10 (dez) pontos.
                                  </p>
                                </li>
                              </ol>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={8}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "justify",
                            }}
                          >
                            8. PREMIAÇÃO NO PORTAL DE PRÊMIOS
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l24">
                            <ol id="l25">
                              <li data-list-text="8.1.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "114%",
                                    textAlign: "justify",
                                  }}
                                >
                                  8.1. Os pontos acumulados pelos Participantes neste
                                  Programa poderão ser trocados por produtos do
                                  catálogo disponibilizado no site de prêmios (“
                                  <b>Prêmio</b>”). As condições para a troca dos
                                  pontos por Prêmios serão aquelas estabelecidas
                                  pelo site de prêmios.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="8.2.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  8.2. No momento em que for solicitado o resgate, será
                                  verificado se a quantidade de pontos válidos é
                                  suficiente para a troca desejada. Em caso
                                  negativo, o Participante será informado da
                                  insuficiência de saldo para resgate, uma vez que
                                  não é possível utilizar, cumulativamente, pontos
                                  e recursos financeiros próprios para o resgate
                                  de Prêmios.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="8.3.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  8.3. Os saldos atualizados dos pontos estarão
                                  disponíveis para o Participante até o 20º
                                  (vigésimo) dia útil do mês de apuração, desde
                                  que a Revenda Participante forneça as
                                  informações até o 15º (décimo quinto) dia deste
                                  mesmo mês.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="8.4.">
                                <p
                                  style={{
                                    paddingLeft: "7pt",
                                    textIndent: "0pt",
                                    lineHeight: "119%",
                                    textAlign: "justify",
                                  }}
                                >
                                  8.4. No caso de indisponibilidade do Prêmio escolhido
                                  pelo Participante no momento em que for
                                  solicitado o resgate, caberá a ele, o
                                  Participante, selecionar outro Prêmio que esteja
                                  disponível. A disponibilidade de prêmios varia
                                  de acordo com o momento de resgate, não havendo
                                  qualquer ingerência da Whirlpool na definição de
                                  quais prêmios estarão disponíveis e em qual
                                  período.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="8.5.">
                                <p
                                  style={{
                                    paddingLeft: "30pt",
                                    textIndent: "-24pt",
                                    textAlign: "justify",
                                  }}
                                >
                                  8.5. O resgate dos pontos poderá ser parcial ou
                                  integral, de acordo com o interesse dos
                                </p>
                              </li>
                            </ol>
                          </ol>
                          <p
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Participantes e de acordo com os termos deste
                            Programa.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={9}>
                          <h2
                            style={{
                              paddingLeft: "18pt",
                              textIndent: "-12pt",
                              textAlign: "justify",
                            }}
                          >
                            9. DA VALIDADE DOS PONTOS E DA ENTREGA DOS PRÊMIOS
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l26">
                            <ol id="l27">
                              <li data-list-text="9.1.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "113%",
                                    textAlign: "justify",
                                  }}
                                >
                                  9.1. Observados e cumpridos todas as disposições
                                  deste instrumento, incluindo, mas não se
                                  limitando à Cláusula 2ª, e salvo disposição
                                  contrária, o Participante terá até 12 (doze)
                                  meses após a data de crédito da pontuação para
                                  resgatar os pontos acumulados, realizando a
                                  troca por Prêmios.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <ol id="l28">
                                  <li data-list-text="9.1.1.">
                                    <p
                                      style={{
                                        paddingLeft: "41pt",
                                        textIndent: "0pt",
                                        lineHeight: "113%",
                                        textAlign: "left",
                                      }}
                                    >
                                      9.1.1 Após o término do prazo acima mencionado, os
                                      pontos não estarão mais disponíveis para
                                      resgate do Participante, expirando-se
                                      automaticamente.
                                    </p>
                                    <p
                                      style={{
                                        textIndent: "0pt",
                                        textAlign: "left",
                                      }}
                                    >
                                      <br />
                                    </p>
                                  </li>
                                </ol>
                              </li>
                              <li data-list-text="9.2.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  9.2. Os Prêmios resgatados serão entregues pela
                                  empresa responsável no endereço cadastrado pelo
                                  Participante no Site, de acordo com o prazo de
                                  entrega de cada parceiro, considerando que o
                                  Prêmio esteja disponível em estoque.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="9.3.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  9.3. A partir do resgate, o Participante contemplado
                                  deverá seguir as condições e regras gerais da
                                  Política de troca da empresa responsável pelo
                                  site de prêmios, ficando a Whirlpool totalmente
                                  isenta de qualquer responsabilidade pelo envio
                                  do prêmio resgatado.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <ol id="l29">
                                  <li data-list-text="9.3.1.">
                                    <p
                                      style={{
                                        paddingLeft: "42pt",
                                        textIndent: "0pt",
                                        lineHeight: "113%",
                                        textAlign: "left",
                                      }}
                                    >
                                      9.3.1. Fica determinado que eventuais despesas com
                                      frete para entrega dos Prêmios resgatados
                                      serão suportadas pelo Participante.
                                    </p>
                                    <p
                                      style={{
                                        textIndent: "0pt",
                                        textAlign: "left",
                                      }}
                                    >
                                      <br />
                                    </p>
                                  </li>
                                </ol>
                              </li>
                              <li data-list-text="9.4.">
                                <p
                                  style={{
                                    paddingLeft: "27pt",
                                    textIndent: "-21pt",
                                    textAlign: "justify",
                                  }}
                                >
                                  9.4. Toda pontuação e os prêmios concedidos são
                                  pessoais e intransferíveis.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="9.5.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "117%",
                                    textAlign: "justify",
                                  }}
                                >
                                  9.5. Não será permitido ao Participante, em hipótese
                                  alguma, trocar sua pontuação ou Prêmios
                                  resgatados por qualquer outro bem, produto ou
                                  serviço, nem mesmo por dinheiro.
                                </p>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "112%",
                              textAlign: "justify",
                            }}
                          >
                            9.6 O prazo para contestação de qualquer pontuação é
                            de 07 (sete) dias corridos contados da data do seu
                            crédito ou da data em que tal crédito deveria ter sido
                            computado; ou de 07 dias corridos a partir da
                            divulgação dos ganhadores, não podendo o Participante
                            reclamar sobre divergências ou irregularidades em sua
                            pontuação após este prazo.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={10}>
                          <h2
                            style={{
                              paddingLeft: "24pt",
                              textIndent: "-18pt",
                              textAlign: "justify",
                            }}
                          >
                            10. CAMPANHAS ADICIONAIS
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l30">
                            <ol id="l31">
                              <li data-list-text="10.1.">
                                <p
                                  style={{
                                    paddingLeft: "34pt",
                                    textIndent: "-27pt",
                                    textAlign: "justify",
                                  }}
                                >
                                  10.1. Campanhas adicionais são aquelas lançadas pela
                                  Whirlpool sazonalmente no Site.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="10.2.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "113%",
                                    textAlign: "justify",
                                  }}
                                >
                                  10.2. Para participação de campanhas adicionais, as
                                  pessoas elegíveis para participar da
                                  correspondente campanha adicional deverão
                                  acessar o Site e aceitar a respectiva Campanha.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="10.3.">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  10.3. Para apuração de campanhas adicionais, serão
                                  considerados válidos apenas os resultados dos
                                  Participantes que confirmaram a sua participação
                                  no Site mediante aceitação dos Termos e
                                  Condições/Regulamento das respectivas Campanhas
                                  e que foram abrangidos pelas informações
                                  fornecidas pelo Gestor da Informação da Revenda
                                  Participante.
                                </p>
                                <ol id="l32">
                                  <li data-list-text="10.3.1">
                                    <p
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "42pt",
                                        textIndent: "0pt",
                                        lineHeight: "112%",
                                        textAlign: "justify",
                                      }}
                                    >
                                      10.3.1 Estes termos e condições são complementares
                                      ao regulamento específico de cada Campanha
                                      adicional e, caso tal regulamento seja
                                      omisso, as disposições destes termos e
                                      condições são aplicáveis, o que inclui, mas
                                      não se limita à cláusula 9ª e 12ª.
                                    </p>
                                  </li>
                                </ol>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l33">
                            <ol id="l34">
                              <li data-list-text="10.4">
                                <p
                                  style={{
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  10.4 Ademais, caso o Regulamento específico da
                                  Campanha Adicional não estipule uma regra
                                  específica de desempate entre os vendedores
                                  ganhadores da correspondente premiação, será
                                  obedecida a seguinte ordem de desempate:
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "114%",
                                    textAlign: "left",
                                  }}
                                >
                                  1º) Maior número de unidades vendidas de
                                  produtos supertops no período da Campanha;
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "113%",
                                    textAlign: "left",
                                  }}
                                >
                                  2º) Se o empate persistir: maior número de
                                  unidades de produtos participantes no período da
                                  Campanha;
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    lineHeight: "114%",
                                    textAlign: "left",
                                  }}
                                >
                                  3º) Se o empate persistir: maior quantidade de
                                  treinamentos obrigatórios realizados no período
                                  da Campanha;
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <p
                                  style={{
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  4º) Se o empate persistir: Participante com data
                                  mais antiga de cadastro.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="10.5">
                                <p
                                  style={{
                                    paddingLeft: "5pt",
                                    textIndent: "1pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  10.5 Caso o prêmio de uma Campanha Adicional seja uma
                                  viagem, não será possível modificar o destino e
                                  o período da viagem a pedido do Vendedor
                                  Participante, os quais serão definidos
                                  unicamente pela Whirlpool. Cabe aos
                                  Participantes e à Revenda Participante
                                  conversarem entre si sobre a possibilidade de
                                  ausência do referido Participante durante
                                  referido período. A premiação em viagem é
                                  limitada ao custeio das passagens e eventuais
                                  outros custos divulgados expressamente pela
                                  Whirlpool, estando certo que quaisquer custos
                                  adicionais relacionados direta ou indiretamente
                                  à viagem, tais como estada, passeios, transfer,
                                  visto e passaporte, se necessários, serão de
                                  responsabilidade integral do Vendedor
                                  Participante.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                                <ol id="l35">
                                  <li data-list-text="10.5.1">
                                    <p
                                      style={{
                                        paddingLeft: "5pt",
                                        textIndent: "36pt",
                                        lineHeight: "114%",
                                        textAlign: "justify",
                                      }}
                                    >
                                      10.5.1 A Whirlpool reserva a si a faculdade de
                                      alterar o prêmio ou, caso mantido o prêmio
                                      <i>viagem</i>, a Whirlpool reserva a si a
                                      faculdade de alterar o destino e o período
                                      da viagem, a qualquer tempo.
                                    </p>
                                    <p
                                      style={{
                                        textIndent: "0pt",
                                        textAlign: "left",
                                      }}
                                    >
                                      <br />
                                    </p>
                                  </li>
                                </ol>
                              </li>
                              <li data-list-text="10.6">
                                <p
                                  style={{
                                    paddingLeft: "5pt",
                                    textIndent: "1pt",
                                    lineHeight: "117%",
                                    textAlign: "justify",
                                  }}
                                >
                                  10.6 Em caso de divergências entre os presentes
                                  Termos e Condições e o Regulamento de cada
                                  Campanha Adicional, prevalecerão os termos do
                                  presente Instrumento.
                                </p>
                              </li>
                            </ol>
                          </ol>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={11}>
                          <h2
                            style={{
                              paddingLeft: "24pt",
                              textIndent: "-17pt",
                              textAlign: "justify",
                            }}
                          >
                            11. INFORMAÇÕES SOBRE A PROPRIEDADE INTELECTUAL E DIREITOS
                            AUTORAIS
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <ol id="l36">
                            <li data-list-text="11.1">
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "112%",
                                  textAlign: "justify",
                                }}
                              >
                                11.1 Ao aceitar estes Termos, o Participante reconhece
                                e concorda que todo o conteúdo apresentado no Site
                                está protegido por direito autoral e direito de
                                propriedade intelectual. O Participante só poderá
                                utilizar o seu conteúdo conforme expressamente
                                indicado neste regulamento, ou seja, para o seu
                                uso pessoal e sem que isso signifique qualquer
                                tipo de licença. O Participante não pode copiar,
                                reproduzir, modificar, republicar, carregar,
                                publicar, transmitir ou distribuir qualquer
                                documento ou informação do Site de qualquer forma
                                ou por outro meio. O uso não autorizado dos
                                materiais que aparecem no Site pode violar
                                direitos
                              </p>
                              <p
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                autorais e direito de propriedade intelectual,
                                podendo resultar em penalidades criminais ou
                                civis.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                          </ol>
                        </li>
                        <li data-list-text={12}>
                          <h2
                            style={{
                              paddingLeft: "24pt",
                              textIndent: "-18pt",
                              textAlign: "justify",
                            }}
                          >
                            12. ACEITAÇÃO TOTAL DOS TERMOS
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            style={{
                              paddingLeft: "5pt",
                              textIndent: "1pt",
                              lineHeight: "113%",
                              textAlign: "justify",
                            }}
                          >
                            12.1. O Participante manifesta expressamente ter a
                            capacidade legal para usar o Site. Assim, manifesta
                            ter fornecido informação real, veraz e fidedigna.
                            Portanto, de forma expressa e inequívoca declara ter
                            lido e que aceita todas as disposições contidas nestes
                            Termos e Condições.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={13}>
                          <h2
                            style={{
                              paddingLeft: "24pt",
                              textIndent: "-18pt",
                              textAlign: "justify",
                            }}
                          >
                            13. CONSIDERAÇÕES GERAIS
                          </h2>
                        </li>
                      </ol>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <ol id="l37">
                        <ol id="l38">
                          <li data-list-text="13.1.">
                            <p
                              style={{
                                paddingLeft: "5pt",
                                textIndent: "1pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              13.1. Ao participar deste Programa e ao aceitar estes
                              Termos e Condições, os Participantes autorizam o uso
                              de sua imagem, voz e textos, bem como os direitos de
                              expor, publicar, reproduzir, armazenar e/ou de
                              qualquer outra forma delas se utilizarem, o que os
                              Participantes fazem de modo expresso e em caráter
                              irrevogável e irretratável, desde já e de pleno
                              direito, em caráter gratuito e sem qualquer
                              remuneração, ônus ou encargo, podendo referidos
                              direitos serem exercidos por meio de cartazes,
                              filmes e/ou spots, jingles e/ou vinhetas, bem como
                              em qualquer tipo de mídia e/ou peças promocionais,
                              inclusive em televisão, rádio, jornal, cartazes,
                              faixas, outdoors, mala-direta e na Internet, para a
                              ampla divulgação deste Regulamento e/ou de seu
                              desenvolvimento posterior, com exclusividade e sem
                              que tal autorização signifique, implique ou resulte
                              em qualquer obrigação de divulgação nem de
                              pagamento, concordando ainda, inclusive, em assinar
                              eventuais recibos e instrumentos neste sentido e
                              para tal efeito, sempre que solicitado pela
                              Realizadora.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.2.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              13.2. Aceitando participar do Programa e estes Termos e
                              Condições, o Participante entende que não estabelece
                              nenhum vínculo de natureza empregatícia,
                              subordinação ou de responsabilidade entre a
                              Whirlpool e o Participante.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.3.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              13.3. O Programa, por exclusivo critério da Whirlpool e a
                              qualquer tempo, poderá ser interrompido, suspenso ou
                              encerrado, bem como pode ter os seus termos e
                              condições modificados a qualquer tempo, inclusive
                              alterar as regras das campanhas adicionais, o que
                              inclui, mas não se limita a alterar os pontos e as
                              premiações. Nesses casos, todas as informações
                              pertinentes serão divulgadas através de e-mail ou
                              outro meio de comunicação escolhido pela Whirlpool.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.4.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "left",
                              }}
                            >
                              13.4. A participação no Programa será voluntária e
                              facultativa, não havendo qualquer sanção àqueles que
                              optarem por não participar.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.5.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "left",
                              }}
                            >
                              13.5. Para obter esclarecimentos sobre este Programa, os
                              interessados poderão fazer contato com a Whirlpool
                              através do telefone ou WhatsApp{" "}
                              <b>0800 780 0606 </b>ou pelo “Fale Conosco”
                              disponível no Site.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.6.">
                            <p
                              style={{
                                paddingLeft: "34pt",
                                textIndent: "-26pt",
                                textAlign: "left",
                              }}
                            >
                              13.6. As dúvidas não esclarecidas neste Instrumento serão
                              dirimidas pela Whirlpool, de forma
                            </p>
                            <p
                              style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                textAlign: "left",
                              }}
                            >
                              soberana e irrecorrível.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.7.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              13.7. O Participante se obriga a manter atualizados seus
                              dados cadastrais junto ao Site. Desse modo,
                              comunicações enviadas ao Participante serão tidas
                              como devidamente efetivadas se dirigidas a um dos
                              meios de contato presentes no cadastro.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.8.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              13.8. Tendo em vista as características inerentes ao
                              ambiente da Internet, a Whirlpool não garante o
                              pleno funcionamento e acesso ao Site de maneira
                              ininterrupta. Ademais, a Whirlpool não garante que o
                              Site esteja livre de invasões, interrupções ou
                              suspensões. O Participante reconhece e concorda que
                              a Whirlpool não poderá ser responsabilizada por tais
                              fatos e/ou atos em decorrência de hipótese de força
                              maior, caso fortuito ou ainda pela ocorrência
                              indicada no item 13.8.2.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <ol id="l39">
                              <li data-list-text="13.8.1.">
                                <p
                                  style={{
                                    paddingLeft: "41pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  13.8.1. Em razão do exposto no item anterior, na
                                  hipótese de ocorrência de invasões, interrupções
                                  ou suspensões de qualquer gênero, durante o
                                  período de vigência do Programa, a Whirlpool não
                                  ficará obrigada a prorrogar o prazo do mesmo,
                                  salvo decisão em contrário a exclusivo critério
                                  da Whirlpool.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="13.8.2.">
                                <p
                                  style={{
                                    paddingLeft: "41pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  13.8.2. O Participante reconhece que dada as
                                  características do Programa no ambiente da
                                  Internet, terceiros de má-fé poderão remotamente
                                  tentar utilizar-se de subterfúgios tecnológicos
                                  a fim de terem acesso a dados pessoais do
                                  Participante ou a fraudar o programa. Diante
                                  disso, o Participante também reconhece que a
                                  Whirlpool não poderá ser responsabilizada pelos
                                  atos praticados por terceiros.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                              <li data-list-text="13.8.3.">
                                <p
                                  style={{
                                    paddingLeft: "41pt",
                                    textIndent: "0pt",
                                    lineHeight: "112%",
                                    textAlign: "justify",
                                  }}
                                >
                                  13.8.3. A Whirlpool reserva a si o direito de efetuar
                                  toda e qualquer ação corretiva que se aplique às
                                  ações de terceiros que venham a interferir no
                                  bom funcionamento do Programa, como também
                                  reserva a si o direito de efetuar análises
                                  periódicas, baseadas nos registros dos
                                  Participantes inscritos, hipótese em que, se
                                  constatadas inconsistências, fica-lhe facultado
                                  o direito de efetuar automático bloqueio da
                                  respectiva conta.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                            </ol>
                          </li>
                          <li data-list-text="13.9.">
                            <p
                              style={{
                                paddingLeft: "41pt",
                                textIndent: "0pt",
                                lineHeight: "114%",
                                textAlign: "justify",
                              }}
                            >
                              13.9. A participação neste Programa implica na aceitação
                              total de todos os itens deste Regulamento, não sendo
                              dado a qualquer dos Participantes o direito de
                              alterá-lo.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                            <ol id="l40">
                              <li data-list-text="13.9.1.">
                                <p
                                  style={{
                                    paddingLeft: "77pt",
                                    textIndent: "0pt",
                                    lineHeight: "125%",
                                    textAlign: "justify",
                                  }}
                                >
                                  13.9.1 O Regulamento poderá ser alterado pela Whirlpool
                                  tantas vezes quanto necessário, a seu exclusivo
                                  critério, com posterior divulgação. A
                                  participação não implica em qualquer
                                  exclusividade de venda, sendo os Participantes
                                  livres para comercializar quaisquer produtos de
                                  outras empresas de acordo com os seus interesses
                                  e com as orientações de seu empregador.
                                </p>
                                <p
                                  style={{ textIndent: "0pt", textAlign: "left" }}
                                >
                                  <br />
                                </p>
                              </li>
                            </ol>
                          </li>
                          <li data-list-text="13.10.">
                            <p
                              style={{
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "113%",
                                textAlign: "justify",
                              }}
                            >
                              13.10. A participação no Programa é voluntária e gratuita,
                              não estando sujeita a qualquer espécie de cobrança
                              ou à necessidade de desembolso por parte dos
                              Participantes.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                              <br />
                            </p>
                          </li>
                          <li data-list-text="13.11.">
                            <p
                              style={{
                                paddingLeft: "7pt",
                                textIndent: "0pt",
                                lineHeight: "113%",
                                textAlign: "left",
                              }}
                            >
                              13.11. Em caso de fraude comprovada ou descumprimento deste
                              Regulamento, o Participante será automaticamente
                              excluído do Programa.
                            </p>
                          </li>
                          <li data-list-text="13.12.">
                            <h2
                              style={{
                                paddingTop: "3pt",
                                paddingLeft: "6pt",
                                textIndent: "0pt",
                                lineHeight: "112%",
                                textAlign: "justify",
                              }}
                            >
                              Lei aplicável e foro
                              <span className="p">
                                13.12. Esses termos e condições são regidos,
                                interpretados e executados de acordo com as leis
                                da República Federativa do Brasil,
                                independentemente dos conflitos dessas leis com
                                leis de outros estados ou países, sendo competente
                                o Foro da Comarca de São Paulo (SP), para dirimir
                                qualquer dúvida decorrente deste instrumento. O
                                Participante consente, expressamente, com a
                                competência desse juízo, e renuncia, neste ato, à
                                competência de qualquer outro foro, por mais
                                privilegiado que seja ou venha a ser.
                              </span>
                            </h2>
                          </li>
                        </ol>
                      </ol>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <h2
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "left",
                        }}
                      >
                        Versão: <span className="p">setembro de 2023.</span>
                      </h2>
                    </div>
                  </Card.Text>
                  <Button
                    className="mt-5 mb-3 w-100 btn-laranja"
                    onClick={handleAceiteRegulamento}
                  >
                    Li e aceito o regulamento
                  </Button>
                  {/* <Button
                    className="mb-3 w-100 btn-laranja-outline"
                    onClick={handleModalRegulamento}
                  >
                    Fechar
                  </Button> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </BodyModal>
      )}
      {modalPolitica && (
        <BodyModal className="d-flex justify-content-center align-items-center position-absolute top-0">
          <Row className="m-0">
            <Col
              lg={{ span: 6, offset: 3 }}
              className="d-flex justify-content-center"
            >
              <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                <Card.Body>
                  <Card.Title className="text-center" as="h5">
                    POLÍTICA DE PRIVACIDADE "PROGRAMA + TOP"
                  </Card.Title>
                  <Card.Text className="text-center modal-text">
                    <div className="m-2">
                      <h1
                        style={{
                          paddingTop: "4pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "center",
                        }}
                      >
                        POLÍTICA DE PRIVACIDADE "PROGRAMA + TOP"
                      </h1>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <p
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        A presente Política de Privacidade (“<b>Política</b>”
                        <b>) </b>tem por finalidade demonstrar o compromisso da
                        Whirlpool S.A., sociedade empresária inscrita no CNPJ sob
                        o no. 59.105.999/0001-86, com sede na Rua Olympia Semeraro
                        675, Jardim Santa Emília, São Paulo, SP, e demais empresas
                        do grupo econômico Whirlpool (“<b>Whirlpool</b>” ou “
                        <b>Nós</b>”), donas das marcas Brastemp, Compra Certa,
                        Consul e KitchenAid, com a privacidade e a proteção dos
                        dados pessoais dos Participantes do Programa +TOP (“
                        <b>Você</b>” ou “<b>Participantes</b>”<b>)</b>, explicando
                        como utilizamos seus Dados Pessoais para a realização do
                        Programa + TOP (Programa), quais os seus direitos como
                        titular de dados e como você pode exercê-los.
                      </p>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <p
                        className="s1"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "left",
                        }}
                      >
                        1- Para participar da Campanha, você declara:
                      </p>
                      <ol id="l1">
                        <li data-list-text="(i)">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "17pt",
                              textIndent: "-11pt",
                              textAlign: "justify",
                            }}
                          >
                            ser maior de 18 (dezoito) anos;
                          </p>
                        </li>
                        <li data-list-text="(ii)">
                          <p
                            style={{
                              paddingTop: "5pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            ter feito a leitura completa e atenta das regras desta
                            Política e aceitado os Termos e Condições do Programa
                            +TOP e Uso da Plataforma (“<b>Termos</b>”<b>)</b>,
                            estando plenamente ciente sobre os respectivos
                            conteúdos;
                          </p>
                        </li>
                        <li data-list-text="(iii)">
                          <p
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "22pt",
                              textIndent: "-16pt",
                              textAlign: "justify",
                            }}
                          >
                            ter compartilhado conosco apenas dados e informações
                            verdadeiras
                          </p>
                        </li>
                      </ol>
                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                      </p>
                      <ol id="l2">
                        <li data-list-text={2}>
                          <p
                            className="s1"
                            style={{
                              paddingTop: "7pt",
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "left",
                            }}
                          >
                            - Quando coletamos os seus dados?
                          </p>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            Os dados que coletamos dependem da forma como você se
                            relaciona conosco e por quê. Podemos coletar seus
                            dados, por exemplo:
                          </p>
                          <ol id="l3">
                            <li data-list-text="(i)">
                              <p
                                style={{
                                  paddingTop: "4pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                quando a Revenda Participante (“<b>Revenda</b>”),
                                conforme definição estabelecida nos Termos, adere
                                ao Programa e compartilha conosco seu nome, CPF,
                                cargo e histórico de vendas de produtos Whirlpool,
                                a fim de viabilizar seu pré-cadastro na plataforma
                                e participação no Programa;
                              </p>
                            </li>
                            <li data-list-text="(ii)">
                              <p
                                style={{
                                  paddingTop: "4pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                quando você realiza, voluntariamente, seu cadastro
                                na Plataforma do Programa +TOP (“<b>Plataforma</b>
                                ”) e compartilha os demais dados necessários à
                                realização do Programa;
                              </p>
                            </li>
                            <li data-list-text="(iii)">
                              <p
                                style={{
                                  paddingTop: "4pt",
                                  paddingLeft: "22pt",
                                  textIndent: "-16pt",
                                  textAlign: "justify",
                                }}
                              >
                                quando você os fornece através dos canais de
                                atendimento do Programa;
                              </p>
                            </li>
                          </ol>
                          <p
                            style={{
                              paddingTop: "5pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            (iii) através de cookies, tags e tecnologias
                            semelhantes.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={3}>
                          <p
                            className="s1"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "left",
                            }}
                          >
                            - Quais dados são coletados no âmbito do Programa e
                            qual a finalidade?
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              marginLeft: "6.75pt",
                            }}
                            cellSpacing={0}
                          >
                            <tbody>
                              <tr style={{ height: "20pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s2"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Dados Coletados
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s2"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Finalidades
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "42pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                  rowSpan={4}
                                >
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "6pt",
                                      paddingLeft: "80pt",
                                      paddingRight: "2pt",
                                      textIndent: "-52pt",
                                      lineHeight: "114%",
                                      textAlign: "left",
                                    }}
                                  >
                                    Nome completo, CPF, cargo e histórico de venda
                                    de produtos Whirlpool.
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Confirmar sua identidade e elegibilidade para
                                    a participação no Programa e realizar seu
                                    pré-cadastro na Plataforma
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "41pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Permitir o crédito de
                                  </p>
                                  <p
                                    className="s3"
                                    style={{
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    pontos e resgate de prêmios, conforme o
                                    previsto nos Termos.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "32pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "92pt",
                                      paddingRight: "2pt",
                                      textIndent: "-73pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    Realizar análises de prevenção à fraude e
                                    riscos associados<span className="s4">.</span>
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "21pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Permitir o cumprimento de obrigações legais e
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              marginLeft: "6.75pt",
                            }}
                            cellSpacing={0}
                          >
                            <tbody>
                              <tr style={{ height: "30pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    style={{
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    <br />
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "4pt",
                                      paddingLeft: "103pt",
                                      paddingRight: "2pt",
                                      textIndent: "-89pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    regulatórias pela Whirlpool e resguardar os
                                    direitos desta.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "31pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                  rowSpan={5}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Nome completo, e-mail, número de telefone
                                    (celular e fixo), endereço, data de
                                    nascimento, gênero, estado civil, senha de
                                    acesso e foto.
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "71pt",
                                      paddingRight: "2pt",
                                      textIndent: "-65pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    Permitir que você realize seu cadastro na
                                    Plataforma e participe do Programa
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "21pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "22pt",
                                      textIndent: "0pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    Prestar atendimento relacionado ao Programa.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "41pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Realizar estudos internos sobre o Programa,
                                    com o intuito de aprimorar nossos produtos,
                                    serviços e operações.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "52pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Enviar comunicações sobre o funcionamento do
                                  </p>
                                  <p
                                    className="s3"
                                    style={{
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Programa, novidades relacionadas a este e
                                    assuntos relevantes para a manutenção do
                                    relacionamento com os Participantes.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "41pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "5pt",
                                      paddingRight: "5pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Possibilitar ações promocionais e de
                                    marketing, dentre elas a de promover nossos
                                    produtos e serviços e novas campanhas do
                                    Programa.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "62pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Filiação
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Possibilitar o cumprimento de obrigações
                                    legais e regulatórias referentes à emissão de
                                    cartões
                                  </p>
                                  <p
                                    className="s3"
                                    style={{
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    pré-pagos, quando o Participante opta por essa
                                    forma de resgate dos prêmios, conforme o
                                    previsto nos Termos.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "31pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                  rowSpan={4}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "6pt",
                                      paddingRight: "5pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Dados de navegação, como endereço IP, data e
                                    hora de acesso, tipo de navegador, duração da
                                    visita, páginas visitadas. Dados sobre o
                                    dispositivo de acesso como modelo, fabricante,
                                    sistema operacional, operadora de telefonia,
                                    tipo de navegador, tipo e velocidade da
                                    conexão.
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "46pt",
                                      paddingRight: "2pt",
                                      textIndent: "-27pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    Registrar o seu IP, data e hora de acesso,
                                    para o cumprimento de obrigações legais.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "41pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "7pt",
                                      paddingRight: "7pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Ativar funcionalidades essenciais, como
                                    software antivírus, apresentação do conteúdo
                                    na tela, entre outras funções.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "31pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "86pt",
                                      paddingRight: "2pt",
                                      textIndent: "-82pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    Impedir atividades fraudulentas e melhorar a
                                    segurança da Plataforma.
                                  </p>
                                </td>
                              </tr>
                              <tr style={{ height: "62pt" }}>
                                <td
                                  style={{
                                    width: "234pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "2pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "2pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "2pt",
                                  }}
                                >
                                  <p
                                    className="s3"
                                    style={{
                                      paddingTop: "5pt",
                                      paddingLeft: "5pt",
                                      paddingRight: "5pt",
                                      textIndent: "0pt",
                                      textAlign: "center",
                                    }}
                                  >
                                    Entender seu comportamento de navegação e como
                                    o website está sendo usado, para melhorar sua
                                    experiência como usuário. Os dados coletados
                                    para essa finalidade são agregados e, sempre
                                    que possível, anonimizados.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={4}>
                          <p
                            className="s1"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "justify",
                            }}
                          >
                            - Cookies e tecnologias semelhantes
                          </p>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            Utilizamos cookies e tecnologias semelhantes, tais
                            como tags e pixels (Cookies) para coletar informações
                            sobre seu navegador, dispositivo e demais dados de
                            navegação. Eles nos permitem saber como e quando o
                            website é acessado, bem como quantas pessoas o
                            acessam. Eles podem ser úteis para, por exemplo,
                            fazermos o website encaixar na sua tela, entender
                            melhor a utilização do website. Utilizamos os
                            seguintes tipos de cookies:
                          </p>
                          <ul id="l4">
                            <li data-list-text="●">
                              <p
                                style={{
                                  paddingTop: "4pt",
                                  paddingLeft: "42pt",
                                  textIndent: "-18pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                Cookies essenciais: São os Cookies estritamente
                                necessários para fornecer nossos serviços e para
                                que o website funcione corretamente, garantindo a
                                segurança da navegação, o dimensionamento do
                                conteúdo e o cumprimento de obrigações legais pela
                                Whirlpool.
                              </p>
                            </li>
                            <li data-list-text="●">
                              <p
                                style={{
                                  paddingTop: "4pt",
                                  paddingLeft: "42pt",
                                  textIndent: "-18pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                Cookies de publicidade: São Cookies usados para
                                direcionamento de conteúdos e publicidade conforme
                                o seu perfil e preferências. Eles servem para que
                                você veja anúncios mais relevantes e mais
                                interessantes durante a sua navegação.
                              </p>
                            </li>
                            <li data-list-text="●">
                              <p
                                style={{
                                  paddingLeft: "42pt",
                                  textIndent: "-18pt",
                                  lineHeight: "114%",
                                  textAlign: "justify",
                                }}
                              >
                                Cookies estatísticos (analytics): Fornecem
                                informações sobre seu comportamento de navegação e
                                como o website está sendo usado. Os dados
                                coletados são agregados e nosso objetivo é
                                entender melhor o nosso público, para que possamos
                                oferecer conteúdo, serviços e produtos mais
                                interessantes para quem acessa nosso website.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingTop: "9pt",
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "156%",
                                  textAlign: "justify",
                                }}
                              >
                                Se você quiser saber quais cookies estão
                                instalados no seu dispositivo, ou se deseja
                                excluí-los ou restringi-los, use a configuração do
                                seu navegador. Você encontrará mais explicações
                                sobre como proceder clicando nos links abaixo.
                                Para encontrar informações relacionadas a outros
                                navegadores, visite o site do desenvolvedor do
                                navegador.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "150%",
                                  textAlign: "left",
                                }}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox"
                                  className="s5"
                                  target="_blank"
                                >
                                  Firefox
                                </a>
                                <a
                                  rel="noopener noreferrer"
                                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                                  style={{
                                    color: "#263874",
                                    fontFamily: "Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    textDecoration: "none",
                                    fontSize: "10pt",
                                  }}
                                  target="_blank"
                                >
                                  {" "}
                                </a>
                                <a
                                  rel="noopener noreferrer"
                                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                                  className="s5"
                                  target="_blank"
                                >
                                  Chrome
                                </a>
                                <a
                                  rel="noopener noreferrer"
                                  href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                  style={{
                                    color: "#263874",
                                    fontFamily: "Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    textDecoration: "none",
                                    fontSize: "10pt",
                                  }}
                                  target="_blank"
                                >
                                  {" "}
                                </a>
                                <a
                                  rel="noopener noreferrer"
                                  href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                  className="s5"
                                  target="_blank"
                                >
                                  Safari
                                </a>
                              </p>
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href="https://support.microsoft.com/pt-br/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                                  className="s5"
                                >
                                  Internet Explorer
                                </a>
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                              <p
                                style={{
                                  paddingLeft: "6pt",
                                  textIndent: "0pt",
                                  lineHeight: "156%",
                                  textAlign: "justify",
                                }}
                              >
                                Lembre-se que o uso de cookies nos permite
                                oferecer uma melhor experiência em nossos produtos
                                e serviços. Se você bloquear cookies em nosso
                                website, não podemos garantir o correto
                                funcionamento de todas as funcionalidades e talvez
                                você não consiga acessar determinadas áreas e/ou
                                páginas. Além disso, é bem provável que certas
                                funções e páginas não funcionem da maneira
                                adequada.
                              </p>
                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text={5}>
                          <p
                            className="s1"
                            style={{
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "left",
                            }}
                          >
                            - Os seus direitos como titular de dados
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            <a
                              rel="noopener noreferrer"
                              href="mailto:privacidadebr@whirlpool.com"
                              style={{
                                color: "black",
                                fontFamily: "Arial, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "none",
                                fontSize: "10pt",
                              }}
                              target="_blank"
                            >
                              Você tem alguns direitos relativos aos dados que
                              tratamos. Abaixo, trazemos uma lista deles, além de
                              informações sobre como você pode exercê-los. Ao
                              recebermos a sua solicitação, poderemos pedir
                              algumas informações adicionais para verificarmos sua
                              identidade e analisarmos seu pedido. Para exercer
                              quaisquer desses direitos, envie um e-mail para{" "}
                            </a>
                            <span
                              style={{
                                color: "#1154CC",
                                fontFamily: "Arial, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "underline",
                                fontSize: "10pt",
                              }}
                            >
                              privacidadebr@whirlpool.com
                            </span>
                            , aos cuidados do Encarregado de Proteção de Dados.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p
                            className="s7"
                            style={{
                              paddingLeft: "155pt",
                              textIndent: "-134pt",
                              lineHeight: "67%",
                              textAlign: "left",
                            }}
                          >
                            Confirmação e Acesso{" "}
                            <span className="s8">
                              Permite que você possa verificar se tratamos dados
                              pessoais seus e, em caso positivo, requisitar uma
                              cópia dos dados pessoais que nós temos sobre você.
                            </span>
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p
                            className="s7"
                            style={{
                              paddingLeft: "155pt",
                              textIndent: "-107pt",
                              lineHeight: "67%",
                              textAlign: "left",
                            }}
                          >
                            Correção
                            <span className="s8">
                              Permite que você corrija alguns dos seus dados
                              pessoais incompletos, inexatos ou desatualizados por
                              meio do seu próprio cadastro no site.
                            </span>
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p
                            className="s8"
                            style={{
                              paddingLeft: "44pt",
                              textIndent: "-32pt",
                              textAlign: "left",
                            }}
                          >
                            Anonimização, bloqueio ou eliminação
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "12pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Permite que você nos peça, quando os dados tratados
                            sejam desnecessários, excessivos ou tratados em
                            desconformidade com a lei, para (a) anonimizar seus
                            dados, de forma a que eles não possam mais ser
                            relacionados a você e, portanto, deixem de ser dados
                            pessoais; (b) bloquear seus dados, suspendendo
                            temporariamente a sua possibilidade de tratarmos; e
                            (c) eliminar seus dados, caso em que apagaremos todos
                            os seus dados sem possibilidade de reversão,
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "155pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            salvo nos casos previstos em lei e quando a manutenção
                            for necessária para o cumprimento de obrigação legal
                            ou regulatória.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "7pt",
                              paddingLeft: "40pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Portabilidade
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "40pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Você tem o direito de solicitar, mediante requisição
                            expressa e dentro dos parâmetros estabelecidos pela
                            Autoridade Nacional de Proteção de Dados (ANPD), que
                            seja fornecido a você, ou a terceiro que você
                            escolher, os seus dados pessoais em formato
                            estruturado e interoperável, para transferência a
                            outra empresa, desde que não viole a propriedade
                            intelectual ou segredo de negócios da empresa.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "30pt",
                              textIndent: "-3pt",
                              textAlign: "left",
                            }}
                          >
                            Informação sobre o compartilhamento
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "27pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Você tem o direito de saber as entidades públicas e
                            privadas com as quais realizamos uso compartilhado de
                            dados.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "21pt",
                              textAlign: "left",
                            }}
                          >
                            Informação sobre a possibilidade de não consentir
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Permite que você tenha informações claras e completas
                            sobre a possibilidade e as consequências de não
                            fornecer consentimento. O seu consentimento, quando
                            necessário, deve ser livre e informado. Portanto,
                            sempre que pedirmos seu consentimento, você poderá
                            negá-lo – ainda que, nesses casos, é possível que
                            tenhamos que limitar alguns serviços.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Revogação do consentimento
                          </p>
                          <p
                            className="s8"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Você tem o direito de retirar o seu consentimento em
                            relação às atividades de tratamento que se baseiam no
                            consentimento. No entanto, isso não afetará a
                            legalidade de qualquer tratamento realizado
                            anteriormente. Se você retirar o seu consentimento,
                            talvez não possamos fornecer determinadas comunicações
                            ou serviços para você, mas iremos avisá-lo quando isso
                            ocorrer.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                        </li>
                        <li data-list-text={6}>
                          <p
                            className="s1"
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "justify",
                            }}
                          >
                            - Com quem compartilhamos os seus dados?
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <p
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            A Whirlpool opera em parceria com outras empresas para
                            o desenvolvimento desta Campanha e pode compartilhar
                            com elas seus dados pessoais, sempre dentro dos
                            limites das finalidades informadas no tópico 3 acima e
                            de forma a preservar ao máximo a sua privacidade:
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <h2
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            Grupo Econômico Whirlpool
                            <span className="p">
                              : Suas informações podem ser compartilhadas entre as
                              empresas do mesmo grupo econômico da Whirlpool para
                              (i) atender aos legítimos interesses da Whirlpool e
                              aos seus interesses; (ii) possibilitar o cumprimento
                              de obrigações legais e regulatórias e o exercício
                              regular de direitos por parte da Whirlpool (iii)
                              suportar o desenvolvimento de novos produtos da
                              Whirlpool; (iv) segmentar e fazer profiling e
                              estudos, para melhorar nossos produtos e serviços;
                              (vi) possibilitar reorganizações societárias, como
                              fusões, aquisições e incorporações.
                            </span>
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <h2
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            Fornecedores e Parceiros
                            <span className="p">
                              : Contamos com a ajuda de fornecedores e parceiros
                              que podem tratar dados pessoais que coletamos. As
                              informações são divulgadas apenas para as
                              finalidades informadas no tópico 3 acima. Sempre
                              buscamos avaliar cuidadosamente nossos fornecedores
                              e firmar com eles obrigações contratuais de proteção
                              de dados pessoais e segurança da informação, com o
                              objetivo de minimizar riscos para os titulares.
                              Entre esses fornecedores, estão, por exemplo:
                              agências de marketing e publicidade; parceiros do
                              Programa responsáveis por fazer a entrega e
                              disponibilização dos produtos e serviços resgatados;
                              servidores de hospedagem de dados; bancos e
                              instituições financeiras.
                            </span>
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <h2
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            Autoridades públicas:
                            <span className="p">
                              podemos compartilhar quaisquer dados pessoais
                              necessários ao cumprimento de obrigações legais ou
                              regulatórias ou, ainda, proteger os nossos direitos
                              e dos nossos colaboradores. Além disso, também
                              podemos ser obrigados a compartilhar alguns de seus
                              dados pessoais com entidades públicas para cumprir
                              obrigações legais ou regulatórias ou para cumprir
                              ordens de autoridades competentes
                            </span>
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                          <h2
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "108%",
                              textAlign: "justify",
                            }}
                          >
                            Outros países
                            <span className="p">
                              : Ainda que a Campanha seja destinada a pessoas
                              localizadas no Brasil, a Whirlpool é uma empresa
                              global, de modo que os dados pessoais podem ser
                              transferidos para empresas e fornecedores
                              localizados em outros países, como os Estados
                              Unidos. Adotamos as salvaguardas adequadas para que
                              essa transferência seja realizada de forma segura e
                              que seus direitos como titular dos dados pessoais
                              sejam resguardados.
                            </span>
                          </h2>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={7}>
                          <p
                            className="s1"
                            style={{
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "justify",
                            }}
                          >
                            - Quais são nossas responsabilidades e como protegemos
                            os seus dados pessoais?
                          </p>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Nossa responsabilidade é cuidar dos seus dados
                            pessoais, e utilizá-los para finalidades lícitas,
                            conforme descrito nesta Política. Para garantir a sua
                            privacidade e a proteção dos seus dados pessoais,
                            adotamos as práticas de segurança adequadas para nosso
                            mercado, com o uso de técnicas de criptografia e
                            outros sistemas de segurança da informação.
                          </p>
                          <p
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Nós trabalhamos para preservar a sua privacidade e
                            proteger os seus dados pessoais, mas infelizmente não
                            podemos garantir segurança absoluta. Entrada ou uso
                            não autorizado de terceiros em sua conta, falha de
                            hardware ou software que não esteja sob controle da
                            Whirlpool e outros fatores podem comprometer a
                            segurança dos seus dados pessoais. Por isso, sua
                            atuação é fundamental para a manutenção de um ambiente
                            seguro para todos. Você pode nos ajudar adotando boas
                            práticas de segurança em relação à sua conta e aos
                            seus dados e caso você tome conhecimento de algo que
                            comprometa a segurança dos seus dados, por favor,
                            entre em contato conosco por meio do nosso Encarregado
                            (Data Protection Officer), cujos canais de contato se
                            encontram abaixo.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={8}>
                          <p
                            className="s1"
                            style={{
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "justify",
                            }}
                          >
                            - Por quanto tempo os dados pessoais serão
                            armazenados?
                          </p>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            A Whirlpool tem uma política com regras sobre a
                            retenção e o descarte ou anonimização de dados
                            pessoais. Ela define as diretrizes para determinar o
                            período de retenção adequado para cada tipo de dado
                            pessoal coletado, considerando a sua natureza,
                            necessidade de coleta e finalidade para a qual ele
                            será tratado. Dados pessoais são armazenados somente
                            pelo tempo que for necessário para cumprir com as
                            finalidades para as quais foram coletados, salvo se
                            houver qualquer outra razão para sua manutenção, por
                            exemplo, cumprimento de quaisquer obrigações legais,
                            regulatórias, contratuais, entre outras, desde que
                            fundamentadas com uma base legal.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={9}>
                          <p
                            className="s1"
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "15pt",
                              textIndent: "-9pt",
                              textAlign: "justify",
                            }}
                          >
                            - Informações Gerais
                          </p>
                          <h2
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Alterações na Política de Privacidade
                          </h2>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            Como estamos sempre buscando melhorar nossos Produtos
                            e Experiências, esta Política de Privacidade pode
                            passar por atualizações para refletir as melhorias
                            realizadas, bem para permitir a adequação a novas
                            disposições legais e regulamentares aplicáveis. Desta
                            forma, recomendamos a visita periódica desta página
                            para que você tenha conhecimento sobre as modificações
                            efetivadas<span style={{ color: "#6E6E6E" }}>.</span>
                          </p>
                          <p
                            style={{
                              paddingTop: "4pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Versão atual publicada em: abril de 2022
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                          <h2
                            style={{
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            Lei aplicável e jurisdição
                          </h2>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "justify",
                            }}
                          >
                            A presente Política de Privacidade será regida,
                            interpretada e cumprida segundo a legislação
                            brasileira. O foro competente para dirimir qualquer
                            litígio ou controvérsia envolvendo o presente
                            documento será definido conforme a legislação
                            aplicável.
                          </p>
                        </li>
                        <li data-list-text={10}>
                          <p
                            className="s1"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "21pt",
                              textIndent: "-15pt",
                              textAlign: "justify",
                            }}
                          >
                            - Dúvidas e Contato
                          </p>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "6pt",
                              textIndent: "0pt",
                              textAlign: "justify",
                            }}
                          >
                            <a
                              rel="noopener noreferrer"
                              href="mailto:privacidadebr@whirlpool.com"
                              style={{
                                color: "black",
                                fontFamily: "Arial, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "none",
                                fontSize: "10pt",
                              }}
                              target="_blank"
                            >
                              Se quiser exercer algum direito previsto no item 5
                              acima ou tiver dúvidas, comentários ou sugestões
                              relacionadas a esta Política envie um e-mail para{" "}
                            </a>
                            <span
                              style={{
                                color: "#1154CC",
                                fontFamily: "Arial, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "underline",
                                fontSize: "10pt",
                              }}
                            >
                              privacidadebr@whirlpool.com
                            </span>
                            , aos cuidados do Encarregado de Proteção de Dados.
                          </p>
                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                          </p>
                        </li>
                        <li data-list-text={11}>
                          <p
                            className="s1"
                            style={{
                              paddingLeft: "20pt",
                              textIndent: "-14pt",
                              textAlign: "justify",
                            }}
                          >
                            - Vocabulário
                          </p>
                        </li>
                      </ol>
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "justify",
                        }}
                      >
                        A fim de descomplicar a sua leitura, apresentamos algumas
                        definições úteis para a sua interpretação
                        <span style={{ color: "#6E6E6E" }}>:</span>
                      </p>
                      <h2
                        style={{
                          paddingTop: "5pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        Dado Pessoal
                        <span className="p">
                          : É o dado relativo a uma pessoa física e que seja capaz
                          de identificar a pessoa ou tornar possível a sua
                          identificação. São exemplos de Dados Pessoais que
                          permitem a sua identificação: Nome, CPF, RG, endereço,
                          telefone celular, e-mail etc.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "justify",
                        }}
                      >
                        Dado Anonimizado:{" "}
                        <span className="p">
                          É qualquer informação que perde a capacidade de
                          identificar uma pessoa física.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "7pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        Encarregado de Proteção de Dados (DPO)
                        <span className="p">
                          : é aquele que atua como canal de comunicação entre o
                          Grupo Whirlpool, os Titulares dos Dados e a Autoridade
                          Nacional de Proteção de Dados (ANPD), quando o assunto é
                          a proteção dos seus Dados Pessoais.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        Endereço IP
                        <span className="p">
                          : Abreviatura de Internet Protocol. É o conjunto
                          alfanumérico que identifica os dispositivos dos Usuários
                          na Internet.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        Grupo Whirlpool:{" "}
                        <span className="p">
                          Para os fins da presente Política, considera-se a
                          WHIRLPOOL S.A., sociedade empresária com sede na Rua
                          Olympia Semeraro, nº 675, São Paulo, SP, CEP 04183-090,
                          inscrita no CNPJ/MF sob o nº 59.105.999/0001-86, e todas
                          sociedades nas quais ela detém participação societária
                          direta ou indiretamente, e/ou com sócio em comum,
                          existentes ou que venham a existir.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        Lei Geral de Proteção de Dados ou LGPD:{" "}
                        <span className="p">
                          Lei nº 13.709/18, que dispõe sobre o Tratamento de Dados
                          Pessoais, com o objetivo de proteger os direitos
                          fundamentais de liberdade e de privacidade e o livre
                          desenvolvimento da personalidade da pessoa natural.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "left",
                        }}
                      >
                        Marcas
                        <span className="p">
                          : são as Marcas do Grupo Whirlpool, existentes ou que
                          venham a ser, como Brastemp, Compra Certa, Consul,
                          KitchenAid etc.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "left",
                        }}
                      >
                        Plataforma:
                        designa o endereço eletrônico{" "}
                        <a
                          rel="noopener noreferrer"
                          href="https://www.programamaistop.com.br/"
                          style={{
                            fontFamily: "Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            textDecoration: "none",
                            fontSize: "10pt",
                          }}
                          target="_blank"
                        >
                          https://www.programamaistop.com.br/
                        </a>


                        <span className="s11"> </span>
                        <span className="p">e seus subdomínios.</span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "8pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "left",
                        }}
                      >
                        Produtos e Serviços:{" "}
                        <span className="p">
                          São todos os produtos, negócios, experiências e jornada
                          de consumo oferecidas pelas Marcas do Grupo Whirlpool,
                          como vendas, plataformas, tecnologias e outros
                        </span>
                        <span style={{ color: "#6E6E6E" }}>.</span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "left",
                        }}
                      >
                        Programa +TOP:{" "}
                        <span className="p">
                          programa de relacionamento, capacitação e reconhecimento
                          mantido pela Whirlpool.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "7pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "left",
                        }}
                      >
                        Titular de Dados:{" "}
                        <span className="p">
                          É você, a pessoa física a quem os Dados Pessoais se
                          referem.
                        </span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "7pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "114%",
                          textAlign: "justify",
                        }}
                      >
                        Tratamento de Dados Pessoais:{" "}
                        <span className="p">
                          são os usos que nós fazemos dos seus Dados Pessoais,
                          incluindo, mas não se limitando às seguintes atividades:
                          coleta, armazenamento, consulta, compartilhamento,
                          classificação, reprodução e avaliação{" "}
                        </span>
                        <span style={{ color: "#6E6E6E" }}>destes.</span>
                      </h2>
                      <h2
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          textAlign: "justify",
                        }}
                      >
                        Participante:{" "}
                        <span className="p">
                          é você, a pessoa física que participa do Programa.
                        </span>
                      </h2>
                    </div>
                  </Card.Text>
                  <Button
                    className="mt-5 mb-3 w-100 btn-laranja"
                    onClick={handleAceitePolitica}
                  >
                    Li e aceito a política de privacidade
                  </Button>
                  {/* <Button
                    className="mb-3 w-100 btn-laranja-outline"
                    onClick={handleModalPolitica}
                  >
                    Fechar
                  </Button> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </BodyModal>
      )}
    </>
  );
}
