import { Breadcrumb, Col, Container, Row, Button, Form, Modal } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Home, Etapa2, Etapa1, TabelaResultado } from "./styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import iconPlanilha from '../../images/img/icon-planilha1.svg'
import iconPlanilha2 from '../../images/img/icon-planilhas2.svg'
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import Pagination from 'react-bootstrap/Pagination'


export function Planilhas({ user }) {

    // eslint-disable-next-line no-unused-vars
    const [etapa, setEtapa] = useState(1)
    const [tipoArquivo, setTipoArquivo] = useState()
    const [modalMensagem, setmodalMensagem] = useState()
    const [planilhaEnviada, setPlanilhaEnviada] = useState(false)
    const [modalShowAceiteVendas, setModalShowAceiteVendas] = useState(false)
    const [showTableError, setShowTableError] = useState(false)
    const [tamanhoProgressBrar, setTamanhoProgressBrar] = useState('w100')


    const [errosPlanilha, setErrosPlanilha] = useState('')
    const [errosPlanilhaPaginado, setErrosPlanilhaPaginado] = useState()
    const [numPaginaAtiva, setNumPaginaAtiva] = useState(parseInt(1))
    const [numeroPaginas, setNumeroPaginas] = useState([])


    let items = [];

    const [itemStatusUpload, setitemStatusUpload] = useState(' ')
    const [itemStatusDados, seItemStatusDados] = useState(' ')
    const [itemStatusFinalizado, seItemStatusFinalizado] = useState(' ')
    const [dataAtualEnvioPlanilha, setDataAtualEnvioPlanilha] = useState()

    // item-status-planilha d-flex erro justify-content-center flex-column text-center

    const handleClose = () => setModalShowAceiteVendas(false);

    var today = new Date();

    var mm = String(today.getMonth() + 1);
    var yyyy = today.getFullYear();
    var anos = [];
    if (mm === '1') {
        anos.push({
            ano: yyyy - 1,
        });
    } else {
        anos.push({
            ano: yyyy,
        });
    }

    var meses = [
        { valMes: 1, mes: "Janeiro" },
        { valMes: 2, mes: "Fevereiro" },
        { valMes: 3, mes: "Março" },
        { valMes: 4, mes: "Abril" },
        { valMes: 5, mes: "Maio" },
        { valMes: 6, mes: "Junho" },
        { valMes: 7, mes: "Julho" },
        { valMes: 8, mes: "Agosto" },
        { valMes: 9, mes: "Setembro" },
        { valMes: 10, mes: "Outubro" },
        { valMes: 11, mes: "Novembro" },
        { valMes: 12, mes: "Dezembro" },
    ];
    const DadosImportacaoFiltrar = () => {
        console.log('')
    }

    const downloadFileFuncionario = (arquivo) => {

        window.open(require('./modelo-hierarquia.xlsx'), '_self', 'download="modelo-hierarquia"');

        if (arquivo === 1) {
            setTimeout(() => {
                downloadFileVendas()
            }, 2000);
        } else { }
    };


    const downloadFileVendas = () => {
        window.open(require('./modelo-vendas.xlsx'), '_self', 'download="modelo-vendas"');
    };

    const publicarPlanilha = (e) => {

        const formData = new FormData();
        formData.append("TipoPlanilha", tipoArquivo);
        formData.append("Mes", (mm - 1) === 0 ? 12 : mm - 1)
        formData.append("Ano", (mm - 1) === 0 ? yyyy - 1 : yyyy);
        formData.append("file", e);

        api
            .post(rotasApi.PublicarPlanilha, formData)
            .then(result => {
                setmodalMensagem(result.data.mensagem)
                setModalShowAceiteVendas(true)
                ObterStatusPlanilha()
            })
            .catch(error => {
                setmodalMensagem(error.response.data.mensagem)
                setModalShowAceiteVendas(true)
            });
    };

    const ObterErrosPlanilha = (e) => {

        let type = tipoArquivo === 1 ? 'HIERARQUIA' : 'VENDAS'
        let mesStatus = (mm - 1) === 0 ? 12 : mm - 1
        let anoStatus = (mm - 1) === 0 ? yyyy - 1 : yyyy
        api
            .get(`${rotasApi.ObterErrosPlanilha}?TipoPlanilha=${type}&Mes=${mesStatus}&Ano=${anoStatus}`)
            .then(result => {

                setErrosPlanilha(result.data.erros)
                setErrosPlanilhaPaginado(result.data.erros.slice(0, 9))
                setNumeroPaginas(Math.ceil(result.data.erros.length / 10))
                setNumPaginaAtiva(1)
            })
            .catch(error => {
                console.log(error)
            });

    }

    const dataAtualPlanilha = () => {

        if ((mm - 2) === -2) {
            setDataAtualEnvioPlanilha(meses[0])
        } else if ((mm - 2) === -1) {
            setDataAtualEnvioPlanilha(meses[0])
        } else {
            setDataAtualEnvioPlanilha(meses[mm - 2])

        }

    }

    const ObterStatusPlanilha = () => {

        let type = tipoArquivo === 1 ? 'HIERARQUIA' : 'VENDAS'
        let mesStatus = (mm - 1) === 0 ? 12 : mm - 1
        let anoStatus = (mm - 1) === 0 ? yyyy - 1 : yyyy

        api
            .get(`${rotasApi.StatusPlanilha}?TipoPlanilha=${type}&Mes=${mesStatus}&Ano=${anoStatus}`)
            .then(result => {

                if (result.data.mensagem === 'Arquivo encontrado' && result.data.obj.statusArquivo === 'PENDENTE') {

                    setitemStatusUpload(' pendente ')
                    setPlanilhaEnviada(true)
                    setShowTableError(false)
                    setTamanhoProgressBrar(' w10 ')
                    seItemStatusDados('')
                    seItemStatusFinalizado('')

                }
                else if (result.data.mensagem === 'Arquivo encontrado' && result.data.obj.statusArquivo === 'EM_ANDAMENTO' && tipoArquivo === 1) {

                    setPlanilhaEnviada(true)
                    setShowTableError(false)
                    setitemStatusUpload(' success ')
                    seItemStatusDados(' pendente ')
                    setTamanhoProgressBrar(' w30 ')

                }
                else if (result.data.mensagem === 'Arquivo encontrado' && result.data.obj.statusArquivo === 'FINALIZADO_COM_SUCESSO') {

                    setPlanilhaEnviada(true)
                    setShowTableError(false)
                    setitemStatusUpload(' success ')
                    seItemStatusDados(' success ')
                    setTamanhoProgressBrar(' w100 ')
                    seItemStatusFinalizado(' success ')
                }

                else if (result.data.mensagem === 'Arquivo encontrado' && result.data.obj.statusArquivo === 'FINALIZADO_COM_ERRO') {

                    setPlanilhaEnviada(true)
                    setShowTableError(true)
                    setitemStatusUpload(' success ')
                    seItemStatusDados(' erro ')
                    setTamanhoProgressBrar(' w30 ')
                    ObterErrosPlanilha(type)
                }

                else if (result.data.mensagem === 'Arquivo encontrado'
                    && (result.data.obj.statusArquivo === 'APROVACAO_QUANTIDADE' ||
                        result.data.obj.statusArquivo === 'EM_ANDAMENTO' ||
                        result.data.obj.statusArquivo === 'APROVACAO_QUANTIDADE' ||
                        result.data.obj.statusArquivo === 'VENDAS_PROCESSADAS')
                    && tipoArquivo === 2) {

                    setPlanilhaEnviada(true)
                    setShowTableError(false)
                    setitemStatusUpload(' success ')
                    seItemStatusDados(' success ')
                    setTamanhoProgressBrar(' w100 ')
                    seItemStatusFinalizado(' success ')

                }
                else {
                    return
                }
            })
            .catch(error => {

                console.log(error)
            });

    }


    useEffect(() => {
        dataAtualPlanilha()
        if (etapa === 2) {
            ObterStatusPlanilha()

        } else if (etapa === 1) {

            setPlanilhaEnviada(false)
            setShowTableError(false)
            setitemStatusUpload('')
            seItemStatusDados('')
            seItemStatusFinalizado('')
            setTamanhoProgressBrar('')

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etapa])



    const mudaPaginaTabela = (e) => {

        let numMenor = e * 10 - 10
        let numMaior = e * 10
        setErrosPlanilhaPaginado('')
        setErrosPlanilhaPaginado(errosPlanilha.slice(numMenor, numMaior))
        setNumPaginaAtiva(e)
    }

    const voltarPagina = (e) => {

        let pagina = numPaginaAtiva > 1 ? numPaginaAtiva - 1 : numPaginaAtiva
        setNumPaginaAtiva(pagina)
        let numMaior = pagina * 10
        let numMenor = (numMaior - 10)
        setErrosPlanilhaPaginado('')
        setErrosPlanilhaPaginado(errosPlanilha.slice(numMenor, numMaior))
    }

    const proximaPagina = (e) => {

        let pagina = (numPaginaAtiva >= 0 && numPaginaAtiva < numeroPaginas) ? numPaginaAtiva + 1 : numPaginaAtiva
        setNumPaginaAtiva(pagina)
        let numMaior = pagina * 10
        let numMenor = (numMaior - 10)
        setErrosPlanilhaPaginado('')
        setErrosPlanilhaPaginado(errosPlanilha.slice(numMenor, numMaior))
    }

    let frente = numPaginaAtiva + 4
    let traz = numPaginaAtiva

    for (let number = numPaginaAtiva; (number <= numeroPaginas && number < frente && number >= traz); number++) {
        items.push(
            <Pagination.Item onClick={(e) => { mudaPaginaTabela(number) }} key={number} active={number === numPaginaAtiva}>
                {number}
            </Pagination.Item>,
        );
    }

    return (
        <>
            <Modal
                show={modalShowAceiteVendas}
                onHide={handleClose}
                centered
                backdropClassName='background-dialog'
                contentClassName="modal-css"
                size="sm"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="pontos-participantes col-12 d-flex flex-column justify-center">
                    <div className="col-11 col-lg-10 mt-4 align-self-center text-center">
                        {modalMensagem}
                    </div>
                    <Button className="col-5 align-self-center mt-5 mb-5 btn-laranja" size="sm" onClick={handleClose}>Fechar</Button>
                </Modal.Body>
            </Modal>
            <Home className="tema-planilhas">
                <NavBar user={user} />
                <Etapa1 className="etapa-1">
                    {etapa === 1 ? <div>
                        <Container className="p-0">
                            <Breadcrumb className="mt-3">
                                <Link className="breadcrumb-item" to="/home">Home</Link>
                                <Link className="breadcrumb-item">Planilhas</Link>
                            </Breadcrumb>
                            <hr className="mt-3" />
                            <Row className="m-0 p-0">
                                <Col className="mt-0 mb-4" md="12">
                                    <div className="d-flex justify-content-center flex-column mb-5">
                                        <div className="align-self-center text-center mt-5 mb-5">
                                            <h1>Publique suas planilhas</h1>
                                            <p><strong>De olho no prazo:</strong> Publique suas planilhas até o 5º dia útil do mês<br /> subsequente e ganhe 300 pontos!</p>
                                        </div>
                                        <div className="align-self-center mb-5 col-12 col-lg-12 d-flex justify-content-center flex-wrap ">
                                            <Button className="btn-laranja me-lg-5 col-12 col-lg-4 btn-planilhas mb-2 mb-lg-0" onClick={() => { setEtapa(2) || setTipoArquivo(1) }}>
                                                <img src={iconPlanilha} alt="" className="me-3" />
                                                Enviar arquivos de funcionários</Button>

                                            <Button className="btn-verde col-12 col-lg-4 btn-planilhas" onClick={() => { setEtapa(2) || setTipoArquivo(2) }} >
                                                <img src={iconPlanilha2} alt="" className="me-3" />
                                                Enviar arquivos de vendas</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Col className="baixar-arquivos">
                            <Container>
                                <div className="d-flex justify-content-center pt-5">
                                    <div className="d-flex flex-column col-11 col-lg-4 text-center">
                                        <h4>Clique e baixe os modelos de planilhas de hierarquia e vendas.</h4>

                                        <Button onClick={() => { downloadFileFuncionario(1) }} className="btn-laranja col-11 col-lg-101 align-self-center mb-5 mt-3" size="lg">Baixar arquivos</Button>

                                    </div>
                                </div>
                            </Container>
                        </Col>
                        <Col>
                            <Container>
                                <div className="text-center mt-5 mb-5">
                                    <h1>Novo fluxo de informações</h1>
                                    <p>Veja o passo a passo para publicar planilhas e garantir sua pontuação mensal.</p>
                                </div>
                                <div className="d-flex justify-content-lg-around justify-content-sm-center container-cards mb-5">
                                    <div className="card-item d-flex justify-content-center pt-2">
                                        <div className="d-flex col-lg-11">
                                            <h1>1</h1>
                                            <div>
                                                <h6 className="align-self-center mt-2">Acesso pelo<br /> site +Top</h6>
                                                <p>
                                                    Entre com seu login e
                                                    senha e coloque na opção
                                                    “publicar planilha”.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item d-flex justify-content-center pt-2">
                                        <div className="d-flex col-lg-12">
                                            <h1>2</h1>
                                            <div>
                                                <h6 className="align-self-center mt-2">Envie o arquivo<br /> de funcionários</h6>
                                                <p>
                                                    O arquivo passará por uma
                                                    validação do sistema em caso de aprovação, você receberá uma mensagem e a publicação do arquivo de vendas será liberada.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item d-flex justify-content-center pt-2">
                                        <div className="d-flex col-lg-11">
                                            <h1>3</h1>
                                            <div>
                                                <h6 className="align-self-center mt-2">Envie o arquivo
                                                    <br /> de vendas</h6>
                                                <p>
                                                    Novamente, o sistema
                                                    avaliará o correto preenchimento das
                                                    planilhas, se o arquivo
                                                    for aprovado, você será informado por mensagem. Fique ligado!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item d-flex justify-content-center pt-2">
                                        <div className="d-flex col-lg-11">
                                            <h1>4</h1>
                                            <div>
                                                <h6 className="align-self-center mt-2">Finalizado com <br />sucesso</h6>
                                                <p>
                                                    O processamento das vendas de sua revenda foi finalizado com sucesso! Os pontos dos participantes estarão disponíveis até o 15º dia útil deste mês.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div> : ('')}

                </Etapa1>


                <Etapa2>
                    {etapa === 2 ? <div>
                        <Container className="p-0">
                            <Breadcrumb className="mt-3">
                                <Link className="breadcrumb-item" to="/home">Home</Link>
                                {/* <Breadcrumb.Item onClick={breadcrumbNavigation} active={!campanhaMostraDetalhes}>Campanhas ativas</Breadcrumb.Item>
						{campanhaMostraDetalhes && (<Breadcrumb.Item active>{campanhaDetalhada.titulo}</Breadcrumb.Item>)} */}
                            </Breadcrumb>
                            <hr className="mt-3" />
                            <Row className="m-0 p-0">
                                <div className="mb-4">
                                    <span onClick={() => { setEtapa(1) }} style={{ cursor: "pointer" }} >
                                        <i className="bi bi-arrow-left"></i><span className="small">Voltar</span>
                                    </span>
                                </div>
                                <Col className="mt-0 m-0 p-0 mb-4" md="12">
                                    <div className="d-flex justify-content-center flex-column mb-5">
                                        <div className="text-center"><h1>Siga as etapas abaixo para importar<br />o arquivo de {tipoArquivo === 1 ? <span>funcionários </span> : <span> vendas </span>}</h1></div>
                                        <div className="container-itens-instrucao d-flex justify-content-lg-between flex-wrap ">
                                            <div className="d-flex itens-instrucao col-12 mb-3 mb-lg-0">
                                                <h1>1</h1>
                                                <p>Selecione o mês correspondente ao arquivo que será enviado.</p>
                                            </div>
                                            <div className="d-flex itens-instrucao col-12 mb-3 mb-lg-0">
                                                <h1>2</h1>
                                                <p>Clique em "Importar" para selecionar o arquivo.</p>
                                            </div>
                                            <div className="d-flex itens-instrucao col-12 mb-3 mb-lg-0">
                                                <h1>3</h1>
                                                <p>Aguarde a validação do sistema (notificação via e-mail).</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="m-0 p-0">
                                <Col className="m-0 p-0">
                                    <div className="container-dados-importacao d-flex flex-column col-12 mb-5">
                                        <div className="header-busca-dados d-flex justify-content-center">
                                            <p className="align-self-center mb-0">Dados de importação</p>
                                        </div>
                                        <div className=" d-flex align-items-center align-self-center h-100 flex-wrap col-lg-12" >
                                            <Formik
                                                initialValues={{
                                                    mes: mm,
                                                    ano: yyyy,
                                                }}
                                                onSubmit={(values) => DadosImportacaoFiltrar(values)}
                                            >
                                                {({
                                                    values,
                                                    handleChange,
                                                    errors,
                                                    isValid,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    dirty,
                                                }) => (
                                                    <Form
                                                        noValidate
                                                        onSubmit={handleSubmit}
                                                        className="d-flex justify-content-lg-around flex-wrap form-inputs col-lg-12"
                                                    >
                                                        {/* <div className="col-lg-4 col-12 justify-content-center d-flex">
															<Form.Select
																name="mes"
																value={values.mes}
																onChange={handleChange}
																className="input-mes-ano input-revenda mb-2"
															>
																{meses.map(
																	({ valMes, mes }, index) =>
																		valMes <= mm && (
																			<option value={valMes} key={index}>
																				{mes}
																			</option>
																		)
																)}
															</Form.Select>
														</div> */}

                                                        <div className="inputs-select d-flex col-11 col-lg-8 justify-content-lg-between">
                                                            <Form.Select
                                                                name="mes"
                                                                value={values.mes}
                                                                onChange={handleChange}
                                                                className="input-mes-ano input-mes me-lg-4 mb-2"
                                                            >
                                                                <option>
                                                                    {dataAtualEnvioPlanilha.mes}
                                                                </option>
                                                            </Form.Select>

                                                            <Form.Select
                                                                name="ano"
                                                                value={values.ano}
                                                                onChange={handleChange}
                                                                className="input-mes-ano input-ano  me-lg-4"
                                                            >
                                                                {anos.map(({ ano }, index) => (
                                                                    <option value={ano} key={index}>
                                                                        {ano}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>

                                                        <div className="d-flex col-lg-3 flex-wrap justify-content-lg-between col-11">
                                                            {/* <Button type="submit" className="btn-buscar btn-laranja col-lg-5 col-12 me-lg-1 mb-2" Label>
																Importar
															</Button> */}
                                                            <label style={{ cursor: "pointer" }} htmlFor="#inputArquivo" className="btn-buscar btn-laranja col-lg-5 col-12 me-lg-1 mb-2 text-white d-flex align-items-center justify-content-center rounded">
                                                                <span>Importar</span>
                                                            </label>
                                                            {/*<input
																className="input-img d-none"
																accept=".png, .jpg, .jpeg"
																id="inputArquivo"
																type="file"
															>
															</input> */}
                                                            <Form.Control
                                                                className="input-img d-none"
                                                                accept=".xlsx"
                                                                type="file"
                                                                id="#inputArquivo"
                                                                isInvalid={!!errors.avatarImg}
                                                                onChange={(e) => { publicarPlanilha(e.currentTarget.files[0]) }}
                                                            />


                                                            {/* 
															<Form.Control
																className="input-img"
																accept=".png, .jpg, .jpeg"
																type="file"
																onChange={(e) => {
																	setImgSelecionada(true)
																	if (e.currentTarget.files[0].size <= 2000000) {
																		ImageAvatar(e);
																		setFieldValue(
																			"avatarImg",
																			e.currentTarget.files[0]
																		);
																		setImgAviso(false);
																	} else {
																		setImgAviso(true);
																	}
																}}
																isInvalid={!!errors.avatarImg}
															/> */}
                                                            <Button type="submit" className="btn-buscar btn-laranja-outline col-lg-5 col-12" onClick={() => { tipoArquivo === 1 ? downloadFileFuncionario(2) : downloadFileVendas() }}>
                                                                Baixar modelo
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                    {planilhaEnviada ? <div className="resultados">
                                        <div className="d-flex col-12 justify-content-center mt-4">
                                            <div className="d-flex justify-content-around col-lg-8 col-10">
                                                <div className={itemStatusUpload + 'item-status-planilha '}>
                                                    <i className="bi bi-cloud-upload align-self-center mt-3"></i>
                                                    <p>Upload</p>
                                                </div>
                                                <div className={itemStatusDados + ' item-status-planilha '}>
                                                    <i className="bi bi-file-earmark-check align-self-center mt-3"></i>
                                                    <p>Validando Dados</p>
                                                </div>
                                                <div className={itemStatusFinalizado + ' item-status-planilha '}>
                                                    <i className="bi bi-file-earmark-arrow-up align-self-center mt-3"></i>
                                                    {tipoArquivo === 1 ? <p>Finalizado</p> : <p>Arquivo publicado</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center  mt-3">
                                            <div className="eight columns progress-container col-lg-7 col-10 mb-4">
                                                <div className="progressBarcontainer">
                                                    <div
                                                        className={tamanhoProgressBrar + ' progressBarValue'}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* {showTableError ?  */}
                                        {/* <div className="d-flex justify-content-center">
											<div className="d-flex justify-content-between align-items-center col-lg-7 col-11 filtros-tabela-resultado">
												<Form.Group className="col-lg-5 col-6 p-3">
													<Form.Label>
														Pesquisar
													</Form.Label>

													<Form.Control
														placeholder="Busque aqui"
														type="text"
														name="rede"
														className="input-busca-nome"
													/>
												</Form.Group>
												<Form.Group className="col-lg-4 col-6 p-3">
													<Form.Label>
														Mostrar por página
													</Form.Label>
													<Form.Select
														name="mes"
														className="input-busca-nome"

													>
														
														<option >
															5
														</option>
														<option >
															10
														</option>
														<option >
															20
														</option>
													</Form.Select>
												</Form.Group>
											</div>
										</div>  */}
                                        {/* : ('')} */}

                                        {showTableError ? <TabelaResultado className="d-flex justify-content-center">
                                            <div className="lista-chamados-container col-lg-7 col-11 mt-4">
                                                <div className="header-table">
                                                    <div className="d-flex justify-content-between items-header">
                                                        <p className="w30">Linha</p>
                                                        <p className="w30">Erro</p>
                                                        <p className="w30">Planilha</p>
                                                    </div>
                                                </div>
                                                <div className="container-items">
                                                    {errosPlanilhaPaginado ? errosPlanilhaPaginado.map(({ descricao, linha, planilha }) => (
                                                        <div className="item-historico" >
                                                            <div
                                                                className="d-flex justify-content-around text-center "
                                                            >
                                                                <p className="w30">

                                                                    {linha}
                                                                </p>
                                                                <p className="w30">

                                                                    {descricao}
                                                                </p>

                                                                <p className="w30">

                                                                    {planilha}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )) : (<p>Nenhum resultado</p>)}

                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div>
                                                        <Pagination size="sm">
                                                            <Pagination.First onClick={(e) => { voltarPagina() }} />
                                                            {items}
                                                            <Pagination.Last onClick={(e) => { proximaPagina() }} />
                                                        </Pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabelaResultado> : ('')}

                                    </div> : ('')}
                                </Col>
                            </Row>
                        </Container>
                    </div> : ('')}
                </Etapa2>


                <Footer className="m-0 p-0"></Footer>
            </Home>

        </>
    );
}



