import styled from "styled-components";

export const CloseButton = styled.div`
    position: absolute;
    top: -9px;
    right: 7px;
    cursor: pointer;
    font-size: 31px;
    color: #000;
`;
export const ModalDePesquisa = styled.div`
    /* background: red; */
    @media (max-width: 770px) {
            width: 110%;
        }
    .container-pesquisa{
        padding: 32px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
    }
    .titulo-pesquisa{
        padding-top: 10px;
        padding-bottom: 36px;
    }
    .container-notas{
        display: flex;
        justify-content: center;
            .btn-nota{
            /* background-color: red; */
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 7px;
            cursor: pointer;
            margin: 0px 7px;
                   
             }
             .btn-selecionado{
                
             }
             .selecionado-0{
                border: 4px solid black;
             }
            .nota0{
                background-color: rgb(170, 0, 0);
            }
            .nota1{
                background-color: rgb(193, 0, 0);
            } 
            .nota2{
                background-color: rgb(237, 4, 4);
            }
            .nota3{
                background-color: rgb(237, 82, 4);
            }
            .nota4{
                background-color: rgb(237, 148, 4);
            }
            .nota5{
                background-color: rgb(237, 171, 4);
            }
            .nota6{
                background-color: rgba(177, 199, 13, 0.848);
            }
            .nota7{
                background-color: rgb(162, 180, 3);
            }
            .nota8{
                background-color: rgb(100, 159, 17);
            }
            .nota9{
                background-color: rgb(127, 255, 89);
            }
            .nota10{
                background-color: rgb(57, 247, 0);
            }
    }
    
`;