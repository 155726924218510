import styled from "styled-components";

export const Home = styled.div`
	background: #efeeed;
	.space-fale-conosco{
		height: 4rem;
		@media (max-width: 770px) {
			height: 8rem;
		}
	}
`;

export const Breadcrumbs = styled.div`
	.breadcrumbs {
		font-size: 12px;
		padding-bottom: 20px;
		border-bottom: 1px solid #c4c4c4;
		@media (max-width: 770px) {
			margin: 0 15px;
		}
	}

	.link {
		font-weight: bold;
		text-decoration: none;
		color: #f58420;
	}
`;

export const DuvidasEstilo = styled.div`
	.titulo {
		width: 100%;
		max-width: 330px;
		font-size: 32px;
		color: #000;
		line-height: 48px;
		margin: 50px auto 0;
	}

	.input-busca {
		width: 100%;
		max-width: 552px;
		border: 1px solid #c4c4c4;
		margin: 60px auto;
		border-radius: 5px;
		height: 45px;
		padding-left: 60px;
		color: #313131;
		font-size: 14px;

		&::placeholder {
			color: #c4c4c4;
		}
	}

	.btn-buscar {
		border: none;
		position: relative;
		left: 39px;
		background: #fff;
		font-size: 24px;
		top: 4px;
		color: #ccc;

		@media (max-width: 769px) {
			position: absolute;
			top: 65px;
			left: 15px;
		}
	}

	.form-busca {
		position: relative;
	}
`;

export const BarraFaleConosco = styled.div`
	.barra-flutuante {
		width: 100%;
		height: 80px;
		position: fixed;
		background: #7032d1;
		margin: auto;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		left: 0;
		bottom: 0;
		z-index: 2;

		@media (max-width: 769px) {
			height: 106px;
		}
	}
	.mensagem {
		width: 100%;
		max-width: 750px;
		margin: 0 auto;
		padding: 18px 0;
		display: flex;
		justify-content: space-between;
		align-items: end;

		@media (max-width: 769px) {
			flex-direction: column;
			align-items: center;
		}

		.texto {
			font-size: 24px;
			font-weight: bold;
			color: #fff;

			@media (max-width: 769px) {
				font-size: 14px;
			}
		}

		.btn-fale-conosco {
			width: 300px;
			height: 45px;
			border-radius: 5px;
			background: #f58420;
			color: #fff;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.2s;
			border: none;
			text-decoration: none;

			&:hover {
				background: #f24404;
			}
		}
	}
`;
