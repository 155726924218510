import styled from 'styled-components'

export const MeusDadosCampos = styled.div`
    background: #EFEEED;
        .img-prev{
            position: absolute;
            height: 12rem;
            border-radius: 50%;
            @media (min-width: 768px) { 
                left: 10%;
             }
        }
        .input-img{
            margin: 0;
            height: 12rem;
            cursor: pointer;
            position: absolute;
            opacity: 0;
            left: 0;
            input{
                height: 12rem;
            }
        }
        @media (max-width: 768px) { 
            padding-top: 0;
        }
        .img-invalid{
            position: absolute;
            top: 13rem;
            right:25px;
            @media (max-width: 768px) { 
                top: 23rem;
                right: 0px; 
            }
        }
        .formatos-imgs{
            margin-top: 13rem;
            position: absolute;
            width: 100%;
        }
    
    
    .container-campos{
        @media (min-width: 768px) { 
            margin-left: 50px;
        }
        @media (max-width: 768px){
            margin-top: 20rem;
        }  
        .titulo-page{
            padding-bottom: 34px;     
                @media (max-width: 768px) { 
                    margin-top: 18rem;
                    display: none;
                }
               // margin-top: 105px;
                /* margin-bottom: 35px; */
            
            span{
                color: #989898;
                font-size: 14px;
            }
        }
        .titulo-page-mobile{
            h2{
                @media (max-width: 768px) { 
                    margin-top: 20rem;
                    /* display: none; */
                    /* text-align: center; */
                }
            }
        }
        .select-perguntas{
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
            max-width: 332px;
            @media (max-width: 768px) { 
                min-width: 270px;
            }
        }
        .info-senha{
            font-size: 14px;
            color: #313131;
            margin-bottom: 35px;
        }
        .checkbox-regulamentos{
            font-size: 12px;
            margin-left: 25px;
            a{
                color: #58595B;
            }
        }
    }
    .img-usuario{
        margin-top:212px;
        margin-right:60px;
        @media (max-width: 768px) { 
            margin: 0;
            margin-bottom: 70px;
         }
    }
    .container-principal{
        @media (max-width: 768px) { 
            justify-content: center;
         }
    }
    .navbar-meus-dados{
      //  display:none!important;
    }
    .titulo-page{
        display: block;
        @media (max-width: 768px) { 
          //  display: none;
         }
    }
    .titulo-page-mobile{
        display: none;
        @media (max-width: 768px) { 
            display: block;
            /* margin-top: 1px; */
            margin-bottom: 40px;
         }
    }
    .busca-cep{
        position: absolute;
        right: 0;
        top: -2rem;
    }
    .pass-hide-cadastro{
        position:absolute;
        font-size: 1.2rem;
        top:2.4rem;
        right:2rem;
  }
 
`
export const FooterStyle = styled.div`
    margin-right: -15px;
`
export const BodyModal = styled.div`
    background: var(--background-escuro);
    height: 100vh;

    .info-telefone{
        font-size: 14px;
        color: #fff;
    }
    .body-card{
        @media (min-width: 992px) { 
            width: 28rem;
        }
        
    }
    .icon-size{
        font-size: 4rem;
    }
    .modal-text{
        overflow-x: hidden;
        height: 20rem;
    }


    h1 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 11pt;
        }

        .p,
        p {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
            margin: 0pt;
        }

        h2 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 10pt;
        }

        .s1 {
            color: #666;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        .s2 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 9pt;
        }

        .s3 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
        }

        .s4 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        .s5 {
            color: #263874;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: underline;
            font-size: 10pt;
        }

        .s7 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
            vertical-align: -5pt;
        }

        .s8 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
        }

        .s11 {
            color: #3366D5;
            font-family: "Trebuchet MS", sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        li {
            display: block;
        }

        #l1 {
            padding-left: 0pt;
            counter-reset: c1 1;
        }

        #l1>li>*:first-child:before {
            counter-increment: c1;
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        #l1>li:first-child>*:first-child:before {
            counter-increment: c1 0;
        }

        li {
            display: block;
        }

        #l2 {
            padding-left: 0pt;
            counter-reset: d1 2;
        }

        #l2>li>*:first-child:before {
            counter-increment: d1;
            content: counter(d1, decimal);
            color: #666;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        #l2>li:first-child>*:first-child:before {
            counter-increment: d1 0;
        }

        #l3 {
            padding-left: 0pt;
            counter-reset: e1 1;
        }

        #l3>li>*:first-child:before {
            counter-increment: e1;
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        #l3>li:first-child>*:first-child:before {
            counter-increment: e1 0;
        }

        #l4 {
            padding-left: 0pt;
        }

        #l4>li>*:first-child:before {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        table,
        tbody {
            vertical-align: top;
            overflow: visible;
        }
`
export const BodyModalCheckbox = styled.div`
    background: var(--background-escuro);
    height: 100%;
    width: 100%;
    position: absolute;
`