import { Row, Col, Container, Button, Breadcrumb, Card, Accordion, Fade } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Home, TopoConvideUmAmigo, ListaAmigosConvidados, RegrasConvideAmigo } from "./styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { useEffect } from "react";
import copy from 'copy-to-clipboard';
// import { rotasApi } from "../../services/rotasApi";
// import api from "../../services/api";



export function ConvideUmAmigo({ user }) {


	const [amigosParaConvite, SetAmigosParaConvite] = useState()

	const [codigoConvite, SetCodigoConvite] = useState("")
	const [modalMsg, setModalMsg] = useState("");
	const [modalErroCatalogo, setModalErroCatalogo] = useState(false);
	const [buscarAmigosNumero, setBuscarAmigosNumero] = useState(0)
	const [btnCopiarClass, setBtnCopiarClass] = useState(false)
	const [btnCopiarClass2, setBtnCopiarClass2] = useState(false)

	const [itemsCarregados, setItemCarregados] = useState()

	useEffect(() => {
		ListarAmigos();
		ObterCodigoConvite();
		setBuscarAmigosNumero(8)
	}, []);

	const ListarAmigos = () => {
		api
			.get(rotasApi.ListarAmigosParaConvite + `?skip=${buscarAmigosNumero}&take=8`,)
			.then(result => {

				SetAmigosParaConvite(result.data.listaUsuarios.concat(amigosParaConvite ? amigosParaConvite : []))
				// if(itemsCarregados===buscarAmigosNumero){
				setItemCarregados(result.data.listaUsuarios.concat(amigosParaConvite ? amigosParaConvite : []))
				// }else{}

				console.log(amigosParaConvite)
			})
			.catch(error => {
				console.log(error.response);
			});
	};

	const ObterCodigoConvite = () => {
		api
			.get(rotasApi.ObterCodigoConvite,)
			.then(result => {
				SetCodigoConvite(result.data.mensagem);
			})
			.catch(error => {
				console.log(error.response);
			});
	};


	const ConvidaUsuario = (usuarioId, lojaId) => {

		const formData = new FormData();

		formData.append("usuarioId", usuarioId);
		formData.append("lojaId", lojaId);

		api
			.post(rotasApi.ConvidarAmigo, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			}).then(result => {

				window.open(`https://wa.me/?text=${result.data.mensagem}`, "_blank");

			})
			.catch(error => {
				console.log(error.response);
				setModalErroCatalogo(true);
				setModalMsg(error.response.data.mensagem);
				setTimeout(() => {
					setModalErroCatalogo(false);
				}, 3000);
			});
	}

	const BuscaPorNome = (nome) => {

		if (nome === '') {
			SetAmigosParaConvite(itemsCarregados)
			console.log(itemsCarregados, 'aqui')
		} else {
			let filteredItems = amigosParaConvite.filter(item => item.nomeUsuario.toLocaleLowerCase().includes(nome.toLowerCase()) ? item.nomeUsuario.toLocaleLowerCase().includes(nome.toLowerCase()) : '')
			SetAmigosParaConvite(filteredItems)
		}



	}

	const CarregaAmigos = (e) => {
		console.log('carrega amigos')
		setBuscarAmigosNumero(buscarAmigosNumero + 8)
		ListarAmigos()
	}

	const CopyClipboard = (e) => {

		copy(`Olá, você foi indicado para fazer parte do Programa +TOP e já pode começar a juntar pontos que valem prêmios. 
Para participar basta entrar em https://www.programamaistop.com.br/CadastroConvidado/${codigoConvite}`,
			{
				debug: true,
				onCopy: setBtnCopiarClass(!btnCopiarClass)
			});
	}

	const CopyClipboardCodigo = (e) => {
		copy(`${codigoConvite}`,
			{
				debug: true,
				onCopy: setBtnCopiarClass2(!btnCopiarClass2)
			});
	}

	return (
		<>
			<Home className="convide-amigo-blackfriday">
				<NavBar user={user} />
				<Container>
					<Row>
						<Col className="d-flex justify-content-center">
							<Fade in={modalErroCatalogo}>
								<Card
									className="text-center p-3 m-2 z-index-top position-absolute error-aviso"
									bg="danger"
									text="white"
								>
									<Card.Body>
										<Card.Title as="h2">Erro</Card.Title>
										<Card.Text>{modalMsg}</Card.Text>
									</Card.Body>
								</Card>
							</Fade>
						</Col>
					</Row>
					<Breadcrumb className="mt-3">
						<Link className="breadcrumb-item" to="/home">
							Home
						</Link>
						<Breadcrumb.Item active>Convide um amigo</Breadcrumb.Item>
					</Breadcrumb>
					<hr className="mt-3" />
					<Row>
						<Col className=" mt-0 mb-4" md="5">
							<TopoConvideUmAmigo>
								{/* <h3 className="titulo">
									Como indico
									<br />
									um amigo?
								</h3> */}

								<div className="texto-como-fazer pt-1">
									<p> <strong>INDIQUE UM AMIGO e GANHE PONTOS</strong></p>
									<p>O “Indique Amigos” do +TOP é turbinado:</p>
									<p>Quem indica alguém para fazer parte do Programa +TOP <strong>GANHA 10 Pontos</strong>,
										e o indicado já chega à plataforma <strong>GANHANDO 5 Pontos</strong>!</p>
									<p><strong>Quanto mais indicações e cadastros, mais pontos se acumulam</strong>!</p>
									<p>
										Não fique de fora e traga todos seus amigos.
									</p>
								</div>
								<Button href="/convide-um-amigo/historico" variant="outline-secondary" className="btn-ver-amigos">
									Ver amigos indicados
								</Button>
							</TopoConvideUmAmigo>
						</Col>

						<Col md="7">
							<TopoConvideUmAmigo>
								<div className="msg-copia">
									<div className="quadro-roxo">
										<p className="titulo-quadro">
											Copie o texto abaixo e envie um WhatsApp ou E-mail para seus amigos.
										</p>
										<div className="quadro-menor">

											Olá, você foi indicado para fazer parte do Programa +TOP
											e já pode começar a juntar pontos que valem prêmios. Para
											participar basta entrar em <span>https://www.programamaistop.com.br/CadastroConvidado/{codigoConvite}</span><br />
											<br />
											O seu Código é : <strong>{codigoConvite}</strong><span></span>
										</div>
										<div>
											{/* <p>O seu código é : {codigoConvite}</p> */}
										</div>
										{btnCopiarClass ? <Button variant="outline-success" onClick={CopyClipboard} className="btn-copiar-success">
											Convite copiado
										</Button> : <Button variant="outline-secondary" onClick={CopyClipboard} className="btn-copiar">
											Copiar convite
										</Button>}
										{btnCopiarClass2 ? <Button variant="outline-secondary" onClick={CopyClipboardCodigo} className="btn-copiar-success">
											Copiar Apenas código
										</Button> : <Button variant="outline-secondary" onClick={CopyClipboardCodigo} className="btn-copiar">
											Copiar Apenas código
										</Button>}
									</div>
								</div>
							</TopoConvideUmAmigo>
						</Col>
					</Row>

				</Container>
				<Row className="p-0 m-0">
					<Col className=" mt-0 mb-4 p-0" md="12">
						<ListaAmigosConvidados className="lista-amigos-convidados">
							<Container>
								<div>
									<h3 className="titulo">Convide amigos próximos a você </h3>

									<form className="form-busca">
										<button type="submit" className="btn-buscar">
											<i className="bi bi-search"></i>
										</button>
										<input
											type="text"
											placeholder="Buscar amigo por nome"
											className="input-busca"
											onChange={(e) => { BuscaPorNome(e.target.value) }}
										/>
									</form>
								</div>
								<div>
									<div className="container-lista-amigos d-flex flex-wrap justify-content-center ">

										{amigosParaConvite ? amigosParaConvite.map(({ lojaNome, nomeUsuario, cidadeUsuario, estadoUsuario, usuarioId, lojaId }, index) => (
											<Card
												style={{ width: '15rem' }}
												className="mb-2 mt-4 mx-2 position-relative card-users"
											>
												<Card.Body style={{ height: '180px' }}>
													<Card.Text>
														<p className="nome-user-card"><strong>{nomeUsuario}</strong></p>
														<p>Loja: <strong> {lojaNome}</strong></p>
														{/* <p>{cidadeUsuario}-{estadoUsuario}</p> */}
														<p>RJ-Rio de janeiro</p>
													</Card.Text>
													<Button className="btn-convida" onClick={(e) => { ConvidaUsuario(usuarioId, lojaId) }}>Convida por WhatsApp</Button>
												</Card.Body>
											</Card>
										)) : ('')}
										{/* 
										<Card
											style={{ width: '15rem' }}
											className="mb-2 mt-4 mx-2"
										>
											<Card.Body>
												<Card.Text>
													<span><strong>Samuel Barrocas Brum</strong></span>
													<span>Loja:<strong>BMOL</strong></span>
													<p>São Paulo-SP</p>
												</Card.Text>
												<Button className="btn-convida">Convida por WhatsApp</Button>
											</Card.Body>
										</Card>
										<Card
											style={{ width: '15rem' }}
											className="mb-2 mt-4 mx-2"
										>
											<Card.Body>
												<Card.Text>
													<span><strong>Samuel Barrocas Brum</strong></span>
													<span>Loja:<strong>BMOL</strong></span>
													<p>São Paulo-SP</p>
												</Card.Text>
												<Button className="btn-convida">Convida por WhatsApp</Button>
											</Card.Body>
										</Card> */}

									</div>

								</div>
								<Button className="btn-laranja col-4 mt-5" onClick={CarregaAmigos}>Ver mais amigos</Button>
							</Container>
						</ListaAmigosConvidados>
						<RegrasConvideAmigo className="regras-convide-amigo">
							<Container>
								<div className="container-regras">
									<h2 className="text-center">Conheça as regras:</h2>
									<Accordion>
										<Accordion.Item
											eventKey="0"
											className="mb-3 border-0 rounded"

										>
											<Accordion.Header>
												<span className="fw-bold">Quem pode indicar?</span>
											</Accordion.Header>
											<Accordion.Body>
												<p>Vendedores, Gerentes de Loja e Gerentes Regionais de Lojas e Redes cadastradas no +TOP.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
									<Accordion>
										<Accordion.Item
											eventKey="0"
											className="mb-3 border-0 rounded"

										>
											<Accordion.Header>
												<span className="fw-bold">Quem pode ser indicado?</span>
											</Accordion.Header>
											<Accordion.Body>
												<p>Vendedores, Gerentes de Loja e Gerentes Regionais que ainda não são cadastrados no Programa +Top.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
									<Accordion>
										<Accordion.Item
											eventKey="0"
											className="mb-3 border-0 rounded"

										>
											<Accordion.Header>
												<span className="fw-bold">Como funciona?</span>
											</Accordion.Header>
											<Accordion.Body><p>Ao enviar o convite para os seus amigos próximos via WhatsApp ou E-mail e incentivando o cadastro no Programa +Top, você ganha 10 Pontos pela indicação e quem foi indicado recebe 5 Pontos.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
									<Accordion>
										<Accordion.Item
											eventKey="0"
											className="mb-3 border-0 rounded"

										>
											<Accordion.Header>
												<span className="fw-bold">Quando eu visualizo os meus pontos de Convide Amigos?</span>
											</Accordion.Header>
											<Accordion.Body><p>Quando o seu amigo estiver com o cadastro ativado no Programa +TOP, os seus pontos aparecerão no seu <strong>Extrato. </strong>
												É super simples: com o cadastro do seu amigo aprovado você leva 10 Pontos, e ele que se cadastrou com a sua indicação, recebe 5 Pontos!</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
									<Accordion>
										<Accordion.Item
											eventKey="0"
											className="mb-3 border-0 rounded"

										>
											<Accordion.Header>
												<span className="fw-bold">Como posso acompanhar os convites enviados?
												</span>
											</Accordion.Header>
											<Accordion.Body><p>Na sua sessão de <strong>Amigos Indicados</strong>, você encontrará um painel de acompanhamento com todas as informações sobre os amigos indicados, mostrando o status do convite: ativo, em andamento, pendente ou recusado. Assim você poderá lembrá-lo de concluir o cadastro para os dois saírem ganhando.
											</p></Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<p className="text-center mt-5 telefone">Dúvidas ? Ligue para <strong> 0800 780 0606 </strong> e fale com um de nossos atendentes.</p>
							</Container>
						</RegrasConvideAmigo>
					</Col>
				</Row>
				<Footer className="m-0 p-0"></Footer>
			</Home>
		</>
	);
}
