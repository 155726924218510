import styled from 'styled-components'

export const NavBarPrincipal = styled.div`
background: #fff;
color: #000;
.items-container{
    height: 88px;
    .navbar-brand{
        display: flex;
        justify-content: center;
        margin-left: -15px;
    }
}
    .logo-nav-bar{
        width: 96px;
        height: 40px;
    }

    .modalMsgErro {
        @media(max-width: 990px) {
            position: relative;
            z-index: 9999;
        }
    }
    
`
export const MenuMobile = styled.div`
    .logo-nav-bar-mobile{
        width: 96px;
        height: 40px;
    }
    .container-items-menu{
        background: red;
    }
    @media (min-width: 992px) { 
        display: none;
    }
`;

export const MenuDesk = styled.div`
    position: relative;
    @media (max-width: 992px) { 
        display: none;
     }
     
     @media (max-width: 1400px) and (min-width:990px ){ 
        .gestor{
            .nav-link{
                font-size: 11px;
                margin-left: 10px;
            }
        }   
     }
     .gestor{
        .notificacao-icon{
            top: -5px;
            width: 21px;
            height: 21px;
            p{
                margin-top:1px;
                margin-right:1px;
            }
        }
     }
    .nav-link{
        position: relative;
        font-size: 14px;
        color: #000!important;
        height: 29px;
        padding-bottom: 0px;
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    .active{
        border-bottom: 1px solid #EF4E23!important;
        color: #EF4E23!important;
    }
    .perfil{
        border-left: 1px solid #CECDD9;
        height: 30px;
        padding-left: 17px!important;
        border-bottom: none;
        span{

            padding-bottom: 3px;
        }
        span .active{
            border-bottom: 1px solid black;
        }
    }
    .notificacao-icon{
        right: -10px;
        width: 24px;
        height: 24px;
        background-color: red;
        position: absolute;
        cursor: pointer;
        border-radius: 50%;
        top: -6px;
        z-index: 4;
        text-align: center;
        border: 2px solid rgb(255, 255, 255);
        color: white;
        box-shadow: rgba(0, 0, 0, 0.1) -3px 1px 6px 0px;
        p{
            margin-top: 3px;
            font-size: 12px;
        }
    }
`;
