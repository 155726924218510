import styled from "styled-components";

export const HistoricoMensagensStyle = styled.div`
height:80vh;
.titulo-pagina{
  margin-top: 50px;
  @media (max-width:992px) { 
      /* display: none;    */
    }
  .subtitulo{
    font-weight: 700;
    margin-top:-15px;
  }
}

.titulo-pagina-mobile{
  display:none;
    @media (max-width:992px) { 
      display: block; 

    }
  } 

  .container-itens{
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  .container-campos{
    margin-top: 50px;
  }
  .btn-historico{
    height:45px ;
    @media (max-width:992px) { 
      display: none; 

    }
  }
  .btn-historico-mobile,
  .btn-wpp-mobile{
    display: none;
    @media (max-width:992px) { 
      display: block; 
      height:45px ;
    }
  }
  .anexo-arquivos{
  .label-anexo{  
      border: 1px solid #F58420;
      width: 67px;
      border-radius: 5px;
      height: 45px;
      cursor:pointer;
    }
    #file-input{
      display:none ;
    }
    .btn-enviar{
      height:45px;
    }
  }
  .lista-chamados-container{
      .botoes-filtro{
          background: #fff;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          height: 45px;
          align-items: center;
          border-radius: 5px;
          button{
              width: 48.5%;
              height: 40px;
              margin: 0 3px;
          }
          button:hover{
            background: #cdcdcd;
          }
      }
      .header-table{
          background: #000;
          color: #fff;
          border-radius: 5px;
          .items-header{
              p{
                  margin: 0;
                  padding: 10px;
              }
             
              .w30{
            width: 30%;
          }
          .w40{
            width: 40%;
          }
          }
      }
      .item-historico{
          margin: 15px 0;
          background: #fff;
          border-radius: 5px;
          p{
              margin: 0;
              padding: 10px;
          }
         
          .w35{
            width: 30%;
          }
          .w40{
            width: 40%;
          }
      }
      .item-historico:hover{
          background: #cdcdcd;
          cursor: pointer;
      }
      .container-items{
        max-height: 360px;
        overflow-x: auto;
      }
  }
`; 