import React from "react";
import { Container } from "./styles";


export function ModalInfo({ show, onClose, Message, error }) {

    if (!show) {
        return null;
    }

    return (
        <Container>
            <div className='modalStyle'>
                <h2 className="headerStyle">
                    {error ?
                        <>
                            Atenção
                            <i class="bi bi-exclamation-triangle-fill text-danger ps-1"></i>
                        </>
                        :
                        <>
                            Sucesso!
                            <i class="bi bi-check-circle-fill text-success ps-1"></i>
                        </>
                    }
                </h2>
                <p className="messageStyle">{Message ? Message : ''}</p>

                {error ?
                    <>
                        <button onClick={onClose} className="btn btn-danger">Fechar</button>
                    </>
                    :
                    <>
                        <button onClick={onClose} className="btn btn-success">Fechar</button>
                    </>
                }
            </div>
        </Container>
    );
};
