import styled from "styled-components";

export const LojaStyle = styled.div`
  position: relative;
  background: #efeeed;
  margin-bottom: 4rem;
  .back {
    color: var(--fontColor);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 2rem;
    @media (max-width: 770px) {
      top: -3rem;
      left: 1rem;
    }
    .bi-arrow-left {
      margin-right: 1rem;
      font-size: 2rem;
    }
  }
`;
