import { Breadcrumb, Col, Container, Row, Button, Card } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import {
  Home,
  ContainerCards,
  ContainerCampanhaInfo,
} from "./styles";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import AvisoPrazoCampanha from "../../images/img/aviso-prazo-campanha.svg";
import AvisoArquivoCampanha from "../../images/img/aviso-upload-arquivo-campanha.svg"
import StatusCampanha from "../../images/img/participacao-campanha.svg"
import { ModalAviso } from "../../components/ModalAviso";
import parse from "html-react-parser";
import { LoadingSpinner } from "../../components/LoadingSpinner"
import { LoadingArquivo } from "../../components/LoadingArquivo"
import { ModalInfo } from "../../components/ModalInfo"
import metas from "./metas.png"

export function Campanhas({ user }) {
  const [campanhaMostraDetalhes, setCampanhaMostraDetalhes] = useState(false);
  const [campanhas, setCampanhas] = useState([]);
  const [segmentacaoId, setSegmentacaoId] = useState();
  const [participando, setParticipando] = useState();
  const [campanhaDetalhada, setCampanhaDetalhada] = useState([""]);
  const [metaUsuario, setMetaUsuario] = useState();
  const [modalAviso, setModalAviso] = useState(false);
  const [modalAvisoPendente, setModalAvisoPendente] = useState(false);
  const [docFrenteCamera, setDocFrenteCamera] = useState();
  const [campanhaDetalhadaId, setCampanhaDetalhadaId] = useState();
  const [campanhasAtivas, setCampanhasAtivas] = useState([])
  const [indexArquivo, setIndexArquivo] = useState()
  const [idArquivo, setIdArquivo] = useState()
  const [imageFiles, setImageFiles] = useState('');
  const [imageFilesBanco, setImageFilesBanco] = useState()
  const [loadingSpin, setLoadingSpin] = useState(false)
  const [permiteAnexos, setPermiteAnexos] = useState(false)
  const [statusCampanha, setStatusCampanha] = useState(false)
  const [expandMenuConquistas, setExpandMenuConquistas] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [ShowModalInfo, setShowModalInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  let navigate = useNavigate();
  function breadcrumbNavigation() {
    navigate('/campanhas');
    campanhaMostraDetalhes === true
      ? setCampanhaMostraDetalhes(false)
      : setCampanhaMostraDetalhes();
  }

  const buscarParticipacao = (id) => {

    api
      .get(`${rotasApi.statusParticipacaoCampanha}${id}`)
      .then((result) => {
        setStatusCampanha(result.data.status)
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  const expandMenu = () => {
    setExpandMenuConquistas(!expandMenuConquistas)
  }


  const buscaCampanhaDetalhada = (id, segmentacaoId, participando) => {

    buscarParticipacao(id)
    setCampanhaDetalhadaId(id)
    buscarArquivosCampanha(id)
    setParticipando(participando);
    setSegmentacaoId(segmentacaoId);
    obterMetaUsuario(id)

    api
      .get(`${rotasApi.buscarCampanhaDetalhada}${id}`)
      .then((result) => {
        setCampanhaDetalhada(result.data.campanha);
        campanhaMostraDetalhes === false
          ? setCampanhaMostraDetalhes(true)
          : setCampanhaMostraDetalhes(false);
        navigate(`${result.data.campanha.id}`);
        setCampanhaMostraDetalhes(true)
        setPermiteAnexos(result.data.campanha.permiteAnexo)
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const idRedirect = localStorage.getItem("id");

  useEffect(() => {
    const pathname = window.location.pathname;
    const campanha = pathname.substring(pathname.lastIndexOf("/") + 1);
    buscarArquivosCampanha(campanha)
    setCampanhaDetalhadaId(campanha)
    obterMetaUsuario(campanha)

    api
      .get(`${rotasApi.buscarCampanhaDetalhada}${idRedirect}`)
      .then((result) => {
        setCampanhaDetalhada(result.data.campanha);
        campanhaMostraDetalhes === false
          ? setCampanhaMostraDetalhes(true)
          : setCampanhaMostraDetalhes(false);
        setCampanhaMostraDetalhes(true)
        localStorage.clear();
        setPermiteAnexos(result.data.campanha.permiteAnexo)
      })
      .catch((error) => {
        console.log(error.response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buscaCampanhas = () => {
    setLoadingSpin(true)
    api
      .get(rotasApi.listaCampanhas)
      .then((result) => {

        if (result.data.campanhas) {
          setCampanhas(result.data.campanhas.sort((a, b) => a.finalizado - b.finalizado));
          setCampanhasAtivas(result.data.campanhas.some(objeto => objeto.ativo === true))
        } else { setCampanhasAtivas(false) }
        // console.log(algumAtivo)
        setLoadingSpin(false)
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const participarCampanha = (id) => {
    const formData = new FormData();
    formData.append("campanhaSegmentacaoId", id);

    api
      .post(rotasApi.participarCampanha, formData)
      .then((result) => {
        setParticipando(true);
        buscaCampanhas();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const obterMetaUsuario = (id) => {

    api
      .post(`${rotasApi.ConsultarMeta}?idCampanha=${id}`)
      .then((result) => {
        setMetaUsuario(result.data.metas)

      })
  }
  const excluiArquivoCampanha = () => {

    api
      .delete(rotasApi.excluirFotoVideoCampanha + `?id=${idArquivo}`
        , {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(result => {
          buscarArquivosCampanha(campanhaDetalhadaId)

        }).catch(error => {
          console.log(error)
        })

  }


  const handleFileUpload = (event) => {
    if (event.target.files[0] === undefined) {
      return
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("campanhaId", campanhaDetalhadaId);
      formData.append("fotoVideos", event.target.files[0]);
      api
        .post(rotasApi.enviarFotoVideoCampanha, formData)
        .then((result) => {
          buscarArquivosCampanha(campanhaDetalhadaId)
          setIsError(false)
          setMessage(result.data.mensagem);
          setShowModalInfo(true);
        })
        .catch((error) => {
          setMessage(error.response.data.mensagem);
          setShowModalInfo(true);
          setIsError(true)
        }).finally(() => {
          setIsLoading(false);
        });
    }

  };

  const handleRemoveImage = (index) => {
    setModalAvisoPendente(true)
    setIndexArquivo(index)
  };

  const handleRemoveImage2 = (index, id) => {
    setModalAviso(true)
    setIndexArquivo(index)
    setIdArquivo(id)
  };
  const excluirItemBanco = (e) => {
    setModalAviso(false)
    if (e) {
      setImageFilesBanco(imageFilesBanco.filter((_, i) => i !== indexArquivo))
      excluiArquivoCampanha()
    } else { }
  }
  const excluirItemPendente = (e) => {
    setModalAvisoPendente(false)
    if (e) {
      setImageFiles(imageFiles.filter((_, i) => i !== indexArquivo))
      excluiArquivoCampanha()
    } else {
    }
  }

  const handleCloseModal = () => {
    setShowModalInfo(false);
    setMessage('');
  };

  const buscarArquivosCampanha = (id) => {
    api
      .get(`${rotasApi.buscarFotoVideoCampanhaUsuario}?campanhaId=${id}`)
      .then((result) => {
        function extractInformation(files) {
          return files.map(file => ({ id: file.id, fileName: file.arquivoNome, fileExtension: file.arquivoExtencao, url: file.arquivoUrl }));
        }
        const extractedFiles = extractInformation(result.data.listafotovideo);
        setImageFilesBanco(extractedFiles)
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  function formatCNPJ(cnpj) {
    const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    const formattedCNPJ = cnpj.replace(cnpjRegex, '$1.$2.$3/$4-$5');
    return formattedCNPJ;
  }



  const OpenPdf = (e) => {
    if (e === "detalhes") {
      window.open(campanhaDetalhada.arquivoGanhadores, '_blank', 'download="PDF-Exemple"');
    } else {
      window.open(campanhas[e].arquivoGanhadores, '_blank', 'download="PDF-Exemple"');
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  useEffect(() => {
    buscaCampanhas();
    // buscarRakingCampanha();
  }, []);

  return (
    <>
      <Home className="campanha-blackfriday">
        <ModalAviso modalShow={modalAviso} excluirItem={excluirItemBanco} mensagem={'Deseja realmente excluir ?'} />
        <ModalAviso modalShow={modalAvisoPendente} excluirItem={excluirItemPendente} mensagem={'Deseja realmente excluir?'} />
        <ModalInfo show={ShowModalInfo} error={isError} onClose={handleCloseModal} Message={message} />

        {isLoading && <LoadingArquivo />}
        <NavBar user={user} />
        <Container className="p-0">
          <Breadcrumb className="mt-3">
            <Link className="breadcrumb-item" to="/home">
              Home
            </Link>
            <Breadcrumb.Item
              onClick={breadcrumbNavigation}
              active={!campanhaMostraDetalhes}
            >
              Campanhas ativas
            </Breadcrumb.Item>
            {campanhaMostraDetalhes && (
              <Breadcrumb.Item active>
                {campanhaDetalhada.titulo}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          <hr className="mt-3" />
          <Row className="m-0 p-0">
            <Col className="mt-0 mb-4" md="12">
              {!campanhaMostraDetalhes && (
                <ContainerCards className="d-flex flex-wrap container-cards">
                  <LoadingSpinner show={loadingSpin} />
                  {campanhas ? campanhas.map(
                    (
                      {
                        titulo,
                        id,
                        participando,
                        fotoThumb,
                        segmentacaoId,
                        subTitulo,
                        finalizado,
                        ativo,
                      },
                      index
                    ) => (
                      ativo ?
                        (<Card className="card-item-campanha" key={index}>
                          <Card.Img variant="top" src={fotoThumb}></Card.Img>
                          <Card.Body>
                            <Card.Title>{titulo}</Card.Title>
                            <Card.Text>{subTitulo}</Card.Text>
                            {participando ? (
                              <Button
                                className={finalizado ? 'btn-cinza col-12' : 'btn-verde col-12'}
                                disabled
                                onClick={() => {
                                  participarCampanha(segmentacaoId);
                                }}
                              >
                                {finalizado ? "Finalizada" : "Participando"}
                              </Button>
                            ) : (
                              <Button
                                className={finalizado ? 'btn-cinza col-12' : 'btn-verde col-12'}
                                onClick={() => {
                                  participarCampanha(segmentacaoId);
                                }}
                                disabled={finalizado}
                              >
                                {finalizado ? "Finalizada" : "Participe"}
                              </Button>

                            )}
                            {campanhas[index].arquivoGanhadores ?
                              <Button
                                className="btn-verde-outline col-12 mt-3"
                                onClick={() =>
                                  OpenPdf(index)
                                }
                              >
                                Visualizar Ganhadores
                              </Button> : ('')
                            }
                            <Button
                              className="btn-laranja-outline col-12 mt-3"
                              onClick={() =>
                                buscaCampanhaDetalhada(
                                  id,
                                  segmentacaoId,
                                  participando
                                )
                              }
                            >
                              Acessar
                            </Button>
                          </Card.Body>
                        </Card>) : ('')
                    )
                  ) : ('')}
                  {campanhasAtivas === null ? ('') : (campanhasAtivas ? '' : "Não existem campanhas ativas para sua revenda.")}
                </ContainerCards>
              )}
              {campanhaMostraDetalhes && (
                <ContainerCampanhaInfo lg={12}>
                  <div className="container-banner-principal">
                    <div className="img-banner-principal">
                      <img
                        className="col-12 d-none d-lg-flex"
                        src={campanhaDetalhada.foto}
                        alt=""
                      />
                      <img
                        className="col-12 d-lg-none d-sm-flex"
                        src={campanhaDetalhada.fotoThumb}
                        alt=""
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-between flex-wrap">
                      <div className="d-flex justify-content-around col-lg-3 col-12">
                        <div className="data-inicio">
                          <p>Início</p>
                          <span>
                            {campanhaDetalhada.dataInicio.slice(8, 10) +
                              "/" +
                              campanhaDetalhada.dataInicio.slice(5, 7) +
                              "/" +
                              campanhaDetalhada.dataInicio.slice(0, 4)}
                          </span>
                        </div>
                        <div className="data-termino">
                          <p>Término</p>
                          <span>
                            {campanhaDetalhada.dataFim.slice(8, 10) +
                              "/" +
                              campanhaDetalhada.dataFim.slice(5, 7) +
                              "/" +
                              campanhaDetalhada.dataFim.slice(0, 4)}
                          </span>
                        </div>
                        <div className="data-limite">
                          <p>Participação limite</p>
                          <span>
                            {campanhaDetalhada.dataLimite.slice(8, 10) +
                              "/" +
                              campanhaDetalhada.dataLimite.slice(5, 7) +
                              "/" +
                              campanhaDetalhada.dataLimite.slice(0, 4)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-around col-lg-3 col-12  botoes-acao">
                        {/* <Button className="btn-laranja col-lg-5 col-5" onClick={() => { participarCampanha(campanhaDetalhada.id) }}>Participar</Button> */}
                        {participando ? (
                          <Button
                            className="btn-verde btn-campanha-detalhada"
                            onClick={() => {
                              participarCampanha(segmentacaoId);
                            }}
                            disabled={campanhaDetalhada.finalizado}
                          >
                            {campanhaDetalhada.finalizado ? "Finalizada" : "Participando"}
                          </Button>
                        ) : (
                          (<Button
                            className="btn-laranja btn-campanha-detalhada"
                            onClick={() => {
                              participarCampanha(segmentacaoId);
                            }}
                            disabled={campanhaDetalhada.finalizado}
                          >
                            {campanhaDetalhada.finalizado ? "Finalizada" : "Participar"}
                          </Button>)
                        )}
                        {campanhaDetalhada.arquivoGanhadores ? <Button style={{ fontSize: "13px" }} className="btn-laranja-outline btn-campanha-detalhada col-lg-5 col-5" onClick={() => {
                          OpenPdf("detalhes");
                        }} >Consulte os  Ganhadores</Button> : ''}
                      </div>
                    </div>
                    <hr className="mt-4" />
                  </div>

                  <div className=" col-lg-12 col-12 container-conteudo-campanha d-flex align-items-end">
                    <div className="col-lg-6 col-12 container-descricao align-self-center">
                      <h2>{campanhaDetalhada.titulo}</h2>

                      <h5>{campanhaDetalhada.subTitulo}</h5>

                      {campanhaDetalhada.descricao &&
                        parse(campanhaDetalhada.descricao)}

                      {campanhaDetalhada.quemParticipa &&
                        parse(campanhaDetalhada.quemParticipa)}

                      {campanhaDetalhada.produtoParticipante &&
                        parse(campanhaDetalhada.produtoParticipante)}

                      {campanhaDetalhada.mecanica &&
                        parse(campanhaDetalhada.mecanica)}

                      {campanhaDetalhada.comoEFeito &&
                        parse(campanhaDetalhada.comoEFeito)}
                    </div>
                    <div className="col-lg-3 col-12 d-flex justify-content-center flex-column mb-5 ">
                      <div className="status-campanha">
                        <img src={StatusCampanha} alt="" />
                        <h6>Status:</h6>
                        <p>{statusCampanha === 'Participando' ? 'Participou da campanha' : 'Não participou da campanha'}</p>
                      </div>
                      <div>
                        <img
                          className="col-12 mt-3 mb-4"
                          src={AvisoPrazoCampanha}
                          alt=""
                        />
                      </div>
                      {metaUsuario ?
                        <div
                          className="container-metas text-center"
                          style={{
                            overflow: expandMenuConquistas ? "scroll" : "hidden",
                            height: expandMenuConquistas ? (metaUsuario ? (metaUsuario > 2 ? '350px' : '240px') : '') : '170px',
                          }}
                        >


                          <div style={{ cursor: "pointer" }} className="btn-minhas-metas" onClick={() => { expandMenu() }}>
                            <div>
                              <img src={metas} alt="" />
                            </div>
                            {/* <p className="minhas-metas-title">Minhas metas</p> */}
                            <div className="arrow-container position-relative" >
                              <p className="p-0 m-0 text-center" style={{ opacity: !expandMenuConquistas ? 1 : 0, transition: 'opacity 1s, height 1s', fontSize: '13px' }} >Expandir</p>
                              <div class="arrow bounce"
                                style={{
                                  rotate: expandMenuConquistas ? "180deg" : "0deg",
                                }}></div>
                            </div>
                          </div>
                          {/* <Slider {...settings}> */}
                          <div>
                            {metaUsuario ? metaUsuario.map(
                              (
                                { meta, cnpjLoja },
                                index
                              ) => (
                                <div key={index}>
                                  <div className="box-categoria-participante">
                                    <h4>CNPJ: {formatCNPJ(cnpjLoja)}</h4>
                                    <h5>Meta: {meta} produtos participantes</h5>
                                  </div>
                                </div>
                              )
                            ) : ('')}
                          </div>
                          {/* </Slider> */}
                        </div> : ('')}

                      {permiteAnexos ?
                        <a href='#meuelemento'>
                          <div >
                            <div >
                              <img
                                className="col-12 mt-1 mb-4"
                                src={AvisoArquivoCampanha}
                                alt=""
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </a> : ('')}
                      <div>
                        <Card className="col-12 card-foto-premio">
                          <p className="mt-3 ps-3 pe-3">
                            <strong>Prêmios</strong>
                          </p>
                          <Card.Img
                            className="img-card-premio ps-3 pe-3"
                            src={campanhaDetalhada.fotoPremio}
                          />
                          <Card.Body>
                            <Card.Title>
                              {campanhaDetalhada.descricaoPremio ? parse(campanhaDetalhada.descricaoPremio) : ''}
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </div>

                      {permiteAnexos ?
                        <div className="mt-4 card-envio-itens">

                          <Card className="col-12">
                            {docFrenteCamera ? <img
                              src={docFrenteCamera}
                              alt=""
                              className="img-fluid img-prev"
                            /> :
                              <label
                                htmlFor="image_Front"
                              >
                                <input id="image_Front" type="file" multiple onChange={handleFileUpload} />
                                <p className="mt-3 ps-3 pe-3 " id="teste2">
                                  <strong>Faça o seu upload aqui</strong>
                                </p>
                                <div className="dashed-box">
                                  <div>
                                    <strong>Arraste seus arquivos aqui</strong>
                                    <br />
                                    <p>Ou se preferir</p>
                                    <div className="btn-selec-arq mt-2">Selecione seu arquivo</div>
                                    <p className="aviso-tamanho">*Formato JPEG, PNG e MP4 com tamanho máximo de 100 mega.</p>
                                  </div>
                                </div>
                              </label>
                            }
                          </Card>
                        </div> : ('')}
                      {permiteAnexos ?
                        <div style={{ overflowY: "scroll", maxHeight: "439px" }}>
                          <div className="col-12 d-flex flex-column justify-content-center mt-2 lista-img-selecionas">
                            {imageFiles !== '' ?
                              <ul id="teste" className="">
                                Pendente de envio
                                {imageFiles ? imageFiles.map((file, index) => (
                                  <li key={index}>
                                    {file?.type?.startsWith("image/") ?
                                      <img src={URL.createObjectURL(file)} alt={file.name} width="100" height="100" />
                                      :
                                      <i className="bi bi-film icon-video"></i>
                                    }
                                    <p>{file.name !== undefined ? file.name : ''}</p>
                                    <button onClick={() => handleRemoveImage(index)}>
                                      <i className="bi bi-x-circle-fill"></i>
                                    </button>
                                  </li>
                                )) : ('')}
                              </ul> : ('')}
                            {imageFilesBanco ?
                              <ul className="">
                                <span>Histórico de Envio</span>
                                {imageFilesBanco ? imageFilesBanco.map(
                                  ({ fileName, fileExtension, id }, index) =>
                                    <li key={index}>
                                      {
                                        fileExtension === "mp4" ?
                                          <i className="bi bi-film icon-video"></i>
                                          : <i className="bi bi-card-image icon-video"></i>
                                      }
                                      <p>{fileName}</p>
                                      <button onClick={() => handleRemoveImage2(index, id)}>
                                        <i className="bi bi-x-circle-fill"></i>
                                      </button>
                                    </li>
                                ) : ('')}
                              </ul>
                              : ('')}
                          </div>
                        </div> : ('')}

                      {/* <Button className="btn-verde-outline mb-2" onClick={() => { setModalSalvar(true) }}>Salvar</Button> */}
                    </div>
                  </div>
                </ContainerCampanhaInfo>
              )}
            </Col>
          </Row>
        </Container>
        <Footer className="m-0 p-0"></Footer>
      </Home>
    </>
  );
}
