import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { rotasApi } from "../services/rotasApi";

export const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: "",
  loginState: "",
};

// A slice for recipes with our 3 reducers
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.loading = true;
    },
    getLoginSuccess: (state, { payload }) => {
      state.loginState = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getLoginFailure: (state, { payload }) => {
      state.errorMessage = payload;
      state.loading = false;
      state.hasErrors = true;
    },
    getLoginReset: (state) => {
      state.loginState = "";
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = "";
    },
  },
});

// The reducer
export default loginSlice.reducer;

export const loginSelector = (state) => state.login;

export const { getLogin, getLoginSuccess, getLoginFailure, getLoginReset } =
  loginSlice.actions;

// Asynchronous thunk action
export function loginAction(data) {
  return async (dispatch) => {
    dispatch(getLoginReset());
    dispatch(getLogin());

    const formData = new FormData();
    formData.append("Cpf", data.cpf);
    formData.append("Senha", data.senha);
    formData.append("token", data.recaptcha);
    formData.append("Sistema", 1);
    formData.append("Ip", data.ip);
    formData.append("AppId", "");

    api
      .post(rotasApi.login, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        if (!result.data.success) {
          dispatch(
            getLoginFailure(result.data.mensagem ? result.data.mensagem : "")
          );
        } else {
          if (result.data.message === "Usuário encontrado com sucesso") {
            if (result.data.usuario.status === "PENDENTE_ENVIO_DE_DOCUMENTO") {
              sessionStorage.setItem(
                "UserInfo",
                JSON.stringify(result.data.usuario)
              );
              window.location.href = "/cadastro-selfie";
            } else if (
              result.data.usuario.status === "MODERACAO_DE_DOCUMENTO"
            ) {
              dispatch(getLoginSuccess(result.data.usuario.status));
            } else {
              sessionStorage.setItem("token", result.data.token);
              sessionStorage.setItem(
                "UserInfo",
                JSON.stringify(result.data.usuario)
              );
              dispatch(getLoginSuccess(result.data.usuario));
            }
          }
          if (result.data.message === "Usuário somente catalogo") {
            const url = result.data.link;
            if (url.length > 0) {
              window.open(`${url}`, "_self");
              return;
            }
          }
        }
      })
      .catch((error) => {
        console.log("error >", error);
        console.log("error response >", error.response);
        dispatch(
          getLoginFailure(
            error.response.data.mensagem
              ? error.response.data.mensagem
              : "Ocorreu um erro inesperado. Feche o aplicativo e abra novamente."
          )
        );
      });
  };
}
