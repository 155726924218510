import styled from 'styled-components'
export const BodyModal = styled.div`
background: var(--background-escuro);
padding-top: 1rem;
padding-bottom: 1rem;
.info-telefone{
    font-size: 14px;
    color: #fff;
}
.body-card{
    @media (min-width: 992px) { 
        width: 28rem;
    }
    
}
.icon-size{
    font-size: 4rem;
}
.modal-text{
    overflow-x: hidden;
    height: 82vh;
    padding-right: 2rem;
    @media (max-width: 992px) { 
        width: 23rem;
        height: 70vh;
        padding-right: 1rem;
    }
}


        h1 {
            color: black;
            
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 11pt;
        }

        .p,
        p {
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
            margin: 0pt;
        }

        h2 {
            color: black;
            
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 10pt;
        }

        .s1 {
            color: #666;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        .s2 {
            color: black;
            
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 9pt;
        }

        .s3 {
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
        }

        .s4 {
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        .s5 {
            color: #263874;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: underline;
            font-size: 10pt;
        }

        .s7 {
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
            vertical-align: -5pt;
        }

        .s8 {
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
        }

        .s11 {
            color: #3366D5;
            font-family: "Trebuchet MS", sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        li {
            display: block;
        }

        #l1 {
            padding-left: 0pt;
            counter-reset: c1 1;
        }

        #l1>li>*:first-child:before {
            counter-increment: c1;
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        #l1>li:first-child>*:first-child:before {
            counter-increment: c1 0;
        }

        li {
            display: block;
        }

        #l2 {
            padding-left: 0pt;
            counter-reset: d1 2;
        }

        #l2>li>*:first-child:before {
            counter-increment: d1;
            color: #666;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        #l2>li:first-child>*:first-child:before {
            counter-increment: d1 0;
        }

        #l3 {
            padding-left: 0pt;
            counter-reset: e1 1;
        }

        #l3>li>*:first-child:before {
            counter-increment: e1;
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        #l3>li:first-child>*:first-child:before {
            counter-increment: e1 0;
        }

        #l4 {
            padding-left: 0pt;
        }

        #l4>li>*:first-child:before {
            color: black;
            
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 10pt;
        }

        table,
        tbody {
            vertical-align: top;
            overflow: visible;
        }
`