import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { rotasApi } from "../services/rotasApi";

export const initialState = {
  primeiroAcessoLoading: false,
  primeiroAcessoErrors: false,
  primeiroAcessoMessage: "",
  primeiroAcessoState: "",
};

const primeiroAcessoSlice = createSlice({
  name: "primeiroAcesso",
  initialState,
  reducers: {
    primeiroAcessoLoading: (state) => {
      state.primeiroAcessoLoading = true;
    },
    primeiroAcessoSuccess: (state, { payload }) => {
      state.primeiroAcessoState = payload;
      state.primeiroAcessoLoading = false;
      state.primeiroAcessoErrors = false;
    },
    primeiroAcessoFailure: (state, { payload }) => {
      state.primeiroAcessoMessage = payload
      state.primeiroAcessoLoading = false;
      state.primeiroAcessoErrors = true;
    },
    primeiroAcessoReset: (state) => {
      state.primeiroAcessoLoading = false
      state.primeiroAcessoErrors = false
      state.primeiroAcessoMessage = ""
      state.primeiroAcessoState = ""
    },
  },
});

export default primeiroAcessoSlice.reducer;

export const primeiroAcessoSelector = (state) => state.primeiroAcesso;

export const { primeiroAcessoLoading, primeiroAcessoSuccess, primeiroAcessoFailure, primeiroAcessoReset } =
  primeiroAcessoSlice.actions;

export function primeiroAcessoAction(data) {

  return async (dispatch) => {
    dispatch(primeiroAcessoReset());
    dispatch(primeiroAcessoLoading());

    //  const formData = new FormData();
    //  formData.append("cpf", data.cpf);
    //formData.append("Senha", data.senha);
    //formData.append("Codigo", data.codigo);

    api
      .get(rotasApi.cadastroPrimeiroAcesso + data.cpf, {
        headers: {
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },
      })
      .then((result) => {
        console.log('result primeiro acesso >', result.data);
        if (!result.data.sucesso) {
          dispatch(
            primeiroAcessoFailure(result.data.mensagem ? result.data.mensagem : "")
          );
        } else {
          if (result.data.mensagem === "Usuário encontrado") {
            console.log(result.data);

          }
          result.data.cpf = data.cpf
          dispatch(primeiroAcessoSuccess(result.data));
        }
      })
      .catch((error) => {
        console.log('error >', error)
        dispatch(
          primeiroAcessoFailure(
            error.response.data.mensagem
              ? error.response.data.mensagem
              : "Ocorreu um erro inesperado. Feche o aplicativo e abra novamente."
          )
        );
      });
  };
}
