import { Modal, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import { ModalDePesquisa, CloseButton } from "./styles";
import { useEffect } from "react";

import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

export function ModalPesquisa({ modalShowPesquisa }) {


    const [modalShow1, setModalShow1] = useState(modalShowPesquisa);
    const [nota, setNota] = useState(null);
    // const [numSelect, setNumSelect] = useState()
    // const [numeroTeste, setNumeroTeste] = useState('')
    const [radioValue, setRadioValue] = useState('1');

    const notasConteudo = [
        { numero: '0', Value: '0' },
        { numero: '1', Value: '1' },
        { numero: '2', Value: '2' },
        { numero: '3', Value: '3' },
        { numero: '4', Value: '4' },
        { numero: '5', Value: '5' },
        { numero: '6', Value: '6' },
        { numero: '7', Value: '7' },
        { numero: '8', Value: '8' },
        { numero: '9', Value: '9' },
        { numero: '10', Value: '10' },

    ]
    const salvarPesquisa = () => {

        console.log('salvei')

        const formData = new FormData();

        formData.append("UsuarioId", '');
        formData.append("LojaId", '');
        formData.append("Nota", nota ? nota : 0);
        formData.append("IgnorarModal", nota === null ? true : false);

        api
            .post(rotasApi.SalvarReposta, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(result => {
                setModalShow1(false);

            }).catch(error => {
                console.log(error)
            })

    }
    const handleClose = () => {
        setModalShow1(false);
        salvarPesquisa()
    };
    const salvarNota = (e) => {
        console.log(e)
        setNota(e)
    }

    useEffect(() => {
        setModalShow1(modalShowPesquisa)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            show={modalShow1}
            size="lg"
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName="background-dialog"
            contentClassName="modal-css"
        >
            <Modal.Body className="show-grid d-flex justify-content-center p-0 position-relative">

                <ModalDePesquisa>
                    <CloseButton onClick={(e) => { handleClose() }} >
                        x
                    </CloseButton>
                    <div className="container-pesquisa">
                        <div className="titulo-pesquisa">
                            <span>Em uma escala de 0 a 10, qual é a probabilidade de você recomendar o +TOP para seus colegas de trabalho?</span>
                        </div>
                        <div className="container-notas flex-column ">
                            <ButtonGroup className="mb-2">
                                {notasConteudo.map((notasConteudos, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant={` nota${idx}`}
                                        name="radio"
                                        value={notasConteudos.Value}
                                        checked={radioValue === notasConteudos.Value}
                                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                                        className="mx-lg-2"
                                        onClick={(e) => { salvarNota(e.target.outerText) }}
                                    >
                                        {notasConteudos.numero}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                            <div className="d-flex justify-content-between mt-3">
                                <span>Pouco provável</span>
                                <span>Muito provável</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="text-end pe-5 pb-5 ">
                        <Button onClick={(e) => { salvarPesquisa() }}>
                            Salvar
                        </Button>
                    </div>
                </ModalDePesquisa>

            </Modal.Body>
        </Modal>
    )
}
