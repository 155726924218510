import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
export function AcessoEspelho() {
  const [erroEspelho, setErroEspelho] = useState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("t");
  var decoded = jwt_decode(token);
  useEffect(() => {
    api
      .get(rotasApi.obterDadosCadastrais + decoded.cpf, {
        headers: { 
        "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
        "SkipAuth": "true"},
      })
      .then((result) => {
        console.log("sucesso >", result.data);

        if (result.data.sucesso) {
          sessionStorage.removeItem("acessoEspelho");
          sessionStorage.setItem("acessoEspelho", true);
          sessionStorage.setItem("token", token);

          result.data.dadosCadastrais.foto =
            result.data.dadosCadastrais.fotoPerfil;

          result.data.dadosCadastrais.aceitePoliticasDePrivacidade = true;
          result.data.dadosCadastrais.aceitePontuacaoVendas = true;
          result.data.dadosCadastrais.aceiteRegulamento = true;

          sessionStorage.setItem(
            "UserInfo",
            JSON.stringify(result.data.dadosCadastrais)
          );
          window.location.href = "/home";
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error.response.data.mensagem);
        setErroEspelho(error.response.data.mensagem);
      });
  }, [decoded, token]);
  return <>{erroEspelho}</>;
}
