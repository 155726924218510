import styled from "styled-components";

export const Home = styled.div`
	background: #efeeed;
	.space-fale-conosco {
		height: 4rem;
		@media (max-width: 770px) {
			height: 8rem;
		}
	}
	.error-aviso{
		top: 80vh;
	}
`;

export const ListaAmigosConvidados = styled.div`
	text-align: center;
	background: white;
	padding-top: 7vh;
	padding-bottom: 7vh;
	.input-busca {
		width: 100%;
		max-width: 552px;
		border: 1px solid #c4c4c4;
		margin: 60px auto;
		border-radius: 5px;
		height: 45px;
		padding-left: 60px;
		color: #313131;
		font-size: 14px;

		&::placeholder {
			color: #c4c4c4;
		}
	}

	.btn-buscar {
		border: none;
		position: relative;
		left: 39px;
		background: #fff;
		font-size: 24px;
		top: 4px;
		color: #ccc;

		@media (max-width: 769px) {
			position: absolute;
			top: 65px;
			left: 15px;
		}
	}

	.form-busca {
		position: relative;
	}

	.container-lista-amigos{
		max-height: 393px;
		overflow: auto;
		.nome-user-card{
			display: block;
			width: 194px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.card-users{
			height: 35%;

			@media (max-width: 769px) {
				height: 24vh;
			}
		}
	}

	.btn-convida{
		background-color: var(--verde);
		border: none;
		position: absolute;
		width: 90%;
		left: 5%;
		bottom:5%;
		@media (max-width: 769px) {
			bottom:7%;
		}
	}
`;



export const TopoConvideUmAmigo = styled.div`
	margin-top: 50px;
	margin-bottom: 100px;
	color: #313131;

	.titulo {
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 32px;
	}

	.texto-como-fazer {
		font-size: 14px;

		.paragrafo {
			margin-bottom: 22px;
		}
	}

	.btn-ver-amigos {
		width: 100%;
		max-width: 261px;
		font-size: 14px;
		height: 45px;
		border-color: #e68220;
		color: #f58420;
		padding-top: 10px;
		margin-top: 15px;
		transition: 0.2s;
		&:hover {
			background: #f58420;
			color: #fff;
		}
	}

	.msg-copia {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: right;
	}
	.quadro-roxo {
		width: 100%;
		max-width: 635px;
		min-height: 347px;
		text-align: center;
		color: #fff;
		background: #7032d1;
		border: 1px solid #7032d1;
		padding: 30px 0;
		font-size: 14px;
		border-radius: 5px;

		.titulo-quadro {
			font-size: 16px;
			font-weight: bold;
			width: 100%;
			max-width: 288px;
			margin: 0 auto 28px auto;
		}

		.quadro-menor {
			margin: 28px 0;
			padding: 16px;
			border: 1px solid #fff;
			border-radius: 5px;
			width: 100%;
			max-width: 492px;
			margin: 0 auto;
			padding: 16px 23px;
			background: #7f47d6;
			display: block;
			@media (max-width: 769px) {
				span{
				display: block;
				width: 320px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			}
			
		}

		.btn-copiar,
		.btn-ver-amigos {
			width: 100%;
			max-width: 230px;
			font-size: 14px;
			height: 45px;
			border-color: #e68220;
			color: #fff;
			padding-top: 10px;
			margin-top: 15px;
			background: #f58420;
			transition: 0.2s;
			&:hover {
				background: none;
				color: #f58420;
			}
		}
		.btn-copiar-success{
			width: 100%;
			max-width: 230px;
			font-size: 14px;
			height: 45px;
			border-color: green;
			color: #fff;
			padding-top: 10px;
			margin-top: 15px;
			margin-right: 10px;
			margin-left: 10px;
			background: green;
			transition: 0.2s;
			&:hover {
				background: none;
				color: #f58420;
			}
		}
		.btn-copiar {
			color: #FFF;
			margin-right: 10px;
			margin-left: 10px;
			&:hover {
				color: #FFF;
			}
		}
	}
`;

export const RegrasConvideAmigo = styled.div`
	padding-top: 7vh;
	padding-bottom: 7vh;
`;
