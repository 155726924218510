import React, { useState } from "react";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import { rotasApi } from "../../services/rotasApi";
import { Form } from "react-bootstrap";
import api from "../../services/api";

export function GraficoGChart({ dadosdata, abaAtiva }) {

    const [graficoData, setGraficoData] = useState()
    const [anoFiltro, setAnoFiltro] = useState(2022)
    var today = new Date();
    var yyyy = today.getFullYear();


    const MeuDesempenhoDadosTreinamento = () => {
        if (abaAtiva === 2) {
            api
                .get(rotasApi.MeuDesempenhoDadosTreinamento + `?ano=${anoFiltro}`)
                .then((result) => {
                    preparaDados(result)
                }).catch((error) => {
                    console.log('error obter desempenho Treinamento')
                })
        } else { }
    };

    const preparaDados = (result) => {

        const getMonthName = (monthNumber) => {
            const monthNames = [
                'Jan', 'Fev', 'Mar', 'Abr',
                'Mai', 'Jun', 'Jul', 'Ago',
                'Set', 'Out', 'Nov', 'Dez'
            ];
            return monthNames[monthNumber - 1];
        }

        let data = result.data.abaTreinamentoDto.meses.map((item) => ({
            label: getMonthName(item.mes),
            value: item.pontosGanhos,
        }));

        let chartData = [['Mes', 'Pontos'], ...data.map(({ label, value, }) => [label, value])];
        result.data.abaTreinamentoDto.meses = chartData
        setGraficoData(result.data.abaTreinamentoDto)
    }

    useEffect(() => {
        MeuDesempenhoDadosTreinamento()
    }, [abaAtiva]);


    return <>
        {abaAtiva === 2 ?
            <div className="filtros">
                <div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { setAnoFiltro(e.target.value) }}>
                        <option value={yyyy}>{yyyy}</option>
                        <option value={yyyy - 1}>{yyyy - 1}</option>
                    </Form.Select>
                </div>
            </div> : ('')}
        {abaAtiva !== 2 ?

            < Chart
                chartType="Bar"
                width="97%"
                height="270px"
                data={dadosdata.meses}
                options={{
                    colors: '#9ED530'

                }}
                style={{ "marginLeft": "12px" }}
            />
            :
            (graficoData) ?

                <Chart
                    chartType="Line"
                    width="97%"
                    height="270px"
                    data={graficoData.meses}
                    options={{
                        colors: '#9ED530'
                    }}
                    style={{ "marginLeft": "12px" }}
                />
                : ('')
        }
    </>
};
export default GraficoGChart;
