import { useState, useEffect } from "react";
import {
  Row,
  Form,
  Container,
  Col,
  Button,
  ListGroup,
  Card,
  Placeholder,
} from "react-bootstrap";
import { NavBar } from "../../components/NavBar";
import {
  MeusDadosCampos,
  MenuChange,
  ButtoesNavMobile,
  PorcentagemDados,
  CamposBusca,
  CamposBuscaTreinamento,
  CamposBuscaVendas,
  ProgressoNivel,
} from "./styles";
import { Footer } from "../../components/Footer/index";
import { Formik } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import fileDownload from "js-file-download";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { ConquistasUsuarioEquipe } from "../../components/ConquistasUsuarioEquipe"
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

export function MinhaRevenda({ user }) {
  const [powerBiEmbedConfig, setPowerBiEmbedConfig] = useState({
    type: 'report',
    id: '',
    embedUrl: '',
    accessToken: '',
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  });

  let [passo, setPasso] = useState(1);
  // let [dadosCadastroUsuarios, setDadosCadastroUsuarios] = useState("");
  let [dadosCadastroPorcentagens, setDadosCadastroPorcentagens] = useState();
  // let [dadosTreinamentoUsuarios, setdadosTreinamentoUsuarios] = useState("");

  let [dadosCampanhas, setDadosCampanhas] = useState({
    totalLoja: 0,
    totalRevenda: 0,
  });

  let [dadosLojas, setDadosLojas] = useState([]);
  let [campanhas, setCampanhas] = useState([]);

  let [selectCampanha, setSelectCampanha] = useState(0);
  let [selectLoja, setSelectLoja] = useState(0);
  // const [data1, setData1] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
    fetchPowerBiConfig(isMobile);
  }, [isMobile]);
  
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Somente catálogo</Popover.Header>
      <Popover.Body>
        Participantes que não realizaram ao menos uma venda válida por mês na plataforma por 02 (dois) meses consecutivos.
      </Popover.Body>
    </Popover>
  );




  // useEffect(() => {
  //   fetchPowerBiConfig();
  // }, []);





  const isMobileDevice = () => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  };

  const fetchPowerBiConfig = () => {
    
    api.get(rotasApi.MinhaRevendaPowerBi, { params: { mobile: isMobile } })
      .then((result) => {
        setPowerBiEmbedConfig({
          ...powerBiEmbedConfig,
          id: result.data.retornoIntegracaoDash.embedReport[0].reportId,
          embedUrl: result.data.retornoIntegracaoDash.embedReport[0].embedUrl,
          accessToken: result.data.retornoIntegracaoDash.embedToken.token
        });
      })
      .catch((error) => {
        console.error('Erro ao buscar configurações do Power BI:', error);
      });
  };

  var meses = [
    { valMes: 1, mes: "Janeiro" },
    { valMes: 2, mes: "Fevereiro" },
    { valMes: 3, mes: "Março" },
    { valMes: 4, mes: "Abril" },
    { valMes: 5, mes: "Maio" },
    { valMes: 6, mes: "Junho" },
    { valMes: 7, mes: "Julho" },
    { valMes: 8, mes: "Agosto" },
    { valMes: 9, mes: "Setembro" },
    { valMes: 10, mes: "Outubro" },
    { valMes: 11, mes: "Novembro" },
    { valMes: 12, mes: "Dezembro" },
  ];


  let [
    dadosTreinamentoUsuariosPorcentagem,
    setdadosTreinamentoUsuariosPorcentagem,
  ] = useState("");

  //let [dadosVendasUsuarios, setdadosVendasUsuarios] = useState("");
  let [
    dadosVendasUsuariosPorcentagem,
    setdadosVendasUsuariosPorcentagem,
  ] = useState("");

  function mudarPasso(num) {
    setPasso((passo = num));
  }

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1); //January is 0!
  var yyyy = today.getFullYear();

  var anos = [];
  var anoInicio = 2022;
  for (anoInicio; anoInicio <= yyyy; anoInicio++) {
    anos.push({
      ano: anoInicio,
    });
  }

  if (yyyy === 2022) {
    meses = [
      { valMes: 4, mes: "Abril" },
      { valMes: 5, mes: "Maio" },
      { valMes: 6, mes: "Junho" },
      { valMes: 7, mes: "Julho" },
      { valMes: 8, mes: "Agosto" },
      { valMes: 9, mes: "Setembro" },
      { valMes: 10, mes: "Outubro" },
      { valMes: 11, mes: "Novembro" },
      { valMes: 12, mes: "Dezembro" },
    ];
  }


  const [selectAnoCadastro, setSelectAnoCadastro] = useState(yyyy);
  const [selectAnoTreinamentos, setSelectAnoTreinamentos] = useState(yyyy);
  const [selectAnoVendas, setSelectAnoVendas] = useState(yyyy);

  const [selectMesCadastro, setSelectMesCadastro] = useState(mm);
  const [selectMesTreinamentos, setSelectMesTreinamentos] = useState(mm);
  const [selectMesVendas, setSelectMesVendas] = useState(mm);

  const [selectLojaCadastro, setSelectLojaCadastro] = useState(0);
  const [selectLojaTreinamentos, setSelectLojaTreinamentos] = useState(0);
  const [selectLojaVendas, setSelectLojaVendas] = useState(0);
  const [mudaArquivoTipo, SetMudaArquivoTipo] = useState(false)

  let [loadingCadastro, setLoadingCadastro] = useState(false);
  let [loadingTreinamentos, setLoadingTreinamentos] = useState(false);
  let [loadingVendas, setLoadingVendas] = useState(false);
  let [loadingCampanhas, setLoadingCampanhas] = useState(false);

  let [loadingRelatorioCadastro, setLoadingRelatorioCadastro] = useState(false);
  let [loadingRelatorioTreinamentos, setLoadingRelatorioTreinamentos] = useState(false);
  let [loadingRelatorioVendas, setLoadingRelatorioVendas] = useState(false);
  let [loadingRelatorioCampanhas, setLoadingRelatorioCampanhas] = useState(false);

  today = yyyy + "-" + mm + "-" + dd;

  let [erroCadastro, setErroCadastro] = useState();
  let [erroTreinamentos, setErroTreinamentos] = useState();
  let [erroVendas, setErroVendas] = useState();
  let [erroCampanhas, setErroCampanhas] = useState();

  const ExtratoGeralFiltrar = (values) => {
    setLoadingCadastro(true);
    api
      .get(
        rotasApi.MinhaRevendaCadastro +
        `?LojaId=${values.loja}&Mes=${values.mes}&Ano=${values.ano}`
      )
      .then((result) => {
        setLoadingCadastro(false);
        console.log("MINHA REVENDA>> ", result.data);
        //     setDadosCadastroUsuarios(result.data.cadastro.usuarios);
        setDadosCadastroPorcentagens(result.data);
        setErroCadastro();
      })
      .catch((error) => {
        setLoadingCadastro(false);
        //    setDadosCadastroUsuarios();
        setDadosCadastroPorcentagens();
        console.log("error > ", error.response);
        if (error.response.status === 400) {
          setErroCadastro("Não foi possível exibir os dados");
        } else {
          setErroCadastro(error.response.data.mensagem);
        }
      });
  };
  // const childToParent = (childdata) => {
  //   setData1(childdata);
  // };


  const RelatorioExportarCadastro = () => {
    setLoadingRelatorioCadastro(true);

    let rota = mudaArquivoTipo ? rotasApi.RelatorioExportarCadastroPDF : rotasApi.RelatorioExportarCadastro

    api
      .get(
        rota +
        `?lojaId=${selectLojaCadastro}&mes=${selectMesCadastro}&ano=${selectAnoCadastro}`,
        {
          responseType: "blob", // the data type of the server response. It can be 'arraybuffer', 'blob', 'document', 'JSON', 'text', 'stream'. The default is' JSON '
        }
      )
      .then((result) => {
        setLoadingRelatorioCadastro(false);
        mudaArquivoTipo ? fileDownload(result.data, "cadastro.pdf") : fileDownload(result.data, "cadastro.xlsx");
      })
      .catch((error) => {
        setLoadingRelatorioCadastro(false);
      });
  };

  const FiltrarRevendaTreinamentos = (values) => {
    setLoadingTreinamentos(true);
    api
      .get(
        rotasApi.MinhaRevendaTreinamento +
        `?LojaId=${values.loja}&Mes=${values.mes}&Ano=${values.ano}`
      )
      .then((result) => {
        setLoadingTreinamentos(false);
        // console.log("MINHA REVENDA>> ", result.data);
        //   setdadosTreinamentoUsuarios(result.data.vendas.treinamentos);
        setdadosTreinamentoUsuariosPorcentagem(result.data);
        setErroTreinamentos();
        console.log(result.data);

        // console.log(dadosTreinamentoUsuarios)
        // console.log(result.data.vendas.treinamentos)
      })
      .catch((error) => {
        setLoadingTreinamentos(false);
        //    setdadosTreinamentoUsuarios();
        setdadosTreinamentoUsuariosPorcentagem();
        if (error.response.status === 400) {
          setErroTreinamentos("Não foi possível exibir os dados");
        } else {
          setErroTreinamentos(error.response.data.mensagem);
        }
      });
  };

  const RelatorioExportarTreinamentos = () => {

    setLoadingRelatorioTreinamentos(true);

    console.log(rotasApi)

    let rota = mudaArquivoTipo ? rotasApi.RelatorioExportarTreinamentosMinhaRevendaPDF : rotasApi.RelatorioExportarTreinamentosMinhaRevenda

    api
      .get(
        rota +
        `?lojaId=${selectLojaTreinamentos}&mes=${selectMesTreinamentos}&ano=${selectAnoTreinamentos}`,
        {
          responseType: "blob", // the data type of the server response. It can be 'arraybuffer', 'blob', 'document', 'JSON', 'text', 'stream'. The default is' JSON '
        }
      )
      .then((result) => {
        setLoadingRelatorioTreinamentos(false);
        console.log("result >>", result);
        mudaArquivoTipo ? fileDownload(result.data, "treinamento.pdf") : fileDownload(result.data, "treinamento.xlsx");
      })
      .catch((error) => {
        setLoadingRelatorioTreinamentos(false);
      });
  };

  const FiltrarRevendaVendas = (values) => {
    setLoadingVendas(true);
    api
      .get(
        rotasApi.MinhaRevendaVendas +
        `?LojaId=${values.loja}&Mes=${values.mes}&Ano=${values.ano}`
      )
      .then((result) => {
        setLoadingVendas(false);
        setErroVendas();
        //    setdadosVendasUsuarios(result.data.vendas.usuarios);
        setdadosVendasUsuariosPorcentagem(result.data.vendas);
        // console.log(result.data)
        console.log(result.data.vendas.usuarios);
      })
      .catch((error) => {
        setLoadingVendas(false);
        //  setdadosVendasUsuarios();
        setdadosVendasUsuariosPorcentagem();
        if (error.response.status === 400) {
          setErroVendas("Não foi possível exibir os dados");
        } else {
          setErroVendas(error.response.data.mensagem);
        }
      });
  };

  const RelatorioExportarVendas = () => {
    setLoadingRelatorioVendas(true);
    let rota = mudaArquivoTipo ? rotasApi.RelatorioExportarVendasMinhaRevendaPDF : rotasApi.RelatorioExportarVendasMinhaRevenda

    api
      .get(
        rota +
        `?lojaId=${selectLojaVendas}&mes=${selectMesVendas}&ano=${selectAnoVendas}`,
        {
          responseType: "blob", // the data type of the server response. It can be 'arraybuffer', 'blob', 'document', 'JSON', 'text', 'stream'. The default is' JSON '
        }
      )
      .then((result) => {
        setLoadingRelatorioVendas(false);
        mudaArquivoTipo ? fileDownload(result.data, "vendas.pdf") : fileDownload(result.data, "vendas.xlsx");
      })
      .catch((error) => {
        setLoadingRelatorioVendas(false);
      });
  };

  const FiltrarCampanhas = (values) => {
    setLoadingCampanhas(true);
    console.log("filtrar campanhas", values);

    api
      .get(
        rotasApi.MinhaRevendaCampanhas +
        `?LojaId=${selectLoja}&CampanhaId=${selectCampanha}`
      )
      .then((result) => {
        setLoadingCampanhas(false);
        setErroCampanhas();
        setDadosCampanhas(result.data.vendas);
        console.log("result campanhas", result.data.vendas);
      })
      .catch((error) => {
        setLoadingCampanhas(false);
        if (error.response.status === 400) {
          setErroCampanhas("Não foi possível exibir os dados");
        } else {
          setErroCampanhas(error.response.data.mensagem);
        }
      });
  };

  const RelatorioExportarCampanhas = () => {
    setLoadingRelatorioCampanhas(true);
    let rota = mudaArquivoTipo ? rotasApi.RelatorioExportarCampanhasMinhaRevendaPDF : rotasApi.RelatorioExportarCampanhasMinhaRevenda

    api
      .get(
        rota +
        `?lojaId=${selectLoja}&campanhaId=${selectCampanha}`,
        {
          responseType: "blob", // the data type of the server response. It can be 'arraybuffer', 'blob', 'document', 'JSON', 'text', 'stream'. The default is' JSON '
        }
      )
      .then((result) => {
        setLoadingRelatorioCampanhas(false);
        mudaArquivoTipo ? fileDownload(result.data, "campanhas.pdf") : fileDownload(result.data, "campanhas.xlsx");
      })
      .catch((error) => {
        setLoadingRelatorioCampanhas(false);
      });
  };


  useEffect(() => {
    let values = {
      loja: 0,
      mes: mm,
      ano: yyyy,
    };
    ExtratoGeralFiltrar(values);
    FiltrarRevendaTreinamentos(values);
    FiltrarRevendaVendas(values);

    api
      .get(rotasApi.ListarLojasDaRede + `/${user.lojas[0].redeId}`)
      .then((result) => {
        setDadosLojas(result.data);

      })
      .catch((error) => {
        throw new Error(error);
      });

    api
      .get(rotasApi.MinhaRevendaCampanhasDaRevenda)
      .then((result) => {
        setCampanhas(result.data.vendas.reverse());

      })
      .catch((error) => {
        throw new Error(error);

      });


  }, [mm, yyyy, user]);

  const exportPlaceholder = <Placeholder
    className="mt-4"
    as="p"
    animation="glow"
  >
    <Placeholder
      xs={12}
      bg="dark"
      size="lg"
      className="d-flex justify-content-center align-items-center rounded p-1 btn-height-45 width-export-btn btn-mobile-export"
    >
      <p className="text-center m-0 text-white">
        Exportando...
      </p>
    </Placeholder>
  </Placeholder>

  const porcentagensPlaceholder = <Placeholder
    className="w-75"
    as="h1"
    animation="glow"
  >
    <Placeholder
      xs={12}
      bg="secondary"
      size="lg"
      className="rounded"
    />
  </Placeholder>

  return (
    <>

      <div className="minha-equipe-blackfriday">
        {/* <Row>
          <Col className="d-flex justify-content-center">
            <Fade in={modalAviso}>
              <Card
                className="text-center p-3 m-2 z-index-top position-absolute"
                bg={modalType}
                text="white"
              >
                <Card.Body>
                  <Card.Title as="h4">{msgModal}</Card.Title>
                  <Card.Text></Card.Text>
                </Card.Body>
              </Card>
            </Fade>
          </Col>
        </Row> */}

        <NavBar user={user} />
        <MeusDadosCampos className="minha-equipe-blackfriday">
          <Container className="pb-5">
            {!powerBiEmbedConfig && user.cargo.descricao !== 'GESTOR DA INFORMACAO' ?
              <Row className="pt-3 d-flex bd-highlight">
                <Col>
                  <Breadcrumb>
                    <Link className="breadcrumb-item" to="/home">
                      Home
                    </Link>
                    <Breadcrumb.Item active>Minha revenda</Breadcrumb.Item>

                    {passo === 1 ? (
                      <Breadcrumb.Item active>Cadastro</Breadcrumb.Item>
                    ) : passo === 2 ? (
                      <Breadcrumb.Item active>Treinamentos</Breadcrumb.Item>
                    ) : passo === 3 ? (
                      <Breadcrumb.Item active>Vendas</Breadcrumb.Item>
                    ) : passo === 4 ? (
                      <Breadcrumb.Item active>Campanhas</Breadcrumb.Item>
                    ) : passo === 5 ? (
                      <Breadcrumb.Item active>Conquistas</Breadcrumb.Item>
                    ) : (
                      ""
                    )}
                  </Breadcrumb>

                  <hr className="mb-5" />
                  <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                      <h2 className="text-center mt-3 mb-4 titulo-meu-perfil">
                        Minha revenda
                      </h2>
                      <div className="d-flex justify-content-center">
                        <ButtoesNavMobile>
                          <Row className="pe-1 rowCarrossel">
                            <Col className="pe-1">
                              <div
                                onClick={() => mudarPasso(1)}
                                className={passo === 1 ? "box active" : "box"}
                              >
                                Cadastro
                              </div>
                            </Col>
                            <Col className="pe-1">
                              <div
                                onClick={() => mudarPasso(2)}
                                className={passo === 2 ? "box active" : "box"}
                              >
                                Treinamentos
                              </div>
                            </Col>
                            <Col className="pe-1">
                              <div
                                onClick={() => mudarPasso(3)}
                                className={passo === 3 ? "box active" : "box"}
                              >
                                Vendas
                              </div>
                            </Col>
                            <Col className="pe-1">
                              <div
                                onClick={() => mudarPasso(4)}
                                className={passo === 4 ? "box active" : "box"}
                              >
                                Campanhas
                              </div>
                            </Col>
                            <Col className="pe-1">
                              <div
                                onClick={() => mudarPasso(5)}
                                className={passo === 5 ? "box active" : "box"}
                              >
                                Conquistas
                              </div>
                            </Col>
                          </Row>
                        </ButtoesNavMobile>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} className="bd-highligh">
                      <MenuChange>
                        <ListGroup as="ul">
                          <ListGroup.Item as="li" className="pt-4">
                            <h5>Minha revenda</h5>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            onClick={() => mudarPasso(1)}
                            className={passo === 1 ? "active" : ""}
                          >
                            Cadastro
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            onClick={() => mudarPasso(2)}
                            className={passo === 2 ? "active" : ""}
                          >
                            Treinamentos
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            onClick={() => mudarPasso(3)}
                            className={passo === 3 ? "active" : ""}
                          >
                            Vendas
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            onClick={() => mudarPasso(4)}
                            className={passo === 4 ? "active" : ""}
                          >
                            Campanhas
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            onClick={() => mudarPasso(5)}
                            className={passo === 5 ? "active" : ""}
                          >
                            Conquistas
                          </ListGroup.Item>
                        </ListGroup>
                      </MenuChange>
                    </Col>

                    <Col sm={12} lg={10}>
                      <Row>
                        <Col className="position-relative container-img-prev mx-2">
                          {passo === 1 ? (
                            <div>
                              <h4>Cadastro</h4>

                              <CamposBusca>
                                <Formik
                                  initialValues={{
                                    loja: 0,
                                    mes: mm,
                                    ano: yyyy,
                                  }}
                                  onSubmit={(values) =>
                                    ExtratoGeralFiltrar(values)
                                  }
                                >
                                  {({
                                    values,
                                    handleChange,
                                    errors,
                                    isValid,
                                    handleSubmit,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <Form
                                      noValidate
                                      onSubmit={handleSubmit}
                                      className="form-flex align-items-center"
                                    >
                                      <Form.Group className="">
                                        <Form.Label>Filtrar busca por</Form.Label>
                                        <Form.Select
                                          name="loja"
                                          placeholder="Selecionar loja"
                                          value={values.loja}
                                          onChange={(e) => {
                                            setFieldValue("loja", e.target.value);
                                            setSelectLojaCadastro(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-selec-revendas"
                                        >
                                          <option value="0">
                                            Selecionar loja
                                          </option>
                                          {dadosLojas.map(
                                            ({ id, nome, cnpj, cidade }) => (
                                              <option value={id} key={id}>
                                                {nome} - {cidade} - {cnpj}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                      {selectAnoCadastro === yyyy ? (
                                        <Form.Select
                                          name="mes"
                                          value={values.mes}
                                          onChange={(e) => {
                                            setFieldValue("mes", e.target.value);
                                            setSelectMesCadastro(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-mes-ano"
                                        >
                                          {meses.map(
                                            ({ valMes, mes }, index) =>
                                              valMes <= mm && (
                                                <option
                                                  value={valMes}
                                                  key={index}
                                                >
                                                  {mes}
                                                </option>
                                              )
                                          )}
                                        </Form.Select>
                                      ) : (
                                        <Form.Select
                                          name="mes"
                                          value={values.mes}
                                          onChange={(e) => {
                                            setFieldValue("mes", e.target.value);
                                            setSelectMesCadastro(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-mes-ano"
                                        >
                                          {meses.map(({ valMes, mes }, index) => (
                                            <option value={valMes} key={index}>
                                              {mes}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      )}

                                      <Form.Select
                                        name="ano"
                                        value={values.ano}
                                        onChange={(e) => {
                                          setFieldValue("ano", e.target.value);
                                          setSelectAnoCadastro(
                                            Number(e.target.value)
                                          );
                                        }}
                                        className="input-mes-ano"
                                      >
                                        {anos.map(({ ano }, index) => (
                                          <option value={ano} key={index}>
                                            {ano}
                                          </option>
                                        ))}
                                      </Form.Select>

                                      <Button
                                        type="submit"
                                        className="btn-buscar btn-laranja"
                                      >
                                        Filtrar
                                      </Button>
                                    </Form>
                                  )}
                                </Formik>
                              </CamposBusca>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-center">
                                  <p className="mt-3">{erroCadastro}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-flex justify-content-center position-relative mb-3">

                                  {loadingRelatorioCadastro ? (
                                    exportPlaceholder
                                  ) : (
                                    <>
                                      <Button
                                        onClick={RelatorioExportarCadastro}
                                        type="submit"
                                        className="btn btn-laranja d-lg-block d-none btn-height-45 btn btn-primary width-export-btn btn-desk-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>

                                      <Button
                                        onClick={RelatorioExportarCadastro}
                                        type="submit"
                                        className="btn btn-laranja d-lg-none w-100 btn-height-45 btn-mobile-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>

                                    </>
                                  )}

                                  <Form className="d-flex flex-column formato-arquivo">
                                    <span>Selecione o formato do arquivo que deseja exportar.</span>
                                    <div className="d-flex btn-container">
                                      <Form.Check

                                        label="PDF"
                                        name="group1"
                                        type='radio'
                                        defaultChecked={mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      /><Form.Check

                                        label="Excel"
                                        name="group1"
                                        type='radio'
                                        defaultChecked={!mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      />
                                    </div>
                                  </Form>


                                </Col>
                              </Row>
                              <PorcentagemDados>
                                <div className="container-porcentagem-dados">
                                  <Row>
                                    <Col lg={4}>
                                      <div className="item-aceites-ativos">
                                        <span>Ativo</span>
                                        <ProgressoNivel className="rounded p-0">
                                          <div className="nivel-atual-user border-0">
                                            <div className="eight columns progress-container">
                                              <div className="progressBarcontainer">
                                                <div
                                                  className="progressBarValue"
                                                  style={{
                                                    width: `${dadosCadastroPorcentagens
                                                      ? dadosCadastroPorcentagens
                                                        .cadastro.totalAtivo +
                                                      "%"
                                                      : "0%"
                                                      }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </ProgressoNivel>
                                        <div className="d-flex justify-content-center align-items-center h-75">
                                          {loadingCadastro ? (
                                            porcentagensPlaceholder
                                          ) : (
                                            <h1>
                                              {dadosCadastroPorcentagens
                                                ? dadosCadastroPorcentagens
                                                  .cadastro.totalAtivo + "%"
                                                : "0%"}
                                            </h1>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={8}>
                                      <Row>
                                        <Col>
                                          <div className="item-nao-aceite">
                                            <span>Pré-cadastrados</span>
                                            <ProgressoNivel className="rounded p-0">
                                              <div className="nivel-atual-user border-0">
                                                <div className="eight columns progress-container">
                                                  <div className="progressBarcontainer">
                                                    <div
                                                      className="progressBarValue"
                                                      style={{
                                                        width: `${dadosCadastroPorcentagens
                                                          ? dadosCadastroPorcentagens
                                                            .cadastro
                                                            .totalPreCadastro +
                                                          "%"
                                                          : "0%"
                                                          }`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </ProgressoNivel>
                                            <div className="d-flex justify-content-center align-items-center">
                                              {loadingCadastro ? (
                                                porcentagensPlaceholder
                                              ) : (
                                                <h1>
                                                  {dadosCadastroPorcentagens
                                                    ? dadosCadastroPorcentagens
                                                      .cadastro
                                                      .totalPreCadastro + "%"
                                                    : "0%"}
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col>
                                          <div className="item-aceites-gerais position-relative">
                                            <span>Somente catálogo </span>
                                            <OverlayTrigger
                                              placement="bottom-end"
                                              overlay={popover}
                                            >
                                              <i class="bi bi-question-circle" style={{ position: "absolute", top: "13px", right: "54px", }}></i>
                                            </OverlayTrigger>
                                            <ProgressoNivel className="rounded p-0">
                                              <div className="nivel-atual-user border-0">
                                                <div className="eight columns progress-container">
                                                  <div className="progressBarcontainer">
                                                    <div
                                                      className="progressBarValue"
                                                      style={{
                                                        width: `${dadosCadastroPorcentagens
                                                          ? dadosCadastroPorcentagens
                                                            .cadastro
                                                            .totalSomenteCatalogo +
                                                          "%"
                                                          : "0%"
                                                          }`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </ProgressoNivel>
                                            <div className="d-flex justify-content-center align-items-center">
                                              {loadingCadastro ? (
                                                porcentagensPlaceholder
                                              ) : (
                                                <h1>
                                                  {dadosCadastroPorcentagens
                                                    ? dadosCadastroPorcentagens
                                                      .cadastro
                                                      .totalSomenteCatalogo +
                                                    "%"
                                                    : "0%"}
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="item-aceites">
                                            <span>Pendente envio Documento</span>
                                            <ProgressoNivel className="rounded p-0">
                                              <div className="nivel-atual-user border-0">
                                                <div className="eight columns progress-container">
                                                  <div className="progressBarcontainer">
                                                    <div
                                                      className="progressBarValue"
                                                      style={{
                                                        width: `${dadosCadastroPorcentagens
                                                          ? dadosCadastroPorcentagens
                                                            .cadastro
                                                            .totalPendenteEnvioDeDocumento +
                                                          "%"
                                                          : "0%"
                                                          }`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </ProgressoNivel>
                                            <div className="d-flex justify-content-center align-items-center">
                                              {loadingCadastro ? (
                                                porcentagensPlaceholder
                                              ) : (
                                                <h1>
                                                  {dadosCadastroPorcentagens
                                                    ? dadosCadastroPorcentagens
                                                      .cadastro
                                                      .totalPendenteEnvioDeDocumento +
                                                    "%"
                                                    : "0%"}
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col>
                                          <div className="item-aceites">
                                            <span>Moderação de cadastro</span>
                                            <ProgressoNivel className="rounded p-0">
                                              <div className="nivel-atual-user border-0">
                                                <div className="eight columns progress-container">
                                                  <div className="progressBarcontainer">
                                                    <div
                                                      className="progressBarValue"
                                                      style={{
                                                        width: `${dadosCadastroPorcentagens
                                                          ? dadosCadastroPorcentagens
                                                            .cadastro
                                                            .totalModeracaoDeCadastro +
                                                          "%"
                                                          : "0%"
                                                          }`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </ProgressoNivel>
                                            <div className="d-flex justify-content-center align-items-center">
                                              {loadingCadastro ? (
                                                porcentagensPlaceholder
                                              ) : (
                                                <h1>
                                                  {dadosCadastroPorcentagens
                                                    ? dadosCadastroPorcentagens
                                                      .cadastro
                                                      .totalModeracaoDeCadastro +
                                                    "%"
                                                    : "0%"}
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </PorcentagemDados>



                            </div>
                          ) : passo === 2 ? (
                            <div>
                              <h4>Treinamentos</h4>
                              <CamposBuscaTreinamento>
                                <Formik
                                  initialValues={{
                                    loja: 0,
                                    mes: mm,
                                    ano: yyyy,
                                  }}
                                  onSubmit={(values) =>
                                    FiltrarRevendaTreinamentos(values)
                                  }
                                >
                                  {({
                                    values,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                  }) => (
                                    <Form
                                      noValidate
                                      onSubmit={handleSubmit}
                                      className="form-flex"
                                    >
                                      <Form.Group className="">
                                        <Form.Label>Filtrar busca por</Form.Label>
                                        <Form.Select
                                          name="loja"
                                          placeholder="Selecionar loja"
                                          value={values.loja}
                                          onChange={(e) => {
                                            setFieldValue("loja", e.target.value);
                                            setSelectLojaTreinamentos(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-selec-revendas"
                                        >
                                          <option value="0">
                                            Selecionar loja
                                          </option>
                                          {dadosLojas.map(
                                            ({ id, nome, cnpj, cidade }) => (
                                              <option value={id} key={id}>
                                                {nome} - {cidade} - {cnpj}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                      {selectAnoTreinamentos === yyyy ? (
                                        <Form.Select
                                          name="mes"
                                          value={values.mes}
                                          onChange={(e) => {
                                            setFieldValue("mes", e.target.value);
                                            setSelectMesTreinamentos(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-mes-ano"
                                        >
                                          {meses.map(
                                            ({ valMes, mes }, index) =>
                                              valMes <= mm && (
                                                <option
                                                  value={valMes}
                                                  key={index}
                                                >
                                                  {mes}
                                                </option>
                                              )
                                          )}
                                        </Form.Select>
                                      ) : (
                                        <Form.Select
                                          name="mes"
                                          value={values.mes}
                                          onChange={(e) => {
                                            setFieldValue("mes", e.target.value);
                                            setSelectMesTreinamentos(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-mes-ano"
                                        >
                                          {meses.map(({ valMes, mes }, index) => (
                                            <option value={valMes} key={index}>
                                              {mes}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      )}

                                      <Form.Select
                                        name="ano"
                                        value={values.ano}
                                        onChange={(e) => {
                                          setFieldValue("ano", e.target.value);
                                          setSelectAnoTreinamentos(
                                            Number(e.target.value)
                                          );
                                        }}
                                        className="input-mes-ano"
                                      >
                                        {anos.map(({ ano }, index) => (
                                          <option value={ano} key={index}>
                                            {ano}
                                          </option>
                                        ))}
                                      </Form.Select>

                                      <Button
                                        type="submit"
                                        className="btn-buscar btn-laranja"
                                      >
                                        Filtrar
                                      </Button>
                                    </Form>
                                  )}
                                </Formik>
                              </CamposBuscaTreinamento>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-center">
                                  <p className="mt-3">{erroTreinamentos}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-flex justify-content-center position-relative mb-3">
                                  {loadingRelatorioTreinamentos ? (
                                    exportPlaceholder
                                  ) : (
                                    <>
                                      <Button
                                        onClick={RelatorioExportarTreinamentos}
                                        type="submit"
                                        className="btn btn-laranja d-lg-block d-none mt-4 btn-height-45 btn btn-primary width-export-btn btn-desk-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>
                                      <Button
                                        onClick={RelatorioExportarTreinamentos}
                                        type="submit"
                                        className="btn btn-laranja d-lg-none w-100 btn-height-45 btn-mobile-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>
                                    </>
                                  )}
                                  <Form className="d-flex flex-column formato-arquivo">
                                    <span>Selecione o formato do arquivo que deseja exportar.</span>
                                    <div className="d-flex btn-container">
                                      <Form.Check
                                        label="PDF"
                                        name="group1"
                                        type='radio'
                                        checked={mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      /><Form.Check
                                        label="Excel"
                                        name="group1"
                                        type='radio'
                                        checked={!mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      />
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                              <PorcentagemDados>
                                <div className="container-porcentagem-dados">
                                  <Row>
                                    <Col lg={4} sm={12}>
                                      <div className="item-aceites m-0">
                                        <span>Realizaram 1 treinamento</span>
                                        <ProgressoNivel className="rounded p-0">
                                          <div className="nivel-atual-user border-0">
                                            <div className="eight columns progress-container">
                                              <div className="progressBarcontainer">
                                                <div
                                                  className="progressBarValue"
                                                  style={{
                                                    width: `${dadosTreinamentoUsuariosPorcentagem
                                                      ? dadosTreinamentoUsuariosPorcentagem
                                                        .vendas
                                                        .treinamentoUmFinalizado +
                                                      "%"
                                                      : "0%"
                                                      }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </ProgressoNivel>
                                        <div className="d-flex justify-content-center align-items-center">
                                          {loadingTreinamentos ? (
                                            porcentagensPlaceholder
                                          ) : (
                                            <h1>
                                              {dadosTreinamentoUsuariosPorcentagem
                                                ? dadosTreinamentoUsuariosPorcentagem
                                                  .vendas.treinamentoUmFinalizado +
                                                "%"
                                                : "0%"}
                                            </h1>)}

                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={4} sm={12}>
                                      <div className="item-nao-aceite">
                                        <span>Realizaram 2 treinamentos</span>
                                        <ProgressoNivel className="rounded p-0">
                                          <div className="nivel-atual-user border-0">
                                            <div className="eight columns progress-container">
                                              <div className="progressBarcontainer">
                                                <div
                                                  className="progressBarValue"
                                                  style={{
                                                    width: `${dadosTreinamentoUsuariosPorcentagem
                                                      ? dadosTreinamentoUsuariosPorcentagem
                                                        .vendas
                                                        .treinamentoDoisFinalizado +
                                                      "%"
                                                      : "0%"
                                                      }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </ProgressoNivel>
                                        <div className="d-flex justify-content-center align-items-center">
                                          {loadingTreinamentos ? (
                                            porcentagensPlaceholder
                                          ) : (
                                            <h1>
                                              {dadosTreinamentoUsuariosPorcentagem
                                                ? dadosTreinamentoUsuariosPorcentagem
                                                  .vendas
                                                  .treinamentoDoisFinalizado + "%"
                                                : "0%"}
                                            </h1>)}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={4} sm={12}>
                                      <div className="item-aceites-gerais">
                                        <span>Não realizaram treinamentos</span>
                                        <ProgressoNivel className="rounded p-0">
                                          <div className="nivel-atual-user border-0">
                                            <div className="eight columns progress-container">
                                              <div className="progressBarcontainer">
                                                <div
                                                  className="progressBarValue"
                                                  style={{
                                                    width: `${dadosTreinamentoUsuariosPorcentagem
                                                      ? dadosTreinamentoUsuariosPorcentagem
                                                        .vendas
                                                        .treinamentoNaoFinalizado +
                                                      "%"
                                                      : "0%"
                                                      }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </ProgressoNivel>
                                        <div className="d-flex justify-content-center align-items-center">
                                          {loadingTreinamentos ? (
                                            porcentagensPlaceholder
                                          ) : (
                                            <h1>
                                              {dadosTreinamentoUsuariosPorcentagem
                                                ? dadosTreinamentoUsuariosPorcentagem
                                                  .vendas.treinamentoNaoFinalizado +
                                                "%"
                                                : "0%"}
                                            </h1>)}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </PorcentagemDados>

                            </div>
                          ) : passo === 3 ? (
                            <div>
                              <h4>Vendas</h4>
                              <CamposBuscaVendas>
                                <Formik
                                  initialValues={{
                                    loja: 0,
                                    mes: mm,
                                    ano: yyyy,
                                  }}
                                  onSubmit={(values) =>
                                    FiltrarRevendaVendas(values)
                                  }
                                >
                                  {({
                                    values,
                                    handleChange,
                                    errors,
                                    isValid,
                                    handleSubmit,
                                    setFieldValue,
                                    dirty,
                                  }) => (
                                    <Form
                                      noValidate
                                      onSubmit={handleSubmit}
                                      className="form-flex"
                                    >
                                      <Form.Group className="">
                                        <Form.Label>Filtrar busca por</Form.Label>
                                        <Form.Select
                                          name="loja"
                                          placeholder="Selecionar loja"
                                          value={values.loja}
                                          onChange={(e) => {
                                            setFieldValue("loja", e.target.value);
                                            setSelectLojaVendas(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-selec-revendas"
                                        >
                                          <option value="0">
                                            Selecionar loja
                                          </option>
                                          {dadosLojas.map(
                                            ({ id, nome, cnpj, cidade }) => (
                                              <option value={id} key={id}>
                                                {nome} - {cidade} - {cnpj}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                      {selectAnoVendas === yyyy ? (
                                        <Form.Select
                                          name="mes"
                                          value={values.mes}
                                          onChange={(e) => {
                                            setFieldValue("mes", e.target.value);
                                            setSelectMesVendas(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-mes-ano"
                                        >
                                          {meses.map(
                                            ({ valMes, mes }, index) =>
                                              valMes < mm && (
                                                <option
                                                  value={valMes}
                                                  key={index}
                                                >
                                                  {mes}
                                                </option>
                                              )
                                          )}
                                        </Form.Select>
                                      ) : (
                                        <Form.Select
                                          name="mes"
                                          value={values.mes}
                                          onChange={(e) => {
                                            setFieldValue("mes", e.target.value);
                                            setSelectMesVendas(
                                              Number(e.target.value)
                                            );
                                          }}
                                          className="input-mes-ano"
                                        >
                                          {meses.map(({ valMes, mes }, index) => (
                                            <option value={valMes} key={index}>
                                              {mes}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      )}

                                      <Form.Select
                                        name="ano"
                                        value={values.ano}
                                        onChange={(e) => {
                                          setFieldValue("ano", e.target.value);
                                          setSelectAnoVendas(
                                            Number(e.target.value)
                                          );
                                        }}
                                        className="input-mes-ano"
                                      >
                                        {anos.map(({ ano }, index) => (
                                          <option value={ano} key={index}>
                                            {ano}
                                          </option>
                                        ))}
                                      </Form.Select>

                                      <Button
                                        type="submit"
                                        className="btn-buscar btn-laranja"
                                      >
                                        Filtrar
                                      </Button>
                                    </Form>
                                  )}
                                </Formik>
                              </CamposBuscaVendas>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-center">
                                  <p className="mt-3">{erroVendas}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-flex justify-content-center position-relative mb-3">
                                  {loadingRelatorioVendas ? (
                                    exportPlaceholder
                                  ) : (
                                    <>
                                      <Button
                                        onClick={RelatorioExportarVendas}
                                        type="submit"
                                        className="btn btn-laranja d-lg-block d-none mt-4 btn-height-45 btn btn-primary width-export-btn btn-desk-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>
                                      <Button
                                        onClick={RelatorioExportarVendas}
                                        type="submit"
                                        className="btn btn-laranja d-lg-none w-100  btn-height-45 btn-mobile-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>
                                    </>
                                  )}
                                  <Form className="d-flex flex-column formato-arquivo">
                                    <span>Selecione o formato do arquivo que deseja exportar.</span>
                                    <div className="d-flex btn-container">
                                      <Form.Check
                                        label="PDF"
                                        name="group1"
                                        type='radio'
                                        checked={mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      /><Form.Check
                                        label="Excel"
                                        name="group1"
                                        type='radio'
                                        checked={!mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      />
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                              <PorcentagemDados>
                                <div className="container-porcentagem-dados">
                                  <Row>
                                    <Col lg={6} sm={12}>
                                      <div className="item-aceites m-0">
                                        <span>Produtos participantes</span>
                                        <ProgressoNivel className="rounded p-0">
                                          <div className="nivel-atual-user border-0">
                                            <div className="eight columns progress-container">
                                              <div className="progressBarcontainer">
                                                <div
                                                  className="progressBarValue"
                                                  style={{
                                                    width: `${dadosVendasUsuariosPorcentagem
                                                      ? dadosVendasUsuariosPorcentagem.totalProdutoParticipanteDaRevenda +
                                                      "%"
                                                      : "0%"
                                                      }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </ProgressoNivel>
                                        <div className="d-flex justify-content-center align-items-center">
                                          {loadingVendas ? (
                                            porcentagensPlaceholder
                                          ) : (
                                            <h1>
                                              {dadosVendasUsuariosPorcentagem
                                                ? dadosVendasUsuariosPorcentagem.totalProdutoParticipanteDaRevenda +
                                                "%"
                                                : "0%"}
                                            </h1>)}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={6} sm={12}>
                                      <div className="item-nao-aceite">
                                        <span>Produtos Supertops</span>
                                        <ProgressoNivel className="rounded p-0">
                                          <div className="nivel-atual-user border-0">
                                            <div className="eight columns progress-container">
                                              <div className="progressBarcontainer">
                                                <div
                                                  className="progressBarValue"
                                                  style={{
                                                    width: `${dadosVendasUsuariosPorcentagem
                                                      ? dadosVendasUsuariosPorcentagem.totalProdutoSupertopDaRevenda +
                                                      "%"
                                                      : "0%"
                                                      }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </ProgressoNivel>
                                        <div className="d-flex justify-content-center align-items-center">
                                          {loadingVendas ? (
                                            porcentagensPlaceholder
                                          ) : (
                                            <h1>
                                              {dadosVendasUsuariosPorcentagem
                                                ? dadosVendasUsuariosPorcentagem.totalProdutoSupertopDaRevenda +
                                                "%"
                                                : "0%"}
                                            </h1>)}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </PorcentagemDados>

                            </div>
                          ) : passo === 4 ? (
                            <>
                              <h4>Campanhas</h4>
                              <Row>
                                <Col className="d-flex justify-content-center position-relative">
                                  {loadingRelatorioCampanhas ? (
                                    exportPlaceholder
                                  ) : (
                                    <>
                                      <Button
                                        onClick={RelatorioExportarCampanhas}
                                        type="submit"
                                        className="btn btn-laranja d-lg-block d-none mt-4 btn-height-45 btn btn-primary width-export-btn btn-desk-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>
                                      <Button
                                        onClick={RelatorioExportarCampanhas}
                                        type="submit"
                                        className="btn btn-laranja d-lg-none w-100  btn-height-45 btn-mobile-export"
                                      >
                                        Exportar dados
                                        <span className="aviso-senha">Obs: O relatório pedirá uma senha que é sua data de aniversário, no formato DDMMAA

                                          Exemplo: 15/03/1982 = 150382 </span>
                                      </Button>
                                    </>
                                  )}
                                  <Form className="d-flex flex-column formato-arquivo">
                                    <span>Selecione o formato do arquivo que deseja exportar.</span>
                                    <div className="d-flex btn-container">
                                      <Form.Check
                                        label="PDF"
                                        name="group1"
                                        type='radio'
                                        checked={mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      /><Form.Check
                                        label="Excel"
                                        name="group1"
                                        type='radio'
                                        checked={!mudaArquivoTipo}
                                        onClick={(e) => { SetMudaArquivoTipo(!mudaArquivoTipo) }}
                                      />
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                              <PorcentagemDados>
                                <Card className="card bg-white p-3 border-0 mt-4">
                                  <Card.Body>
                                    <Row className="d-flex align-items-center">
                                      <Col lg={4}>
                                        <h4 className="m-0">Aceites campanha</h4>
                                      </Col>
                                      <Col lg={8}>
                                        <CamposBusca>
                                          <Formik
                                            initialValues={{
                                              campanha: "",
                                            }}
                                            onSubmit={(values) =>
                                              FiltrarCampanhas(values)
                                            }
                                          >
                                            {({
                                              values,
                                              handleChange,
                                              errors,
                                              isValid,
                                              handleSubmit,
                                              setFieldValue,
                                              dirty,
                                            }) => (
                                              <Form
                                                noValidate
                                                onSubmit={handleSubmit}
                                              >
                                                <Form.Group className="d-lg-flex form-mobile justify-content-end align-items-center">
                                                  <Form.Label className="m-0 me-2">
                                                    Filtrar busca por
                                                  </Form.Label>
                                                  <Form.Select
                                                    name="campanha"
                                                    placeholder="Selecionar loja"
                                                    value={values.campanha}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        "campanha",
                                                        e.target.value
                                                      );
                                                      setSelectCampanha(
                                                        e.target.value
                                                      );
                                                      handleSubmit();
                                                    }}
                                                    className="input-selec-revendas m-0"
                                                  >
                                                    <option value="0">
                                                      Selecionar campanha
                                                    </option>
                                                    {campanhas.map(
                                                      ({ id, titulo }) => (
                                                        <option
                                                          value={id}
                                                          key={id}
                                                        >
                                                          {titulo}
                                                        </option>
                                                      )
                                                    )}
                                                  </Form.Select>
                                                </Form.Group>
                                              </Form>
                                            )}
                                          </Formik>
                                        </CamposBusca>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col>
                                        <Card>
                                          <Card.Body>
                                            <Row>
                                              <Col lg={10}>
                                                Total de aceites revenda
                                              </Col>
                                              <Col
                                                lg={2}
                                                className="d-flex justify-content-end"
                                              >
                                                {loadingCampanhas ? (
                                                  porcentagensPlaceholder
                                                ) : (
                                                  <h2>
                                                    {dadosCampanhas &&
                                                      dadosCampanhas.totalRevenda +
                                                      "%"}
                                                  </h2>)}
                                              </Col>
                                            </Row>

                                            <div className="task-card-container">
                                              <div className="task-info">
                                                <div className="task-nivel">
                                                  <div className="progressBarcontainer">
                                                    <div
                                                      className="progressBarValue"
                                                      style={{
                                                        width:
                                                          dadosCampanhas &&
                                                          dadosCampanhas.totalRevenda +
                                                          "%",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>

                                <Card className="card bg-white p-3 border-0 mt-3">
                                  <Card.Body>
                                    <Row className="d-flex align-items-center">
                                      <Col lg={4}>
                                        <h4 className="m-0">Aceites loja</h4>
                                      </Col>
                                      <Col lg={8}>
                                        <CamposBusca>
                                          <Formik
                                            initialValues={{
                                              loja: "",
                                            }}
                                            onSubmit={(values) =>
                                              FiltrarCampanhas(values)
                                            }
                                          >
                                            {({
                                              values,
                                              handleChange,
                                              errors,
                                              isValid,
                                              handleSubmit,
                                              setFieldValue,
                                              dirty,
                                            }) => (
                                              <Form
                                                noValidate
                                                onSubmit={handleSubmit}
                                              >
                                                <Form.Group className="d-lg-flex form-mobile justify-content-end align-items-center">
                                                  <Form.Label className="m-0 me-2">
                                                    Filtrar busca por
                                                  </Form.Label>
                                                  <Form.Select
                                                    name="loja"
                                                    placeholder="Selecionar loja"
                                                    value={values.loja}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        "loja",
                                                        e.target.value
                                                      );
                                                      setSelectLoja(
                                                        e.target.value
                                                      );
                                                      handleSubmit();
                                                    }}
                                                    className="input-selec-revendas m-0"
                                                  >
                                                    <option value="0">
                                                      Selecionar loja
                                                    </option>
                                                    {dadosLojas.map(
                                                      ({
                                                        id,
                                                        nome,
                                                        cnpj,
                                                        cidade,
                                                      }) => (
                                                        <option
                                                          value={id}
                                                          key={id}
                                                        >
                                                          {nome} - {cidade} -{" "}
                                                          {cnpj}
                                                        </option>
                                                      )
                                                    )}
                                                  </Form.Select>
                                                </Form.Group>
                                              </Form>
                                            )}
                                          </Formik>
                                        </CamposBusca>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col>
                                        <Card>
                                          <Card.Body>
                                            <Row>
                                              <Col lg={10}>
                                                Total de aceites loja
                                              </Col>
                                              <Col
                                                lg={2}
                                                className="d-flex justify-content-end"
                                              >{loadingCampanhas ? (
                                                porcentagensPlaceholder
                                              ) : (
                                                <h2>
                                                  {dadosCampanhas &&
                                                    dadosCampanhas.totalLoja +
                                                    "%"}
                                                </h2>)}
                                              </Col>
                                            </Row>

                                            <div className="task-card-container">
                                              <div className="task-info">
                                                <div className="task-nivel">
                                                  <div className="progressBarcontainer">
                                                    <div
                                                      className="progressBarValue"
                                                      style={{
                                                        width:
                                                          dadosCampanhas &&
                                                          dadosCampanhas.totalLoja +
                                                          "%",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </PorcentagemDados>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-center">
                                  <p className="mt-3">{erroCampanhas}</p>
                                </Col>
                              </Row>

                            </>
                          ) : passo === 5 ? (
                            <div>
                              <h4 className="mt-3">Conquistas</h4>
                              <PorcentagemDados>
                                {/* <ConquistasVendedor
                                user={user}
                                minhaEquipe={true}
                              /> */}
                                <ConquistasUsuarioEquipe
                                  user={user}
                                // childToParent={childToParent}
                                />
                              </PorcentagemDados>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              <>
                <Breadcrumb>
                  <Link className="breadcrumb-item" to="/home">
                    Home
                  </Link>
                  <Breadcrumb.Item active>Minha revenda</Breadcrumb.Item>
                </Breadcrumb>
                <PowerBIEmbed
                  embedConfig={powerBiEmbedConfig}
                  eventHandlers={
                    new Map([
                      ['loaded', function () { console.log('Report carregado'); }],
                      ['rendered', function () { console.log('Report renderizado'); }],
                      ['error', function (event) { console.log('Erro no Power BI:', event.detail); }]
                    ])
                  }
                  cssClassName={"powerbi-container"}
                  getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                  }}
                  style={{
                    height: '100vh',
                    width: '100%'
                  }}
                />
              </>}
          </Container>
        </MeusDadosCampos>
        <Footer />
      </div>
    </>
  );
}
