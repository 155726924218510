import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const token = urlParams.get("t") || sessionStorage.getItem("token");

api.interceptors.request.use(
  (config) => {

    if (config.headers.SkipAuth) {
      delete config.headers.SkipAuth;
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  function (response) {
    return response; 
  },
  function (error) {
    console.log('error api > ', error.response)
    console.log('error api > ', error.response.status)
    if (401 === error.response.status) {
      // sessionStorage.removeItem("token");
			// sessionStorage.removeItem("UserInfo");
      // window.location.href = '/'
    } else {
      return Promise.reject(error);
    }
  }
);
export default api;
