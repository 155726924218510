import styled from "styled-components";

export const Container = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        .modalStyle{
            background-color: #fff;
            border-radius: 8px;
            padding: 20px;
            width: 400px;
            text-align: center;
        }
        .headerStyle{
            margin: 0;
            margin-bottom: 10px;
            font-size: 20px;
            color: #333;
            .headerStyle{
                .icon{
                    ba: red;
                }  
            }
        }
        .messageStyle{
            font-size: 16px;
            color: #666;
            margin-bottom: 20px;
        }
        .buttonStyle{
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
        }
`;