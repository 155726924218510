import React from "react";
import Check from '../../../images/img/check-icon.svg';
import UnCheck from '../../../images/img/unCheck-icon.svg';
import { Marcador } from "../styles";

const IndicadoresMeses = ({ mesesConsecutivos, dadoIndicatorTopo, nivelUser, mesAtual }) => {
  const indicadores = [];
  let mes = new Date().getMonth() + 1; // obter o mês atual como número (1-12)

  indicadores.push(
    <img
      src={mesAtual ? `${Check}` : `${UnCheck}`}
      alt="Ícone Ranking MEGA TOP"
      className="mx-2 check"
    />);
  for (let i = 0; i < 11; i++) {
    
    const mesIndicado = i < mesesConsecutivos;
    const corBolinha = mesIndicado ? `${Check}` : `${UnCheck}`;
    const indicador = (
      <img
        src={corBolinha}
        alt="Ícone Ranking MEGA TOP"
        className={((i +2) % 3 === 0) ? 'space mx-2 check' : 'mx-2 check'}
        key={i}
      />
    );
    indicadores.push(indicador);
    if (mesIndicado) {
      mes++;
    }
  }

  return <Marcador className="indicadores-meses">
    {indicadores}
    <div  class={`quadrado quadrado-1 col-2 ${nivelUser} ` + dadoIndicatorTopo}>
      {dadoIndicatorTopo}
    </div>
  </Marcador>;
};

export default IndicadoresMeses;
