import { Modal } from "react-bootstrap";
import images from "../../images/images";
import React, { useState } from "react";
import { CloseButton } from "./styles";
import { useEffect } from "react";


export function ModalAniversario({ modalShow }) {


    const [modalShow1, setModalShow1] = useState(modalShow);
    const handleClose = () => setModalShow1(false);

    useEffect(() => {
        setModalShow1(modalShow)
      }, [modalShow]);
    return (
        <Modal
            show={modalShow1}
            size="xs"
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName="background-dialog"
            contentClassName="modal-css"
        >
            <Modal.Body className="show-grid d-flex justify-content-center p-0 position-relative">
                <CloseButton onClick={handleClose} >
                    x
                </CloseButton>
                <img
                    className="d-block w-100"
                    src={images.ArteAniversaio}
                    alt="First slide"
                />
            </Modal.Body>
        </Modal>
        )
}
