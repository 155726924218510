import { Row, Form, Container, Col, Button, Modal, Fade, Card } from "react-bootstrap";
import * as yup from "yup";
import { Link} from "react-router-dom";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api.js";
import { LojaStyle } from "./styles";
import React, { useState, useRef } from "react";
import { Formik } from "formik";
import { CloseButton } from "./styles";
import { useEffect } from "react";



export function ModalCadastroLoja({ modalShow, lojaDados, fecharModal, novaLoja }) {

    const [modalErro, setModalErro] = useState(false);
    const [modaSuccess, setModalSuccess] = useState('d-none')
    const [modalErro2, setModalErro2] = useState(false);
    const [modalMensagem, setModalMensagem] = useState("");
    const [modalShow1, setModalShow1] = useState(modalShow);
    const handleClose = () => setModalShow1(false);

    useEffect(() => {
        setModalShow1(modalShow)
    }, [modalShow]);

    const [enderecoAPI, setEnderecoAPI] = useState(true);
    const form1Ref = useRef(null);

    const handleLogin = (values) => {

        const formData = new FormData();

        formData.append("Nome", values.nome);
        formData.append("CodigoLoja", values.codigo);
        formData.append("Cnpj", values.cnpj);
        formData.append("Id", lojaDados.lojaDto.id);
        formData.append("Endereco.CEP", values.cep.slice(0, 5) + values.cep.slice(6, 9));
        formData.append("Endereco.Endereco", values.endereco);
        formData.append("Endereco.Numero", values.numero);
        formData.append("Endereco.Complemento", values.complemento);
        formData.append("Endereco.Bairro", values.bairro);
        formData.append("Endereco.Cidade", values.cidade);
        formData.append("Endereco.Estado", values.uf);


        if (novaLoja) {
            formData.delete("Id")
            api
                .post(rotasApi.CadastrarLoja, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }).then(result => {
                    console.log(result)
                    fecharModal(novaLoja, true)

                }).catch(error => {

                    setModalErro(true);
                    setModalMensagem(error.response.data.mensagem);
                    setTimeout(() => {
                        setModalErro(false);
                    }, 5000);
                })
        } else {
            api
                .put(rotasApi.AtualizarLojaPorId, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }).then(result => {

                    setModalErro2(true);
                    setTimeout(() => {
                        setModalErro2(false);
                    }, 2000);
                    setTimeout(() => {
                        fecharModal()
                    }, 3500);
                })
        }


    }



    const handleCEP = (cep) => {
        api
            .get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(result => {
                form1Ref.current.setFieldValue('endereco', result.data.logradouro);
                form1Ref.current.setFieldValue('bairro', result.data.bairro);
                form1Ref.current.setFieldValue('cidade', result.data.localidade);
                form1Ref.current.setFieldValue('uf', result.data.uf);
                form1Ref.current.setFieldValue('complemento', result.data.complemento);
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    const validationSchema = yup.object().shape({
        nome: yup
            .string()
            // .matches(
            //     /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
            //     "Nome inválido"
            // )
            .required("Nome é obrigatório"),

        codigo: yup
            .string()
            .min(1, "Código é obrigatório")
            .required("Código é obrigatório"),
            

        cnpj: yup
            .string()
            .min(14, "CNPJ inválido")
            .max(14, "CNPJ Muito Grande")
            .required("CNPJ é obrigatório"),


        cep: yup
            .string()
            .test("cep", "buscar cep", (val) => {
                if (val) {
                    if (enderecoAPI && val.length === 9) {
                        handleCEP(val)
                        setEnderecoAPI(false)
                    }
                    if (val.length < 9) {
                        setEnderecoAPI(true)
                    }
                }
                return true
            })
            .required("CEP é obrigatório")
            .matches(
                /^[0-9]{5}-[0-9]{3}$/,
                "preencha no formato xxxxx-xxx (com hífen)"
            ),

        endereco: yup.string().min(4, "Endereço inválido").required("Endereço é obrigatório"),
        numero: yup.number().integer().required("Número é obrigatório"),
        bairro: yup.string().min(2, "Bairro inválido").required("Bairro é obrigatório"),
        cidade: yup
            .string()
            .min(2, "Cidade inválida")
            .matches(
                /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                "Cidade inválida"
            )
            .required("Cidade é obrigatório"),

        uf: yup
            .string()
            .min(2, "UF inválida")
            .max(2, "UF inválida")
            .matches(
                /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                "UF inválida"
            )
            .required("UF é obrigatório"),
    });

    return (
        <>
            {/* <ModalAvisoCadastroLoja modalSuccess={modaSuccess} /> */}
            <Modal
                show={modalShow1}
                size="xs"
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdropClassName="background-dialog"
                contentClassName="modal-css-erro"
                backdrop="static"
            >
                <Row>
                    <Col className="d-flex justify-content-center modal-erro-cadastro-loja">
                        <Fade in={modalErro}>
                            <Card
                                className="text-center p-3 m-2 z-index-top position-absolute modal-erro-cadastro-loja"
                                bg="danger"
                                text="white"
                            >
                                <Card.Body className="modal-erro-cadastro-loja">
                                    <Card.Title as="h2">Erro</Card.Title>
                                    <Card.Text>{modalMensagem}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Fade>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center modal-erro-cadastro-loja">
                        <Fade in={modalErro2}>
                            <Card
                                className={modalErro2 ? ' text-center p-3 m-2 z-index-top position-absolute modal-erro-cadastro-loja' : modaSuccess}
                                bg="success"
                                text="white"
                            >
                                <Card.Body className="modal-erro-cadastro-loja">
                                    <Card.Title as="h2">Atualizado com sucesso!</Card.Title>
                                    {/* <Card.Text>{modalMensagem}</Card.Text> */}
                                </Card.Body>
                            </Card>
                        </Fade>
                    </Col>
                </Row>
                <Modal.Body className="show-grid d-flex justify-content-center p-0 position-relative">
                    <CloseButton onClick={handleClose} >
                        x
                    </CloseButton>
                    <LojaStyle>
                        <Container className="col-12">
                            <Formik
                                innerRef={form1Ref}
                                initialValues={{
                                    nome: lojaDados.lojaDto.nome ? lojaDados.lojaDto.nome : '',
                                    codigo: lojaDados.lojaDto.codigoLoja ? lojaDados.lojaDto.codigoLoja : '',
                                    cnpj: lojaDados.lojaDto.cnpj ? lojaDados.lojaDto.cnpj : '',
                                    cep: lojaDados.lojaDto.endereco.cep ? lojaDados.lojaDto.endereco.cep.slice(0, 5) + '-' + lojaDados.lojaDto.endereco.cep.slice(5, 9) : '',
                                    endereco: lojaDados.lojaDto.endereco.endereco ? lojaDados.lojaDto.endereco.endereco : '',
                                    numero: lojaDados.lojaDto.endereco.numero ? lojaDados.lojaDto.endereco.numero : '',
                                    complemento: lojaDados.lojaDto.endereco.complemento ? lojaDados.lojaDto.endereco.complemento : '',
                                    bairro: lojaDados.lojaDto.endereco.bairro ? lojaDados.lojaDto.endereco.bairro : '',
                                    cidade: lojaDados.lojaDto.endereco.cidade ? lojaDados.lojaDto.endereco.cidade : '',
                                    uf: lojaDados.lojaDto.endereco.estado ? lojaDados.lojaDto.endereco.estado : '',
                                }}
                                onSubmit={(values) => {
                                    handleLogin(values);
                                }}
                                validationSchema={validationSchema}
                            >
                                {({
                                    values,
                                    handleChange,
                                    errors,
                                    isValid,
                                    handleSubmit,
                                    setFieldValue,
                                    dirty,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mt-5 col-12 d-flex justify-content-center m-0">
                                            <Col lg={{ span: 12, offset: 12 }} className="d-flex justify-content-center">
                                                <div className="align-self-center col-12 ">
                                                    <div className="titulo-page text-center">
                                                        {novaLoja ? <h2>Cadastrar nova loja</h2> : <h2>Editar loja</h2>}
                                                        <span>Preencha todos os campos com (*)</span>
                                                    </div>
                                                    <Row className="pt-5">
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Nome da loja*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    placeholder=""
                                                                    type="text"
                                                                    name="nome"
                                                                    value={values.nome}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.nome}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.nome}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Código da loja*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    placeholder=""
                                                                    type="text"
                                                                    name="codigo"
                                                                    value={values.codigo}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.codigo}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.codigo}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>


                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    CNPJ*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    type="string"
                                                                    name="cnpj"
                                                                    value={values.cnpj}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.cnpj}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.cnpj}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>




                                                    <Row className="">
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    CEP*
                                                                </Form.Label>
                                                                <div className="small position-relative">
                                                                    <a className="busca-cep" rel="noopener noreferrer" href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Não sei meu CEP</a>
                                                                </div>

                                                                <Form.Control
                                                                    type="text"
                                                                    maxLength="9"
                                                                    name="cep"
                                                                    value={values.cep}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.cep}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.cep}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Rua /  Avenida / Travessa / Praça*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    type="text"
                                                                    name="endereco"
                                                                    value={values.endereco}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.endereco}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.endereco}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Número*
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="numero"
                                                                    value={values.numero}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.numero}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.numero}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Complemento
                                                                </Form.Label>

                                                                <Form.Control
                                                                    type="text"
                                                                    name="complemento"
                                                                    value={values.complemento}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Bairro*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    type="text"
                                                                    name="bairro"
                                                                    value={values.bairro}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.bairro}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.bairro}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    Cidade*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    type="text"
                                                                    name="cidade"
                                                                    value={values.cidade}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.cidade}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.cidade}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="label-input">
                                                                    UF*
                                                                </Form.Label>

                                                                <Form.Control
                                                                    className="text-uppercase"
                                                                    type="text"
                                                                    maxLength="2"
                                                                    name="uf"
                                                                    value={values.uf}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.uf}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.uf}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex justify-content-center mt-5 mb-4">
                                                        <Col>

                                                            <Button
                                                                type="submit"
                                                                disabled={!isValid || !dirty}
                                                                className="mb-3 w-100 btn-laranja"
                                                            >
                                                                {novaLoja ? 'Cadastrar' : 'Atualizar'}
                                                            </Button>
                                                            <Link
                                                                to="/cadastro-loja"
                                                                className="btn btn-outline-secondary btn-laranja-outline w-100"
                                                                onClick={() => fecharModal(novaLoja)}
                                                            >
                                                                Cancelar
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
                    </LojaStyle>
                </Modal.Body>
            </Modal>
        </>
    )
}
