import styled from "styled-components";

export const Background = styled.div`
  background: #efeeed;
  .form-flex {
    display: flex;
    @media (max-width: 770px) {
      display: block;
    }
  }
  .btn-novaLoja{
    width: 20rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      margin-right: 1rem;
    }
  }
`;

export const Breadcrumbs = styled.div`
  .breadcrumbs {
    font-size: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;
    @media (max-width: 770px) {
      margin: 0 15px;
    }
  }

  .link {
    font-weight: bold;
    text-decoration: none;
    color: #f58420;
  }
`;
export const Tabela = styled.div`
  text-align: left;
  margin-bottom: 3rem;

  .desktop {
    display: block;
    @media (max-width: 770px) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @media (max-width: 770px) {
      display: block;
    }
  }

  @media (max-width: 770px) {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .h3-resultado {
    font-size: 24px;
    color: #313131;
    font-weight: bold;

    @media (max-width: 770px) {
      display: none;
    }
  }

  .campos-filtro {
    display: flex;
    justify-content: space-around;
    align-items: baseline;

    @media (max-width: 770px) {
      display: block;
      margin-top: 0;
    }

    .frase-filtrar-busca {
      font-size: 16px;
      padding-right: 81px;

      @media (max-width: 770px) {
        font-size: 14px;
      }
    }

    .input-mes-ano {
      width: 18rem;
      height: 2.8rem;
      padding: 11px 11px;
      border-radius: 5px;
      border: 1px solid #000;
      margin-right: 30px;

      @media (max-width: 770px) {
        width: 100%;
      }
    }

    .btn-buscar {
      width: 100%;
      max-width: 261px;
      background: #f58420;
      border: none;
      color: #fff;
      font-size: 14px;
      padding: 11px 0;
      border-radius: 5px;

      @media (max-width: 770px) {
        max-width: 100%;
        margin-top: 15px;
        margin-bottom: 30px;
      }
    }
  }

  .tabela-resultado {
    border-radius: 5px;

    @media (max-width: 770px) {
      max-width: 92%;
      overflow: scroll;
      width: 40rem;
    }

    .topo-tabela,
    .rodape-tabela {
      display: flex;
      justify-content: space-between;
      background: #313131;
      color: #fff;
    }
    .topo-tabela {
      font-size: 14px;
      padding: 12px 0 14px 30px;
      border-radius: 10px 10px 0 0;

      @media (max-width: 770px) {
        padding: 12px 0 14px 15px;
        width: 40rem;
      }

      .titulo-loja {
        width: 25%;
        padding-left: 1.5rem!important;
        @media (max-width: 770px) {
          width: 30%;
          padding-right:4.5rem!important;
          padding-left: 0rem!important;
        }
      }
      .titulo-cnpj {
        width: 25%;
        //	max-width: 250px;
        padding-left: 3rem!important;
        span{
          padding-left: 1.5rem!important;
        }
        @media (max-width: 770px) {
          width: 10%;
          padding-left: 0rem!important;
          span{
            padding-left:0rem!important;
        }
        }
      }
      .titulo-status {
        width: 33%;
        //	max-width: 250px;
        padding-left: 3rem!important;
        padding-left: 1.5rem!important;
        span{
          padding-left: 1.5rem!important;
        }
        @media (max-width: 770px) {
          width: 35%;
          
        }
      }
      .titulo-edit {
        width: 15%;
        //	max-width: 250px;
        @media (max-width: 770px) {
          width: 25%;
        }
      }
      .titulo-space {
        width: 100%;
        //	max-width: 250px;

        @media (max-width: 770px) {
          width: 0;
        }
      }
    }
    .corpo-tabela {
      background: #fff;
      overflow-y: scroll;
        height: 20rem;
      @media (max-width: 770px) {
        width: 40rem;
      }
    }
    .linha-tabela {
      border-bottom: 1px solid #707070;
      display: flex;
      align-items: ce;
      padding: 15px 0 12px 30px;

      @media (max-width: 770px) {
        padding: 15px 30px 15px 15px;
      }
    }
    .loja,
    .cnpj,
    .status,
    .edit {
      width: 15%;
      font-size: 14px;
    }
    .status {
      width: 35%;
    }
    .edit {
      width: 15%;
    }
    .loja {
      @media (max-width: 770px) {
        width: 27%;
      }
    }
    .cnpj {
      @media (max-width: 770px) {
        width: 37%;
      }
    }
    
    .status {
      @media (max-width: 770px) {
        width: 15%;
      }
    }
    
    .edit {
        @media (max-width: 770px) {
        width: 25%;
      }
    }
    .space {
      @media (max-width: 770px) {
        width: 0;
      }
    }
    .rodape-tabela {
      padding: 12px 0 2px 30px;
      border-radius: 0 0 10px 10px;
      background: #9ccb3d;

      @media (max-width: 770px) {
        padding-left: 15px;
        width: 28rem;
      }

      .total {
        font-size: 16px;
        width: 100%;
        //	max-width: 390px;

        @media (max-width: 770px) {
          width: 12rem;
        }
      }

      .pontos {
        font-size: 24px;
        font-weight: bold;
        padding-right: 30px;

        .pts {
          font-size: 14px;
        }
      }
    }
  }
  .form-flex {
    width: 100%;
    display: flex;
    @media (max-width: 770px) {
      display: block;
    }
  }
  .row-flex{
    @media (max-width: 770px) {
      flex-direction: column;
    }
  }
`;
