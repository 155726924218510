import { FooterComponente } from "./styles";
import Nav from 'react-bootstrap/Nav';
import Logo from "../../images/img/logo.svg";
// import Logo from "../../images/img/logo-bf.svg";
import { Link } from "react-router-dom";
import images from "../../images/images";

export function Footer() {
    return (
        <FooterComponente className="footer-component">
            {/* <img src={images.BgFooterBf} className="bg-footer-tema" alt="" /> */}
            {/* <img src={images.BgFooterBf} className="bg-footer-tema-mobile" alt="" /> */}
            <div className="footer d-flex align-items-center">
                <Nav className="container-fluid d-flex align-items-center container-itens-nav "
                    bg="light"
                >
                    <a className="navbar-brand mr-2 icon-footer col-lg-3" href="#/">
                        <img src={Logo} alt="" />
                    </a>
                    {/* <div className="col-3"></div> */}
                    <div className="d-lg-flex col-lg-5 justify-content-center">
                        <Nav.Item>
                            <Nav.Link href="/regulamento" >Termos de uso</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/politica" eventKey="link-1">Política de Privacidade</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className="nav-link" to="/duvidas">
                                Dúvidas
                            </Link>
                        </Nav.Item>
                    </div>
                </Nav>

            </div>
            {/* <img src={images.BgFooterBf} className="bg-footer-tema" alt="" /> */}
        </FooterComponente>

    )
}
