import styled from "styled-components";

export const Home = styled.div`
  background: #efeeed;
  @media (min-width: 770px) {
    height: 52.2rem;
  }
 
`;

export const FiltroExtrato = styled.div`
  .box-filtro {
    width: 100%;
    //max-width: 261px;
    background: #fff;
    color: #313131;
    border-radius: 5px;
    padding: 23px 0 5px;

    @media (max-width: 770px) {
      background: none;
      width: 100%;
      max-width: 100%;
    }

    .desktop {
      display: block;
      @media (max-width: 770px) {
        display: none;
      }
    }

    .mobile {
      display: none;
      @media (max-width: 770px) {
        display: block;
      }
    }

    .h3 {
      font-size: 24px;
      font-weight: bold;
      margin-left: 20px;

      @media (max-width: 770px) {
        text-align: center;
      }
    }

    .filtro-mobile {
      @media (max-width: 770px) {
        width: 92vw;
        white-space: nowrap;
        overflow-y: auto;
        margin: 0 15px;
        display: grid;
        grid-auto-flow: column;
      }
    }

    .links-filtro {
      font-size: 14px;
      display: block;
      margin: 15px 0;
      color: #313131;
      text-decoration: none;
      padding-left: 20px;
      cursor: pointer;

      @media (max-width: 770px) {
        width: 140px;
        height: 110px;
        background: #989898;
        padding-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        margin-right: 15px;
        white-space: normal;
      }
    }

    .ativo {
      font-weight: bold;
      border-left: 2px solid #9ccb3d;
      padding-left: 18px;
      color: #9ccb3d;

      @media (max-width: 770px) {
        border: none;
        font-weight: 500;
        background: #9ccb3d;
        color: #fff;
      }
    }
  }
  .container-saldo-user {
    background: #7032d1;
    color: #fff;
    height: 85px;
    .valor-pontos {
      width: 100%;
      height: 36px;
      background: #9a5cfa;
    }
    .valor-pts {
      font-size: 1rem;
      position: absolute;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
    }
    p {
      font-size: 12px;
      margin-bottom: -4px;
      margin-top: 5px;
    }
    .white-line {
      background-color: white;
      height: 1px;
      width: 10rem;
    }
    .font-saldo {
      font-size: 2rem;
    }
  }
`;

export const Breadcrumbs = styled.div`
  .breadcrumbs {
    font-size: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;
    @media (max-width: 770px) {
      margin: 0 15px;
    }
  }

  .link {
    font-weight: bold;
    text-decoration: none;
    color: #000;
  }
`;

export const ExtratoGeral = styled.div`
  .box-principal {
    width: 100%;
    max-width: 900px;
    margin-left: 30px;
    text-align: left;

    @media (max-width: 770px) {
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
    }

    .h3-resultado {
      font-size: 24px;
      color: #313131;
      font-weight: bold;

      @media (max-width: 770px) {
        display: none;
      }
    }

    .campos-filtro {
      margin: 1rem 0;
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      @media (max-width: 770px) {
        display: block;
        margin-top: 0;
      }

      .frase-filtrar-busca {
        font-size: 16px;
        padding-right: 81px;
        @media (max-width: 770px) {
          font-size: 14px;
        }
      }

      .input-mes-ano {
        width: 18rem;
        height: 45px;
        font-size: 12px;
        padding: 14px 11px;
        color: #c4c4c4;
        border-radius: 5px;
        border: 1px solid #000;
        margin-right: 30px;
        @media (max-width: 770px) {
          display: inline;
          margin-right: 20px;
          width: 43%;
        }
      }

      .btn-buscar {
        width: 100%;
        max-width: 261px;
        background: #f58420;
        border: none;
        color: #fff;
        font-size: 14px;
        padding: 11px 0;
        border-radius: 5px;
        @media (max-width: 770px) {
          max-width: 92%;
          margin-top: 15px;
          margin-bottom: 30px;
        }
      }
    }

    .tabela-resultado {
      font-size: 0.9rem;
      border-radius: 5px;
      @media (max-width: 770px) {
        max-width: 92%;
        overflow: scroll;
        width: 27rem;
      }
      .topo-tabela,
      .rodape-tabela {
        display: flex;
        justify-content: space-between;
        background: #313131;
        color: #fff;
      }
      .topo-tabela {
        padding: 12px 0 14px 30px;
        border-radius: 5px 5px 0 0;
        @media (max-width: 770px) {
          padding: 12px 0 14px 15px;
        }

        .titulo-descricao {
        }

        .titulo-pontos {
          padding-right: 30px;
        }
      }
      .corpo-tabela {
        background: #fff;
      }
      .linha-tabela {
        border-bottom: 1px solid #707070;
        display: flex;
        align-items: baseline;
        padding: 15px 30px;
        justify-content: space-between;
        @media (max-width: 770px) {
          padding: 15px 30px 15px 15px;
        }
      }
      .descricao {
        width: 100%;
        max-width: 650px;
        font-size: 13px;
      }
      .pontos {
        width: 100%;
        max-width: 90px;
        font-size: 24px;
        font-weight: bold;
        text-align: right;
      }
      .linha-inteira {
        width: 100%;
        text-align: right;
        padding: 15px 50px 30px 15px;
        font-size: 14px;
      }
      .rodape-tabela {
        padding: 21px 0 27px 30px;
        border-radius: 0 0 5px 5px;
        background: #9ccb3d;

        @media (max-width: 770px) {
          padding-left: 14px;
          padding-bottom: 23px;
        }

        .total {
          font-size: 24px;

          @media (max-width: 770px) {
            font-size: 14px;
            display: flex;
            align-self: end;
            padding-bottom: 8px;
          }
        }

        .pontos {
          font-size: 32px;
          font-weight: bold;
          padding-right: 30px;
          width: 120px;
          max-width: 100%;

          .pts {
            font-size: 14px;
            position: relative;
            top: -15px;
          }
        }
      }
    }
  }
  .form-flex {
    display: flex;
    @media (max-width: 770px) {
      display: block;
    }
  }
`;

export const TresColunas = styled.div`
  .box-principal {
    width: 100%;
    max-width: 900px;
    margin-left: 30px;
    text-align: left;
    margin-bottom: 68px!important;
    .desktop {
      display: block;
      @media (max-width: 770px) {
        display: none;
      }
    }

    .mobile {
      display: none;
      @media (max-width: 770px) {
        display: block;
      }
    }

    @media (max-width: 770px) {
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
    }

    .h3-resultado {
      font-size: 24px;
      color: #313131;
      font-weight: bold;
      position: relative;
      margin-bottom: 49px;
      span{
        font-size: 14px;
        position: absolute;
        bottom: -22px;
        left: 0;
        color: #333;
      }

      @media (max-width: 770px) {
        display: none;
      }
    }

    .campos-filtro {
      margin: 1rem 0;
      display: flex;
      justify-content: space-around;
      align-items: baseline;

      @media (max-width: 770px) {
        display: block;
        margin-top: 0;
      }

      .frase-filtrar-busca {
        font-size: 16px;
        padding-right: 81px;

        @media (max-width: 770px) {
          font-size: 14px;
        }
      }

      .input-mes-ano {
        width: 18rem;
        height: 45px;
        font-size: 12px;
        padding: 14px 11px;
        color: #c4c4c4;
        border-radius: 5px;
        border: 1px solid #000;
        margin-right: 30px;

        @media (max-width: 770px) {
          display: inline;
          margin-right: 20px;
          width: 43%;
        }
      }

      .btn-buscar {
        width: 100%;
        max-width: 261px;
        background: #f58420;
        border: none;
        color: #fff;
        font-size: 14px;
        padding: 11px 0;
        border-radius: 5px;

        @media (max-width: 770px) {
          max-width: 92%;
          margin-top: 15px;
          margin-bottom: 30px;
        }
      }
    }

    .tabela-resultado {
      font-size: 0.9rem;
      border-radius: 5px;

      @media (max-width: 770px) {
        max-width: 92%;
        overflow: scroll;
        width: 27rem;
      }

      .topo-tabela,
      .rodape-tabela {
        display: flex;
        justify-content: space-between;
        background: #313131;
        color: #fff;
      }
      .topo-tabela {
        font-size: 14px;
        padding: 14px 20px 14px 14px;
        border-radius: 5px 5px 0 0;

        @media (max-width: 770px) {
          width: 40rem;
          padding: 14px 26px 14px 14px;
        }

        .titulo-descricao {
          width: 100%;
          max-width: 390px;

          @media (max-width: 770px) {
            width: 25%;
          }
        }
        .titulo-quantidade {
          width: 100%;
          //	max-width: 250px;

          @media (max-width: 770px) {
            width: 25%;
          }
        }
        .titulo-space {
          width: 100%;
          //	max-width: 250px;

          @media (max-width: 770px) {
            width: 0;
          }
        }
        .titulo-numero {
          width: 100%;
          //	max-width: 250px;

          @media (max-width: 770px) {
            width: 25%;
          }
        }
        .titulo-pontos {
          width: 100%;
          max-width: 230px;

          @media (max-width: 770px) {
            width:  25%;
          }
        }
        .titulo-data {
          width: 100%;
          max-width: 90px;

          @media (max-width: 770px) {
            width: 20%;
          }
        }
      }
      .barra{
        @media (max-width: 770px) {
          width: 40rem;
        }
      }
      .corpo-tabela {
        background: #fff;
        overflow-y: scroll;
        height: 20rem;
        @media (max-width: 770px) {
          width: 40rem;
          padding: 0px 0 0 7px;
          margin: 0px 0px 0px -7px;
        }
        ::-webkit-scrollbar {
          width: 6px;  
        }

        ::-webkit-scrollbar-track {
          background: #eee;
          border-radius: 20px;
        }
        
        ::-webkit-scrollbar-thumb {
          background-color: orange; 
          border-radius: 20px;       
        }
      }
      .linha-tabela {
        border-bottom: 1px solid #707070;
        display: flex;
        align-items: baseline;
        padding: 14px;
        justify-content: space-between;

 
      }
      .pontos,
      .data,
      .quantidade,
      .numero,
      .space,
      .descricao {
        width: 100%;
        max-width: 390px;
        font-size: 13px;
      }
      .numero {
        @media (max-width: 770px) {
          width: 40%;
        }
      }
      .quantidade {
        @media (max-width: 770px) {
          width: 20%;
        }
      }
      .space {
        @media (max-width: 770px) {
          width: 0;
        }
      }
      .descricao {
        @media (max-width: 770px) {
          width: 35%;
        }
      }
      .pontos {
        width: 100%;
        max-width: 210px;

        @media (max-width: 770px) {
          width: 25%;
        }
      }
      .data {
        width: 100%;
        max-width: 80px;

        @media (max-width: 770px) {
          width: 20%;
        }
      }
      .rodape-tabela {
        font-size: 1rem;
        padding: 12px 15px 12px 14px;
        border-radius: 0 0 5px 5px;
        background: #9ccb3d;

        @media (max-width: 770px) {
          padding-left: 15px;
          width: 40rem;
        }

        .total {
          width: 100%;
          //	max-width: 390px;

          @media (max-width: 770px) {
            width: 12rem;
          }
        }

        .pontos {
          font-weight: bold;
          padding-right: 30px;

        }
        .valor-resgates{
          padding-left: 3rem;
          @media (max-width: 770px) {
            padding-left: 2rem;
          }
        }
      }
    }
  }
  .form-flex {
    display: flex;
    @media (max-width: 770px) {
      display: block;
    }
  }
`;
