import styled from "styled-components";

export const BarraStatus = styled.div`
  height: 4rem;
  background-color: #f58420;
  .space-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    @media (max-width: 992px) {
        width: 100%;
    }
  }
`;
export const Chat = styled.div`
border: 1px solid #ced4da;
border-top-right-radius: 0.25rem;
border-top-left-radius: 0.25rem;
  height: 40rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
  .bg-card{
      background-color: #EFEEED;
  }
  .tempo-message{
      text-align: right;
      color: #C4C4C4;
      font-size: 0.8rem;
      margin-top: 0.2rem;
  }
  .cliente-msg{
      display: flex;
      justify-content: flex-end;
      @media (max-width: 992px) {
        justify-content: flex-start;
    }   
  }
  .data-mensagens{
    color: #989898;
  }
  .msgPadding{
    padding-right: 0;
    padding-left: 25px;
  }
`;
export const CircleAvatar = styled.div`
  height: 2rem;
  border-radius:5rem;
  
`;

export const EnviaMsg = styled.div`
position: relative;
padding: 1rem;
border: 1px solid #ced4da;
border-bottom-right-radius: 0.25rem;
border-bottom-left-radius: 0.25rem;

.textArea{
  display: flex;
  align-items: center;
  width: 90%;
  @media (max-width: 992px) {
    width: 70%;
    }
}
.h36{
  height: 36px;
}
  .anexoMsg{
    display: flex;
    position: absolute;
    top: 5px;
    right: 4rem;
    width: 3rem;
    justify-content: center;
    @media (max-width: 992px) {
      top: 0;
    right: 3rem;
    }
    .invalidImg{
      background: white;
      top: -2rem;
      position: absolute;
      width: 10rem;
      border-radius: 0.25rem;
      border: 1px solid #ededed;
    }
  }
  .btnEnvia{
    position: absolute;
    top: 15px;
    right: 15px;
    @media (max-width: 992px) {
      right: 0;
    top: 10px;
    }
  }
  .imgSize{
    height: 2.6rem;
  }
  .boxImg{
    display: flex;
    align-items: center;
    position: relative;
    width: 16rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background: #fbfbfb;

    @media (max-width: 992px) {
    width: 100%;
    }
    .conteudoAnexo {
        p{
          margin: 0;
          font-size: 0.8rem;
          
        }
        
      }
      .excluirAnexo {
        right: 1rem;
    position: absolute;
    cursor: pointer;
      }
    .closeImg{
      position: absolute;
      color: red;
      right: -6px;
      top: 4px;
      height: 19px;
    
    border-radius: 1rem;
    cursor: pointer;
      @media (max-width: 992px) {
        top: 5px;
        right: -5px;
        
      }
    }
  }
  .marginIcon{
    margin-top: 1rem;
    @media (max-width: 992px) {
      margin-top: 1.3rem;
    }
  }

`;

