import styled from "styled-components";

export const TopNewsPage = styled.div`
background: #efeeed;
    @media (max-width: 800px) {
            /* padding: 20px 20px; */
	    }
    .titulo-pagina{
        margin-bottom: 68px;
        @media (max-width: 800px) {
            margin-bottom: 25px;
	    }
    }
    .container-topnews{
        min-height: 662px;
        @media (max-height: 800px) {
            min-height: 500px;
            padding: 0px 20px;
	    }
        @media (max-width: 800px) {
            padding: 0px 24px;
	    }
    }
`;

export const ContainerCards = styled.div`
    max-height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
    @media (max-width: 800px) {
        max-height: 74vh;
	    }
::-webkit-scrollbar {
    width: 12px; 
    background-color: #FFFFFF;
    border-radius: 10px; 
    @media (max-width: 800px) {
        width: 12px;
	    }
    
}
::-webkit-scrollbar-thumb {
    background-color: #FFA500;
    border-radius: 6px; 
    border: 3px solid #FFFFFF; 
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px; 
    @media (max-width: 800px) {
        box-shadow: none;
	    }
}

.card-container{
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 30px;
    justify-content: space-between;
    width: 97%;
        @media (max-width: 800px) {
            flex-direction: column;
            grid-template-columns: none;
            width: 100%;
            justify-content: center;
	    }
    .grid-item{
        cursor: pointer;
        position: relative;
       .image{
        width: 300px;
        height: 279px;
        @media (max-width: 800px) {
            width: 264px;
            height: 250px;
	    }
       }
       .reacoes{
            margin-top: 10px;
            .reacao-icon{
                margin: 0px 3px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                @media (max-width: 1500px) {
                    width: 22px;
                }
            }
           
       }
       .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background-color: rgba(0, 0, 0, 0.5); /* cor de fundo preta com opacidade */
            display: flex;
            justify-content: center;
            align-items: center;
            height: 279px;
            @media (max-width: 800px) {
                width: 100%;
            height: 86%;
	    }
            
        }
        
        .overlay-text {
            color: white;
            font-size: 20px; /* ou o tamanho desejado */
            visibility: hidden;
            transition: .5s ease;
        }
    }
    .grid-item:hover .overlay {
        opacity: 1;
    }
    .grid-item:hover .overlay-text {
        visibility: visible;
    }

}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal.active {
    display: flex;
}

.modal-content {
    width: 45vw;
    height: max-content;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
    position: relative;
        @media (max-width: 1500px) {
            padding: 87px;
	    }
        @media (max-width: 800px) {
            padding: 20px 10px;
            width: 90vw;
	    }
        @media (max-height: 1100px) {
            padding: 20px 10px;
            /* width: 90vw; */
            height: max-content;
	    }
        @media (max-height: 800px) {
            height: 73vh;
	    }
        @media (max-height: 1100px) {
            height: max-content;
        }
    .icon-tomas{
        width: 100%;
        display: flex;
        padding-bottom: 20px;
        @media (max-width: 400px) {
            padding-bottom: 10px;
	        }
        @media (max-width: 1500px) {
            padding-top:32px;
        }
        font-size: 12px;
        h1{
            font-size: 15px;
            @media (max-width: 400px) {
                font-size: 12px;
	        }
            @media (max-width: 1500px) {
                font-size: 12px;
	        }
        }
        span{
            font-size: 12px;
            @media (max-width: 400px) {
                font-size: 10px;
	        }
        }
        .info{
            display: flex;
            flex-direction: column;
            align-self: center;
            padding-left: 25px;
        }
        img{
            @media (max-width: 400px) {
                width: 50px;
                height: 50px;
	        }
            @media (max-width: 1500px) {
                width: 50px;
                height: 50px;
	        }
        }
        
    }
    .text-post{
        font-size: 14px;
        @media (max-width: 400px) {
            font-size: 13px;
            max-height: 245px;
            h2{
                height: 32px;
            }
	        }
            @media (max-height: 1100px) {
                font-size: 12px;
                h2{
                    height: 30px;
                }
	    }
        
    }
    .reacoes{
        display: flex;
        width: 100%;
        margin-top: 20px;
        @media (max-width: 400px) {
            margin-top: 0px;
            position: absolute;
            bottom: 10px;
            }
	    }
        @media (max-width: 800px) {
            padding: 12px 12px 54px 13px;
	    }
        div{
            margin: 0px 5px;
        }
        .reacao-icon{
                margin: 0px 3px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                @media (max-width: 1500px) {
                    width: 22px;
                }
            }
    }
    .close-btn{
        position: absolute;
        top: -45px;
        right: 1px;
        @media (min-width: 900px) and (max-width: 1500px) {
            position: absolute;
            top: 0px;
            right: -49px;
        } 
    }
    .video{
        width: 100%;
        max-height: 57%;
    }
    


.modal-image {
    width: 67%;
    height: max-content;
    @media (max-width: 800px) {
        width: 90%;
        height: 50%;
        max-height: 220px;
	}
    @media (max-height: 800px) {
        width: 50%;
	    }
}
`