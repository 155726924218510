import { Row, Col, Container, Breadcrumb, Card, Form } from "react-bootstrap";
import { NavBar } from "../../../components/NavBar/index";
import { Footer } from "../../../components/Footer/index";
import { Home, TopoConvideUmAmigo, ListaAmigosConvidadosHistorico } from "./styles";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useEffect } from "react";
import { rotasApi } from "../../../services/rotasApi";
import api from "../../../services/api";

export function HistoricoConvideAmigo({ user }) {

	const [amigosConvidados, SetAmigosConvidados] = useState();

	useEffect(() => {
		ListarAmigosConvidados();
		// ObterCodigoConvite();
	}, []);

	const ListarAmigosConvidados = () => {
		api
			.get(rotasApi.UsuariosConvidados,)
			.then(result => {
				SetAmigosConvidados(result.data.listaConvidados);
			})
			.catch(error => {
				console.log(error.response);
			});
	};

	const FiltrarListaAmigosConvidados = (e) => {

		// console.log(e)


		api
			.get(rotasApi.FiltrarUsuariosConvidados + `?status=${e}`,)
			.then(result => {
				SetAmigosConvidados(result.data.listaConvidados === [] ? (null) : result.data.listaConvidados);
				console.log(amigosConvidados)
			})
			.catch(error => {
				console.log(error.response);
			});
	}

	return (
		<>
			<Home className="historico-amigos-blackfriday">
				<NavBar user={user} />
				<Container className="container-historico-amigos">
					<Breadcrumb className="mt-3">
						<Link className="breadcrumb-item" to="/home">
							Home
						</Link>
						<Link className="breadcrumb-item" to="/convide-um-amigo">
							Convide um amigo
						</Link>
						<Breadcrumb.Item active>Amigos convidados</Breadcrumb.Item>
					</Breadcrumb>

					<hr className="mt-3" />
					<Row>
						<Col className=" mt-0 mb-4" md="8">
							<TopoConvideUmAmigo>
								<h3 className="titulo">
									Progresso dos amigos indicados.
								</h3>

								<div className="texto-como-fazer">
									<p className="paragrafo">
										Aqui você pode acompanhar como anda o progresso de cada amigo indicado e convidar de novo quem ainda não se cadastrou.
									</p>
									<p className="paragrafo">
										Caso tenha dúvidas, entre em contato através do <a href="/duvidas">FALE CONOSCO.</a>
									</p>
								</div>
							</TopoConvideUmAmigo>
						</Col>
					</Row>
					<ListaAmigosConvidadosHistorico>
						<Container>
							<div className="d-flex justify-content-end">
								<div className="col-6 d-flex ">
									<span className="col-7 mt-2">Filtrar busca por status</span>
									<Form.Select
										type="text"
										name="sexo"
										className="mb-4"
										onChange={(e) => { FiltrarListaAmigosConvidados(e.target.value) }}
									>
										<option>Selecionar</option>
										<option value="ativo">Ativo</option>
										<option value="em andamento">Em Andamento</option>
										<option value="pendente">Pendente</option>
										<option value="recusado">Recusado</option>
									</Form.Select>
								</div>
							</div>
							<div>
								<div className="container-lista-amigos d-flex flex-wrap justify-content-center align-left">
									{amigosConvidados ? amigosConvidados.map(({ lojaNome, nomeUsuario, cidadeUsuario, estadoUsuario, status, pontos }, index) => (
										<Card
											style={{ width: '15rem' }}
											className="mb-2 mt-4 mx-2"
										>
											<Card.Body>
												<Card.Text>
													<span><strong>{nomeUsuario}</strong></span><br />
													<span>Loja: <strong> {lojaNome}</strong></span><br />
													<span>{cidadeUsuario}-{estadoUsuario}</span><br />
													<span>Status: <strong className={status === 'Pendente' || status === 'em andamento' ? 'text-warning' : (status === "Recusado" ? 'text-danger' : (status === "Aceite" ? 'text-aceite' : ''))}>{status}</strong></span><br />
													<span>Pontos: <strong>{pontos}</strong></span><br />
												</Card.Text>
											</Card.Body>
										</Card>

									)) : (<h4>Nenhum Usuário encontrado</h4>)}

								</div>
								{/* <p>{amigosConvidados}</p> */}
							</div>
						</Container>
					</ListaAmigosConvidadosHistorico>
				</Container>
				<Footer className="m-0 p-0"></Footer>
			</Home>
		</>
	);
}
