import styled from 'styled-components'

export const SobreMaisTopPage = styled.div`
    background: #EFEEED;
    hr{
      margin: 0;
    }
`
export const BannerInicial = styled.div`
    color: white;
    height: 192px;
    border-radius: 5px ;
    margin-bottom: 60px;
    margin-top: 50px;
    .titulo-banner{
      font-size: 32px;
      font-weight: bold;
      /* margin-left: 40px; */
    }
    @media (max-width: 992px) {
      .container-conteudo-banner{
        display: block!important;
        text-align: center;
      .botao-banner{
        margin-top: 30px;
        button{
          height: 45px;
          width: 265px;
        }
      }
      .titulo-banner{
        font-size: 24px;
        font-weight: bold;
        margin-top: 60px;
      }
    }
    
  }
    .container-conteudo-banner{
      align-items:center ;
      display: flex;
      justify-content: space-around;
    }
    .botao-banner{
        margin-top: 10px;
        button{
          height: 45px;
          width: 265px;
        }
      }
      .img-banner-mobile{
        display: none;
      }
    @media (max-width: 992px) {
      .img-banner{
        display: none;
      }
      .img-banner-mobile{
        display: inline;
        img{
          width: 100%;
        }
      }
  }
`
export const ContainerInformacoes = styled.div`
  background: #fff;
  /* padding-bottom:70px; */
  height: 655px;
  .container-inf{
    height: 100%;
    align-items: center;
  }
  h2{
    font-size: 32px;
    font-weight: bold;
  }
  .container-left{
    /* margin-top: 60px; */
  }
  .tomas-sobre-mobile{
    display: none;
  }
  @media (max-width: 992px) {
    height: 890px;
  .tomas-sobre-mobile{
    display: block;
    height: 407px;
    border-radius: 25px;
  }
   .tomas-sobre-desk{
     display: none;
   }
  }
  .tomas-sobre-desk{
    align-self: center;
    border-radius: 25px;
  }
  .alinhamento-info{
    flex-wrap: wrap;
  }
`;
export const ContainerDuvidas = styled.div`

  .collapsed{
      color: #000!important;
  }
  .accordion-button{
    color: orange;
  }
  .titulo-duvidas{
    font-size: 32px;
    color: #000;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
    span{
      font-weight: bold;
      
    }
  }
`;
