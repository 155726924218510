import styled from "styled-components";

export const CloseButton = styled.div`
    position: absolute;
    top: -9px;
    right: 7px;
    cursor: pointer;
    font-size: 31px;
    color: #000;
`;

export const LojaStyle = styled.div`
  position: relative;
  background: #efeeed;
  width: 100%;
  height: 100%;
  /* margin-bottom: 4rem; */
  .back {
    color: var(--fontColor);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 2rem;
    @media (max-width: 770px) {
      top: -3rem;
      left: 1rem;
    }
    .bi-arrow-left {
      margin-right: 1rem;
      font-size: 2rem;
    }
  }
  .modal-erro-cadastro-loja{
    top: 10vh;
  }
`;
