import styled from 'styled-components'

export const MeusDadosCampos = styled.div`
    /* background: #EFEEED; */
    .container-img-prev{
        height: 14rem;
        margin-bottom: 30px;
    }
        .img-prev{
            position: absolute;
            height: 12rem;
            border-radius: 100%;
            @media (max-width:992px) { 
                height: 10rem;
            }
            @media (min-width: 768px) { 
                /* left: 10%; */
            }
        }
        .input-img{
            margin: 0;
            height: 12rem;
            cursor: pointer;
            position: absolute;
            opacity: 0;
            left: 0;
            input{
                height: 12rem;
            }
        }
        @media (max-width: 768px) { 
            padding-top: 0;
        }
        .img-invalid{
            position: absolute;
            top: 12rem;
        }
        .formatos-imgs{
            margin-top: 13rem;
            position: absolute;
            width: 100%;
            @media (max-width:992px) { 
                margin-top: 11rem;
            }
        }
    
    
    .container-campos{
        @media (min-width: 768px) { 
            margin-left: 50px;
        }
        .titulo-page{
            padding-bottom: 34px;
            h2{
                @media (max-width: 768px) { 
                    /* margin-top: 17rem; */
                }
               // margin-top: 105px;
                /* margin-bottom: 35px; */
            }
            span{
                color: #989898;
                font-size: 14px;
            }
            @media (max-width:992px) { 
                text-align: center;
            }
        }
        .select-perguntas{
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
            max-width: 332px;
            @media (max-width: 768px) { 
                min-width: 270px;
            }
        }
        .info-senha{
            font-size: 14px;
            color: #313131;
            margin-bottom: 35px;
        }
        .checkbox-regulamentos{
            font-size: 12px;
            margin-left: 25px;
            a{
                color: #58595B;
            }
        }
        .busca-cep{
            position: absolute;
            right: 0;
            top: -2rem;
            }
        .pass-hide-cadastro{
            position:absolute;
            font-size: 1.2rem;
            top:2.4rem;
            right:2rem;
        }
    }
    .img-usuario{
        margin-top:212px;
        margin-right:60px;
        @media (max-width: 768px) { 
            margin: 0;
            margin-bottom: 70px;
         }
    }
    .container-principal{
        @media (max-width: 768px) { 
            justify-content: center;
         }
    }
    .navbar-meus-dados{
      //  display:none!important;
    }
    .titulo-page{
        display: block;
        @media (max-width: 992px) { 
            display: none;
         }
    }
    .titulo-page-mobile{
        display: none;
        @media (max-width: 992px) { 
            display: block;
            margin-top: 23px;
            margin-bottom: 50px;
         }
    }
    .align-inputs{
        @media (max-width: 992px) { 
            flex-direction: column;
         }
    }
    .titulo-meu-perfil{
        display:none;
        @media (max-width: 992px) { 
            display: block;
         }
    }
 
`
export const FooterStyle = styled.div`
margin-right: -15px;
`
export const BodyModal = styled.div`
    background: var(--background-escuro);
    height: 100vh;

    .info-telefone{
        font-size: 14px;
        color: #fff;
    }
    .body-card{
        @media (min-width: 992px) { 
            width: 28rem;
        }
        
    }
    .icon-size{
        font-size: 4rem;
    }
`
export const MenuChange = styled.div`
    /* position: absolute; */
    .list-group{
         background: white; 
         border-radius: 5px;
         padding-bottom: 30px;
    }
    .list-group-item{
        border: none;
        cursor: pointer;
    }
    .list-group-item.active{
        background-color: #fff;
        color: green;
        border-left: 4px solid green;
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
    }
    @media (max-width:992px) { 
                display: none;
    }
`;

export const ButtoesNavMobile = styled.div `
    display: flex;
    justify-content: center;
    @media (min-width:992px) { 
        display: none;
    }
    div{
        height: 100px;
        width: 100px;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        border-radius: 5px;
        margin-right: 4px;
        margin-left: 4px;
        background: #fff;
    }
    div.active{
        background: var(--verde);
        color:white;
    }
`;

export const ModalSucesso = styled.div`
    padding-bottom: 40px;
  .modal-sucesso {
    width: 552px;
    max-width: 250px;
    height: 100%;
    max-height: 458px;
    margin: 0 auto;
    .img-sucesso {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .h2-sucesso {
      font-size: 24px;
      line-height: 32px;
      padding-top: 25px;
      padding-bottom: 11px;
      color: #313131;
      margin-bottom: 100px;
    }
    .mensagem-sucesso {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 52px;
    }
  }
`;