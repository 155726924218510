import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle` :root {
    --background: #EFEEED;
    --fontColor: #58595B;
    --laranja: #F58420;
    --verde: #9CCB3D;
    --cinza:#58595B;
    --background-escuro: #313131;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {

    @media (max-width: 1080px) {
        font-size: 93.75%;
    }

    @media (max-width: 720px) {
        font-size: 87.5%;
    }
}
.meu-perfil-blackfriday{
    background: rgb(239, 238, 237);
}
col {
    padding: 0;
}

body {
    /* background: var(--background); */
    -webkit-font-smoothing: antialiased;
    color: var(--fontColor);

    /* @media (max-width): 720px){
        
        } */
    @media (min-width: 768px) {
        //           overflow-x: hidden;
        //          overflow-y: hidden;
    }
}

body,
input,
textarea,
button {
    font-family: 'Epilogue', sans-serif;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
    font-weight: 700;
}

.shadow {
    box-shadow: 0 0 1rem rgb(0 0 0 / 15%);
}

.bd-radius {
    border-radius: 0.5rem;
}

.btn-laranja {
    background: var(--laranja) !important;
    border: none;
}

.btn-verde {
    background: var(--verde) !important;
    border: none;
}
.btn-cinza {
    background: var(--cinza) !important;
    border: none;
}

.btn-laranja-outline {
    color: var(--laranja) !important;
    background: transparent;
    border: 1px solid var(--laranja) !important;

    &:hover {
        color: white !important;
        background: var(--laranja) !important;
    }
}
.btn-verde-outline {
    color: var(--verde) !important;
    background: transparent;
    border: 1px solid var(--verde) !important;

    &:hover {
        color: white !important;
        background: var(--verde) !important;
    }
}
.offcanvas-body {
    background-color: #fff !important;
    border-top: 1px solid #fff !important;
    
    .nav-link {
        color: #15151F!important;
        @media (max-width: 992px) { 
        font-size: 16px!important;
     }
    }
    .active{
        border-bottom: 1px solid #EF4E23!important;
        color: #EF4E23!important;
        width: max-content;
    }
    .navbar-nav{
        line-height: 30px;
    }
}

#offcanvasNavbar{
    #campanhas{
        .notificacao-icon{
            left: 85px;
            width: 24px;
            height: 24px;
            background-color: red;
            position: absolute;
            cursor: pointer;
            border-radius: 50%;
            top: -6px;
            z-index: 4;
            text-align: center;
            border: 2px solid rgb(255, 255, 255);
            color: white;
            box-shadow: rgba(0, 0, 0, 0.1) -3px 1px 6px 0px;
            p{
                margin-top: 3px;
                font-size: 12px;
            }
        }
    }
}
.btn-verde1 {
    background: var(--verde) !important;
    border: none;
}

.btn-disable {
    background: #fff;
    border: none;
    color: #000;
}

.btn-simple {
    padding: 0;
    border: none !important;
    color: var(--fontColor) !important;
    background: none !important;
    font-size: 14px;
}

.z-index-top {
    z-index: 10 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.background-dialog {
    background: #000;
    opacity: 0.8 !important;
}

.modal-css {
    h3 {
        color: var(--laranja);
        margin-top: 7vh;
    }

    h5,
    a {
        color: var(--laranja);
    }
}

.modal-css-erro {
    h3 {
        color: var(--laranja);
        margin-top: 7vh;
    }

    h5,
    a {
        color: var(--laranja);
    }

    .modal-erro-cadastro-loja {
        top: 50%;
    }
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.pass-hide {
    position: absolute;
    font-size: 1.2rem;
    top: 2rem;
    right: 2rem;
}

.icon-large {
    font-size: 1.5rem;
}`;