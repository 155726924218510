import styled from "styled-components";

export const Home = styled.div`
	/* background: #efeeed; */
	.space-fale-conosco {
		height: 4rem;
		@media (max-width: 770px) {
			height: 8rem;
		}
	}
`;

export const ListaAmigosConvidadosHistorico = styled.div`
	text-align: left;
	padding-top: 7vh;
	padding-bottom: 7vh;
	.container-lista-amigos{
		.text-aceite{
			color: green;
		}
	}
	
`;



export const TopoConvideUmAmigo = styled.div`
	margin-top: 50px;
	color: #313131;

	.titulo {
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 32px;
	}

	.texto-como-fazer {
		font-size: 14px;

		.paragrafo {
			margin-bottom: 22px;
		}
	}

	.btn-ver-amigos {
		width: 100%;
		max-width: 261px;
		font-size: 14px;
		height: 45px;
		border-color: #e68220;
		color: #f58420;
		padding-top: 10px;
		margin-top: 15px;
		transition: 0.2s;
		&:hover {
			background: #f58420;
			color: #fff;
		}
	}

`;

export const RegrasConvideAmigo = styled.div`
	padding-top: 7vh;
	padding-bottom: 7vh;
`;
