import styled from 'styled-components'

export const NavBarPrincipalPrimeiroAcesso= styled.div`
    .logo-nav-bar{
        width: 108px;
        height: 45px;
    }
`



