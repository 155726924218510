import React, { useState, useEffect } from "react";
import { ProgressoNivel, Etapas } from "./styles";
import Top from "../../images/img/icon-ranking-top-ativo.svg";
import Topper from "../../images/img/icon-ranking-topper-inativo.svg";
import TopperAtivo from "../../images/img/icon-nivel-topper-active.svg";
import Topissimo from "../../images/img/icon-ranking-topissimo-inativo.svg";
import TopissimoAtivo from "../../images/img/icon-nivel-topissimo-active.svg";
import TopGalaxias from "../../images/img/icon-ranking-megatop-inativo.svg";
import TopGalaxiasAtivo from "../../images/img/icon-nivel-topgalaxias-active.svg";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";

export function ConquistasUsuario({ user, childToParent }) {
	// return (
	// 	<>
	// 		<span>salve simpatia</span>
	// 	</>
	// )
	const [nivelUsuarioVendedor, setNivelUsuarioVendedor] = useState('0%')
	const [nivelPrincipalUsuario, setNivelPrincipalUsuario] = useState(1)
	const [semanasAcessadas, setSemanasAcessadas] = useState([])
	const [ConquistasUsuario, SetConquistasUsuario] = useState({
		conquistas: [
			{
				descricao: "",
				icone: "",
				percentualExecucao:
				{
					inicio: '',
					atual: '',
					fim: '',
					dados: [[
						'',
						''
					]]
				},
				status: "",
				titulo: "",
			},
			{
				descricao: "",
				icone: "",
				percentualExecucao:
				{
					inicio: '',
					atual: '',
					fim: ''
				},
				status: "",
				titulo: "",
			},
			{
				descricao: "",
				icone: "",
				percentualExecucao:
				{
					inicio: '',
					atual: '',
					fim: ''
				},
				status: "",
				titulo: "",
			},
			{
				descricao: "",
				icone: "",
				percentualExecucao:
				{
					inicio: '',
					atual: '',
					fim: ''
				},
				status: "",
				titulo: "",
			}
		]
	});

	const VerificarNivelVendedor = (e) => {
		if (e === 1) {
			setNivelUsuarioVendedor(`0%`)
		}
		else if (e === 2) {
			setNivelUsuarioVendedor(`33%`)
		} else if (e === 3) {
			setNivelUsuarioVendedor(`66%`)
		} else if (e === 4) {
			setNivelUsuarioVendedor(`100%`)
		}
	}

	const ObterConquista = (id) => {

		api
			.post(rotasApi.ObterConquistas)
			.then((result) => {
				SetConquistasUsuario(result.data.conquistas_usuario)
				setSemanasAcessadas(result.data.conquistas_usuario.conquistas[0].percentualExecucao.dados[0])
				VerificarNivelVendedor(result.data.conquistas_usuario.nivel)
				setNivelPrincipalUsuario(result.data.conquistas_usuario.nivel)
				childToParent(result.data.conquistas_usuario.nivel)
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	useEffect(() => {
		ObterConquista()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ProgressoNivel className="rounded p-0">
				<div className="nivel-atual-user">
					{user.cargo.descricao !== 'GESTOR DA INFORMACAO' ? <p className="title-nivel-user">
						Você está no nível:
						{nivelPrincipalUsuario === 1 ? <span className="nivel-usuario">  Top</span>
							:
							nivelPrincipalUsuario === 2 ? <span className="nivel-usuario">  Topper</span>
								:
								nivelPrincipalUsuario === 3 ? <span className="nivel-usuario">  Topíssimo</span>
									:
									nivelPrincipalUsuario === 4 ? <span className="nivel-usuario">  MegaTop</span> : ('')}
					</p> : <div className="d-flex justify-content-between mb-2">
						<h6>GI 5 estrelas</h6>
						<p className="title-nivel-user">
							Nível Atual: 
							{nivelPrincipalUsuario === 1 ? <span className="nivel-usuario gi"> 1 Estrela</span>
								:
								nivelPrincipalUsuario === 2 ? <span className="nivel-usuario"> 2 Estrelas</span>
									:
									nivelPrincipalUsuario === 3 ? <span className="nivel-usuario"> 3 Estrelas</span>
										:
										nivelPrincipalUsuario === 4 ? <span className="nivel-usuario"> 4 Estrelas</span> : ('')}
						</p>
					</div>}
					<div className="eight columns progress-container mb-4">
						<div className="progressBarcontainer">
							<div
								className="progressBarValue"
								style={{
									width: `${nivelUsuarioVendedor}`
								}}
							></div>
						</div>

						{user.cargo.descricao !== 'GESTOR DA INFORMACAO' ?
							<div className="niveis-progressbar">
								<div>
									<img
										src={Top}
										alt="Ícone Ranking TOP"
										className="icone-raking"
									/>
								</div>
								<div className="nivel-alcancado">
									{nivelPrincipalUsuario >= 2 ?

										<img
											src={TopperAtivo}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/> : <img
											src={Topper}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 3 ?
										<img
											src={TopissimoAtivo}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/> :
										<img
											src={Topissimo}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 4 ?
										<img
											src={TopGalaxiasAtivo}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/> :
										<img
											src={TopGalaxias}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/>}
									{/*  */}
								</div>
							</div> : <div className="niveis-progressbar">
								<div>
									<img
										src={TopperAtivo}
										alt="Ícone Ranking TOP"
										className="icone-raking"
									/>
								</div>
								<div className="nivel-alcancado">
									{nivelPrincipalUsuario >= 2 ?

										<img
											src={TopperAtivo}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/> : <img
											src={Topper}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 3 ?
										<img
											src={TopperAtivo}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/> :
										<img
											src={Topper}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 4 ?
										<img
											src={TopperAtivo}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/> :
										<img
											src={Topper}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/>}
								</div>
								<div>
									{nivelPrincipalUsuario >= 5 ?
										<img
											src={TopperAtivo}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/> :
										<img
											src={Topper}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/>}
								</div>
							</div>}
						{user.cargo.descricao !== 'GESTOR DA INFORMACAO' ? <div className="niveis-progressbar mt-4">

							<p>Top</p>

							<p>Topper</p>

							<p>Topíssimo</p>

							<p>MegaTop</p>

						</div> : <p className="text-conquista-gi">Chegue ao nível 5 estrelas,<br /> concluindo os desafios!</p>}
					</div>
				</div>
			</ProgressoNivel>
			<Etapas className="p-0">
				<div className="task-section">
					{ConquistasUsuario.conquistas[0].titulo === 'Acesso Semanal' ? <div className="task-card-container">
						<div className="task-card-conteudo">
							<div className="icon-task">
								{<img src={ConquistasUsuario.conquistas[0].icone} alt="" />}
							</div>
							<div className="task-info">
								<div className="task-text">
									<h2 className="task-name">{ConquistasUsuario.conquistas[0].titulo}</h2>
									<p className="task-data">{ConquistasUsuario.conquistas[0].status}</p>
								</div>
								<div className="task-nivel">

									{
										(ConquistasUsuario.conquistas[0].percentualExecucao.fim >= 1 ?
											(semanasAcessadas.includes(1) ? <div className="task-nivel-circulo completado"></div> :
												(ConquistasUsuario.conquistas[0].percentualExecucao.atual > 1 ? <div className="task-nivel-circulo faltou"></div> : <div className="task-nivel-circulo"></div>)
											) : (''))
									}


									{
										(ConquistasUsuario.conquistas[0].percentualExecucao.fim >= 2 ?
											(semanasAcessadas.includes(2) ? <div className="task-nivel-circulo completado"></div> :
												(ConquistasUsuario.conquistas[0].percentualExecucao.atual > 2 ? <div className="task-nivel-circulo faltou"></div> : <div className="task-nivel-circulo"></div>)) : (''))
									}

									{
										(ConquistasUsuario.conquistas[0].percentualExecucao.fim >= 3 ?
											(semanasAcessadas.includes(3) ? <div className="task-nivel-circulo completado"></div> :
												(ConquistasUsuario.conquistas[0].percentualExecucao.atual > 3 ? <div className="task-nivel-circulo faltou"></div> : <div className="task-nivel-circulo"></div>)
											) : (''))
									}

									{
										(ConquistasUsuario.conquistas[0].percentualExecucao.fim >= 4 ?
											(semanasAcessadas.includes(4) ? <div className="task-nivel-circulo completado"></div> :
												(ConquistasUsuario.conquistas[0].percentualExecucao.atual > 4 ? <div className="task-nivel-circulo faltou"></div> : <div className="task-nivel-circulo"></div>)
											) : (''))
									}

									{
										(ConquistasUsuario.conquistas[0].percentualExecucao.fim >= 5 ?
											(semanasAcessadas.includes(5) ? <div className="task-nivel-circulo completado"></div> :
												(ConquistasUsuario.conquistas[0].percentualExecucao.atual >= 5 ? <div className="task-nivel-circulo faltou"></div> : <div className="task-nivel-circulo"></div>)
											) : (''))
									}

								</div>
								<div>
									<p className="task-sinopse">
										{ConquistasUsuario.conquistas[0].descricao}
									</p>
								</div>
							</div>
						</div>
					</div> : ('')}

					{ConquistasUsuario.conquistas.map(({ descricao, icone, status, titulo }, index) => titulo !== 'Acesso Semanal' && (

						<div className="task-card-container" key={index}>
							<div className="task-card-conteudo">
								<div className="icon-task">
									<img src={icone ? icone : ''} alt="" />
								</div>
								<div className="task-info">
									<div className="task-text">
										<h2 className="task-name">{titulo ? titulo : ''}</h2>
										<p className="task-data">{status ? status : ''}</p>
									</div>
									<div className="task-nivel">
										<div className="progressBarcontainer">
											<div
												className="progressBarValue"
												style={{
													width: (((ConquistasUsuario.conquistas[index].percentualExecucao? ConquistasUsuario.conquistas[index].percentualExecucao.atual : '' ) / (ConquistasUsuario.conquistas[index].percentualExecucao ? ConquistasUsuario.conquistas[index].percentualExecucao.fim : '')) * 100 + '%')
												}}
											></div>
										</div>
									</div>
									<div>
										<p className="task-sinopse">
											{descricao ? descricao : ''}
										</p>
									</div>
								</div>
							</div>
						</div>
					)
					)}
				</div>
			</Etapas>
		</>
	);
}
