import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { rotasApi } from "../services/rotasApi";

export const initialState = {
  obterDadosCadastraisLoading: false,
  obterDadosCadastraisErrors: false,
  obterDadosCadastraisMessage: "",
  obterDadosCadastraisState: "",
};

const obterDadosCadastraisSlice = createSlice({
  name: "obterDadosCadastrais",
  initialState,
  reducers: {
    obterDadosCadastraisLoading: (state) => {
      state.obterDadosCadastraisLoading = true;
    },
    obterDadosCadastraisSuccess: (state, { payload }) => {
      state.obterDadosCadastraisState = payload;
      state.obterDadosCadastraisLoading = false;
      state.obterDadosCadastraisErrors = false;
    },
    obterDadosCadastraisFailure: (state, { payload }) => {
      state.obterDadosCadastraisMessage = payload
      state.obterDadosCadastraisLoading = false;
      state.obterDadosCadastraisErrors = true;
      state.obterDadosCadastraisState = "";
    },
    obterDadosCadastraisReset: (state) => {
      state.obterDadosCadastraisLoading = false
      state.obterDadosCadastraisErrors = false
      state.obterDadosCadastraisMessage = ""
      state.obterDadosCadastraisState = ""
    }
  },
});

export default obterDadosCadastraisSlice.reducer;

export const obterDadosCadastraisSelector = (state) => state.obterDadosCadastrais;

export const { obterDadosCadastraisLoading, obterDadosCadastraisSuccess, obterDadosCadastraisFailure, obterDadosCadastraisReset } =
  obterDadosCadastraisSlice.actions;

export function obterDadosCadastraisAction(cpf) {

  return async (dispatch) => {

    api
      .get(rotasApi.obterDadosCadastrais + cpf, {
        headers: {
          "frontmt": "89u034r897487990u8i43908i45908i453e90i0-9oi5409986",
          "SkipAuth": "true"
        },

      })
      .then((result) => {
        console.log('result obter cadastro >', result.data);
        if (!result.data.sucesso) {
          dispatch(
            obterDadosCadastraisFailure(result.data.mensagem ? result.data.mensagem : "")
          );
        } else {
          if (result.data.mensagem === "Usuário encontrado") {
            console.log(result.data);

          }
          dispatch(obterDadosCadastraisSuccess(result.data));
        }
      })
      .catch((error) => {
        console.log('error >', error)
        dispatch(
          obterDadosCadastraisFailure(
            error.response.data.mensagem
              ? error.response.data.mensagem
              : "Ocorreu um erro inesperado. Feche o aplicativo e abra novamente."
          )
        );
      });
  };
}
