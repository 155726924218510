import styled from "styled-components";

export const ProgressoNivel = styled.div`
	background: #efeeed;
	.nivel-atual-user {
		background: #fff;
		border: 1px solid #e2e2e2;
	}
	.nivel-atual-user,
	.task-card-container {
		position: relative;
		padding: 13px;
		border-radius: 5px;

		.progressBar {
			margin-bottom: 26px;
			margin-bottom: 1.66em;
			position: relative;
		}

		.progressBarcontainer {
			width: 100%;
			height: 5px;
			background: #e2e2e2;
			overflow: hidden;
			border-radius: 5px;
		}

		.progressBarValue {
			height: 5px;
			float: left;
			border-radius: 5px;
			background: #9ed530;
		}

		.progressBarValueGerenteDeLoja {
			height: 5px;
			float: left;
			border-radius: 5px;
			background: #FBC81E;
		}

		.speech-bubble {
			font-size: 0.75em;
			line-height: 2em;
			position: absolute;
			top: -0.6em;
			text-align: center;
			min-width: 3em;
			border-radius: 0.3em;
			color: white;
			display: none;
		}

		.speech-bubble:after {
			border: 0.5em solid transparent;
			content: "";
			margin-left: -0.5em;
			position: absolute;
			bottom: -50%;
			left: 50%;
		}

		.html {
			left: calc(88.5% - 1.5em);
		}

		.html:after {
			border-top-color: #a487b2;
		}

		.progress-container {
			position: relative;
		}

		.speech-bubble {
			font-size: 0.75em;
			line-height: 2em;
			position: absolute;
			top: -0.6em;
			text-align: center;
			min-width: 3em;
			border-radius: 0.3em;
			color: white;
			display: none;
		}

		.niveis-progressbar {
			display: flex;
			position: absolute;
			width: 100%;
			justify-content: space-between;
			top: -10px;
			div{
				&.nivel-alcancado{
					filter: hue-rotate(var(--value, 360deg)); --value:363deg;
				}
			}

			.icone-raking {
				width: 20px;
			}

			p {
				font-size: 12px;
				width: 60px;
				margin-top: 5px;
				margin-right: -10px;
			}
			img {
				&.nivel-alcancado{
					filter: hue-rotate(var(--value, 360deg)); --value:363deg;
				}
			}
			
		}
		.text-conquista-gi{
			margin-bottom: -21px;
    		margin-top: 20px;
			color: #989898;
			}

		.descricao-niveis {
			font-size: 12px;
			margin-left: 12px;
			margin-top: -8px;
			position: relative;
			top: 25px;
			left: -10px;
		}

		.title-nivel-user {
			margin-bottom: 12px;
			font-size: 12px;
			@media (max-width: 370px) {
				font-size: 10px;
			}
			// margin-bottom: 16px;
			.nivel-usuario {
				color: #9ed530;
				&.gi{
					color: #000;
					font-weight: 700;
				}
			}

			.nivel-atual {
				color: #989898;
			}

			.qtdEstrelas {
				color: #000;
			}
			
		}
	}
	.ft-12{
		font-size: 12px;
	}
`;
export const Etapas = styled.div`
	/* display:none; */
	.fundo-branco {
		background: #fff;
	}
	.icon-task {
		border: 1px solid #e2e2e2;
		border-radius: 5px;
		display: grid;
		justify-content: center;
		align-content: center;
		padding: 0;
		margin: 0;
		height: 50px;
		width: 52px;
	}
	.task-section {
		.task-card-container {
			background: #fff;
			margin-top: 18px;
			border: 1px solid #e2e2e2;
			border-radius: 5px;
			padding: 0px;

			.progressBarcontainer {
				width: 100%;
				height: 5px;
				background: #e2e2e2;
				overflow: hidden;
				border-radius: 5px;
				margin-bottom: 6px;
			}

			.progressBarValue {
				height: 5px;
				float: left;
				border-radius: 5px;
				background: #f58420;
			}

			.task-card-conteudo {
				display: flex;
				padding: 15px;

				.task-info {
					height: 60px;
					width: 100%;
					// display: flex;
					.task-text {
						display: flex;
						justify-content: space-between;
						margin-left: 12px;
						height: 21px;

						.task-name {
							color: $laranja;
							font-weight: bolder;
							font-size: 14px;
						}

						.task-data {
							font-size: 12px;
							margin-top: 3px;
							color: #989898;
						}
					}

					.task-sinopse {
						font-size: 12px;
						margin-left: 12px;
						color: #989898;
					}

					.task-nivel {
						margin-left: 12px;
						display: flex;
					}
					.task-nivel-circulo {
						width: 12px;
						height: 12px;
						background: #e2e2e2;
						margin-right: 7px;
						border-radius: 100%;
						margin-bottom: 5px;
						&.completado {
							background:#9CCB3D;
						}
						&.faltou {
							background:red;
						}
					}
					
				}
			}

			.progressBar {
				margin-bottom: 0px;
			}
		}
	}
`;
